import './body.css';
import React, { useState } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import client from './graphql/client';
import WebSocketTransport from '@cubejs-client/ws-transport';
import AppContext from './components/AppContext';
import Routes from './routes/Routes';
import AppContextComponent from "./components/AppState";
import { useTranslation } from 'react-i18next';
// import '@ant-design/compatible';
// import 'antd/dist/antd.css';

import "react-datetime/css/react-datetime.css";
// core styles
import "./scss/volt.scss";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import "react-datetime/css/react-datetime.css";


const API_URL = `${process.env.REACT_APP_CUBESERVERURL}`;
// const CUBEJS_TOKEN =
//   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MTc3ODAzMTUsImV4cCI6MTYxNzg2NjcxNX0.wW0M8TFODq5q_6S8HvKX9UrE4OtULqPXYdTfL4jE51M';




const jwt = require('jsonwebtoken');
const CUBE_API_SECRET = 'secret';

//
const cubejsApi = cubejs(
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJteWFwcGlkIjozLCJpYXQiOjE2MTgyMjkzNDIsInVzZXJfaWQiOjM4MDgxLCJwbGF0Zm9ybV9pZCI6MSwic2VydmljZV9pZCI6OCwiY29tcGFueV9pZCI6OTM0NX0.lYSmPrF55TH3y8HQk96khmXn08XBt5nkUh7vzxfyak4',
  { apiUrl: `/cubejs-api/v1` }
);
/*const cubejsApi = cubejs({
  transport: new WebSocketTransport({
   authorization: cubejsToken,
   // apiUrl: API_URL.replace('http', 'ws'),
  }),
});*/

const App = ({ children }) => {
  const { t, i18n } = useTranslation();
  

  return (
    <div>
      <CubeProvider cubejsApi={cubejsApi}>
      
        <ApolloProvider client={client}>
          {/* <AppContext.Provider value={settings}> */}
          <AppContextComponent>
            <Routes />
            {/* </AppContext.Provider> */}
          </AppContextComponent>
          {/* <AppLayout> <AppContext.Provider value={userSettings}>{children} </AppContext.Provider></AppLayout> */}

        </ApolloProvider>
      </CubeProvider>
    </div>
  );
};

export default App;
