import { Row, Col, Card  } from '@themesberg/react-bootstrap';
import React, { useEffect, useState,useContext } from 'react';
import { useTranslation } from 'react-i18next';
function AppStatisticsPages() {
    const [t, i18n] = useTranslation();
    
    return (
        <div className="px-3">
        <Row className="mb-3">
            <Col lg={12} xl={4}>
                <h2 className="h2 mb-0">{t("header.AppStatisticsTopHeader")}</h2>
            </Col>
        </Row>
        <Row className="mb-3">
                <Col lg={12}>
                    <div className="bg-white shadow-soft border rounded border-light p-4 w-100">
                        <h6 className="h6">{t("header.AppsMarketingContent2")}</h6>
                        <p>
                            <em>{t("header.MarketingQuestion")}</em> {t("header.AppsMarketingContent3")}
                                                    </p>

                        <h6 className="h6">{t("header.AppsMarketingContent4")}</h6>
                        <p>
                            <em>{t("header.MarketingQuestion")}</em> {t("header.AppsMarketingContent5")}
                        </p>

                        <h6 className="h6">{t("header.AppsMarketingContent6")}</h6>
                        <p className="mb-0">
                            <em>{t("header.MarketingQuestion")}</em>{t("header.AppsMarketingContent7")} 
                        </p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default AppStatisticsPages