
import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { useMutation } from '@apollo/react-hooks';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Row, Col, Button, Form, InputGroup, FormCheck } from '@themesberg/react-bootstrap';
import Loader from './VoltLoader';
import DropdownWithIconComponent from "./DropdownWithIcons";
import { isMobile } from "react-device-detect";
import { withRouter } from "react-router";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faChevronLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import { DEFAULT_WEBSITE_DASHBORD, DEFAULT_PROFILE_DASHBORD, object_equals, NULL_VALUE_MESSAGE_DROPDOWN } from "../AppConfig";
import { dashbordDateRangeList } from "../utils/datetime";
import ButtonComponent from "./Buttons";
import AppContext from './AppContext';
import { useTranslation } from 'react-i18next';
import CommonHeader from './CommonHeader';
import { apiChartsCookie, apiChartsPages, getCookieChart, getPagesChart } from '../stubs/ComponentJson/Pages';
import { defaultLayout, defaultLayoutMobile } from "../utils/layoutConfig";
import DashboardItem from './DashboardItem';
import ChartRenderer from './ChartRenderer';
import SummaryRenderer from './SummaryRenderer';
import MultilineToggleComponent from './MultilineToggleComponent';
import ApiChartRenderer from './ApiChartRenderer';
import request from '../apis/request';
import { METHODS, baseURL } from '../apis/utilities/constant';
import { getWebsiteURL } from '../utils/common';
import { decryptData } from '../utils/crypto';

const ReactGridLayout = WidthProvider(RGL);

const deserializeItem = (i) => ({
  ...i,
  layout: i.layout ? JSON.parse(i.layout) : {},
  summary: i.summary ? JSON.parse(i.summary) : {},
  layoutone: i.layoutone ? JSON.parse(i.layoutone) : {},
  layouttwo: i.layouttwo ? JSON.parse(i.layouttwo) : {},
  vizStateSecond: i.vizStateSecond ? JSON.parse(i.vizStateSecond) : {},
  vizState: i.vizState ? JSON.parse(i.vizState) : {},
  isResultShownFromApi: i.isResultShownFromApi ? true : false,
  chartType: i.chartType ? i.chartType : undefined,
  isSummeryShown: i.isSummeryShown ? i.isSummeryShown : false
});

const CookiePerformanceChart = ({ selectedProfileId, selectedWebsiteId }) => {
  const [d3, setd3] = useState();
  const contextData = useContext(AppContext);
  const [chartPerformance, setChartPerformance] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const [t, i18n] = useTranslation();

  const [cookieAddData, setCookieAddData] = useState(undefined);

  useEffect(() => {
    setCookieAddData(undefined);
  }, [])

  useEffect(() => {
    const url = window.location.href.split("/");
    const urlEndpoints = `${url[0]}//${url[2]}/`;
    const selectedstartdate = moment(contextData.startDate).format("YYYY-MM-DD");
    const selectedenddate = moment(contextData.endDate).format("YYYY-MM-DD");
    const selectedPreviousYearStartDate = moment(contextData.startDate).add(-1, 'years').format("YYYY-MM-DD");
    const selectedPreviousYearEndDate = moment(contextData.endDate).add(-1, 'years').format("YYYY-MM-DD");

    let dta;
    if (contextData.selectedProfile["WebsiteProfiles.ProfileId"] !== undefined) {
      dta = ((contextData.selectedProfile["WebsiteProfiles.ProfileId"] !== undefined && selectedstartdate !== "Invalid date" && selectedenddate !== "Invalid date" && selectedPreviousYearStartDate !== "Invalid date" && selectedPreviousYearEndDate !== "Invalid date"))
        ? getCookieChart(contextData.selectedProfile["WebsiteProfiles.ProfileId"], selectedstartdate, selectedenddate, selectedPreviousYearStartDate, selectedPreviousYearEndDate)
        : null;
    } else {
      dta = (selectedstartdate !== "Invalid date" && selectedenddate !== "Invalid date" && selectedPreviousYearStartDate !== "Invalid date" && selectedPreviousYearEndDate !== "Invalid date")
        ? getCookieChart(contextData.selectedProfile["WebsiteProfiles.ProfileId"], selectedstartdate, selectedenddate, selectedPreviousYearStartDate, selectedPreviousYearEndDate)
        : null;
    }

    setChartPerformance(dta);
  }, [contextData.selectedProfile, contextData.selectedProfile, contextData.startDate, contextData.endDate]);

  useEffect(() => {
    setCookieAddData(undefined);
    let profile = JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId"));
    let websiteUrl = undefined;
    if (profile) {
      let w = getWebsiteURL();
      websiteUrl = w?.WebsiteUrl;
    }
      let website = websiteUrl === "https://tokmanni-v2.stage-euw1.investis.com/" ? "https://ir.tokmanni.fi/" : websiteUrl;
      website = websiteUrl === "https://inspecs-group-plc-v2.pid2-e1.investis.com/" ? "https://www.inspecs.com/" : websiteUrl;
    const selectedstartdate = moment(contextData.startDate).format("YYYY-MM-DD");
    const selectedenddate = moment(contextData.endDate).format("YYYY-MM-DD");

    if (website) {
      request(
        baseURL + `/CookieManagerV2/GetCookieAdditionalData?WebsiteURL=${website}&StartDate=${selectedstartdate}&EndDate=${selectedenddate}`,
        METHODS.GET,
        {},
        { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json" }
      )
        .then((response) => {
          setCookieAddData(response?.result);

        })
        .catch((error) => {
          setCookieAddData(undefined);
          //callBackFn && callBackFn(undefined, payload.id);
        })
    }
  }, [contextData.selectedProfile, contextData.selectedProfile, contextData.startDate, contextData.endDate])

  const dashboardItem = (item) => (
    <div key={item.id} className={item.classname} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
      {(item.isResultShownFromApi && item.chartType) ?
        <DashboardItem
          hideTitle={true}
          key={item.id}
          itemId={item.id}
          title={item.name}
          enableOption={item.enableOption}>
          <Row>
            <Col>
              {/* {Object.keys(item.summary).length > 0 && <SummaryRenderer vizState={item.summary} title={item.name} />} */}
              <ApiChartRenderer cookieAddiData={cookieAddData} cardDetails={item.name} isSummeryShown={item.isSummeryShown} services={apiChartsCookie} chartType={item.chartType} itemId={item.id} cardProperty={Object.keys(item.layoutone).length > 0 ? item.layoutone : item.layout} legends={item.legends} isRefresh={isRefresh} setIsRefresh={setIsRefresh} setd3={setd3}
              // pageName={"cookiesanalytics"}
              />
              {/* {item.extraSummary !== undefined && <div className="switch-toggle-component w-100"><MultilineToggleComponent d3={d3} setd3={setd3} extraSummary={JSON.parse(item.extraSummary)}></MultilineToggleComponent></div>} */}
            </Col>
          </Row>
        </DashboardItem> :
        <DashboardItem
          key={item.id}
          itemId={item.id}
          title={item.name}
          enableOption={item.enableOption}>
          <Row>
            <Col>
              {Object.keys(item.summary).length > 0 && <SummaryRenderer vizState={item.summary} title={item.name} />}
              <ChartRenderer vizState={item.vizState} cardProperty={Object.keys(item.layoutone).length > 0 ? item.layoutone : item.layout} legends={item.legends} isRefresh={isRefresh} setIsRefresh={setIsRefresh} setd3={setd3} />
              {item.extraSummary !== undefined && <div className="switch-toggle-component w-100"><MultilineToggleComponent d3={d3} setd3={setd3} extraSummary={JSON.parse(item.extraSummary)}></MultilineToggleComponent></div>}
            </Col>
          </Row>
        </DashboardItem>}
    </div>
  );

  return (
    <>
      {selectedWebsiteId && (selectedWebsiteId["WebsiteList.WebsiteId"] || selectedProfileId["WebsiteProfiles.ProfileId"]) ?
        <Row>
          <Col>
            <div className="d-inline-block pt-3 ps-3 w-100 cookies-analytics-container">
              {/* <div className="shadow-soft border rounded border-light p-3 ps-2 pe-2 w-100 nested-card-container page-performance-detail"> */}
              <ReactGridLayout cols={12} rowHeight={50}
                isDraggable={false} isResizable={false}
              // onLayoutChange={onLayoutChange}
              >
                {chartPerformance.map(deserializeItem).map(dashboardItem)}
              </ReactGridLayout>
              {/* </div> */}
            </div>
          </Col>
        </Row>
        : <div className="p-3  w-100">
          <div className="bg-white shadow-soft border rounded border-light w-100 fmh-200 position-relative">
            <div className="card-no-data mt-n3">{t("header.NoDataFound")}</div>
          </div>
        </div>
      }
    </>
  );
};

export default withRouter(CookiePerformanceChart);
