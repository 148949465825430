import { Row, Col, Card } from '@themesberg/react-bootstrap';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import '../../i18n';
function WebsiteMarketingPages() {
    const [t, i18n] = useTranslation();
    return (
        <div className="px-3">
            {/* <Row className="mb-3">
                <Col lg={12} xl={12}>
                    <h2 className="h2 mb-0">{t("header.WebsiteMarketingTitle")}</h2>
                </Col>
            </Row> */}
            <Row className="mb-3">
                <Col lg={12}>
                    <div className="bg-white shadow-soft border rounded border-light p-4 w-100">
                        <h2>{t("header.WebsiteMarketingTitle")}</h2>

                        <h5>{t("header.WebsiteMarketingContent1")}</h5>

                        <h5>{t("header.WebsiteMarketingContent2")}</h5>
                        <p className='paragraph'>{t("header.WebsiteMarketingContent3")}</p>
                        <p className='paragraph'>{t("header.WebsiteMarketingContent4")}</p>

                        <h5>{t("header.WebsiteMarketingContent5")}</h5>
                        <p className='paragraph'>{t("header.WebsiteMarketingContent6")}</p>

                        <h5>{t("header.WebsiteMarketingContent7")}</h5>
                        <p className='paragraph'>{t("header.WebsiteMarketingContent8")}</p>

                        <h5>{t("header.WebsiteMarketingContent9")}</h5>
                        <p className='paragraph'>{t("header.WebsiteMarketingContent10")}</p>


                        <>
                            {/* <h6 className="h6">{t("header.WebsiteMarketingContent1")}</h6>
                            <p>
                                <em>{t("header.MarketingQuestion")}</em> {t("header.WebsiteMarketingContent2")}
                            </p>

                            <h6 className="h6">{t("header.WebsiteMarketingContent3")}</h6>
                            <p>
                                <em>{t("header.WebsiteMarketingContent4")}</em> {t("header.WebsiteMarketingContent5")}
                            </p>

                            <h6 className="h6">{t("header.WebsiteMarketingContent6")}</h6>
                            <p className="mb-0">
                            {t("header.WebsiteMarketingContent7")}
                            </p>
                            <p className="mb-0">
                            {t("header.WebsiteMarketingContent8")}
                            </p>
                            <p className="mb-0">
                            {t("header.WebsiteMarketingContent9")}
                            </p>
                            <p className="mb-0">
                            {t("header.WebsiteMarketingContent10")}
                            </p>
                            <p className="mb-0">
                            {t("header.WebsiteMarketingContent11")}
                            </p> */}
                        </>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default WebsiteMarketingPages