import React, { useState } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import DashboardPage from '../pages/DashboardPage';
import FormPage from '../pages/FormsPage';
import WebsitePage from '../pages/WebsitePage';
import LoginPage from '../pages/LgnPage';
import NotificationPage from '../pages/NoficationsPages';
import UserSettingsPage from '../pages/UserSettingsPage';
import AlertStatisPage from '../pages/AlertStatsPage';
import AlertSummaryPage from '../pages/AlertSummaryPage';
import ArchiveAlertsPage from '../pages/ArchiveAlertsPage';
import ManualAlertsPage from '../pages/ManualAlertsPage';
import ManageSubscriptionsPage from '../pages/ManageSubscriptionsPage';
import EventCalenderPage from '../pages/EventCalenderPage';
import ReportsPage from '../pages/ReportsPage';
import WebcastStatisticsPage from '../pages/WebcastStatisticsPage';
import WebcastManageUserListPage from '../pages/WebcastManageUsersPage';
import VisitorAnalyticsPage from '../pages/VisitorAnalyticsPage';
import PagePerformanceAnalytics from '../pages/PageAnalyticsPage';
import VideoAnalyticsPage from '../pages/VideoAnalyticsPage';
import FeedbackAnalyticsPage from '../pages/FeedbackAnalyticsPage';
import EditablePages from '../pages/EditablePages';
import PreviewWebsitePage from '../pages/PreviewWebsitePage';
import MediaLibraryPage from '../pages/MediaLibraryPage';
import AddEventPages from '../pages/AddEventPages';
import UploadDownloadPages from '../pages/UploadDownloadPages';
import SocialMediaPages from '../pages/SocialMediaPages';
import PublishMessagePages from '../pages/PublishMessagePages';
import AppStatisticsPages from '../pages/AppStatisticsPages';
import ManageDocumentsPages from '../pages/ManageDocumentsPages';
import AddDocumentsPages from '../pages/AddDocumentsPages';
import BannerImagePages from '../pages/BannerImagePages';
import ServiceCentrePages from '../pages/ServiceCentrePages';
import SettingsPages from '../pages/SettingsPages';
import AlertsMarketingPage from '../pages/MarketingPages/AlertsmarketingPage';
import AppMarketingPage from '../pages/MarketingPages/AppsMarketingPage';
import SocialMediaMarketingPages from '../pages/MarketingPages/SocialMediaPage';
import ToolsMarketingPages from '../pages/MarketingPages/ToolsMarketingPage';
import WebcastMarketingPages from '../pages/MarketingPages/WebcastMarketing';
import WebsiteMarketingPages from '../pages/MarketingPages/WebsiteMarketing';
import MyAccountPage from '../pages/MyAccountPage';
import MyDocumentsPage from '../pages/MyDocumentsPage';
import DigestSubscriptionsPage from '../pages/DigestSubscriptionsPage';
import UserAnalyticsPage from '../pages/UserAnalyticsPage';
import BackToCMSPages from '../pages/BackToCMSPage';
import SendAdHocAlertPage from '../pages/AdHocAlertPage';
import ClientPreview from '../pages/ClientPreview';
import NotificationPages from '../pages/NotificationPage';
import SearchPage from '../pages/SearchPage';
import CookieAnalyticsPage from '../pages/CookieAnalyticsPage';
import NoAccess from '../pages/MarketingPages/NoAccess';
import ToolsOverview from '../pages/ToolsOverview';
import TnfSharePriceCenter from '../components/TnfAnalytics/TnfSharePriceCenter';
import TnfSharePriceMiniChartPage from '../pages/TnfAnalytics/TnfSharePriceMiniChartPage';
import CorporateAlertServicePage from '../pages/TnfAnalytics/CorporateAlertServicePage';
import DividendCalculatorPage from '../pages/TnfAnalytics/DividendCalculatorPage';
import SSOLogin from '../pages/SSOLogin';
import TnfSharePriceCenterPage from '../pages/TnfAnalytics/TnfSharePriceCenterPage';
import { ToolsOverviewPage } from '../pages/TnfAnalytics/ToolsOverviewPage';
import TFMarketingPage from '../pages/MarketingPages/TFMarketingPage';
import { LargestShareholdersPage } from '../pages/TnfAnalytics/LargestShareholdersPage';
import RegulatoryNewsPage from '../pages/TnfAnalytics/RegulatoryNewsPage';
import OMXNewsPage from '../pages/TnfAnalytics/OMXNewsPage';
import { Insights, InsightsPage } from '../pages/TnfAnalytics/InsightsPage';
import ProcessClientData from '../pages/ProcessClientDataPage';
import TnFNotificationPage from '../pages/TnfAnalytics/NotificationPage'
import { SharePriceTablePage } from '../pages/TnfAnalytics/SharePriceTablePage';
import { HistoricalPriceLookupPage } from '../pages/TnfAnalytics/HistoricalPriceLookupPage';
import { TotalReturnCenterPage } from '../pages/TnfAnalytics/TotalReturnCenterPage';
import { SharePriceCalculatorPage } from '../pages/TnfAnalytics/SharePriceCalculatorPage';
import { ExchangeListPage } from '../pages/TnfAnalytics/ExchangeListPage';
import { OMXFilingsPage } from '../pages/TnfAnalytics/OMXFilingsPage';
import { CisionFilingsPage } from '../pages/TnfAnalytics/CisionFilingsPage';
import { DGAPFilingsPage } from '../pages/TnfAnalytics/DGAPFilingsPage';
import { SECFilingsPage } from '../pages/TnfAnalytics/SECFilingsPage';
import { USPressReleasePage } from '../pages/TnfAnalytics/USPressReleasePage';
import { ASXAnnouncementPage } from '../pages/TnfAnalytics/ASXAnnouncementPage';
import { ModularFinanceNewsPage } from '../pages/TnfAnalytics/ModularFinanceNewsPage';
import { STTNewsPage } from '../pages/TnfAnalytics/STTNewsPage';
import { ReportsAndPresentationsPage } from '../pages/TnfAnalytics/ReportsAndPresentationsPage';

// import ExplorePage from '../pages/ExplorePage';

const Routes = ({ }) => {
    return (
        <Switch>
            <PublicRoute key="processclientdata" path="/processclientdata" component={ProcessClientData} />
            <PublicRoute key="login" path="/login/:token?" component={LoginPage} />
            <PublicRoute key="isSSOLogin" path="/bypasslogin" component={SSOLogin} />
            <PublicRoute key="clientPreview" path="/preview-sso" component={ClientPreview} />
            <PrivateRoute key="loginsso" path="/loginsso.aspx" component={BackToCMSPages} />
            <PrivateRoute key="index" exact path={["/", "/website"]} component={WebsitePage} />
            <PrivateRoute key="dashbord" path="/dashbord" component={DashboardPage} />
            <PublicRoute key="form" path="/form" component={FormPage} />
            <PrivateRoute key="website" path="/website/notification" component={NotificationPages} />
            <PrivateRoute key="settings" path="/user-settings" component={UserSettingsPage} />
            <PrivateRoute key="alt-stats" path="/alert/statistics" component={AlertStatisPage} />
            <PrivateRoute key="alt-summary" path="/alert/summary" component={AlertSummaryPage} />
            <PrivateRoute key="alt-archieve" path="/alert/archieve" component={ArchiveAlertsPage} />
            <PrivateRoute key="alt-archieve" path="/alert/adhocalert" component={SendAdHocAlertPage} />
            <PrivateRoute key="alt-manual-alerts" path="/alert/manual-alerts" component={ManualAlertsPage} />
            <PrivateRoute key="alt-mng-subscription" path="/alert/manage-subscription" component={ManageSubscriptionsPage} />
            <PrivateRoute key="tools-event-calender" path="/tools/event-calender" component={EventCalenderPage} />
            <PrivateRoute key="reports" path="/reports" component={ReportsPage} />
            <PrivateRoute key="web-cast-stats" path="/webcast/manage-statistics" component={WebcastStatisticsPage} />
            <PrivateRoute key="web-cast-users" path="/webcast/manage-users" component={WebcastManageUserListPage} />
            <PrivateRoute key="visitor-analytics" path="/website/visitoranalytics-page/:iswatchlist?" component={VisitorAnalyticsPage} />
            <PrivateRoute key="page-analytics" path="/website/page-analytics-page" component={PagePerformanceAnalytics} />
            <PrivateRoute key="cookie-analytics" path="/website/cookie-analytics-page" component={CookieAnalyticsPage} />
            <PrivateRoute key="video-analytics" path="/video-analytics-page" component={VideoAnalyticsPage} />
            <PrivateRoute key="feedback-analytics" path="/feedback-analytics-page" component={FeedbackAnalyticsPage} />
            <PrivateRoute key="editable-pages" path="/editable-pages" component={EditablePages} />
            <PrivateRoute key="preview-website" path="/preview-website-pages" component={PreviewWebsitePage} />
            <PrivateRoute key="add-event" path="/tools/add-event-pages" component={AddEventPages} />
            <PrivateRoute key="upload-download" path="/upload-download-pages" component={UploadDownloadPages} />
            <PrivateRoute key="social-media" path="/social-media-pages" component={SocialMediaPages} />
            <PrivateRoute key="publish-message" path="/publish-message-pages" component={PublishMessagePages} />
            <PrivateRoute key="app-statistics" path="/app-statistics-pages" component={AppStatisticsPages} />
            <PrivateRoute key="manage-documents" path="/manage-documents-pages" component={ManageDocumentsPages} />
            <PrivateRoute key="add-documents" path="/add-documents-pages" component={AddDocumentsPages} />
            <PrivateRoute key="banner-image" path="/banner-image-pages" component={BannerImagePages} />
            <PrivateRoute key="service-centre" path="/service-centre-pages" component={ServiceCentrePages} />
            <PrivateRoute key="settings" path="/settings-pages" component={SettingsPages} />
            <PrivateRoute key="myaccounts" path="/settings/myaccount-page" component={MyAccountPage} />
            <PrivateRoute key="mydocuments" path="/settings/mydocuments-page" component={MyDocumentsPage} />
            <PrivateRoute key="digestsubscriptions" path="/settings/digestsubscriptions-page" component={DigestSubscriptionsPage} />
            <PrivateRoute key="tools-marketing" path="/tools-marketing" component={ToolsMarketingPages} />
            <PrivateRoute key="webcast-marketing" path="/webcast-marketing" component={WebcastMarketingPages} />
            <PrivateRoute key="website-marketing" path="/website-marketing" component={WebsiteMarketingPages} />
            <PrivateRoute key="alert-marketing" path="/alert-marketing" component={AlertsMarketingPage} />
            <PrivateRoute key="app-marketing" path="/app-marketing" component={AppMarketingPage} />
            <PrivateRoute key="sm-marketing" path="/sm-marketing" component={SocialMediaMarketingPages} />
            <PrivateRoute key="tf-marketing" path="/tf-marketing" component={TFMarketingPage} />
            <PrivateRoute key="useranalytics-page" path="/website/useranalytics-page" component={UserAnalyticsPage} />
            <PrivateRoute key="notification-page" path="/notification-page" component={NotificationPages} />
            <PrivateRoute key="search-page" path="/website/search-page" component={SearchPage} />
            <PrivateRoute key="no-access" path="/no-access" component={NoAccess} />
            <PrivateRoute key="tools-overview" path="/Tnf/tools-overview" component={ToolsOverviewPage} />
            <PrivateRoute key="share-price-center" path="/Tnf/share-price-center-page" component={TnfSharePriceCenterPage} />
            <PrivateRoute key="share-price-mini-chart" path="/Tnf/share-price-mini-chart-page" component={TnfSharePriceMiniChartPage} />
            <PrivateRoute key="corporate-alert-service" path="/Tnf/corporate-alert-service-page" component={CorporateAlertServicePage} />
            <PrivateRoute key="dividend-calculator" path="/Tnf/dividend-calculator-page" component={DividendCalculatorPage} />
            <PrivateRoute key="largest-shareholders" path="/Tnf/largest-shareholders-page" component={LargestShareholdersPage} />
            <PrivateRoute key="regulatory-news" path="/Tnf/regulatory-news-page" component={RegulatoryNewsPage} />
            <PrivateRoute key="OMX-news" path="/Tnf/OMX-news-page" component={OMXNewsPage} />
            <PrivateRoute key="share-price-table" path="/Tnf/share-price-table-page" component={SharePriceTablePage} />
            <PrivateRoute key="historical-price-lookup" path="/Tnf/historical-price-lookup-page" component={HistoricalPriceLookupPage} />
            <PrivateRoute key="total-return-center" path="/Tnf/total-return-center-page" component={TotalReturnCenterPage} />
            <PrivateRoute key="share-price-calculator" path="/Tnf/share-price-calculator-page" component={SharePriceCalculatorPage} />
            <PrivateRoute key="exchange-list" path="/Tnf/exchange-list-page" component={ExchangeListPage} />
            <PrivateRoute key="OMX-filings" path="/Tnf/OMX-filings-page" component={OMXFilingsPage} />
            <PrivateRoute key="cision-filings" path="/Tnf/cision-filings-page" component={CisionFilingsPage} />
            <PrivateRoute key="DGAP-filings" path="/Tnf/DGAP-filings-page" component={DGAPFilingsPage} />
            <PrivateRoute key="SEC-filings" path="/Tnf/SEC-filings-page" component={SECFilingsPage} />
            <PrivateRoute key="US-press-release" path="/Tnf/US-press-release-page" component={USPressReleasePage} />
            <PrivateRoute key="ASX-announcement" path="/Tnf/ASX-announcement-page" component={ASXAnnouncementPage} />
            <PrivateRoute key="modular-finance-news" path="/Tnf/modular-finance-news-page" component={ModularFinanceNewsPage} />
            <PrivateRoute key="STT-news" path="/Tnf/STT-news-page" component={STTNewsPage} />
            <PrivateRoute key="reports-and-presentations" path="/Tnf/reports-and-presentations-page" component={ReportsAndPresentationsPage} />
            <PrivateRoute key="insights" path="/Tnf/Insights" component={InsightsPage} />
            <PrivateRoute key="tnf-notifications" path="/Tnf/notifications" component={TnFNotificationPage} />
            <PrivateRoute key="other-page" path="*" component={LoginPage} />
        </Switch>
    );
}

export default Routes;