import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Switch, withRouter } from 'react-router-dom';
import AppContext from '../components/AppContext';
import { isMobile } from "react-device-detect";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import { menuItemsDetais, WebsiteChannelDetailURL } from "../utils/sidebar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Accordion, Navbar, Row, Col, Modal } from '@themesberg/react-bootstrap';
import { getStringValue } from "../utils/common";
import ConnectIDLogo from "../assets/img/ConnectIDIntelligence.svg";
import ProfileIcon from "../assets/Icon/Account.svg";
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import { useTranslation } from 'react-i18next';
import { HiddenMenus, ChannelID } from "../AppConfig";
import ButtonComponent from "../components/Buttons";
import { baseURL, METHODS, baseFrontEndURL, getTFPage } from '../apis/utilities/constant';
import { channelOverviewDetails } from "../AppConfig";
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import request from '../apis/request';
import { decryptData } from "../utils/crypto";


const SideBar = React.memo(props => {

  const contextData = useContext(AppContext);
  const location = useLocation();
  const { pathname } = location;
  const [userName, setUserName] = useState("");
  const [show, setShow] = useState(false);
  const [t, i18n] = useTranslation();
  const [showDefault, setShowDefault] = useState(false);
  const [message, setMessage] = useState("");
  const handleClose = () => setShowDefault(false);
  const showClass = show ? "show" : "";
  const [currentMenuLoadedWebsiteID, setCurrentMenuLoadedWebsiteID] = useState(0);
  const [blurLoader, setBlurLoader] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("");



  const onCollapse = () => setShow(!show);
  const onMouseEnter = () => props.onMouseEnter && props.onMouseEnter();
  const onMouseLeave = () => props.onMouseLeave && props.onMouseLeave();

  //useEffect(() => {
  // const script = document.createElement('script');
  // script.src = "https://cdn.jsdelivr.net/gh/mgalante/jquery.redirect@master/jquery.redirect.js";
  // script.async = true;
  // document.body.appendChild(script);

  // return () => {
  //   document.body.removeChild(script);
  // }
  //}, []);

  // useEffect(() => {
  //   getUserName();
  // }, []);

  useEffect(() => {
    getUserName();
    if (contextData.companyId > 0 && contextData.userId > 0 && (contextData.selectedTab === "" || contextData.menu.length === 0)) {
      setCurrentMenuLoadedWebsiteID(0)
      setBlurLoader(true);
      let oo = localStorage.getItem("WebsiteListWebsiteId");
      const obj2 = JSON.parse(oo);
      let websiteid = 0;
      if (obj2 !== null) {
        websiteid = obj2["WebsiteList.WebsiteId"]
      }

      request(baseURL + `/UserV2/GetMainMenuByClientUser?userId=${contextData.userId}&companyid=${contextData.companyId}`,
        METHODS.GET,
        {},
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        })
        .then((response) => {
          if (response && response.resultData) {

            let menu = response.resultData.menuDetails;
            menu = menu.filter((x) => { return x.id !== 4 })

            let settingDta = {
              id: 0,
              dispIndex: 13,
              isActionPoint: false,
              isActive: true,
              subMenu: [],
              subSections: null,
              title: "Settings"
            };

            if (localStorage.getItem("selectedTab") === "Settings") {

              let submenuarray = [{ actionParam: "", actionType: 1, dispIndex: 0, id: 1, isActionPoint: true, isActive: true, isEnabled: true, title: "My account" },
              { actionParam: "", actionType: 1, dispIndex: 1, id: 2, isActionPoint: true, isActive: true, isEnabled: true, title: "My documents" },
              { actionParam: "", actionType: 1, dispIndex: 2, id: 3, isActionPoint: true, isActive: true, isEnabled: true, title: "Digest subscriptions" }];
              settingDta.subMenu = submenuarray;
            }

            // let tnfDta = {
            //   id: 66,
            //   dispIndex: 6,
            //   isActionPoint: false,
            //   isActive: true,
            //   subMenu: [],
            //   subSections: null,
            //   title: "IR Tools Analytics"
            // };

            // if (localStorage.getItem("selectedTab") === "IR Tools Analytics") {
            //   let submenuarray = [{ actionParam: "", actionType: 1, dispIndex: 0, id: 1, isActionPoint: true, isActive: true, isEnabled: true, title: "Tools overview" },
            //   { actionParam: "", actionType: 1, dispIndex: 1, id: 2, isActionPoint: true, isActive: true, isEnabled: true, title: "Share price centre" },
            //   { actionParam: "", actionType: 1, dispIndex: 2, id: 3, isActionPoint: true, isActive: true, isEnabled: true, title: "Share price mini chart" },
            //   { actionParam: "", actionType: 1, dispIndex: 2, id: 3, isActionPoint: true, isActive: true, isEnabled: true, title: "Corporate alert service" },
            //   { actionParam: "", actionType: 1, dispIndex: 2, id: 3, isActionPoint: true, isActive: true, isEnabled: true, title: "Dividend calculator" }];
            //   tnfDta.subMenu = submenuarray;
            // }

            // menu.push(tnfDta);
            menu.push(settingDta);

            menu.sort(function (a, b) {
              return a.dispIndex - b.dispIndex;
            });

            contextData.setMenu(menu);


            if (!localStorage.getItem("selectedTab")) {

              // if (selectedtab === 1 || selectedtab === 0) {
              localStorage.setItem("selectedTab", "Website")
              setSelectedMenu("Website")
              contextData.changeSelectedTab("Website");
              // }
              // else {
              //   localStorage.setItem("selectedTab", "Alerts")
              //   setSelectedMenu("Alerts")
              //   contextData.changeSelectedTab("Alerts");
              // }

            }
            else {
              //1stroundchange added whole if condition to counter navigation on change of client dropdown for settings

              if (localStorage.getItem("selectedTab") === "Website") {
                let websitemenu = menu.find(x => x.id === 1)

                if (
                  pathname.includes("settings") &&
                  websitemenu.isActive) {
                  props.history.push("/website")
                }
                else if (websitemenu.isActive === false) {
                  props.history.push("/website-marketing")
                }
              }

              //1stroundchange added next line 
              setSelectedMenu(localStorage.getItem("selectedTab"))
              contextData.changeSelectedTab(localStorage.getItem("selectedTab"));
            }
            // setBlurLoader(false);

          }
        })
        .catch((err) => {
          // setBlurLoader(false);
        })

    }


  }, [contextData.companyId]);

  useEffect(() => {

    if (contextData.selectedTab === "IR Tools Analytics"
      && contextData.selectedProfile["WebsiteProfiles.ProfileId"] > 0
    ) {

      let currentProfileId = 0;

      if (contextData.menu) {
        let menu = contextData.menu.find(item => item.id === 15);

        if (menu.subMenu && menu.subMenu.length) {
          currentProfileId = menu.subMenu[0].refId
        }

      }
      if (currentProfileId !== contextData.selectedProfile["WebsiteProfiles.ProfileId"]) {
        let submenu = [{
          actionParam: "",
          actionType: 1,
          dispIndex: 1,
          //id: 112,
          isActionPoint: true,
          isActive: true,
          isEnabled: true,
          title: "Tools Insight",
          link: menuItemsDetais.find(x => x.sid === 0).link,
          refId: contextData.selectedProfile["WebsiteProfiles.ProfileId"]
        }, {
          actionParam: "",
          actionType: 1,
          dispIndex: 2,
          //id: 113,
          isActionPoint: true,
          isActive: true,
          isEnabled: true,
          title: "Visitor Analytics",
          link: menuItemsDetais.find(x => x.sid === 1).link,
          refId: contextData.selectedProfile["WebsiteProfiles.ProfileId"]
        },
        {
          actionParam: "",
          actionType: 1,
          dispIndex: 2,
          //id: 113,
          isActionPoint: true,
          isActive: true,
          isEnabled: true,
          title: "Notification",
          link: menuItemsDetais.find(x => x.sid === 21).link,
          refId: contextData.selectedProfile["WebsiteProfiles.ProfileId"]
        }
        ];

        request(baseURL + `/UserV2/GetSubMenuByClientUserTF?IntelConfigId=` + contextData.selectedProfile["WebsiteProfiles.IntelConfigId"], //passed IntelconfigId in place of profileId
          METHODS.GET,
          {},
          {
            Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
          })
          .then((res) => {
            if (res) {

              res.forEach((dta) => {

                submenu.push({
                  actionParam: "",
                  actionType: 1,
                  dispIndex: dta.displayOrder + 1,
                  //id: 113,
                  isActionPoint: true,
                  isActive: true,
                  isEnabled: true,
                  title: dta.tool_name,
                  toolid: dta.tool_id,
                  link: menuItemsDetais.find(x => x.sid === dta.tool_id + 1).link,
                  refId: contextData.selectedProfile["WebsiteProfiles.ProfileId"]
                });
              });

              if (submenu.length) {
                submenu.sort((a, b) => a.dispIndex - b.dispIndex);
                let menu = contextData.menu;

                let newarr = menu.filter(function (item) {
                  return item.id === 15;
                });
                let newobject = newarr[0];
                newobject.subMenu = submenu;

                let updatedArray = menu.map(obj => {
                  if (obj.id == 15) {
                    return newobject
                  }
                  else {
                    return obj;
                  }

                });
                contextData.setMenu(updatedArray);


              }

              if (!submenu.some(obj => obj.link.includes(pathname))) {
                props.history.push(menuItemsDetais.find(x => x.sid === 0).link);
              }
            }
          })
          .catch((err) => {

          })

      }

    }
    // else if (contextData.selectedTab === "IR Tools Analytics"
    //   && contextData.selectedProfile["WebsiteProfiles.ProfileId"] === 0) {
    //   contextData.changeWebsiteId(0);
    //   props.history.push("/tf-marketing");
    //   contextData.changeSelectedProfile({})
    // }
    else if (contextData.selectedTab === "IR Tools Analytics") {


      let currentProfileId = 0;

      if (contextData.menu) {
        let menu = contextData.menu.find(item => item.id === 15);

        if (menu.subMenu && menu.subMenu.length) {
          currentProfileId = menu.subMenu[0].refId
        }
      }

      if (currentProfileId > 0) {

        let submenu = [{
          actionParam: "",
          actionType: 1,
          dispIndex: 1,
          id: 112,
          isActionPoint: true,
          isActive: true,
          isEnabled: true,
          title: "Tools Insight",
          link: menuItemsDetais.find(x => x.sid === 1).link,
          refId: contextData.selectedProfile["WebsiteProfiles.ProfileId"]
        }];


        let menu = contextData.menu.find(item => item.id === 15);

        let newobject = menu;
        newobject.subMenu = submenu;

        let updatedArray = contextData.menu.map(obj => {
          if (obj.id === 15) {
            return newobject
          }
          else {
            return obj;
          }

        });

        contextData.setMenu(updatedArray);
        props.history.push(menuItemsDetais.find(x => x.sid === 0).link);
      }

    }

  }, [contextData.selectedProfile["WebsiteProfiles.ProfileId"], contextData.selectedTab])

  useEffect(() => {

    let localstoragewebsitewid = 0;
    let contextid = 0;

    if (contextData.selectedTab == "Website") {

      const obj2 = JSON.parse(localStorage.getItem("WebsiteListWebsiteId"));
      if (obj2 !== null) {
        localstoragewebsitewid = obj2["WebsiteList.WebsiteId"]
      }
      else if (localStorage.getItem("websiteData") == null || JSON.parse(localStorage.getItem("websiteData")).length == 0) {

      }
    }
    else if (contextData.selectedTab == "Alerts") {

      const obj2 = JSON.parse(localStorage.getItem("alertWebsiteId"));
      if (obj2 !== null) {
        localstoragewebsitewid = obj2["WebsiteList.WebsiteId"]
      }
      else if (localStorage.getItem("alertWebsiteId") == null || JSON.parse(localStorage.getItem("alertWebsiteId")).length == 0) {

      }
    }
    else if (contextData.selectedTab == "Tools") {
      const obj2 = JSON.parse(localStorage.getItem("SelectedEventId"));
      if (obj2 !== null) {
        localstoragewebsitewid = obj2["WebsiteList.WebsiteId"]
      }

    }

    if (contextData.websiteId > 0) {

      contextid = contextData.websiteId;
    }
    else if (contextData.websiteId && contextData.websiteId["WebsiteList.WebsiteId"] > 0) {
      contextid = contextData.websiteId["WebsiteList.WebsiteId"];
    }
    let sectionid = 1;
    if (localStorage.getItem("selectedTab") === "Alerts") {
      sectionid = 8;
    }
    else if (contextData.selectedTab == "Webcasting & Video") {
      sectionid = 5;
    }
    else if (contextData.selectedTab == "Tools") {
      sectionid = 6;
    }
    else if (localStorage.getItem("selectedTab") === "Settings" || localStorage.getItem("selectedTab") === "IR Tools Analytics") {
      sectionid = 0;
    }

    let menu = contextData.menu;

    let currentmenuchildren = menu.find(x => x.id === sectionid);

    //below 2 if condition is written to counter a situation where a user changes client from website channel and website id not getting set
    // from the child page due to improper config in website- profile dropdown this condition enforces setting id accoringly to pages available

    if (!localstoragewebsitewid && localStorage.getItem("TempWId")) {
      localstoragewebsitewid = localStorage.getItem("TempWId");
      localStorage.removeItem("TempWId")
    }
    if (contextData.selectedTab === localStorage.getItem("selectedTab") && contextData.selectedTab === "Website" && !localstoragewebsitewid) {
      ClickTab(null, "Website")
    }

    //contextid is for context id which sometime takes time to change in few cases hence compared with localstorage value & 2nd condition is the case when there is now website in list for a client
    //same logic applies for tab of context and localstorage
    //for submenu logic is applied to avoid multiple calling if the submenu is already loaded for parent link & 2nd condition is to reload children if it exists for specific website after selection of one client which has no websites
    //for settings section id is 0

    //1stroundchange added contextid>0 because if otp client entry doesnt exist then its going in if condition with localstoragewebsitewid & removed  || newarr[0].subMenu.length == 0 from 3rd condition
    if (((contextid > 0 && localstoragewebsitewid == contextid) || (localstoragewebsitewid == 0 && contextid > 0))
      && (contextData.selectedTab == localStorage.getItem("selectedTab"))
      && ((!currentmenuchildren || currentmenuchildren.subMenu?.length == 0 || ((localstoragewebsitewid != currentMenuLoadedWebsiteID))) || (localstoragewebsitewid == 0 && contextid > 0))
      && sectionid != 0) {

      setCurrentMenuLoadedWebsiteID(localstoragewebsitewid)

      if (!selectedMenu) {
        setSelectedMenu(localStorage.getItem("selectedTab"))
      }

      loadSubmenu(contextData.companyId, sectionid, contextid)

    }
    else if (localstoragewebsitewid != contextid && localstoragewebsitewid > 0 && localStorage.getItem("selectedTab") == contextData.selectedTab) {
      // This condition is executed when we go from one channel to other which has different website id
      //1stroundchange removed && contextid > 0 

      setBlurLoader(false);
      contextData.changeWebsiteId(localstoragewebsitewid);
    }
    else if (contextData.selectedTab == "Webcasting & Video" && contextData.websiteId === 0) {
      // For webcast this condition is written specially to avoid complexity in first condition but the functionality is same to bring submenu
      setCurrentMenuLoadedWebsiteID(0)
      if (!selectedMenu) {
        setSelectedMenu(localStorage.getItem("selectedTab"))
      }
      loadSubmenu(contextData.companyId, sectionid, contextid)
    }
    else {
      //1stround whole else condition
      setBlurLoader(false);
    }

    const index = contextData.menu.findIndex(item => item.id === 0);
    if (index === -1 && contextData.menu.length > 0) {
      let menu = contextData.menu;
      let settingDta = {
        id: 0,
        dispIndex: 13,
        isActionPoint: false,
        isActive: true,
        subMenu: [],
        subSections: null,
        title: "Settings"
      };
      menu[menu.length - 1] = settingDta;
      contextData.setMenu(menu);
    }
    // else if (isSubmenuLoaded == 1) {
    //   setIsSubmenuLoaded(0);
    // }

  }, [contextData.websiteId, contextData.selectedTab]);

  function loadSubmenu(cid, sectionid, wid) {

    setBlurLoader(true);
    let url = sectionid == 1 ? `/UserV2/GetSubMenuByClientUser?companyId=${cid}&sectionid=` + sectionid + `&websiteid=${wid}&aiconsent=1` : `/UserV2/GetSubMenuByClientUser?companyId=${cid}&sectionid=` + sectionid + `&websiteid=${wid}`;
    request(baseURL + url,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        setBlurLoader(false);
        let submenuarray = [];
        if (res[0] && Array.isArray(res[0].subSections) && res[0].subSections.length > 0) {
          let menuDta = res[0].subSections;

          let first = 1;
          let firstobj = {};
          menuDta.forEach((dta) => {
            //this condition works for alerts and website channel only
            if (dta.title == "Insights" && wid > 0) {

              let subMenuDetails = menuItemsDetais.find(men => men.title === (dta.title + ' analytics'));

              let dorder = 1;
              if (subMenuDetails) {
                dorder = subMenuDetails.dispOrder;
              }
              let channelOverviewDetails = {
                actionParam: "",
                actionType: 1,
                dispIndex: dorder,
                id: 0,
                isActionPoint: true,
                isActive: true,
                isEnabled: true,
                title: `${res[0].title} analytics`,
                link: res[0].title == "Website" ? `/website` : `/alert/statistics`
              };
              submenuarray.push(channelOverviewDetails);
            }

            if (dta && Array.isArray(dta.actions) && dta.actions.length > 0) {

              dta.actions.forEach((data) => {

                //Id 53 is for feedback which is not needed in side menu
                if (data.title != "Workflow" && data.id !== 53 && (data.isEnabled === true || data.title == "Page analytics")) {

                  let subMenuDetails = menuItemsDetais.find(men => men.title === data.title);


                  if (first == 1) {
                    firstobj = subMenuDetails;
                    first = 0;
                  }
                  if (subMenuDetails && submenuarray.filter(e => e.title === subMenuDetails.title).length == 0) {

                    if (subMenuDetails) {
                      data.dispIndex = subMenuDetails.dispOrder;
                    }
                    let channeltitle = subMenuDetails.title;
                    //proddata
                    // if (channeltitle === "Pages") {
                    //   channeltitle = "Page analytics"
                    // }
                    // else if (channeltitle === "Visitors") {
                    //   channeltitle = "Visitor analytics"
                    // }
                    let channelOverviewDetails = {
                      actionParam: "",
                      actionType: 1,
                      dispIndex: data.dispIndex,
                      id: data.id,
                      isActionPoint: true,
                      isActive: true,
                      isEnabled: true,
                      title: channeltitle,
                      link: subMenuDetails.link
                    };

                    submenuarray.push(channelOverviewDetails);

                  }
                }
              });
            }
          });


          let wasdisabled = 0;
          if (submenuarray.length > 0) {

            let menu = contextData.menu;
            let newarr = menu.filter(function (item) {
              return item.id === sectionid;
            });
            let newobject = newarr[0];
            newobject.subMenu = submenuarray;
            if (newobject.isActive == false) {
              wasdisabled = 1;
            }

            let updatedArray = menu.map(obj => {
              if (obj.id == sectionid) {
                return newobject;
              }
              else {
                obj.subMenu = [];
              }
              return obj;
            });
            contextData.setMenu(updatedArray);
          }

          //First if -For 1 parent tab to other parent tab transfer
          //Second for children to other children transfer by change of website dropdown

          if (selectedMenu != localStorage.getItem("selectedTab") && (selectedMenu || wasdisabled == 1)) {

            if (localStorage.getItem("selectedTab") == "Website" && (firstobj.link == "/website/visitoranalytics-page" || firstobj.link == "/website/page-analytics-page")) {

              props.history.push("/website");
            }
            else if (localStorage.getItem("selectedTab") == "Website" && (!firstobj.link || wasdisabled == 1)) {

              props.history.push("/website");
            }
            else {
              props.history.push(firstobj.link);
            }

          }
          else if (submenuarray.length > 0 && !submenuarray.some(obj => obj.link.includes(pathname))
            && !WebsiteChannelDetailURL.includes(pathname)
          ) {

            if (submenuarray[0].link) {
              props.history.push(submenuarray[0].link);
            }
            else if (contextData.selectedTab === "Website" && submenuarray.some(obj => obj.id === 6)) {
              props.history.push("/editable-pages");
            }
            else if (contextData.selectedTab === "Website" && !submenuarray.some(obj => obj.id === 6)) {
              props.history.push("/website");
            }
            //2ndroundchange
            //else if (contextData.selectedTab === "Website" && !pathname.includes("page-analytics-page") && !pathname.includes("visitoranalytics-page") && !pathname.includes("notification-page")) {
            //1stroundchange !pathname.includes("*") This is return to navigate to website tab if these tab doesnt exists in current website selection
            //  props.history.push("/website");
            // }

          }
          else if (contextData.selectedTab == "Website" && submenuarray.length > 0 && ((pathname == "/") || (WebsiteChannelDetailURL.includes(pathname) && !submenuarray.some(obj => obj.link.includes("/website"))))) {
            //1stroundchange addition of last && condition to navigate to website tab from detail tab if website tab doesnt exists in selected website
            props.history.push("/website");
          }


        }
        else if (contextData.selectedTab == "Website"
          && localStorage.getItem("path").indexOf("website-marketing") === -1) {

          //  temp commented

          resetToRemoveChildFromMenuArray(sectionid);
          // if (pathname.indexOf("/website") === -1) {
          //   window.location.href = "http://localhost:3000/#/website-marketing"; 
          // }
          // if (ischannelempty) {
          //   props.history.push("/website-marketing")
          // }
          // else {
          props.history.push("/website")
          // }
        }

      })
      .catch((err) => {
        setBlurLoader(false);
        console.log(err)
        //  setBlurLoader(false);
      })
  }
  function ClickTab(linkProps, t) {

    if (!linkProps) {

      let wid = contextData.websiteId;

      if (t === "Alerts") {
        //1stround
        setBlurLoader(true);
        let istabactive = contextData.menu.find(x => x.id === ChannelID["Alerts"]).isActive;
        if (istabactive) {
          request(baseURL + `/Intelligence/GetAlertsChannelWebsiteByClientUser?companyid=` + contextData.companyId,
            METHODS.GET,
            {},
            {
              Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {

              if (res && res.result.length > 0) {

                wid = res.result[0].WebsiteId;
                localStorage.setItem("selectedTab", "Alerts")
                if (contextData.websiteId != wid) {
                  contextData.changeSelectedTab("Alerts");
                  contextData.changeWebsiteId(wid);

                }
                else {

                  contextData.changeSelectedTab("Alerts");
                }
              }
              else {

                resetToRemoveChildFromMenuArray(0);
                localStorage.removeItem("alertWebsiteId")
                localStorage.setItem("selectedTab", "Alerts")
                contextData.changeSelectedTab("Alerts");
                contextData.changeWebsiteId(0);
                props.history.push("/alert-marketing");
                setBlurLoader(false);
              }
            })
            .catch((err) => {

            })
        }
        else {
          resetToRemoveChildFromMenuArray(0);
          localStorage.removeItem("alertWebsiteId")
          localStorage.setItem("selectedTab", "Alerts")
          contextData.changeSelectedTab("Alerts");
          contextData.changeWebsiteId(0);
          props.history.push("/alert-marketing");
          setBlurLoader(false);
        }

      }
      else if (t === "Website") {
        setBlurLoader(true);
        let istabactive = contextData.menu.find(x => x.id === ChannelID["Website"]).isActive;
        if (istabactive) {
          request(baseURL + `/CommonV2/GetWebsiteChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
            METHODS.GET,
            {},
            {
              Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
              if (res && res.result.length > 0) {
                wid = res.result[0].WebsiteId;

                localStorage.setItem("selectedTab", "Website")

                if (contextData.websiteId != wid) {
                  // this condition is added to inject website id if its not setting from child page as above added code in useffect
                  const obj2 = JSON.parse(localStorage.getItem("WebsiteListWebsiteId"));
                  if (JSON.stringify(obj2) == "{}") {

                    localStorage.setItem("TempWId", "" + wid)
                  }
                  contextData.changeSelectedTab("Website");
                  contextData.changeWebsiteId(wid)
                  contextData.changeSelectedProfile({});
                }
                else {
                  setBlurLoader(false);
                  contextData.changeSelectedTab("Website");
                  //This condition  is written to handle 2 situation where 1. if a user logs in it first goes in if and then else which removes profileid
                  // without below written if condition so header shows value but internally profile is erased hence data are not loaded on card
                  //2. if there r 2 profiles in 1 website and user toggles between website and t&f so resetting profileid becomes must
                  
                  if( JSON.parse(localStorage.getItem("WebsiteListWebsiteId"))["WebsiteProfiles.ProfileId"]!=contextData.selectedProfile["WebsiteProfiles.ProfileId"])
                  {
                    contextData.changeSelectedProfile({});
                  }
                  
                }

              }
              else {

                resetToRemoveChildFromMenuArray(0);
                localStorage.setItem("selectedTab", "Website")
                contextData.changeSelectedTab("Website");
                props.history.push("/website-marketing");
              }
            })
            .catch((err) => {

            })
        }
        else {
          resetToRemoveChildFromMenuArray(0);
          localStorage.setItem("selectedTab", "Website")
          contextData.changeSelectedTab("Website");
          props.history.push("/website-marketing");
        }

      }
      else if (t === "Webcasting & Video") {
        let istabactive = contextData.menu.find(x => x.id === ChannelID["Webcasting"]).isActive;
        resetToRemoveChildFromMenuArray(0);
        localStorage.setItem("selectedTab", "Webcasting & Video")
        contextData.changeWebsiteId(0);
        contextData.changeSelectedTab("Webcasting & Video");
        if (!istabactive) {
          props.history.push("/webcast-marketing");
        }
      }
      else if (t === "Tools") {
        let istabactive = contextData.menu.find(x => x.id === ChannelID["Tools"]).isActive;
        if (istabactive) {
          request(baseURL + `/Intelligence/GetToolsWebsiteForClientUser?companyid=` + contextData.companyId,
            METHODS.GET,
            {},
            {
              Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {

              if (res && res.result.length > 0) {

                wid = res.result[0].WebsiteId;
                localStorage.setItem("selectedTab", "Tools")

                if (contextData.websiteId != wid) {

                  contextData.changeWebsiteId(wid);
                  contextData.changeSelectedTab("Tools");
                }
                else {

                  contextData.changeSelectedTab("Tools");
                }
              }
              else {

                resetToRemoveChildFromMenuArray(0);
                //localStorage.removeItem("SelectedEventId")
                localStorage.setItem("selectedTab", "Tools")
                contextData.changeSelectedTab("Tools");
                contextData.changeWebsiteId(0);
                props.history.push("/tools-marketing");
                setBlurLoader(false);
              }
            })
            .catch((err) => {

            })
        }
        else {
          resetToRemoveChildFromMenuArray(0)
          localStorage.setItem("selectedTab", "Tools")
          contextData.changeSelectedTab("Tools");
          contextData.changeWebsiteId(0);
          props.history.push("/tools-marketing");
          setBlurLoader(false);
        }
      }
      else if (t === "IR Tools Analytics") {

        let menu = contextData.menu;

        let newarr = menu.filter(function (item) {
          return item.id === 15;
        });

        let newobject = newarr[0];
        // let submenuarray = [{ actionParam: "", actionType: 1, dispIndex: 0, id: 1, isActionPoint: true, isActive: true, isEnabled: true, title: "Tools overview" },
        // { actionParam: "", actionType: 1, dispIndex: 1, id: 2, isActionPoint: true, isActive: true, isEnabled: true, title: "Share price centre" },
        // { actionParam: "", actionType: 1, dispIndex: 2, id: 3, isActionPoint: true, isActive: true, isEnabled: true, title: "Share price mini chart" },
        // { actionParam: "", actionType: 1, dispIndex: 2, id: 3, isActionPoint: true, isActive: true, isEnabled: true, title: "Corporate alert service" },
        // { actionParam: "", actionType: 1, dispIndex: 2, id: 3, isActionPoint: true, isActive: true, isEnabled: true, title: "Dividend calculator" }];
        let submenuarray = [];

        let istabactive = contextData.menu.find(x => x.id === 15).isActive;
        if (istabactive) {
          request(baseURL + `/UserV2/GetFirstSubMenuByClientUserTF?companyid=` + contextData.companyId,
            METHODS.GET,
            {},
            {
              Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {

              resetToRemoveChildFromMenuArray(0);
              if (Array.isArray(res) && res.length) {

                contextData.changeSelectedProfile({});
                contextData.changeSelectedTab("IR Tools Analytics");
                localStorage.setItem("selectedTab", "IR Tools Analytics")

                props.history.push("/Tnf/tools-overview");
              }
              else {
                contextData.changeSelectedProfile({});
                contextData.changeSelectedTab("IR Tools Analytics");
                localStorage.setItem("selectedTab", "IR Tools Analytics")

                props.history.push("/tf-marketing");
              }
            })
            .catch((err) => {

            })

          // contextData.changeSelectedProfile({})
        }
        else {

          resetToRemoveChildFromMenuArray(0)
          localStorage.setItem("selectedTab", "IR Tools Analytics")
          contextData.changeSelectedTab("IR Tools Analytics");
          contextData.changeWebsiteId(0);
          props.history.push("/tf-marketing");
          contextData.changeSelectedProfile({})

        }

        // newobject.subMenu = submenuarray;

        // let updatedArray = menu.map(obj => {
        //   if (obj.id == 15) {
        //     return newobject;
        //   }
        //   else {
        //     obj.subMenu = [];
        //   }
        //   return obj;
        // });

        // contextData.setMenu(updatedArray);
        // localStorage.setItem("selectedTab", "IR Tools Analytics")
        // contextData.changeSelectedTab("IR Tools Analytics");
        // contextData.changeWebsiteId(0);
        // props.history.push("/Tnf/tools-overview");
      }

      else if (ChannelID[t] === 0) {
        let menu = contextData.menu;
        let newarr = menu.filter(function (item) {
          return item.id === 0;
        });
        let newobject = newarr[0];

        let submenuarray = [{ actionParam: "", actionType: 1, dispIndex: 0, id: 1, isActionPoint: true, isActive: true, isEnabled: true, title: "My account" },
        { actionParam: "", actionType: 1, dispIndex: 1, id: 2, isActionPoint: true, isActive: true, isEnabled: true, title: "My documents" },
        { actionParam: "", actionType: 1, dispIndex: 2, id: 3, isActionPoint: true, isActive: true, isEnabled: true, title: "Digest subscriptions" }];

        newobject.subMenu = submenuarray;
        let updatedArray = menu.map(obj => {
          if (obj.id == 0) {
            return newobject;
          }
          else {
            obj.subMenu = [];
          }
          return obj;
        });
        contextData.setMenu(updatedArray);
        localStorage.setItem("selectedTab", "Settings")
        contextData.changeSelectedTab("Settings");
        contextData.changeWebsiteId(0);
        props.history.push("/settings/myaccount-page");
      }

    }
    else if (t === "Reports") {

      resetToRemoveChildFromMenuArray(0);
      localStorage.setItem("selectedTab", "Reports")
      contextData.changeWebsiteId(0);
      contextData.changeSelectedTab("Reports");
    }

    else if (linkProps.title === "Service Centre") {
      resetToRemoveChildFromMenuArray(0);
      localStorage.setItem("selectedTab", "Service Centre")
      contextData.changeWebsiteId(0);
      contextData.changeSelectedTab("Service Centre");
    }
  }
  function removeDuplicateObjects(array, key) {
    const seen = {};
    return array.filter(item => {
      const itemKey = key ? item[key] : item;
      return seen.hasOwnProperty(itemKey) ? false : (seen[itemKey] = true);
    });
  }


  const events = isMobile ? {} : { onMouseEnter, onMouseLeave };

  const getUserName = () => {
    let userNm = contextData.firstName ? contextData.firstName : "";
    setUserName(userNm);
  };

  function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
  const onNaVItemClickevent = (event, title) => {
    localStorage.setItem("IsPageRefresh", "0");
    localStorage.removeItem("EventId");
    //localStorage.removeItem("PresentationId");
    localStorage.removeItem("ManageusersEventId");
    //localStorage.removeItem("WebsiteProfilesIntelConfigId");
    //localStorage.removeItem("WebsiteListWebsiteId");
    //localStorage.removeItem("SelectedDatePicker");
    localStorage.removeItem("EventCalendarWebsiteId");
    localStorage.removeItem("EventCalendarProfileId");
    localStorage.removeItem("AlertStatsWebsiteId");
    localStorage.removeItem("ArchiveAlertWebsiteId");
    localStorage.removeItem("ManualAlertWebsiteId");
    localStorage.removeItem("ManagesubscriptionWebsiteId");
    localStorage.removeItem("AlertSummaryWebsiteId");
    //temporary comment because website.js not retaining websitedata from localstorage
    //localStorage.removeItem("websiteData");
    setShow(false)
  };
  const onNaVItemClick = (event, title) => {
    event.preventDefault();
    contextData.userEmail = decryptData(localStorage.getItem("email"), contextData.cryptoKey);
    let token = decryptData(localStorage.getItem("token"), contextData.cryptoKey);
    axios
      .get(
        `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetWebsitePlatformStatus`,
        {
          params: {
            cmsplatformid: JSON.parse(localStorage.getItem("WebsiteListWebsiteId"))["cmsplatformid"]
          },
          headers: {
            'Authorization': `Bearer ${token}`
          }
        },
      )
      .then(response => {
        if (response && response.data) {
          if (response.data.isPlatformDown) {
            setMessage(response.data.maintenanceMessage);
            setShowDefault(true);

          }
          else {
            localStorage.setItem("validatelogin", "0");
            localStorage.setItem("sessiontime", new Date());
            const token = generateUUID();
            contextData.changeToken(localStorage.setItem('IStoken', token));
            switch (title) {
              case "Edit Website":
                axios
                  .get(
                    `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetWebsiteSelfServeURL`,
                    {
                      params: {
                        websiteid: contextData.websiteId,
                        requesturl: window.location.href,
                        destinationurl: '',
                        siteindex: contextData.websiteId,
                        token: contextData.token,
                        companyId: contextData.companyId,
                        UserId: contextData.userId,
                        Email: contextData.userEmail,
                        iseditwebsite: 1
                      },
                      headers: {
                        'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
                        'X-CSRF-Token': `${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
                      }
                    },
                  )
                  .then(response => {
                    if (response && response.data && response.data.url) {

                      if (response.data.cmsMethod == 0) {
                        window.location.href = response.data.url;
                      }
                      else {
                        var urldata = response.data.url.split("?token=");
                        //window.location.href = response.data.url;
                        if (urldata.length > 1) {
                          var f = document.createElement('form');
                          f.action = urldata[0];
                          f.method = 'POST';
                          //f.target='_blank';

                          var i = document.createElement('input');
                          i.type = 'hidden';
                          i.name = 'token';
                          i.value = urldata[1];
                          f.appendChild(i);

                          document.body.appendChild(f);
                          f.submit();

                        }
                      }
                    }
                  })
                  .catch(error => {
                  });
                event.preventDefault();
                break;
              case "Media Library":
                axios
                  .get(
                    `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetWebsiteSelfServeURL`,
                    {
                      params: {
                        websiteid: contextData.websiteId,
                        requesturl: window.location.href,
                        destinationurl: '',
                        siteindex: contextData.websiteId,
                        token: contextData.token,
                        urlType: 5,
                        companyId: contextData.companyId,
                        UserId: contextData.userId,
                        Email: contextData.userEmail
                      },
                      headers: {
                        'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
                        'X-CSRF-Token': `${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
                      }
                    },
                  )
                  .then(response => {
                    if (response && response.data && response.data.url) {
                      window.location.href = response.data.url;
                    }
                  })
                  .catch(error => {
                  });
                event.preventDefault();
                break;
              case "Preview Website":
                axios
                  .get(
                    `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetWebsiteSelfServeURL`,
                    {
                      params: {
                        websiteid: contextData.websiteId,
                        requesturl: window.location.href,
                        destinationurl: '',
                        siteindex: contextData.websiteId,
                        token: contextData.token,
                        companyId: contextData.companyId,
                        UserId: contextData.userId,
                        Email: contextData.userEmail,
                        urlType: 2,
                      },
                      headers: {
                        'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
                        'X-CSRF-Token': `${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
                      }
                    },
                  )
                  .then(response => {
                    if (response && response.data && response.data.url) {

                      if (response.data.cmsMethod == 0) {
                        window.location.href = response.data.url;
                      }
                      else {
                        var urldata = response.data.url.split("?token=");
                        //window.location.href = response.data.url;
                        if (urldata.length > 1) {
                          var f = document.createElement('form');
                          f.action = urldata[0];
                          f.method = 'POST';
                          //f.target='_blank';

                          var i = document.createElement('input');
                          i.type = 'hidden';
                          i.name = 'token';
                          i.value = urldata[1];
                          f.appendChild(i);

                          document.body.appendChild(f);
                          f.submit();

                        }
                      }
                    }
                  })
                  .catch(error => {
                  });
                event.preventDefault();
                break;
              case "Logout":
                onLogout();
                break;
              default:
                break
            }
          }
        }
      })
      .catch(error => {
      });


  };

  const onLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    props.history.push("/login");
  }

  const CollapsableNavItem = (props) => {

    const { eventKey, title, icon, isCustomIcon, children = null, active, isEnabled } = props;

    const defaultKey = pathname.indexOf(eventKey) !== -1
      || eventKey == "IR Tools Analytics"
      || pathname.includes("notification-page")
      || pathname.includes("editable-pages") ? eventKey : "";
    const disable = !active && !isEnabled ? "disabled" : "";
    const classes = "d-flex justify-content-between align-items-center " + disable;

    return (

      <Accordion id="mainsidebar" as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className={classes}>
            <span>

              {isCustomIcon ?
                <svg className="sidenav-icon">
                  <use href={iconSprite + `#${icon}`} />
                </svg>
                :
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={icon} />
                </span>
              }

              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };


  const NavItem = (props) => {

    const { title, link, external, target, icon, isCustomIcon, hasButtonClick, image, badgeText, badgeBg = "secondary", badgeColor = "primary", active, isEnabled } = props;
    const disable = !active && !isEnabled ? "disabled" : "";
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between " + disable : disable;
    let navItemClassName = ""

    if (link.toLowerCase() === pathname.toLowerCase()) {
      navItemClassName = "active";
    }
    else if ((pathname === "/website/cookie-analytics-page" && link === "/website")
      || (pathname === "/website/useranalytics-page" && link === "/website")) {
      navItemClassName = "active";
    }
    else if (pathname === "/website/visitoranalytics-page/watchlist" && title === "Visitor analytics") {
      navItemClassName = "active";
    }
    else if (pathname === "/webcast-marketing" && title === "Webcasting & Video") {
      navItemClassName = "active";
    }
    else if (pathname === "/tools-marketing" && title === "Tools") {
      navItemClassName = "active";
    }
    else if (pathname === "/alert-marketing" && title === "Alerts") {
      navItemClassName = "active";
    }
    else if (pathname === "/website-marketing" && title === "Website") {
      navItemClassName = "active";
    }
    else if (pathname === "/tf-marketing" && title === "IR Tools Analytics") {
      navItemClassName = "active";
    }


    // else {

    //      navItemClassName = link === pathname ? "active" : "";
    // }

    const linkProps = link ? (external ? { href: link } : { as: Link, to: link, title: title }) : null;

    return (

      <Nav.Item className={navItemClassName} onClick={(e) => onNaVItemClickevent(e, title)} >
        {hasButtonClick ?
          <Nav.Link className={classNames} onClick={(e) => onNaVItemClick(e, title)}>
            <span>
              {icon ?
                <>
                  {isCustomIcon ?
                    <svg className="sidenav-icon">
                      <use href={iconSprite + `#${icon}`} />
                    </svg>
                    :
                    <span className="sidebar-icon">
                      <FontAwesomeIcon icon={icon} />
                    </span>
                  }
                </>
                : null}
              {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

              <span className="sidebar-text">{title}</span>
            </span>
            {badgeText ? (
              <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
            ) : null}
          </Nav.Link >
          : <Nav.Link {...linkProps} target={target} className={classNames} onClick={() => { ClickTab(linkProps, title) }}>
            <span>
              {icon ?
                <>
                  {isCustomIcon ?
                    <svg className="sidenav-icon">
                      <use href={iconSprite + `#${icon}`} />
                    </svg>
                    :
                    <span className="sidebar-icon">
                      <FontAwesomeIcon icon={icon} />
                    </span>
                  }
                </>
                : null}
              {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

              <span className="sidebar-text">{title}</span>
            </span>
            {badgeText ? (
              <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
            ) : null}
          </Nav.Link>
        }
      </Nav.Item>

    );
  };



  const renderCollapsableNavItem = (collapseNavItemData) => {

    var key = "";
    if (!collapseNavItemData.isActive) {

      if (collapseNavItemData.title === "Social Media") {
        key = (collapseNavItemData.title + "-marketing").toLowerCase().replace(' ', '');
      }
      else {
        key = (collapseNavItemData.title + "-marketing").toLowerCase();
      }
    }

    const collapseNavItemdetails = collapseNavItemData.isActive ? menuItemsDetais.find(dta => dta.title === collapseNavItemData.title && dta.Key != (collapseNavItemData.title + "-marketing").toLowerCase()) : menuItemsDetais.find(dta => dta.title === collapseNavItemData.title && dta.Key === key);

    var title = collapseNavItemData.title;
    if (collapseNavItemData.title === "Webcasting") {
      title = "Webcasting & Video";
    }
    else if (collapseNavItemData.title === "App") {
      title = "Mobile App";
    }
    else if (collapseNavItemData.title === "Alerts") {
      title = "Alerts";
    }
    // else if (collapseNavItemData.title === "Tools") {
    //   title = "Index Tool";
    // }
    if (collapseNavItemData.isActive && collapseNavItemdetails && Object.keys(collapseNavItemdetails).length > 0) {

      return (
        <CollapsableNavItem
          isEnabled={collapseNavItemData.isEnabled}
          active={collapseNavItemData.isActive}
          link={collapseNavItemdetails.link}
          eventKey={collapseNavItemdetails.Key}
          title={title}
          icon={collapseNavItemdetails.icon}
          id={collapseNavItemData.id}
          isCustomIcon={collapseNavItemdetails.isCustomIcon} >
          {
            collapseNavItemData.subMenu && collapseNavItemData.isActive && Array.isArray(collapseNavItemData.subMenu) && collapseNavItemData.subMenu.length > 0 &&
            collapseNavItemData.subMenu.map(collItem => {
              return renderNavItem(collItem);
            })
          }
        </CollapsableNavItem>
      );

    }
    else if (!collapseNavItemData.isActive && collapseNavItemdetails && Object.keys(collapseNavItemdetails).length > 0) {
      return (
        <NavItem
          isEnabled={collapseNavItemData.isEnabled}
          active={collapseNavItemData.isActive}
          title={collapseNavItemData.title}
          icon={collapseNavItemdetails.icon}
          link={collapseNavItemdetails.link}
          isCustomIcon={collapseNavItemdetails.isCustomIcon}
          hasButtonClick={collapseNavItemdetails.hasButtonClick}
          id={collapseNavItemdetails.id}
        />);
    }
  }

  const renderNavItem = (navItemData) => {
    let navItemdetails = navItemData.isActive ? menuItemsDetais.find(dta => dta.title === navItemData.title && dta.Key != (navItemData.title + "-marketing").toLowerCase()) : menuItemsDetais.find(dta => dta.title === navItemData.title && dta.Key === (navItemData.title + "-marketing").toLowerCase());
    
    if(navItemData?.toolid > 0){
      navItemdetails = navItemData.isActive && menuItemsDetais.find(dta => dta.toolid === navItemData.toolid);
    }

    if (navItemdetails && Object.keys(navItemdetails).length > 0) {
      var title = navItemData.title;

      if (navItemData.title === "Alert Statistics") {
        title = "Alert analytics";
      }
      else if (navItemData.title === "Manage Manage Events") {
        title = "Event calendar";
      }
      else if (navItemData.title === "Social Media analytics") {
        title = "Social media analytics";
      }
      else if (navItemData.title === "Webcast Statistics") {
        title = "Webcast analytics";
      }
      else if (navItemData.title === "Webcasting") {
        title = "Webcasting & Video";
      }

      return (
        navItemData.title !== "Edit Website" ?
          <NavItem
            isEnabled={navItemData.isEnabled}
            active={navItemData.isActive}
            title={title}
            icon={navItemdetails.icon}
            link={navItemdetails.link}
            isCustomIcon={navItemdetails.isCustomIcon}
            hasButtonClick={navItemdetails.hasButtonClick}
          />
          : <></>
      );
    }

  };


  const renderSideBarItems = () => {

    if (contextData.menu && Array.isArray(contextData.menu) && contextData.menu.length > 0) {

      return contextData.menu.map(data => {

        const index = HiddenMenus.findIndex(x => x === data.id);

        if (index === -1) {
          if (data.subMenu && Array.isArray(data.subMenu) && data.subMenu.length > 0) {
            return renderCollapsableNavItem(data);
          }
          else {
            return renderNavItem(data);
          }
        }
      });

    }


  };

  const getCompanyDetails = () => {
    if (contextData.companies && Array.isArray(contextData.companies) && contextData.companies.length > 0) {
      if (contextData.companyId) {
        const selectedComapnyData = contextData.companies.find(dta => dta.companyId === contextData.companyId);
        if (selectedComapnyData && Object.keys(selectedComapnyData).length > 0)
          return <span>{selectedComapnyData.companyName}</span>;
      }
    }

  };

  const onUserIconClick = () => {
    setShow(false);
    props.history.push("/user-settings");
  };

  function resetToRemoveChildFromMenuArray(sectionid) {
    let menu = contextData.menu;
    let newobject = {};
    if (sectionid > 0) {
      let newarr = menu.filter(function (item) {
        return item.id === sectionid;
      });
      newobject = newarr[0];
      newobject.subMenu = [];
    }
    let updatedArray = menu.map(obj => {
      if (obj.id == sectionid && sectionid > 0) {
        return newobject;
      }
      else {
        obj.subMenu = [];
      }
      return obj;
    });

    contextData.setMenu(updatedArray);
  }



  return (
    <>

      {
        contextData.menu && Array.isArray(contextData.menu) && contextData.menu.length > 0 &&
        <>
          {blurLoader && <div id='blurdiv' className="blurbackground">
            <div className={`preloader loader-center-align`}>
              <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
            </div>
          </div>}
          <Modal as={Modal.Dialog} size="xs" centered show={showDefault} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title className="h5">Alert</Modal.Title>
              <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Modal.Body>
              <p>{message}</p>
            </Modal.Body>
            <Modal.Footer>
              <ButtonComponent
                isIcon={false}
                isPrimary={true}
                btnText="OK"
                onClick={handleClose}
                btn_class="me-3" />

            </Modal.Footer>
          </Modal>
          <Navbar expand={false} collapseOnSelect className="navbar-theme-primary px-3 d-md-none">
            <Navbar.Brand as={Link} className="me-lg-5" to="/">
              <Image src={ConnectIDLogo} className="logo-avtar large-avatar" />
            </Navbar.Brand>
            <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
              <span className="navbar-toggler-icon pt-2" >
                <i className="fa fa-bars" aria-hidden="true"></i>
              </span>
            </Navbar.Toggle>
          </Navbar>
          <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
            <SimpleBar className={`collapse ${showClass} sidebar d-md-block`} id="sidebardiv">
              <div className="sidebar-inner" id="mainbar">
                <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center">
                  <div className="d-flex align-items-center">
                    <div className="d-block">
                      <Button className="profile-btn" onClick={onUserIconClick}><Image src={ProfileIcon} /> </Button>
                      <span className="mb-0 ms-3 fw-bold profile-name mt-1">{t("header.WelcomeBackText")}, {getStringValue(userName)}</span>
                      <div className="mt-1 w-100 header-company-name">
                        {getCompanyDetails()}
                      </div>
                    </div>
                  </div>
                  <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                    <FontAwesomeIcon icon={faTimes} />
                  </Nav.Link>
                </div>
                <Nav className="flex-column pt-3 pt-md-0">
                  {renderSideBarItems()}
                </Nav>
              </div>
            </SimpleBar>
          </CSSTransition>
        </>
      }
    </>
  );

});

export default withRouter(SideBar);