import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from "react-device-detect";
// import { useQuery } from '@apollo/react-hooks';
// import { GET_DASHBOARD_ITEMS } from '../graphql/queries';
import ChartRenderer from '../components/ChartRenderer';
import PagePerformance from '../components/PagePerformance';
import DashboardItem from '../components/DashboardItem';
import { CARD_MOBILE_LAYOUT, DEFAULT_PAGE_NO } from "../AppConfig";
import { defaultLayout, defaultLayoutMobile } from "../utils/layoutConfig";
import SummaryRenderer from '../components/SummaryRenderer';
import moment from "moment-timezone";
import AppContext from '../components/AppContext';
import context from '@themesberg/react-bootstrap/lib/esm/AccordionContext';
import { Row, Col, Image } from '@themesberg/react-bootstrap';
import { getPages, getPageSearch } from '../stubs/ComponentJson/Pages';
import { TABLE_TOTAL_ROWS } from "../AppConfig";
import MultilineToggleComponent from '../components/MultilineToggleComponent';
import ChartRendererTable from '../components/ChartRendererTable';
import Search from "../components/Search";
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import Filters from "../components/Filters";
import { useTranslation } from "react-i18next";
import { decryptData } from '../utils/crypto';
import LoaderImage from "../assets/img/LoaderGraphic.svg";

const deserializeItem = (i) => ({
  ...i,
  layout: JSON.parse(i.layout) || {},
  summary: JSON.parse(i.summary) || {},
  layoutone: JSON.parse(i.layoutone) || {},
  layouttwo: JSON.parse(i.layouttwo) || {},
  vizStateSecond: JSON.parse(i.vizStateSecond) || {},
  vizState: JSON.parse(i.vizState)
});

const PagePerformanceAnalytics = (props) => {

  const contextData = useContext(AppContext);
  const [pageNo, setPageNo] = useState(DEFAULT_PAGE_NO);
  const [searchText, setSearchText] = useState("");
  // const [legends, setlegends] = useState("");
  // const [websiteId, setWebsiteId] = useState(0);
  const [selectedWebsiteId, setSelectedWebsiteId] = useState({});
  // const [profileId, setProfileId] = useState(0);
  const [selectedProfileId, setSelectedProfileId] = useState({});
  const [pagesData, setPagesData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [sourceMedium, setSourceMedium] = useState("direct");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isRefresh, setIsRefresh] = useState(false);
  const [offset, setOffset] = useState(0);
  const [d3, setd3] = useState();
  // const [pageviewsAndUsersApi, setPageviewsAndUsersApi] = useState({ url: "http://localhost:3004/audiencetrends/", type: "get", count: "" });
  const [apiResponse, setApiResponse] = useState([]);
  const [apiResponseMaster, setApiResponseMaster] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [updateString, setUpdateString] = useState("")
  const [t] = useTranslation();
  const [getTotalResults, setGetTotalResults] = useState(0)
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    // const profileId = selectedProfileId && Object.keys(selectedProfileId).length > 0 ? selectedProfileId["WebsiteProfiles.ProfileId"]: 18841;

    // const url = window.location.href.split("/");
    // const urlEndpoints = `${url[0]}//${url[2]}/`;
    const selectedstartdate = moment(contextData.startDate).format("YYYY-MM-DD");
    const selectedenddate = moment(contextData.endDate).format("YYYY-MM-DD");
    const selectedPreviousYearStartDate = moment(contextData.startDate).add(-1, 'years').format("YYYY-MM-DD");
    const selectedPreviousYearEndDate = moment(contextData.endDate).add(-1, 'years').format("YYYY-MM-DD");

    if (contextData.selectedTab == "Website") {
      setShowLoader(true)
      let dta = null;
      if (contextData.selectedProfile["WebsiteProfiles.ProfileId"]) {
        if (searchText == undefined || searchText == "") {
          dta = ((contextData.selectedProfile["WebsiteProfiles.ProfileId"] !== undefined && contextData.selectedWebsite["WebsiteList.WebsiteUrl"] !== undefined
            && contextData.selectedProfile["WebsiteProfiles.IntelConfigId"] !== undefined && sourceMedium !== undefined && selectedstartdate !== "Invalid date" && selectedenddate !== "Invalid date" && selectedPreviousYearStartDate !== "Invalid date" && selectedPreviousYearEndDate !== "Invalid date"))
            ? getPages(contextData.selectedProfile["WebsiteProfiles.ProfileId"], contextData.selectedWebsite["WebsiteList.WebsiteUrl"], sourceMedium, selectedstartdate, selectedenddate, selectedPreviousYearStartDate, selectedPreviousYearEndDate, contextData.userId, contextData.selectedProfile["WebsiteProfiles.IntelConfigId"], contextData.companyId, offset)
            : null;
        }
      }
      setPagesData(dta);
    }
    else {
      setPagesData([])
    }

  }, [contextData.selectedProfile["WebsiteProfiles.ProfileId"], contextData.selectedTab, sourceMedium, contextData.startDate, contextData.endDate, searchText, offset]);

  useEffect(() => {
    setSearchData("")
    setApiResponse({})
    setUpdateString(Math.random().toString())
    setApiResponseMaster({});
  }, [contextData.selectedProfile]);

  const getData = (url) => {
    localStorage.setItem("pageNo", 1)
    request(url, METHODS.GET, {}, { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json" })
      .then((response) => {
        setApiResponseMaster(response);
        setApiResponse(response)
        setShowLoader(false);
        setUpdateString(Math.random().toString())
      })
      .catch(() => {

      })
  }
  const userSearch = (result, searchText) => {

    if (searchText !== undefined && searchText !== "") {
      let resultObject = result;
      if (result !== null) {
        if (resultObject?.data !== null && resultObject?.data?.length > 0) {
          // let resultData=[];
          // resultObject.data.forEach((element) => {
          //     if(element["ContentInsights.Page"] !== undefined){
          //       if (element["ContentInsights.Page"].match(new RegExp(searchText, 'gi'))) {
          //         resultData.push(element)
          //       }
          //     }

          // });
          let filterResult = resultObject?.data?.filter(item => item["ContentInsights.Page"]?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1);
          resultObject.data = filterResult;
        }
        //resultObject.data = result.data.slice(0, 10);
        //setCurrentPageNo(1);

        return resultObject;
      }

    } else {
      return result
    }

  }

  useEffect(() => {
    //setApiResponse({});
    setUpdateString(Math.random().toString())
    let searchResult = userSearch({ ...apiResponseMaster }, searchData)
    localStorage.setItem("pageNo", 1)
    setTimeout(() => { setApiResponse(searchResult); }, 500)
  }, [searchData, apiResponseMaster])

  const onPageNoChange = (number) => {
    const num = (number - 1) * TABLE_TOTAL_ROWS;
    const page = number;
    setOffset(num);
    setPageNo(page);
  };

  const showTooltips = (item) => {
    if (item.name == 'Page views and Users') {
      return "header.pageAnalyticsPageviewsandUserTooltipText";
    } else if (item.name == 'Content Insight') {
      return 'header.pageAnalyticsContentInsightTooltipText';
    } else {
      return "";
    }
  }
  useEffect(() => {

    if (pagesData !== null && pagesData.length > 0) {
      getData(pagesData[0].apiUrl.url);
    }

  }, [pagesData]);

  const tableData = useMemo(() => <ChartRendererTable
    pageNo={localStorage.getItem("pageNo") != undefined ? parseInt(localStorage.getItem("pageNo")) : 1}
    showPagination={true}
    resultSet={apiResponse}
    showCount={true}
    showNumberOfRows={10}
    setPageNo={1}
    searchText={updateString}
    setGetTotalResults={(value) => setGetTotalResults(value)}
  />, [updateString, apiResponse]);

  const dashboardItem = (item) => (
    <div className="page-analytics-switch-toggle page-analytics-summary-tooltip " key={item.id} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
      <DashboardItem
        key={item.id}
        itemId={item.id}
        title={item.name}
        enableOption={item.enableOption}>

        {!showLoader ?
          <>
            <Row>
              <Col className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
                <div className="d-inline-block float-end position-relative search-box-container-box">
                  <div className="d-inline-block float-end search-box-container">
                    <Search searchWaterMark={t("header.searchPlaceHolder")} setsearchData={setSearchData} searchData={searchData} isAutoComplete={false} type={'client'}></Search>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {tableData}
              </Col>
            </Row>
          </>
          : <div className={`preloader loader-center-align`}>
            <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
          </div>}
      </DashboardItem>

    </div>
  );

  const Empty = () => (
    <div
      style={{
        textAlign: 'center',
        padding: 12,
      }}
    >
      <h2>{t("header.getConnectIdIntelligence")}</h2>
    </div>
  );
  const handleBackButtonClick = (e) => {
    localStorage.setItem("IsPageRefresh", "0");
    props.history.push(`/website`);
  }

  return !pagesData || pagesData.length > 0 ? (
    <>
      <PagePerformance
        //dashboardItems={pagesData}
        handleBackButtonClick={handleBackButtonClick}
      // usersData={usersData}
      // deserializeItem={deserializeItem}
      // dashboardItem={dashboardItem}
      // selectedProfileId={contextData.selectedProfile}
      // setSelectedProfileId={setSelectedProfileId}
      // selectedWebsiteId={contextData.selectedWebsite}
      // setSelectedWebsiteId={setSelectedWebsiteId}
      // startDate={contextData.startDate}
      // endDate={contextData.endDate}
      // setStartDate={setStartDate}
      // setEndDate={setEndDate}
      >
        {pagesData && pagesData.map(deserializeItem).map(dashboardItem)}
      </PagePerformance>
    </>
  ) : (
    <Empty />
  );

}

export default PagePerformanceAnalytics