import React, { useState } from 'react';
import PropTypes from 'prop-types';

const NumberComponent = ({
    showPercentage,
    showPositiveOrNegative,
    isPrimary,
    isCarouselCard,
    number
}) => {
    const percentageColor = isPrimary ? "metrics" : (isCarouselCard ? "metrics-positive " + (showPositiveOrNegative === true ? "text-success" : "text-danger") + " ms-2" : "metrics-neutral ms-2");
    const showSign = number > 0 ? "+" : "";
    const value=showPositiveOrNegative?number>100?100:number:number;
    return (

        <span className={`${percentageColor}`}>
            {showPositiveOrNegative ?
                `${showSign}${value}`
                :
                number
            }
            {showPercentage ? "%" : ""}
        </span>

    );
};

NumberComponent.propTypes = {
    showPercentage: PropTypes.bool,
    showPositiveOrNegative: PropTypes.bool,
    isPrimary: PropTypes.bool,
    isCarouselCard: PropTypes.bool,
    number: PropTypes.number
};


NumberComponent.defaultProps = {
    showPercentage: false,
    showPositiveOrNegative: false,
    isPrimary: false,
    isCarouselCard: false,
    number: 0
};


export default NumberComponent;