import { Row, Col  } from '@themesberg/react-bootstrap';
import React, { useEffect, useState,useContext } from 'react';
import { useTranslation } from "react-i18next";

function PreviewWebsitePage() {
    const [t] = useTranslation();
    return (
        <>
        <Row className="mb-3">
            <Col lg={12} xl={4}>
                <h2 className="h2 mb-0 ps-3">{t("header.previewWebsiteTitle")}</h2>
            </Col>
        </Row>
        <Row className="mb-3">
                <Col lg={12}>
                    <h5 className="h5 ps-3">{t("header.previewWebsiteHeading")}</h5>
                    <p className="ps-3">
                        <em>{t("header.previewWebsiteWhy")}</em>{t("header.previewWebsiteMessage")}
                    </p>
                </Col>
            </Row>
        </>
    )
}

export default PreviewWebsitePage