import { Row, Col, Image, Card } from '@themesberg/react-bootstrap';
import React, { useEffect, useState, useContext } from 'react';
import TableComponent from "../components/Table";
import Dropzone from "../components/Dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faFilter, faTrashAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Nav } from '@themesberg/react-bootstrap';
import MyDocuments from '../components/MyDocuments';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import ButtonsComponent from '../components/Buttons';
import { useTranslation } from "react-i18next";

function MyDocumentsPage() {
    const [blurLoader, setBlurLoader] = useState(false);
    const [isTableRefresh, setIsTableRefresh] = useState(false);
    const [t] = useTranslation();
    return (
        <>
            {blurLoader && <div id='blurdiv' className="blurbackground">
                <div className={`preloader loader-center-align`}>
                    <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                </div>
            </div>}
            <div className="px-3">
                <Row className="mb-3">
                    <Col lg={12}>
                        <h2 className="h2 mb-0">{t("header.SettingsTabMyDocuments")}</h2>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col lg={12}>
                        <div className="bg-white shadow-soft border rounded border-light w-100 my-documents">
                            <Row>
                                <Col xs={12}>
                                    <div className="p-4 pb-0"><Dropzone setBlurLoader={setBlurLoader} setIsTableRefresh={setIsTableRefresh} /></div>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12}>
                                    <MyDocuments setBlurLoader={setBlurLoader} setIsTableRefresh={setIsTableRefresh} isTableRefresh={isTableRefresh}></MyDocuments>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default MyDocumentsPage