import { Row, Col  } from '@themesberg/react-bootstrap';
import React, { useEffect, useState,useContext } from 'react';
import { useTranslation } from 'react-i18next';

function BannerImagePages() {
    const [t, i18n] = useTranslation();
    return (
        <div className="px-3">
        <Row className="mb-3">
            <Col lg={12}>
                <h2 className="h2 mb-0">{t("header.AppBannerTabTitle")}</h2>
            </Col>
        </Row>
        {/* <Row className="mb-3">
                <Col lg={12}>
                    <h5 className="h5">What? Change banner image</h5>
                    <p className="ps-3">
                        Change the banner image within the app to coincide with campaigns or to alert users to key announcements, then track how your app audience reacts and engages with your app.
                    </p>
                </Col>
            </Row> */}
        </div>
    )
}

export default BannerImagePages