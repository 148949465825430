import CryptoJS from 'crypto-js';

const encryptData = (data, secretKey) => {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    return encryptedData;
}

const decryptData = (encryptedData, secretKey) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    } 
    catch (error) {
        sessionStorage.clear();
        localStorage.clear();
    }
}

export { encryptData, decryptData };