import { getCardDataFromGAMultiline, getCookieDetailsView } from "../../apis/services/websitePageService";

export const apiChartsPages = [
    {
        id: 1,
        service: (cryptoKey, payload, callBackFn) => { getCardDataFromGAMultiline(cryptoKey, payload, callBackFn) },
    },
]

export const apiChartsCookie = [
    {
        id: 1,
        service: (cryptoKey, payload, callBackFn) => { getCookieDetailsView(cryptoKey, payload, callBackFn) },
    },
]

export const getPages = (profileId, urlEndpoints, sourceMedium, startDate, endDate, previousYearStartDate, previousYearEndDate, userId, intellConfigId, companyid, offset) => {
    let pagesData = [
        // dummy
        {
            id: "1",
            layout: "{\"x\":0,\"y\":25,\"w\":12,\"h\":22}",
            layoutone: `{}`,
            layouttwo: `{}`,
            name: "Content Insight",
            title: "Pages",
            mainlayout: `{}`,
            vizStateSecond: `{}`,
            showSearch: true,
            pagination: { show: true, query: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsdata.count\"],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{},\"dimensions\":[], \"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}]}}` },
            summary: `{}`,
            vizState: `{}`,
            extraSummary: `{}`,
            legends: ``,
            downloadExcel: true,
            //classname: "col-full-width",
            enableOption: false,
            //      apiUrl: { url: `${process.env.REACT_APP_API_KEY_NEW}/WebsiteAnalytics/GetCardDataFromGA?StartDate=${startDate}&EndDate=${endDate}&profileID=${profileId}&Matrix=screenPageViews,avgTimeOnPage,bounceRate,totalUsers&Dimensions=pageTitle,pagePath&Component=table,contentinsights`, type: "get", count: "" },
            apiUrl: { url: `${process.env.REACT_APP_API_KEY_NEW}/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=${startDate}&EndDate=${endDate}&profileID=${profileId}&Matrix=screenPageViews,averageSessionDuration,bounceRate,totalUsers&Dimensions=pageTitle,pagePath&Component=table,contentinsights`, type: "get", count: "" },

        }
    ];
    return pagesData;
};


export const getPageSearch = (profileId, startDate, endDate, offset, pagetitle) => {
    let pagesData = [
        {
            id: "1",
            layout: "{\"x\":0,\"y\":25,\"w\":12,\"h\":17}",
            layoutone: `{}`,
            layouttwo: `{}`,
            name: "Content Insight",
            title: "Pages",
            mainlayout: `{}`,
            vizStateSecond: `{}`,
            showSearch: true,
            // pagination: { show: true, query: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsdata.count\"],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{},\"dimensions\":[], \"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]},{\"member\":\"Googleanalyticsdata.pagetitlepath\",\"operator\":\"contains\",\"values\":[\"${pagetitle}"]}]}}` },
            // summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsdata.count\"],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{},\"dimensions\":[], \"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]},{\"member\":\"Googleanalyticsdata.pagetitlepath\",\"operator\":\"contains\",\"values\":[\"${pagetitle}"]}]}}`,
            // vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Googleanalyticsdata.pagetitlepath\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false,\"limit\":15},\"query\":{\"measures\":[\"Googleanalyticsdata.avgtimeonpage\",\"Googleanalyticsdata.pageviews\",\"Googleanalyticsdata.bouncerate\",\"Googleanalyticsdata.users\"],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Googleanalyticsdata.pageviews\":\"desc\"},\"limit\":15,\"offset\": ${offset},\"dimensions\":[\"Googleanalyticsdata.pagetitlepath\"], \"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]},{\"member\":\"Googleanalyticsdata.pagetitlepath\",\"operator\":\"contains\",\"values\":[\"${pagetitle}"]}]}}`,
            legends: ``,
            downloadExcel: true,
            //classname: "col-full-width",
            enableOption: false
        }
    ];
    return pagesData;
};


export const getPagesChart = (
    //profileId, startDate, endDate, previousYearStartDate, previousYearEndDate
) => {
    let pagesData = [
        {
            isSummeryShown: true,
            isResultShownFromApi: true,
            chartType: "multiline",
            id: "1",
            layout: "{\"x\":1,\"y\":24,\"w\":12,\"h\":8}",
            layoutone: `{}`,
            layouttwo: `{}`,
            name: "Page views and Users",
            mainlayout: `{}`,
            vizStateSecond: `{}`,
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsdata.sessions\"],\"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\" , \"compareDateRange\": [[\"${previousYearStartDate}\",\"${previousYearEndDate}\"], [\"${startDate}\",\"${endDate}\"]]}],\"order\":{},\"dimensions\":[]}}`,
            //vizState: `{\"chartType\":\"line\",\"pivotConfig\":{\"x\":[\"Googleanalyticsdata.Mname\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsdata.sessions\"],\"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\", \"compareDateRange\": [[\"2020-06-27\",\"2020-07-27\"], [\"${startDate}\",\"${endDate}\"]]}],\"order\":{\"Googleanalyticsdata.Monthid\":\"asc\"},\"dimensions\":[\"Googleanalyticsdata.Mname\", \"Googleanalyticsdata.Monthid\"]}}`,
            //vizState: `{\"chartType\":\"multiLine\",\"pivotConfig\":{\"x\":[\"Googleanalyticsdata.gadate.month\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false,\"limit\":5},\"query\":{\"measures\":[\"GoogleanalyticsdataExtraSummary.pageviews\",\"GoogleanalyticsdataExtraSummary.users\"],\"filters\":[],\"timeDimensions\":[{\"dimension\": \"GoogleanalyticsdataExtraSummary.gadate\",\"granularity\":\"month\",\"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Googleanalyticsdata.Monthid\":\"asc\"},\"limit\":5,\"dimensions\":[], \"filters\":[{\"member\": \"GoogleanalyticsdataExtraSummary.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}]}}`,
            //extraSummary: `{\"dimensions\":[],\"timeDimensions\":[{\"dimension\":\"GoogleanalyticsdataExtraSummary.gadate\",\"dateRange\":[\"${startDate}\",\"${endDate}\"]}],\"order\":{},\"measures\":[\"GoogleanalyticsdataExtraSummary.pageviews\",\"GoogleanalyticsdataExtraSummary.users\"],\"filters\":[{\"member\":\"GoogleanalyticsdataExtraSummary.profileid\",\"operator\":\"equals\",\"values\":[\"${profileId}"]},{\"member\":\"GoogleanalyticsdataExtraSummary.profileid\",\"operator\":\"set\"}],\"limit\":5}`,
            legends: [{ name: "Pageviews", name: "Users" }],
            enableOption: false,
            cardName: "PageviewsandUsers",
        }
    ];

    return pagesData;
};

export const getCookieChart = (profileId, websiteURL, startDate, endDate) => {
    let pagesData = [
        {
            isSummeryShown: true,
            isResultShownFromApi: true,
            chartType: "multiline",
            id: "1",
            layout: "{\"x\":1,\"y\":24,\"w\":12,\"h\":8}",
            layoutone: `{}`,
            layouttwo: `{}`,
            name: "Cookies",
            mainlayout: `{}`,
            vizStateSecond: `{}`,
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsdata.sessions\"],\"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\" , \"compareDateRange\": [[\"${previousYearStartDate}\",\"${previousYearEndDate}\"], [\"${startDate}\",\"${endDate}\"]]}],\"order\":{},\"dimensions\":[]}}`,
            //vizState: `{\"chartType\":\"line\",\"pivotConfig\":{\"x\":[\"Googleanalyticsdata.Mname\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsdata.sessions\"],\"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\", \"compareDateRange\": [[\"2020-06-27\",\"2020-07-27\"], [\"${startDate}\",\"${endDate}\"]]}],\"order\":{\"Googleanalyticsdata.Monthid\":\"asc\"},\"dimensions\":[\"Googleanalyticsdata.Mname\", \"Googleanalyticsdata.Monthid\"]}}`,
            //vizState: `{\"chartType\":\"multiLine\",\"pivotConfig\":{\"x\":[\"Googleanalyticsdata.gadate.month\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false,\"limit\":5},\"query\":{\"measures\":[\"GoogleanalyticsdataExtraSummary.pageviews\",\"GoogleanalyticsdataExtraSummary.users\"],\"filters\":[],\"timeDimensions\":[{\"dimension\": \"GoogleanalyticsdataExtraSummary.gadate\",\"granularity\":\"month\",\"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Googleanalyticsdata.Monthid\":\"asc\"},\"limit\":5,\"dimensions\":[], \"filters\":[{\"member\": \"GoogleanalyticsdataExtraSummary.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}]}}`,
            //extraSummary: `{\"dimensions\":[],\"timeDimensions\":[{\"dimension\":\"GoogleanalyticsdataExtraSummary.gadate\",\"dateRange\":[\"${startDate}\",\"${endDate}\"]}],\"order\":{},\"measures\":[\"GoogleanalyticsdataExtraSummary.pageviews\",\"GoogleanalyticsdataExtraSummary.users\"],\"filters\":[{\"member\":\"GoogleanalyticsdataExtraSummary.profileid\",\"operator\":\"equals\",\"values\":[\"${profileId}"]},{\"member\":\"GoogleanalyticsdataExtraSummary.profileid\",\"operator\":\"set\"}],\"limit\":5}`,
            legends: [{ name: "Accepted all", name: "Declined all", name: "Accepted a subset" }],
            enableOption: false
        }
    ];

    return pagesData;
};