
import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { useMutation } from '@apollo/react-hooks';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
// import { GET_DASHBOARD_ITEMS } from '../graphql/queries';
// import { UPDATE_DASHBOARD_ITEM } from '../graphql/mutations';
import { Row, Col, Button, Form, InputGroup, FormCheck, Image } from '@themesberg/react-bootstrap';
import Loader from './VoltLoader';
import DropdownWithIconComponent from "./DropdownWithIcons";
import { isMobile } from "react-device-detect";
import { withRouter } from "react-router";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faChevronLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import { DEFAULT_WEBSITE_DASHBORD, DEFAULT_PROFILE_DASHBORD, object_equals, NULL_VALUE_MESSAGE_DROPDOWN } from "../AppConfig";
import { dashbordDateRangeList } from "../utils/datetime";
import ButtonComponent from "./Buttons";
import AppContext from './AppContext';
import { useTranslation } from 'react-i18next';
import CommonHeader from './CommonHeader';
import PagePerformanceChart from './PagePerformanceChart';
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import CookiePerformanceChart from './CookiePerformanceChart';
import { decryptData } from '../utils/crypto';

const ReactGridLayout = WidthProvider(RGL);


const CookiePerformance = ({ children, dashboardItems, selectedProfileId, setSelectedProfileId, selectedWebsiteId, setSelectedWebsiteId, history, location, startDate, setStartDate, endDate, setEndDate, profileData, setProfileData, handleBackButtonClick }) => {

  const contextData = useContext(AppContext);
  // // const [websiteId, setWebsiteId] = useState(0);
  // const [selectedWebsiteId, setSelectedWebsiteId] = useState({});
  // // const [profileId, setProfileId] = useState(0);
  // const [selectedProfileId, setSelectedProfileId] = useState({});
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);

  const [datePickerValue, setDatePickerValue] = useState("");
  const [selectedDatePicker, setSelectedDatePicker] = useState({});
  const [datePickerCompareValue, setDatePickerCompareValue] = useState("");
  const [companyIdValue, setcompanyIdValue] = useState("");
  const [toggle, setToggle] = useState(false);
  const [t, i18n] = useTranslation();
  const [siteData, setSiteData] = useState([])
  const [updateKey, setUpdateKey] = useState(0)
  const [websiteData, setWebsiteData] = useState([])
  const [selectedProfileIdNew, setSelectedProfileIdNew] = useState("")
  const [selectedWebsiteIdNew, setSelectedWebsiteIdNew] = useState("");
  const [autoLoad, setAutoLoad] = useState("");
  const [profilesList, setProfilesList] = useState([]);
  const [blurLoader, setBlurLoader] = useState(false);
  //   const [updateDashboardItem] = useMutation(UPDATE_DASHBOARD_ITEM, {
  //     refetchQueries: [
  //       {
  //         query: GET_DASHBOARD_ITEMS,
  //       },
  //     ],
  //   });

  const showLeftIcon = isMobile ? true : false;

  const cubejsApi = cubejs(
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJteWFwcGlkIjozLCJpYXQiOjE2MTgyMjkzNDIsInVzZXJfaWQiOjM4MDgxLCJwbGF0Zm9ybV9pZCI6MSwic2VydmljZV9pZCI6OCwiY29tcGFueV9pZCI6OTM0NX0.lYSmPrF55TH3y8HQk96khmXn08XBt5nkUh7vzxfyak4',
    { apiUrl: `${process.env.REACT_APP_CUBESERVERURL}/cubejs-api/v1` }
  );


  useEffect(() => {

    if (profilesList != null && profilesList.length) {
      let profilesData = profilesList.filter((e) => { return e.WebsiteId === selectedWebsiteIdNew["WebsiteList.WebsiteId"] });
      let profileDataUpdated = profilesData.map((e) => ({ ["WebsiteProfiles.ProfileId"]: e.ProfileId, ["WebsiteProfiles.DisplayName"]: e.DisplayName, ["WebsiteProfiles.IntelConfigId"]: e.IntelConfigId }));
      localStorage.setItem("cookieProfileData", JSON.stringify(profilesData));
      if (selectedWebsiteIdNew["WebsiteList.WebsiteId"]) {
        renderWebsiteProfiles({ resultSet: profileDataUpdated })
      }

    }

  }, [selectedWebsiteIdNew["WebsiteList.WebsiteId"], profilesList])

  useEffect(() => {
    localStorage.setItem("websiteData", JSON.stringify([]))
    localStorage.setItem("profileData", JSON.stringify([]))
    if (contextData.companyId > 0) {
      getWebsites();
    }
  }, [contextData.companyId]);

  const getWebsites = () => {
    setBlurLoader(true);
    request(baseURL + `/CommonV2/GetWebsiteChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        setBlurLoader(false);
        let resultData = [];
        if (res.result !== null && res.result.length) {
          resultData = res.result.map((e) => ({ ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl }))
        }
        rendersite({ resultSet: resultData })
      })
      .catch((err) => {
        setBlurLoader(false);
      })

    request(baseURL + `/WebsiteAnalytics/GetWebsites?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        setProfilesList(res.result)
        setBlurLoader(false);
      })
      .catch((err) => {
        setBlurLoader(false);
      })
  }

  useEffect(() => {
    if (contextData.userId > 0 && contextData.companyId > 0) {
      setcompanyIdValue(contextData.companyId);
      // Fetching values from Local storage in case of Page Refresh
      if (localStorage.getItem("IsPageRefresh") == "1") {

        if (localStorage.getItem("WebsiteListWebsiteId")) {
          //setSelectedWebsiteIdNew();
          onWebsiteDropdownChange(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")));
        }
        if (localStorage.getItem("WebsiteProfilesIntelConfigId") && localStorage.getItem("WebsiteProfilesIntelConfigId") != "") {
          //setSelectedProfileIdNew(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")));
          onProfileDropdownChange(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")));

        }
        if (localStorage.getItem("SelectedDatePicker") && localStorage.getItem("SelectedDatePicker") != "") {
          setSelectedDatePicker(JSON.parse(localStorage.getItem("SelectedDatePicker")));

        }
      }
      // contextData.changeSelectedWebsite({});
      // setSelectedWebsiteId({});
      //setSelectedWebsiteIdNew({})
    }
    setUpdateKey(Math.random());
  }, [contextData.userId, contextData.companyId]);

  useEffect(() => {
    localStorage.setItem("websiteData", JSON.stringify([]))
    localStorage.setItem("profileData", JSON.stringify([]))
    //setSelectedProfileIdNew({})
  }, [contextData.companyId]);

  const compareItem = (items, compareItem) => {
    if (items.length > 0) {
      for (let i = 0; i <= items.length; i++) {
        if (object_equals(items[i], compareItem)) {
          return true;
        }
      }
    }
  }

  const onWebsiteDropdownChange = (data) => {

    setSelectedProfileIdNew({});

    contextData.changeSelectedWebsite(data);
    setSelectedWebsiteIdNew(data)

  };

  const onProfileDropdownChange = (data) => {
    const intellConfigId = data["WebsiteProfiles.IntelConfigId"];
    contextData.changeIntelConfigId(intellConfigId);
    // contextData.changeSelectedProfile(data);
    // setSelectedProfileId(data);
    setSelectedProfileIdNew(data)

    setUpdateKey(Math.random())
  };

  const setDefaultPickerValueOnDateChange = (dateType) => {
    if (datePickerValue.length > 0) {
      const datePickerSplit = datePickerValue.split("-");
      let datePickerVle;
      if (dateType == "startDate") {

        datePickerVle = `${moment(startDate).format("MMM DD,YYYY")} - ${moment(datePickerSplit[1]).format("MMM DD,YYYY")}`;
        setDtPickerCompareValueOnDatePickerChange(startDate, moment(datePickerSplit[1]));
      }
      else if (dateType == "endDate") {
        datePickerVle = `${moment(moment(datePickerSplit[0])).format("MMM DD,YYYY")} - ${moment(endDate).format("MMM DD,YYYY")}`;
        setDtPickerCompareValueOnDatePickerChange(moment(datePickerSplit[0]), endDate);
      }
      setDatePickerValue(datePickerVle);
    }
  };

  const setDtPickerCompareValueOnDatePickerChange = (startDate, endDate) => {
    endDate = moment(endDate);
    startDate = moment(startDate);

    let previousEndDate = moment(endDate).subtract(1, 'years').format("MMM DD,YYYY");
    let previousStartDate = moment(startDate).subtract(1, 'years').format("MMM DD,YYYY");
    let datePickerCmpr = `${previousStartDate} - ${previousEndDate}`;
    setDatePickerCompareValue(datePickerCmpr);

  };
  const applyFilters = () => {
    setBlurLoader(false);
    const websiteId = selectedWebsiteIdNew["WebsiteList.WebsiteId"];
    contextData.changeWebsiteId(websiteId);
    setSelectedWebsiteId(selectedWebsiteIdNew)
    contextData.changeSelectedProfile(selectedProfileIdNew);
    setSelectedProfileId(selectedProfileIdNew);
    localStorage.setItem("WebsiteListWebsiteId", JSON.stringify(selectedWebsiteIdNew));// Adding WebsiteList.WebsiteId to local stoarge in case of Page Refresh
    localStorage.setItem("WebsiteProfilesIntelConfigId", JSON.stringify(selectedProfileIdNew));// Adding WebsiteProfiles.IntelConfigId to local stoarge in case of Page Refresh
  }
  const cancelFilters = () => {
    onWebsiteDropdownChange(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
    onProfileDropdownChange(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")))
  }
  const rendersite = ({ resultSet, error, pivotConfig, request }) => {

    // if (error) {
    //   return <div>{error.toString()}</div>;
    // }

    // if (!resultSet) {
    //   return <Loader show={false} />;
    // }

    localStorage.setItem("websiteData", JSON.stringify([]))
    localStorage.setItem("websiteData", JSON.stringify(resultSet))
    if (resultSet !== null && resultSet.length) {

      if (localStorage.getItem("WebsiteListWebsiteId")) {
        onWebsiteDropdownChange(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
      } else {
        onWebsiteDropdownChange(resultSet[0])
      }
      setUpdateKey(Math.random());
    }

    return (

      <>


      </>
    );

  }

  const renderWebsiteProfiles = ({ resultSet, error, pivotConfig, request }) => {

    // if (error) {
    //   return <div>{error.toString()}</div>;
    // }

    // if (!resultSet) {
    //   return <Loader show={false} />;
    // }
    localStorage.setItem("profileData", JSON.stringify([]))
    //commented below line because selectedProfileIdNew was not in use in if loop
    // if (resultSet !== undefined && resultSet.length && Object.keys(selectedProfileIdNew).length <= 0) 
    if (resultSet !== undefined && resultSet.length) {
      //setTimeout(()=>{
      if (compareItem(resultSet, JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId"))) === true) {

        onProfileDropdownChange(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")))
        //setSelectedProfileIdNew(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")));
        setAutoLoad(true)

      } else {

        onProfileDropdownChange(resultSet[0])
        //contextData.changeSelectedProfile(resultSet.tablePivot(pivotConfig)[0]);
        setSelectedProfileIdNew(resultSet[0]);
        setAutoLoad(true)
      }

      //},3000)

    }

    if (resultSet !== undefined && resultSet.length) {
      localStorage.setItem("profileData", JSON.stringify(resultSet))
      setUpdateKey(JSON.stringify(resultSet))
      // if(Object.keys(selectedProfileIdNew).length){
      //   setAutoLoad(true)
      // }


    } else if (resultSet !== undefined && resultSet.length <= 0) {
      setUpdateKey(JSON.stringify(resultSet))
      setAutoLoad(true)
    } else {

    }
    return (<></>);

  };
  // const renderWebsiteProfiles = ({ resultSet, error, pivotConfig, request }) => {

  //   if (error) {
  //     return <div>{error.toString()}</div>;
  //   }

  //   if (!resultSet) {
  //     return <Loader show={false} />;
  //   }
  //   if (resultSet !== null && resultSet.tablePivot(pivotConfig).length && Object.keys(selectedProfileIdNew).length <= 0) {
  //     //setTimeout(()=>{
  //     if (compareItem(resultSet.tablePivot(pivotConfig), JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")))) {
  //       onProfileDropdownChange(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")))
  //       setAutoLoad(true)
  //     } else {
  //       onProfileDropdownChange(resultSet.tablePivot(pivotConfig)[0])
  //       setSelectedProfileIdNew(resultSet.tablePivot(pivotConfig)[0])
  //       setAutoLoad(true)
  //     }

  //     //},3000)

  //     //
  //   }
  //   localStorage.setItem("profileData", JSON.stringify([]))
  //   if (resultSet !== null && resultSet.tablePivot(pivotConfig).length) {
  //     localStorage.setItem("profileData", JSON.stringify(resultSet.tablePivot(pivotConfig)))
  //     setUpdateKey(JSON.stringify(resultSet.tablePivot(pivotConfig)))
  //     // if(Object.keys(selectedProfileIdNew).length){
  //     //   setAutoLoad(true)
  //     // }


  //   } else if (resultSet !== null) {
  //     setUpdateKey(JSON.stringify(resultSet.tablePivot(pivotConfig)))
  //     setAutoLoad(true)
  //   } else {

  //   }


  //   return (

  //     <></>
  //   );

  // };

  const onDatePickerDropdownChange = (data) => {

    if (data && Object.keys(data).length > 0) {
      if (data.key.length > 0) {
        const datePickerSplit = data.key.split("-");
        contextData.changeStartDate(moment(datePickerSplit[0]));
        contextData.changeEndDate(moment(datePickerSplit[1]));
        if (data.compareOn || data.showDatePicker) {
          const compareOnStartDate = moment(datePickerSplit[0]).add(-data.compareOn, 'days').format("MMM DD,YYYY");
          const compareOnEndDate = moment(datePickerSplit[1]).add(-data.compareOn, 'days').format("MMM DD,YYYY");
          const compareOnValue = `${compareOnStartDate} - ${compareOnEndDate}`;
          setDatePickerValue(`${moment(datePickerSplit[0]).format("MMM DD,YYYY")} - ${moment(datePickerSplit[1]).format("MMM DD,YYYY")}`);
          setDatePickerCompareValue(compareOnValue);
        }
      }

      if (data.showDatePicker)
        setShowCustomDatePicker(true);
      else
        setShowCustomDatePicker(false);
    }
    // if (value) {
    // setDatePickerValue(data.key);
    setSelectedDatePicker(data)
    localStorage.setItem("SelectedDatePicker", JSON.stringify(data));// Adding SelectedDatePicker to local stoarge in case of Page Refresh

    // }

  };

  const validStartDate = (current) => {
    let currentDay = moment(endDate).format("MM/DD/YYYY");
    return current.isBefore(currentDay);
  };

  const validEndDate = (current) => {
    // let currentDay = moment().format();
    let yesterday = moment().subtract(1, 'day');
    return current.isBefore(yesterday);
  };

  const toggleChange = () => {
    setToggle(!toggle);
  };

  const onLeftIconClick = (e) => {
    e.preventDefault();
    history.goBack();
    // window.history.back();
    // history.push(`${location.pathname}`);
  };


  //slider dropdown
  const onClickOutsideListener = () => {
    setShowCustomDatePicker(false);
    document.removeEventListener("click", onClickOutsideListener)
  }
  const commonHeaderChild = useMemo(() => <><CommonHeader cancelFilters={cancelFilters} channel="website" autoLoad={autoLoad} history={history} dataSource={[
    { name: 'Dropdown1 - Website', data: JSON.parse(localStorage.getItem("websiteData")), selectedItem: contextData.selectedWebsite, isVisible: true, label: 'Website', callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" },
    { name: 'Dropdown2 - Profile', data: JSON.parse(localStorage.getItem("profileData")), selectedItem: selectedProfileIdNew, isVisible: true, label: 'Website profile', callback: onProfileDropdownChange, displayText: "WebsiteProfiles.DisplayName" },
  ]} title={t("header.coockiesTitle")} showDate={true} applyFilters={applyFilters} /></>, [selectedProfileIdNew, selectedWebsiteIdNew, updateKey, autoLoad])

  // const profileQuery = useMemo(() => <>
  //   <QueryRenderer
  //     query={{
  //       "dimensions": [
  //         "WebsiteProfiles.ProfileId",
  //         "WebsiteProfiles.DisplayName",
  //         "WebsiteProfiles.IntelConfigId"
  //       ],
  //       "timeDimensions": [],
  //       "order": {
  //         "WebsiteProfiles.DisplayName": "asc"
  //       },
  //       "filters": [
  //         {
  //           "member": "WebsiteProfiles.WebsiteId",
  //           "operator": "equals",
  //           "values": [`${selectedWebsiteIdNew["WebsiteList.WebsiteId"]}`]
  //         },
  //         {
  //           "member": "WebsiteProfiles.CompanyId",
  //           "operator": "equals",
  //           "values": [`${contextData.companyId}`]
  //         },
  //         {
  //           "member": "WebsiteProfiles.UserId",
  //           "operator": "equals",
  //           "values": [`${contextData.userId}`]
  //         }
  //       ],
  //     }}
  //     cubejsApi={cubejsApi}
  //     resetResultSetOnChange={true}
  //     render={(props) => renderWebsiteProfiles({
  //       ...props,
  //       chartType: 'dropdown',
  //       pivotConfig: {
  //         "x": [
  //           "WebsiteProfiles.ProfileId",
  //           "WebsiteProfiles.DisplayName",
  //           "WebsiteProfiles.IntelConfigId"

  //         ],
  //         "y": ["WebsiteProfiles.DisplayName"],
  //         "fillMissingDates": true,
  //         "joinDateRange": false
  //       }
  //     })}
  //   />
  // </>, [selectedWebsiteIdNew])

  return (
    <>
      {blurLoader && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align fff`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}
      {/* 
      <QueryRenderer
        query={{
          "dimensions": [
            "WebsiteList.WebsiteId",
            "WebsiteList.WebsiteName",
            "WebsiteList.WebsiteUrl"
          ],
          "timeDimensions": [],
          "order": {
            "WebsiteList.WebsiteId": "asc"
          },
          "filters": [
            {
              "member": "WebsiteList.CompanyId",
              "operator": "equals",
              "values": [`${contextData.companyId}`]
            },
            {
              "member": "WebsiteList.UserId",
              "operator": "equals",
              "values": [`${contextData.userId}`]
            }
          ],
        }}
        cubejsApi={cubejsApi}
        resetResultSetOnChange={false}
        render={(props) => rendersite({
          ...props,
          chartType: 'dropdown',
          pivotConfig: {
            "x": [
              "WebsiteList.WebsiteId",
              "WebsiteList.WebsiteName",
              "WebsiteList.WebsiteUrl"

            ],
            "y": ["WebsiteList.WebsiteName"],
            "fillMissingDates": true,
            "joinDateRange": false
          }
        })}
        resetResultSetOnChange={true}
      /> */}

      <Row>
        <Col lg={12}>
          <Button variant="light" onClick={(e) => handleBackButtonClick(e)} bsPrefix="text" className="ms-3 link-icn-btn">
            <FontAwesomeIcon className="me-1" icon={faChevronLeft} /> {t("header.backButton")}
          </Button>
        </Col>
      </Row>

      <Row className="align-items-center">
        <Col>
          {/* {(selectedWebsiteIdNew["WebsiteList.WebsiteId"] != undefined) && profileQuery} */}
          {commonHeaderChild}
        </Col>
      </Row>

      {selectedWebsiteId && (selectedWebsiteId["WebsiteList.WebsiteId"] || selectedProfileId["WebsiteProfiles.ProfileId"]) ?
        <Row>
          <Col>
            <div className="d-inline-block pt-3 ps-3 w-100" id="cookiesContent">
              <div className="shadow-soft border rounded border-light p-3 ps-2 pe-2 w-100 nested-card-container page-performance-detail">
                <CookiePerformanceChart selectedWebsiteId={selectedWebsiteId} selectedProfileId={selectedProfileId}></CookiePerformanceChart>
              </div>
            </div>
          </Col>
        </Row>
        : <div className="p-3 w-100" id="cookiesContent">
          <div className="bg-white shadow-soft border rounded border-light w-100 fmh-200 position-relative">
            <div className="card-no-data mt-n3">{t("header.NoDataFound")}</div>
          </div>
        </div>
      }
    </>
  );
};

export default withRouter(CookiePerformance);
