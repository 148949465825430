import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { withRouter } from 'react-router';
import AppContext from '../components/AppContext';
import { getMenusAndSubMenuDetails } from "../utils/common";
import { menuItemsDetais } from "../utils/sidebar";
import DropdownWithIconsComponent from "../components/DropdownWithIcons";
import { header_languages } from "../AppConfig";
import { useTranslation } from 'react-i18next';
import '../i18n';
import { Button } from '@themesberg/react-bootstrap';
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import PdfComponent from './PdfComponent';
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import { decryptData, encryptData } from '../utils/crypto';

const HeaderDropdown = (props) => {
    const contextData = useContext(AppContext);

    const [selectedLanguage, setSelectedLanguage] = useState({});
    const [selectedComapny, setSelectedCompany] = useState({});
    const [t, i18n] = useTranslation();
    const [page2, setPage2] = useState("");
    const [currentWebsiteId, setCurrentWebsiteId] = useState(0);
    const [isEditWebsiteEnabled, setIsEditWebsiteEnabled] = useState(false)

    useEffect(() => {
        if (Array.isArray(contextData.menu) && contextData.menu.length === 0) {
            localStorage.setItem("IsPageRefresh", "1");
            localStorage.setItem("PageURL", window.location.href);
        }
        getSelectedLanguage();
        getCompanyData();
    }, []);

    useEffect(() => {
        //check if edit website option is available or not
        const websiteAnalytics = contextData.menu.filter(x => x.title === "Website");
        if (websiteAnalytics.length > 0 && websiteAnalytics[0].subMenu && websiteAnalytics[0].subMenu.filter(x => x.title === "Edit Website").length > 0) {
            const editWebsite = websiteAnalytics[0].subMenu.filter(x => x.title === "Edit Website");
            setIsEditWebsiteEnabled(editWebsite[0].isActive)
        } else (
            setIsEditWebsiteEnabled(false)
        )
    }, [contextData.menu, contextData.selectedProfile["WebsiteProfiles.ProfileId"]])

    // useEffect(() => {
    //     let websiteid = 0;
    //     let url = window.location.href;
    //     if ((contextData.userId > 0 && contextData.companyId > 0 && websiteid > 0 && (url != page2 || currentWebsiteId != websiteid))) {
    //         if (!window.location.href.includes("marketing")) {
    //             props.setValid(false);
    //         }
    //         axios
    //             .get(
    //                 `${process.env.REACT_APP_API_KEY}/UserV2/GetMainMenuByClientUser`,
    //                 {
    //                     params: {
    //                         userId: contextData.userId,
    //                         companyid: contextData.companyId,
    //                         websiteid: websiteid
    //                     },
    //                     headers: {
    //                         'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
    //                     },
    //                 },
    //             )
    //             .then(response => {
    //                 if (response && response.data && response.data.resultData.menuDetails) {
    //                     let menuDta = getMenusAndSubMenuDetails(response.data.resultData.menuDetails);
    //                     contextData.setMenu(menuDta);
    //                     setCurrentWebsiteId(websiteid);
    //                     setPage2(url);
    //                     const page = url.slice(url.lastIndexOf('#') + 1);
    //                     let isvalidurl = 1;
    //                     const currentmenu = menuItemsDetais.find(dta => dta.link.toLowerCase() === page.toLowerCase());
    //                     if (currentmenu !== undefined) {
    //                         menuDta.forEach(function (item, index) {
    //                             if (Array.isArray(item.subMenu) && item.subMenu.length > 1) {
    //                                 let defaultSubMenuData = item.subMenu.filter(dta => dta.isActive === true && dta.isEnabled === true && currentmenu.title === dta.title)[0];
    //                                 if (defaultSubMenuData !== undefined) {
    //                                     isvalidurl = 1;
    //                                 }
    //                             }
    //                             else if (item.isActive === true && currentmenu.title === item.title) {
    //                                 isvalidurl = 1;
    //                             }
    //                         });
    //                     }
    //                     else if (page != "/") {
    //                     }

    //                     if (isvalidurl == 0 && !currentmenu.link.includes("marketing")) {
    //                         props.setValid(false);
    //                         if (currentmenu.title == "Visitor analytics" || currentmenu.title == "Page analytics" || currentmenu.title == "Website analytics" || currentmenu.title == "Notifications"
    //                             || currentmenu.title == "Editable Pages") {
    //                             props.history.push("/website-marketing")
    //                         }
    //                         if (currentmenu.title == "Alert Statistics" || currentmenu.title == "Manage Manual Alerts" || currentmenu.title == "Send Ad-hoc Alerts" || currentmenu.title == "Archived Alerts"
    //                             || currentmenu.title == "Manage Subscribers" || currentmenu.title == "Alert Summary") {
    //                             props.history.push("/alert-marketing")
    //                         }
    //                         if (currentmenu.title == "Webcast Statistics" || currentmenu.title == "Manage Users") {
    //                             props.history.push("/webcast-marketing")
    //                         }
    //                         if (currentmenu.title == "Manage Events" || currentmenu.title == "Add Event") {
    //                             props.history.push("/tools-marketing")
    //                         }
    //                         if (currentmenu.title == "Banner Image" || currentmenu.title == "Add Documents" || currentmenu.title == "Manage Documents" || currentmenu.title == "App Statistics") {
    //                             props.history.push("/app-marketing")
    //                         }
    //                         if (currentmenu.title == "Service Centre" || currentmenu.title == "Reports") {
    //                             props.history.push("/no-access")
    //                         }
    //                         if (currentmenu.title == "No Access") {
    //                             props.setValid(true);
    //                         }
    //                     }
    //                     else {
    //                         props.setValid(true);
    //                     }
    //                 }
    //                 else {
    //                 }
    //             })
    //             .catch(error => {
    //                 console.log("login error", error);
    //             });
    //     }
    //     else if (contextData.userId > 0 && contextData.companyId > 0 && websiteid == 0 && url != page2) {
    //         props.setValid(true);
    //     }
    //     props.setValid(true);
    // }, [contextData.companyId])

    const getCompanies = () => {
        let token = decryptData(localStorage.getItem("token"), contextData.cryptoKey);
        let userId = decryptData(localStorage.getItem("userId"), contextData.cryptoKey);
        if (token && userId) {
            axios
                .get(
                    `${process.env.REACT_APP_API_KEY}/Auth/FetchUserDetail`,
                    {
                        params: {
                            userId: userId
                        },
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    },
                )
                .then(response => {
                    if (response && response.data && response.data.resultData) {
                        contextData.setCompanies(response.data.resultData);
                        contextData.changeUserToken(decryptData(localStorage.getItem("token"), contextData.cryptoKey));
                        contextData.changeUerId(parseInt(decryptData(localStorage.getItem("userId"), contextData.cryptoKey)));
                        contextData.changeEmail(decryptData(localStorage.getItem('email'), contextData.cryptoKey));
                        contextData.changeCompanyId(parseInt(decryptData(localStorage.getItem('companyId'), contextData.cryptoKey)));
                        let companyId = parseInt(decryptData(localStorage.getItem('companyId'), contextData.cryptoKey));
                        if (response.data.resultData && Array.isArray(response.data.resultData) && response.data.resultData.length > 0) {
                            if (companyId) {
                                const selectedComapnyData = response.data.resultData.find(dta => dta.companyId === companyId);
                                if (selectedComapnyData && Object.keys(selectedComapnyData).length > 0) {
                                    setSelectedCompany(selectedComapnyData);
                                    getWebCastData(selectedComapnyData.companyId);
                                }
                            }
                        }
                    }
                })
                .catch(error => {
                    console.log("error", error);
                });
        }
    }

    const getSelectedLanguage = () => {
        let language;
        if (contextData.languagePreference) {
            const selectedLanguagData = header_languages.find(dta => dta.id === contextData.languagePreference);
            if (selectedLanguagData && Object.keys(selectedLanguagData).length > 0)
                language = selectedLanguagData;
        }
        else if (i18n.language) { // In case of page refresh 
            const selectedLanguagData = header_languages.find(dta => dta.id === i18n.language);
            if (selectedLanguagData && Object.keys(selectedLanguagData).length > 0) {
                language = selectedLanguagData;
            }
        }
        setSelectedLanguage(language);
    };

    const getCompanyData = () => {
        if (contextData.companies && Array.isArray(contextData.companies) && contextData.companies.length > 0) {
            if (contextData.companyId) {
                const selectedComapnyData = contextData.companies.find(dta => dta.companyId === contextData.companyId);
                if (selectedComapnyData && Object.keys(selectedComapnyData).length > 0) {
                    setSelectedCompany(selectedComapnyData);
                    localStorage.setItem("companyId", encryptData(selectedComapnyData.companyId, contextData.cryptoKey));
                    localStorage.setItem("companyName", encryptData(selectedComapnyData.companyName, contextData.cryptoKey));
                    getWebCastData(selectedComapnyData.companyId);
                }
            }
        }
        else {
            getCompanies();
        }
    };

    const onComapnyDropdownChange = (data) => {
        const companyId = data.companyId;

        // To avoid event change if same client is selected
        if (companyId != contextData.companyId) {
            getMenuData(companyId);
            getWebCastData(companyId);
            setSelectedCompany(data);
            contextData.changeSelectedWebsite({});
            contextData.changeSelectedProfile({});
            contextData.changeSelectedTab("");
            contextData.changeCompanyId(companyId);
            contextData.changeWebsiteId(0);
            localStorage.removeItem("selectedTab");
            contextData.changePresentationId(0);
            contextData.changePresentation({});
            contextData.changeEventId({});
            localStorage.setItem("companyId", encryptData(companyId, contextData.cryptoKey));
            localStorage.setItem("companyName", encryptData(data.companyName, contextData.cryptoKey));
            //Clear All previous items in local storage
            localStorage.setItem("IsPageRefresh", "0");  // set  page refresh indicator to 0 on side bar click event
            localStorage.removeItem("EventId");
            localStorage.removeItem("PresentationId");
            localStorage.removeItem("webcastEventId");
            localStorage.removeItem("ManageusersEventId");
            localStorage.removeItem("WebsiteProfilesIntelConfigId");
            localStorage.removeItem("WebsiteListWebsiteId");
            localStorage.removeItem("alertWebsiteId")
            localStorage.removeItem("SelectedDatePicker");
            localStorage.removeItem("EventCalendarWebsiteId");
            localStorage.removeItem("EventCalendarProfileId");
            localStorage.removeItem("AlertStatsWebsiteId");
            localStorage.removeItem("ArchiveAlertWebsiteId");
            localStorage.removeItem("ManualAlertWebsiteId");
            localStorage.removeItem("ManagesubscriptionWebsiteId");
            localStorage.removeItem("AlertSummaryWebsiteId");
            localStorage.removeItem("sorting");
            localStorage.removeItem("SelectedEventId");
            localStorage.removeItem("eventsWebsiteData");
            localStorage.removeItem("languageData");
            localStorage.removeItem("websiteData");
            localStorage.removeItem("profileData");
            localStorage.removeItem("AllprofileData");
            localStorage.removeItem("SelectedDatePicker_website");
            localStorage.removeItem("SelectedDatePicker_alert");
            localStorage.removeItem("TnfWebsiteListWebsiteId");
            localStorage.removeItem("TnfWebsiteData");
            localStorage.removeItem("TnfWebsiteProfilesIntelConfigId");
            localStorage.removeItem("TnfAllProfileData");
        }
        if (companyId === 27181) {
            localStorage.setItem("isDemoClient", 1);
        }
        else {
            localStorage.removeItem("isDemoClient");
        }
    }

    const logout = () => {
        request(baseURL + `/Auth/Logout?Token=${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
            METHODS.POST)
            .then((res) => {
                localStorage.clear();         // Clear All Entries from Local Stoarge
                sessionStorage.clear();       // Clear All Entries from Session Stoarge
                props.history.push("/login"); // Redirect to Login Page
                window.location.reload();
            })
            .catch((err) => {
                console.error("Error: " + err);
            })
    }

    const HandleEditWebsiteClick = (event) => {
        console.log("menuData: ", contextData.menu)
        axios
            .get(
                `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetWebsiteSelfServeURL`,
                {
                    params: {
                        websiteid: contextData.websiteId,
                        requesturl: window.location.href,
                        destinationurl: '',
                        siteindex: contextData.websiteId,
                        token: contextData.token,
                        companyId: contextData.companyId,
                        UserId: contextData.userId,
                        Email: contextData.userEmail,
                        iseditwebsite: 1
                    },
                    headers: {
                        'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
                        'X-CSRF-Token': `${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
                    }
                },
            )
            .then(response => {
                if (response && response.data && response.data.url) {

                    if (response.data.cmsMethod == 0) {
                        window.location.href = response.data.url;
                    }
                    else {
                        var urldata = response.data.url.split("?token=");
                        //window.location.href = response.data.url;
                        if (urldata.length > 1) {
                            var f = document.createElement('form');
                            f.action = urldata[0];
                            f.method = 'POST';
                            //f.target='_blank';

                            var i = document.createElement('input');
                            i.type = 'hidden';
                            i.name = 'token';
                            i.value = urldata[1];
                            f.appendChild(i);

                            document.body.appendChild(f);
                            f.submit();

                        }
                    }
                }
            })
            .catch(error => {
            });
        event.preventDefault();
    }

    const getMenuData = (companyId) => {
        if (companyId > 0) {
            let menuDta = contextData.menu;
            if (Array.isArray(menuDta) && menuDta.length > 0) {
                let defaultMenuData = menuDta.filter(dta => dta.isActive === true)[0];

                if (Array.isArray(defaultMenuData.subMenu) && defaultMenuData.subMenu.length > 1) {
                    let defaultSubMenuData = defaultMenuData.subMenu.filter(dta => dta.isActive === true)[0];
                    const subMenuDetails = menuItemsDetais.find(dta => dta.title === defaultSubMenuData.title);
                    if (subMenuDetails && Object.keys(subMenuDetails).length > 0)
                        props.history.push(`${subMenuDetails.link}`);


                }
                else if (defaultMenuData) {
                    const menuDta = menuItemsDetais.find(dta => dta.title === defaultMenuData.title);
                    if (menuDta && Object.keys(menuDta).length > 0)
                        props.history.push(`${menuDta.link}`);
                }
            }
        }
    }

    const getWebCastData = (companyId) => {
        if (companyId > 0) {
            let token = decryptData(localStorage.getItem("token"), contextData.cryptoKey);
            axios
                .get(
                    `${process.env.REACT_APP_API_KEY}/WebcastV2/GetWebcastConfiguration`,
                    {
                        params: {
                            companyId: companyId
                        },
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    },
                )
                .then(response => {
                    if (response && response.data) {
                        contextData.changeHasEvents(response.data.hasEvent);
                        contextData.changeWebcastType(response.data.webcastType);
                        contextData.changeWebcastClientId(response.data.webcastClientId);
                    }
                })
                .catch(error => {
                    console.log("error", error);
                });
        }
    }

    return (
        <>
            {
                (contextData.companies && Array.isArray(contextData.companies) && contextData.companies.length > 0) &&
                <div className="media d-flex align-items-center">
                    <div className="d-flex justify-content-between w-100">
                        <div className="d-flex align-items-center btn-toolbar profile-dropdown">

                            {
                                contextData.companies.length > 1 ?

                                    <DropdownWithIconsComponent
                                        isDropdownDisabled={localStorage.getItem("selectedCompanyID") ? true : false}
                                        dropdownData={contextData.companies}
                                        arrayValue="companyName"
                                        selectedDropdown={selectedComapny}
                                        onChange={onComapnyDropdownChange}
                                        showLabel={false}
                                        showIconInDropdownItem={false}
                                        isPrimaryImage={false}
                                        customClassName="text-overflow"
                                    />
                                    : <>{contextData.companies[0].companyName}</>
                            }
                        </div>
                    </div>
                </div>
            }

            {/* logout and settings dropdown */}
            <div className="media d-flex align-items-center">
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center icon-nav-list svg-icon-nav-list">
                        <PdfComponent iscard={false}></PdfComponent>
                        <Button onClick={() => logout()} variant="outline-primary" className="ms-3 mb-0 header-logout-btn">
                            <span className="icon-nav-box me-2 ms-0">
                                <svg className="icon-nav common-icon-color">
                                    <use href={iconSprite + `#Log out`} />
                                </svg>
                            </span>
                            {t("header.HeaderMyAccountButtonLogout")}
                        </Button>
                    </div>
                </div>

                {
                    isEditWebsiteEnabled &&
                    <div className="d-flex justify-content-between ms-3 header-edit-btn-container">
                        <div className="d-flex align-items-center icon-nav-list svg-icon-nav-list">
                            <Button onClick={(e) => HandleEditWebsiteClick(e)} variant="" className="mb-0 header-edit-btn">
                                {"Edit Website"}
                            </Button>
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

export default withRouter(HeaderDropdown);