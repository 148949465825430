import { Row, Col, Card } from '@themesberg/react-bootstrap';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import '../../i18n';
function NoAccess() {

    const [t, i18n] = useTranslation();
    return (
        <div className="px-3">
            <Row className="mb-3">
                <Col lg={12} xl={12}>
                    <h2 className="h2 mb-0">Access Denied</h2>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col lg={12}>
                    <div className="bg-white shadow-soft border rounded border-light p-4 w-100">
                        <h6 className="h6 center">Access Denied</h6>
                        <p>
                            You do not currently have permission to access this service. Contact your IDX Team for support.
                        </p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default NoAccess