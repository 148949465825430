export const userWebsiteDigestList = (userId, companyId) => {
    let websiteDigestList = [
        {
            apiUrl: { url: `${process.env.REACT_APP_API_KEY}/User/GetUserWebsiteDigestByUserId?CompanyId=${companyId}&UserId=${userId}`, type: 'get', count: 0 },
            id: "1",
            layout: "{\"x\":0,\"y\":1,\"w\":16,\"h\":12}",
            name: "",
            summary: {},
            pagination: { show: false, query: `{}` },
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false}, \"query\":{\"measures\":[\"Videoanalytics.count\"],\"timeDimensions\":[],\"order\":{},\"dimensions\":[],\"filters\":[{\"member\":\"Videoanalytics.presentationid\",\"operator\":\"contains\",\"values\":[\"${PresentationId}\"]}]}}`,
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Miwebsites.websitename\"],\"y\":[\"measures\"],\"fillMissingDates\": true,\"joinDateRange\": false},\"query\":{\"dimensions\": [\"Miwebsites.websitename\", \"Miuserwebsites.userwebsiteid\"], \"timeDimensions\": [],\"order\": {\"Miuserwebsitedigest.weekly\": \"desc\"},\"limit\": 200,\"filters\": [{\"member\": \"Miuserwebsites.userid\",\"operator\": \"equals\",\"values\": [\"${userId}"]},{\"member\": \"Miwebsites.companyid\",\"operator\": \"equals\",\"values\": [\"${companyId}"]},{\"member\": \"Miusercompany.companyid\",\"operator\": \"equals\",\"values\": [\"${companyId}"]},{\"member\": \"Miwebsites.platformid\",\"operator\": \"equals\",\"values\": [\"1\"]},{\"member\": \"Miuserservices.userid\",\"operator\": \"equals\",\"values\": [\"${userId}"]},{\"member\": \"Miwebsiteservices.active\",\"operator\": \"equals\",\"values\":[\"1\"]},{\"member\": \"Miusercompany.active\",\"operator\":\"equals\",\"values\":[\"1\"]},{\"member\": \"miwebsiteservicesTotal.TotalCount\",\"operator\": \"equals\",\"values\": [\"2\"]}],\"measures\":[\"Miuserwebsitedigest.weekly\",\"Miuserwebsitedigest.monthly\",\"Miuserwebsitedigest.yearly\"]}}`
        }
    ];
    return websiteDigestList;
};