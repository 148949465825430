import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { Row, Col } from "@themesberg/react-bootstrap";
import DropdownWithIconComponent from "../components/DropdownWithIcons";
import { withRouter } from "react-router";
import moment from "moment-timezone";
import { object_equals, ERROR_TIMEOUT } from "../AppConfig";
import AppContext from "../components/AppContext";
import { useTranslation } from "react-i18next";
import { Nav, Tab, Image, Alert, Button, Dropdown, ButtonGroup } from "@themesberg/react-bootstrap";
import CommonHeader from "../components/CommonHeader";
import VisitorAll from "../components/VisitorAll";
import Watchlist from "../components/Watchlist";
import Search from "../components/Search";
import Filters from "../components/Filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle, faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import { DATE_FORMAT, DATE_FORMATNEW } from "../utils/constant";
import request from "../apis/request";
import { METHODS } from "../apis/utilities/constant";
import AllPages from "./AllPages";
import LandingPages from "./LandingPages";
import ExitPages from "./ExitPages";
import { baseURL } from '../apis/utilities/constant';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import VisitorDetails from "./VisitorDetails";
import { convertBase64 } from "../utils/common";
import { MultiSelect } from "react-multi-select-component";
import Autocomplete from "./Autocomplete";
import ButtonComponent from "../components/Buttons";
import { decryptData } from "../utils/crypto";

const Visitoranalytics = ({
  selectedCategoryId,
  onDropdownChange,
  visitorItems,
  children,
  watchlistItems,
  dashboardItem,
  deserializeItem,
  visitorCategoryData,
  visitorCountryData,
  visitorTrafficSourceData,
  setSearchText,
  allPagesItems,
  landingPagesItems,
  exitPagesItems,
  iswatchlist
}) => {

  const contextData = useContext(AppContext);

  const [selectedDatePicker, setSelectedDatePicker] = useState({});
  const [t, i18n] = useTranslation();
  const [visitorTabKey, setVistorTabKey] = useState("all");
  const [selectedProfileIdNew, setSelectedProfileIdNew] = useState("");
  const [selectedWebsiteIdNew, setSelectedWebsiteIdNew] = useState("");
  const [autoLoad, setAutoLoad] = useState(false);
  const [searchWaterMark, setSearchWaterMark] = useState(t("header.visitorAllWatermark"));
  const [searchData, setsearchData] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [showAutoFillForCompany, setAutoFillForCompany] = useState(false);
  const [profilesList, setProfilesList] = useState([]);
  const [headerFilterData, setheaderFilterData] = useState(null);
  const [categories, setCategories] = useState({});
  const [isGA4Profile, setIsGA4Profile] = useState(false);
  const [templateType, setTemplateType] = useState("Visitors")
  const [templateSubType, setTemplateSubType] = useState("all")
  const [templateTypeAI, setTemplateTypeAI] = useState("Category")
  const [selectedtabs, setSelectedTabs] = useState([]);
  const [blurLoader, setBlurLoader] = useState(false);
  const [filterCompleteCategoryList, setFilterCompleteCategoryList] = useState([]);
  const [filterCategoryList, setFilterCategoryList] = useState([]);
  const [filterCountryList, setFilterCountryList] = useState([]);
  const [filterSubCategoryList, setFilterSubCategoryList] = useState([]);
  const [filterTempSelectedCategoryList, setFilterTempSelectedCategoryList] = useState({ categoryId: 0, categoryName: "All" });
  const [filterTempSelectedSubCategoryList, setFilterTempSelectedSubCategoryList] = useState({ categoryId: 0, categoryName: "All" });
  const [filterTempSelectedCountryList, setFilterTempSelectedCountryList] = useState({ countryId: 0, countryName: "All" });
  const [selectedFilterItem, setselectedFilterItem] = useState({});
  const [showAddWatchlist, setShowAddWatchlist] = useState(false);
  const [autocompleteSelection, setAutocompleteSelection] = useState({ id: 0 });
  const [dataWatchlist, setDataWatchlist] = useState({});
  const [reloadwatchlist, setReloadwatchlist] = useState(false);
  const [successMessage, setSuccessMessage] = useState(undefined);
  const [errorMessageTransaction, setErrorMessageTransaction] = useState(undefined);
  const [autoTickData, setAutoTickData] = useState([]);
  const [alertVisible, setAlertVisible] = useState(false);
  const [tabdatahide, setTabDataHide] = useState(0);
  const [checkCompanyName, setCheckCompanyName] = useState(false);
  const [websiteList, setWebsiteList] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [listMultiselect, setListMultiselect] = useState([]);
  const [disableAddWatchlistButton, setDisableAddWatchlistButton] = useState(true);

  function AddSelectedTab(tabname) {
    let arr = selectedtabs;
    if (!arr.includes(tabname)) {
      arr.push(tabname);
    }
    setSelectedTabs(arr)
  }

  useEffect(() => {
    request(baseURL + `/VisitorAnalytics/GetCategory`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      }).then((res) => {
        let resultData = [];
        if (res.resultData !== null && res.resultData.length) {
          setFilterCompleteCategoryList(res.resultData);
          resultData = res.resultData.filter((item) => item.parentCategoryId == 0);
          resultData.splice(0, 0, { categoryId: 0, categoryName: "All" });
        }
        setFilterCategoryList(resultData);
      })
      .catch((err) => {
      })
  }, []);


  useEffect(() => {
    setsearchData("");
    if (selectedCategoryId != 0 && templateType == "Pages") {
      setVistorTabKey("allpages");
    }
  }, [selectedCategoryId]);

  useEffect(() => {
    setsearchData("");
    setSelectedTabs([templateSubType, templateTypeAI]);

    if (contextData.selectedProfile["WebsiteProfiles.ProfileId"]) {
      let ms = new Date(Date.parse(contextData.startDate));
      let sdate = ms.getFullYear() + "-" + parseInt(ms.getMonth() + 1) + "-" + ms.getDate()
      let ms2 = new Date(Date.parse(contextData.endDate));
      let sdate2 = ms2.getFullYear() + "-" + parseInt(ms2.getMonth() + 1) + "-" + ms2.getDate()

      request(`${process.env.REACT_APP_API_KEY_NEW}/VisitorAnalytics/GetWebsiteInsightSummaryByProfileId?
      endDate=${sdate2}&isAnalyticsRequired=false&profileId=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}&startDate=${sdate}`, METHODS.GET, {},
        { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json" })
        .then((response) => {
          if (response != null) {
            setCategories(response.data);
            setIsGA4Profile(response.isGA4Profile)
          }
        })
        .catch(() => {
        })
    }

    if (localStorage.getItem("SelectedDatePicker_website") && localStorage.getItem("SelectedDatePicker_website") != "") {
      setSelectedDatePicker(
        JSON.parse(localStorage.getItem("SelectedDatePicker_website"))
      );
    }
  }, [contextData.selectedProfile, contextData.startDate, contextData.endDate]);


  useEffect(() => {
    if (iswatchlist) {
      onchangeVistiorTab("watchlist", {});
    }
  }, []);

  const onchangeVistiorTab = (event, d) => {
    setsearchData("");
    setAutoFillForCompany(false);
    setVistorTabKey(event);
    if (event === "all") {
      setSearchWaterMark(t("header.visitorAllWatermark"));
    } else {
      setSearchWaterMark(t("header.searchwatchlistPlaceHolder"));
    }

    setFilterCountryList([]);
    setFilterTempSelectedCategoryList({ categoryId: 0, categoryName: "All" });
    setFilterTempSelectedCountryList({ countryId: 0, countryName: "All" });
    setselectedFilterItem({});
    setFilterSubCategoryList([]);
    setShowAddWatchlist(false);
  };

  const sendErrorReport = () => {
    window.open(
      "mailto:" +
      "connect.id.admin@idx.inc" +
      "?subject=Visitor Insights - Report an error" +
      "&body=" +
      ""
    );
  };

  const setDowloadDropdownMenu = () => {
    let ddList = ["Excel", "PDF"];
    return ddList.map(item => {
      return (
        <Dropdown.Item className='dropdown-item-download' onClick={() => downloadGrid(item)}>
          {item}
        </Dropdown.Item>
      );
    })
  }

  const downloadGrid = (item) => {
    if (item === "Excel") {
      downloadExcel()
    }
    else {
      downloadPDF();
    }
  }

  const downloadExcel = async () => {
    setBlurLoader(true)
    let type = "watched visitors";
    let url;
    let config;
    let filename = "";
    const selectedComapnyData = contextData.companies.find(dta => dta.companyId === contextData.companyId);
    if (visitorTabKey === "all") {
      type = "All visitors";
      url = "/VisitorAnalytics/ExportVisitorExcelFileWithEPPlus"
      filename = 'Insights_All_Visitors.xlsx';
      config = {
        params: {
          encodeStr: convertBase64(JSON.stringify({
            type: type,
            websiteName: contextData.selectedWebsite["WebsiteList.WebsiteName"],
            pagesectionid: selectedCategoryId.sectionId,
            pageSectionName: selectedCategoryId.title,
            packageid: 5,
            startDate: moment(contextData.startDate).format(DATE_FORMAT),
            endDate: moment(contextData.endDate).format(DATE_FORMAT),
            profileId: contextData.selectedProfile["WebsiteProfiles.ProfileId"],
            profileName: contextData.selectedProfile["WebsiteProfiles.DisplayName"],
            intellConfigId: contextData.selectedProfile["WebsiteProfiles.IntelConfigId"],
            userid: contextData.userId,
            companyid: contextData.companyId,
            CompanyName: selectedComapnyData.companyName,
            culture: localStorage.getItem('i18nextLng')

          }))
        }
      }
    } else if (visitorTabKey === "watchlist") {
      type = "watched visitors";
      url = "/VisitorAnalytics/ExportVisitorExcelFileWithEPPlus"
      filename = 'Insights_Watched_visitors.xlsx';
      config = {
        params: {
          encodeStr: convertBase64(JSON.stringify({
            type: type,
            websiteName: contextData.selectedWebsite["WebsiteList.WebsiteName"],
            pagesectionid: selectedCategoryId.sectionId,
            pageSectionName: selectedCategoryId.title,
            packageid: 5,
            startDate: moment(contextData.startDate).format(DATE_FORMAT),
            endDate: moment(contextData.endDate).format(DATE_FORMAT),
            profileId: contextData.selectedProfile["WebsiteProfiles.ProfileId"],
            profileName: contextData.selectedProfile["WebsiteProfiles.DisplayName"],
            intellConfigId: contextData.selectedProfile["WebsiteProfiles.IntelConfigId"],
            userid: contextData.userId,
            companyid: contextData.companyId,
            CompanyName: selectedComapnyData.companyName,
            culture: localStorage.getItem('i18nextLng')


          },))
        }


      }

    } else if (visitorTabKey === "allpages") {
      type = "All Pages";
      url = "/VisitorAnalytics/ExportPageExcelFileWithEPPLus"
      filename = 'Insights_All_Pages.xlsx';
      config = {
        params: {
          encodeStr: convertBase64(JSON.stringify({
            type: type,
            websitename: contextData.selectedWebsite["WebsiteList.WebsiteName"],
            sectionid: selectedCategoryId.sectionId,
            pageSectionName: selectedCategoryId.title,
            startdate: moment(contextData.startDate).format(DATE_FORMATNEW),
            enddate: moment(contextData.endDate).format(DATE_FORMATNEW),
            profileid: contextData.selectedProfile["WebsiteProfiles.ProfileId"],
            profileName: contextData.selectedProfile["WebsiteProfiles.DisplayName"],
            pagetype: 1,
            companyName: selectedComapnyData.companyName,
            culture: localStorage.getItem('i18nextLng')

          },))
        }
      }

    } else if (visitorTabKey === "landing") {
      type = "Landing pages";
      url = "/VisitorAnalytics/ExportPageExcelFileWithEPPLus"
      filename = 'Insights_Landing_Pages.xlsx';
      config = {
        params: {
          encodeStr: convertBase64(JSON.stringify({
            type: type,
            websitename: contextData.selectedWebsite["WebsiteList.WebsiteName"],
            sectionid: selectedCategoryId.sectionId,
            pageSectionName: selectedCategoryId.title,
            startdate: moment(contextData.startDate).format(DATE_FORMATNEW),
            enddate: moment(contextData.endDate).format(DATE_FORMATNEW),
            profileid: contextData.selectedProfile["WebsiteProfiles.ProfileId"],
            profileName: contextData.selectedProfile["WebsiteProfiles.DisplayName"],
            pagetype: 2,
            companyName: selectedComapnyData.companyName,
            culture: localStorage.getItem('i18nextLng')
          },))
        }
      }
    } else if (visitorTabKey === "exit") {
      type = "Exit Pages";
      url = "/VisitorAnalytics/ExportPageExcelFileWithEPPLus"
      filename = 'Insights_Exit_Pages.xlsx';
      config = {
        params: {
          encodeStr: convertBase64(JSON.stringify({
            type: type,
            websitename: contextData.selectedWebsite["WebsiteList.WebsiteName"],
            sectionid: selectedCategoryId.sectionId,
            pageSectionName: selectedCategoryId.title,
            startdate: moment(contextData.startDate).format(DATE_FORMATNEW),
            enddate: moment(contextData.endDate).format(DATE_FORMATNEW),
            profileid: contextData.selectedProfile["WebsiteProfiles.ProfileId"],
            profileName: contextData.selectedProfile["WebsiteProfiles.DisplayName"],
            pagetype: 3,
            companyName: selectedComapnyData.companyName,
            culture: localStorage.getItem("i18nextLng")
          },
          ))
        }
      }


    }
    const tempVar = await axios.get(
      `${process.env.REACT_APP_API_KEY}${url}`,
      {
        headers: {
          'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
        },
        ...config
      }
    );
    if (tempVar !== undefined && tempVar !== null) {
      setBlurLoader(false)
      let fetchDataModified = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${tempVar.data}`;
      let a = document.createElement("a");
      a.href = fetchDataModified;
      a.download = filename
      a.click();
    }
  };

  const downloadPDF = async () => {
    setBlurLoader(true);
    let clientName = decryptData(localStorage.getItem("companyName"), contextData.cryptoKey);
    let url = "";
    let pageName = "Visitor Insights";
    let countTitle = "";
    let fileName = "";
    let filters = "";
    let ms = new Date(Date.parse(contextData.startDate));
    let sdate = ms.getFullYear() + "-" + parseInt(ms.getMonth() + 1) + "-" + ms.getDate()
    let ms2 = new Date(Date.parse(contextData.endDate));
    let sdate2 = ms2.getFullYear() + "-" + parseInt(ms2.getMonth() + 1) + "-" + ms2.getDate()
    if (visitorTabKey === "all") {
      countTitle = "Total All Visitors: <span class='total-record-count'>{{count}}</span>";
      filters = (searchData !== "" && searchData !== null) ? "companyName|contains|" + searchData : "";
      if (selectedCategoryId.sectionId !== 0) {
        url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetVisitorWithWatchlistBySection?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}&intellConfigId=${contextData.selectedProfile["WebsiteProfiles.IntelConfigId"]}&startDate=${sdate}&endDate=${sdate2}&companyid=${contextData.companyId}&pagesectionid=${selectedCategoryId.sectionId}&packageid=5`;
      }
      else {
        url = `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetVisitorWithWatchlistByProfileId?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}&intellConfigId=${contextData.selectedProfile["WebsiteProfiles.IntelConfigId"]}&startDate=${sdate}&endDate=${sdate2}&companyid=${contextData.companyId}&packageid=5${localStorage.getItem('refreshCache') ? "&refreshCache=" + localStorage.getItem('refreshCache') : ""}`;
      }
      fileName = "Insights_All_Visitors.pdf";
    }
    else if (visitorTabKey === "watchlist") {
      countTitle = "Total Watchlist: <span class='total-record-count'>{{count}}</span>";
      filters = (searchData !== "" && searchData !== null) ? "companyName|contains|" + searchData : "";
      if (selectedCategoryId.sectionId !== 0) {
        url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetWatchedVisitorBySection?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}&intellConfigId=${contextData.selectedProfile["WebsiteProfiles.IntelConfigId"]}
        &startDate=${sdate}&endDate=${sdate2}&companyid=${contextData.companyId}&sectionid=${selectedCategoryId.sectionId}&packageid=5`;
      }
      else {
        url = `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetWatchedVisitorByWebsite?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}&intellConfigId=${contextData.selectedProfile["WebsiteProfiles.IntelConfigId"]}
        &startDate=${sdate}&endDate=${sdate2}&companyid=${contextData.companyId}&packageid=5${localStorage.getItem('refreshCache') ? "&refreshCache=" + localStorage.getItem('refreshCache') : ""}`;
      }
      fileName = "Insights_Watchlist.pdf";
    }
    else if (visitorTabKey === "allpages") {
      countTitle = "<span class='total-record-count'>{{count}}</span> Total Pages";
      filters = (searchData !== "" && searchData !== null) ? "filterybykey|contains|" + searchData : "";
      if (selectedCategoryId.sectionId !== 0) {
        url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetPageBySection?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]} 
        &startDate=${sdate}&endDate=${sdate2}&companyid=${contextData.companyId}&pagesectionid=${selectedCategoryId.sectionId}&packageid=5`;
      }
      else {
        url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetPagesDataByProfileId?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
        &startDate=${sdate}&endDate=${sdate2}&sortexpression=PageViews&sortdirection=true&companyid=${contextData.companyId}&packageid=5`;
      }
      fileName = "Insights_All_Pages.pdf";
    }
    else if (visitorTabKey === "landing") {
      countTitle = "<span class='total-record-count'>{{count}}</span> Total Landing Pages";
      filters = (searchData !== "" && searchData !== null) ? "filterybykey|contains|" + searchData : "";
      url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetEntrancePagesDataByProfileId?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
        &startDate=${sdate}&endDate=${sdate2}&sortexpression=PageViews&sortdirection=true&companyid=${contextData.companyId}&packageid=5`;
      fileName = "Insights_Landing_Pages.pdf";
    }
    else if (visitorTabKey === "exit") {
      countTitle = "<span class='total-record-count'>{{count}}</span> Total Exit Pages";
      filters = (searchData !== "" && searchData !== null) ? "filterybykey|contains|" + searchData : "";
      url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetExitPagesByProfileId?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
      &startDate=${sdate}&endDate=${sdate2}&sortexpression=PageViews&sortdirection=true&companyid=${contextData.companyId}&packageid=5`;
      fileName = "Insights_Exit_Pages.pdf";
    }

    let obj = {
      Title: document.getElementById("websiteWebsiteProfile").innerHTML,
      SubTitle: document.getElementById("dateRange").innerHTML,
      PageName: pageName,
      CountTitle: countTitle,
      Filters: filters,
      ClientName: clientName,
      Url: url,
      Token: decryptData(localStorage.getItem("token"), contextData.cryptoKey),
      FileName: fileName,
      Email: decryptData(localStorage.getItem("email"), contextData.cryptoKey),
      Name: decryptData(localStorage.getItem("firstName"), contextData.cryptoKey),
      Count: 0,
      FilterContent: "",
      TableHeaders: []
    }

    await axios.post(`${process.env.REACT_APP_API_KEY}/CommonV2/GeneratePdf`, obj,
      {
        responseType: 'arraybuffer',
        headers: {
          'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
        }
      })
      .then((res) => {
        const contentType = res.headers['content-type'];
        if (res.status === 200) {
          if (contentType?.includes('application/pdf')) {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          }
          else {
            setAlertVisible(true);
            setTimeout(() => {
              setAlertVisible(false);
            }, 3000);
          }
          setBlurLoader(false);
        }
        else {
          setBlurLoader(false);
          console.error('Error generating PDF.');
        }
      })
      .catch((err) => {
        setBlurLoader(false);
        console.error('Error generating PDF:', err);
      })
  };

  function setCountryListForFilter(data) {
    if (data && data.data?.length > 0) {
      var list = [];
      list = data.country;
      list.splice(0, 0, { countryId: 0, countryName: "All" });
      setFilterCountryList(list);
      setDataWatchlist(data);

      let autoticklist = []
      data.data.map(function (item) {
        autoticklist.push(item.companyName)
      });
      setAutoTickData(autoticklist);
    }
    else {
      setFilterCountryList([]);
      setDataWatchlist([]);
      setAutoTickData([]);
    }
  }

  function onCountryDropDownChange(data) {
    if (data) {
      setFilterTempSelectedCountryList(data)
    }
  }

  function onSubCountryDropDownChange(data) {
    if (data) {
      setFilterTempSelectedSubCategoryList(data)
    }
  }

  function onCategoryDropdownChange(data) {
    if (data) {
      let filteredRecords = filterCompleteCategoryList.filter((item) => item.parentCategoryId == data.categoryId);
      if (filteredRecords.length > 0 && data.categoryId > 0) {
        filteredRecords.splice(0, 0, { categoryId: 0, categoryName: "All" });
        setFilterSubCategoryList(filteredRecords)
        setFilterTempSelectedSubCategoryList({ categoryId: 0, categoryName: "All" })
      }
      else {
        setFilterSubCategoryList([])
      }
      setFilterTempSelectedCategoryList(data)
    }
  }

  function applyfilter1() {
    if (visitorTabKey == "all") {
      var filterfs = {};
      if (filterTempSelectedCountryList.countryId > 0) {
        filterfs["countryId"] = filterTempSelectedCountryList.countryId;
      }
      else {
        filterfs["countryId"] = 0;
      }

      if (filterSubCategoryList.length == 0) {
        if (filterTempSelectedCategoryList.categoryName != "All") {
          filterfs["categoryId"] = filterTempSelectedCategoryList.categoryId;
        }
        else {
          filterfs["categoryId"] = 0;
        }
        setselectedFilterItem(filterfs);
      }
      else {
        if (filterTempSelectedSubCategoryList.categoryName != "All") {
          filterfs["categoryId"] = filterTempSelectedSubCategoryList.categoryId;
        }
        else {
          filterfs["parentCategoryId"] = filterTempSelectedCategoryList.categoryId;
        }
        setselectedFilterItem(filterfs);
      }
    }
    else {
      var filterfs = {};
      if (filterTempSelectedCountryList.countryId > 0) {
        filterfs["CountryId"] = filterTempSelectedCountryList.countryId;
      }
      else {
        filterfs["CountryId"] = 0;
      }
      if (filterSubCategoryList.length == 0) {
        if (filterTempSelectedCategoryList.categoryName != "All") {
          filterfs["categoryId"] = filterTempSelectedCategoryList.categoryId;
        }
        else {
          filterfs["categoryId"] = 0;
        }
        setselectedFilterItem(filterfs);
      }
      else {
        if (filterTempSelectedSubCategoryList.categoryName != "All") {
          filterfs["categoryId"] = filterTempSelectedSubCategoryList.categoryId;
        }
        else {
          filterfs["parentCategoryId"] = filterTempSelectedCategoryList.categoryId;
        }
        setselectedFilterItem(filterfs);
      }
    }
  }

  function cancelfilter1() {
    var filterfs = {};
    setselectedFilterItem(filterfs);
    setFilterTempSelectedCountryList({ countryId: 0, countryName: "All" });
    setFilterTempSelectedCategoryList({ categoryId: 0, categoryName: "All" });
    setFilterSubCategoryList([]);
  }

  function onIconClick() {
    setAutoFillForCompany(false);
  }

  function AutoFillSelection(obj) {
    setAutocompleteSelection(obj);
    setListMultiselect([])
  }

  function setMultiselectOption(data) {
    let dbutton = true;

    if (dataWatchlist.data && dataWatchlist.data.some(item => item.CompanyId === autocompleteSelection.id)) {

      const selectedcompanywatchlistdata = dataWatchlist.data.filter(item => item.CompanyId === autocompleteSelection.id);
      let checked = false;
      for (const item1 of data) {
        if (!selectedcompanywatchlistdata.some(item => item.CountryId === item1.value)) {
          checked = true;
        }
      }

      for (const item2 of selectedcompanywatchlistdata) {
        if (!data.some(item => item.value === item2.CountryId)) {
          checked = true;
        }
      }
      if (checked == true) {
        dbutton = false;
      }
      else {
        dbutton = true;
      }

    }
    else if (data.length > 0) {

      dbutton = false;
    }
    else {

      dbutton = true;
    }

    setDisableAddWatchlistButton(dbutton);
    setSelectedOption(data)

  }

  useEffect(() => {
    if (autocompleteSelection.id > 0) {

      request(baseURL + `/VisitorAnalytics/GetIntelligenceCountryByWatchedVisitor?companyid=` + autocompleteSelection.id, METHODS.GET, {}, { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json" })
        .then((response) => {
          if (dataWatchlist.data && dataWatchlist.data.some(item => item.CompanyId === autocompleteSelection.id) && response.length > 0) {
            const selectedcompanywatchlistdata = dataWatchlist.data.filter(item => item.CompanyId === autocompleteSelection.id);
            const commonRecords = findCommonRecords(selectedcompanywatchlistdata, response);
            setSelectedOption(commonRecords)
          }
          setListMultiselect(response)
        })
        .catch(() => {

        })

    }
    else {
      setListMultiselect([])
      setSelectedOption([])
    }
  }, [autocompleteSelection]);

  function findCommonRecords(array1, array2) {
    const commonRecords = [];
    for (const item1 of array1) {
      const commonItem = array2.find(item2 => item2.value === item1.CountryId);
      if (commonItem) {
        commonRecords.push(commonItem);
      }
    }
    return commonRecords;
  }

  const AddWatchlistCompany = event => {
    event.preventDefault();

    if (autocompleteSelection.id > 0) {

      let acountries = [];
      let rcountries = [];
      if (dataWatchlist && dataWatchlist.data && dataWatchlist.data.length > 0) {

        if (dataWatchlist.data.some(item => item.CompanyId === autocompleteSelection.id)) {

          const selectedcompanywatchlistdata = dataWatchlist.data.filter(item => item.CompanyId === autocompleteSelection.id);

          for (const item1 of selectedOption) {
            if (!selectedcompanywatchlistdata.some(item => item.CountryId === item1.value)) {
              acountries.push({ CountryId: item1.value, CountryName: item1.label })
            }
          }

          for (const item2 of selectedcompanywatchlistdata) {
            if (!selectedOption.some(item => item.value === item2.CountryId)) {
              rcountries.push({ CountryId: item2.CountryId, CountryName: item2.countryName })
            }
          }

        }
        else {
          acountries = selectedOption.map(val => ({ CountryId: val.value, CountryName: val.label }));
        }

      }
      else {
        acountries = selectedOption.map(val => ({ CountryId: val.value, CountryName: val.label }));
      }

      let postobj = {
        CompanyId: autocompleteSelection.id, ProfileId: contextData.selectedProfile["WebsiteProfiles.ProfileId"]
        , AddedCountries: acountries, RemovedCountries: rcountries, selectedcompanyid: contextData.companyId, packageid: 5
      }
      setBlurLoader(true);
      setShowAddWatchlist(false);

      request(baseURL + `/VisitorAnalytics/SaveWatchlistForUserMain`, METHODS.POST,
        postobj, { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json" })
        .then((response) => {
          //if (response == 200) 
          {
            setReloadwatchlist(!reloadwatchlist);
          }

          let msg = "";

          if (acountries.length > 0 && rcountries.length > 0) {
            msg = "Visitor updated in your watchlist successfully";
          }
          else if (acountries.length > 0) {
            msg = t("header.Message_Visitor_added_to_your_watchlist_successfully");
          }
          else if (rcountries.length > 0) {
            msg = t("header.Message_Visitor_removed_from_your_watchlist_successfully");
          }

          setTimeout(() => {
            setSuccessMessage(msg);
          }, 500);

          setTimeout(() => {
            setSuccessMessage(undefined);
          }, ERROR_TIMEOUT);


          setSelectedOption([]);
          setListMultiselect([]);
          setDisableAddWatchlistButton(true);
          setAutocompleteSelection({ id: 0 });
          setBlurLoader(false);
          localStorage.setItem("refreshCache", 1);
        })
        .catch(() => {
          setSelectedOption([]);
          setListMultiselect([]);
          setDisableAddWatchlistButton(true);
          setAutocompleteSelection({ id: 0 });
          setBlurLoader(false);
        })

    }
  }

  const onCloseSuccessMessage = () => {
    setSuccessMessage(undefined)
  };

  const onCloseErrorMessage = () => {
    setErrorMessageTransaction(undefined)
  };

  const WatchlistComponent = useMemo(
    () => (
      <Watchlist
        setCountryListForFilter={setCountryListForFilter}
        selectedCategoryId={selectedCategoryId}
        setheaderFilterData={setheaderFilterData}
        SearchText={searchData}
        setSearchText={setSearchText}
        watchList={watchlistItems}
        setTotalRecords={setTotalRecords}
        FilterParams={selectedFilterItem}
        reloadwatchlist={reloadwatchlist}
      ></Watchlist>
    ),
    [searchData, selectedFilterItem, reloadwatchlist, selectedCategoryId, watchlistItems]
  );

  const VisitorAllComponent = useMemo(
    () => (
      <VisitorAll
        selectedCategoryId={selectedCategoryId}
        setheaderFilterData={setheaderFilterData}
        setCountryListForFilter={setCountryListForFilter}
        SearchText={searchData}
        visitors={visitorItems}
        setTotalRecords={setTotalRecords}
        FilterParams={selectedFilterItem}
      ></VisitorAll>
    ),
    [searchData, selectedFilterItem, selectedCategoryId]
  );

  const onClickOutsideListenerFilters = () => {
    setSelectedOption([]);
    setListMultiselect([]);
    setDisableAddWatchlistButton(true);
    setAutocompleteSelection({ id: 0 });
    setShowAddWatchlist(false)
    document.removeEventListener("click", onClickOutsideListenerFilters)
  }

  useEffect(() => {
    if (showAddWatchlist == true) {
      document.addEventListener('click', onClickOutsideListenerFilters);
    }
  }, [showAddWatchlist]);

  useEffect(() => {
    setSelectedOption([]);
    setListMultiselect([]);
    setDisableAddWatchlistButton(true);
  }, [checkCompanyName])

  useEffect(() => {
    if (contextData.companyId > 0) {

      if (localStorage.getItem("websiteData") && JSON.parse(localStorage.getItem("websiteData")).length > 0
        && localStorage.getItem("AllprofileData") && JSON.parse(localStorage.getItem("AllprofileData")).length > 0) {

        let websiteList = JSON.parse(localStorage.getItem("websiteData"));
        let selectedWebsite = JSON.parse(localStorage.getItem("WebsiteListWebsiteId"));

        renderWebsite(websiteList, selectedWebsite)
      }
      else if (!localStorage.getItem("websiteData") || !localStorage.getItem("AllprofileData")) {
        if (!localStorage.getItem("websiteData")) {
          getWebsites();
        }
        else if (!localStorage.getItem("AllprofileData")) {
          getProfiles(JSON.parse(localStorage.getItem("websiteData")));
        }
      }
    }
  }, [])

  const renderWebsite = (resultSet, selectedWebsiteValue) => {
    setWebsiteList(resultSet)
    setSelectedWebsiteIdNew(selectedWebsiteValue);
    renderProfileList(selectedWebsiteValue, 0);
  };

  const renderProfileList = (selectedWebsiteValue, isTempUpdate) => {

    if (localStorage.getItem("AllprofileData") && JSON.parse(localStorage.getItem("AllprofileData")).length > 0) {

      let profileList = JSON.parse(localStorage.getItem("AllprofileData"));
      let selectedWebsiteProfiles = profileList.filter((e) => { return e.WebsiteId === selectedWebsiteValue["WebsiteList.WebsiteId"] });

      if (selectedWebsiteProfiles.length > 0) {
        localStorage.setItem("cookieProfileData", JSON.stringify(selectedWebsiteProfiles));
        let profileData = selectedWebsiteProfiles.map((e) => ({ ["WebsiteProfiles.ProfileId"]: e.ProfileId, ["WebsiteProfiles.DisplayName"]: e.DisplayName, ["WebsiteProfiles.IntelConfigId"]: e.IntelConfigId }));

        setProfilesList(profileData)

        let selectedProfile = profileData[0];


        if (isTempUpdate === 0) {
          let storedProfile = JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId"));
          if (storedProfile && Object.keys(storedProfile).length > 0
            && profileData.some(obj => obj["WebsiteProfiles.ProfileId"] === storedProfile["WebsiteProfiles.ProfileId"])) {
            selectedProfile = storedProfile;
          };
          localStorage.setItem("profileData", JSON.stringify(profileData))
        }
        setSelectedProfileIdNew(selectedProfile);

      }
      else {
        setProfilesList([])
        setSelectedProfileIdNew({});
        if (isTempUpdate === 0) {
          localStorage.setItem("profileData", JSON.stringify([]))
        }
      }

    }
    setAutoLoad(true)
  }

  const getWebsites = () => {

    setBlurLoader(true);

    request(baseURL + `/CommonV2/GetWebsiteChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        let resultData = [];

        if (res.result && res.result.length > 0) {
          resultData = res.result.map((e) => ({ cmsplatformid: res.platformid, ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl }))
        }

        if (resultData.length > 0) {
          localStorage.setItem("websiteData", JSON.stringify(resultData))
          getProfiles(resultData);
        }
        else {
          localStorage.setItem("websiteData", JSON.stringify([]))
          localStorage.setItem("AllprofileData", JSON.stringify([]))
        }

      })
      .catch((err) => {
        setBlurLoader(false);
      })

  }

  const getProfiles = (resultData) => {
    request(baseURL + `/WebsiteAnalytics/GetWebsites?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {

        if (res.result && res.result.length > 0) {

          let result = removeDuplicateObjects(res.result, 'IntelConfigId')
          localStorage.setItem("AllprofileData", JSON.stringify(result))

        }
        else {
          localStorage.setItem("profileData", JSON.stringify([]))
        }
        // localStorage.setItem("websiteData", JSON.stringify(resultData))
        if (!localStorage.getItem("WebsiteListWebsiteId")) {
          renderWebsite(resultData, resultData[0])
        }
        else {
          renderWebsite(resultData, JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
        }
        setBlurLoader(false);
        if (res.result.length == 0) {
          setAutoLoad(true);
        }

      })
      .catch((err) => {
        setBlurLoader(false);
      })
  }

  function removeDuplicateObjects(array, key) {
    const seen = {};
    return array.filter(item => {
      const itemKey = key ? item[key] : item;
      return seen.hasOwnProperty(itemKey) ? false : (seen[itemKey] = true);
    });
  }

  const onWebsiteDropdownChange = (data) => {
    if (!object_equals(selectedWebsiteIdNew, data)) {
      setSelectedProfileIdNew({});
    }
    setSelectedWebsiteIdNew(data);
    renderProfileList(data, 1)
  };

  const onProfileDropdownChange = (data) => {
    const intellConfigId = data["WebsiteProfiles.IntelConfigId"];
    contextData.changeIntelConfigId(intellConfigId);
    setSelectedProfileIdNew(data)
  };

  const cancelFilters = () => {
    onWebsiteDropdownChange(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
    onProfileDropdownChange(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")))
  }

  const applyFilters = () => {

    const websiteId = selectedWebsiteIdNew["WebsiteList.WebsiteId"];

    if (JSON.stringify(selectedProfileIdNew).length == 2) {
      setTabDataHide(1);
    }
    else {
      setTabDataHide(-1);
    }

    contextData.changeWebsiteId(websiteId);
    contextData.changeSelectedWebsite(selectedWebsiteIdNew);
    contextData.changeSelectedProfile(selectedProfileIdNew);

    if (profilesList.length > 0) {
      localStorage.setItem("profileData", JSON.stringify(profilesList))
    }
    else {
      localStorage.setItem("profileData", JSON.stringify([]))
    }
    localStorage.setItem("WebsiteListWebsiteId", JSON.stringify(selectedWebsiteIdNew));// Adding WebsiteList.WebsiteId to local stoarge in case of Page Refresh
    localStorage.setItem("WebsiteProfilesIntelConfigId", JSON.stringify(selectedProfileIdNew));// Adding WebsiteProfiles.IntelConfigId to local stoarge in case of Page Refresh
  }

  const commonHeader = useMemo(
    () => (
      <CommonHeader
        cancelFilters={cancelFilters}
        channel="website"
        autoLoad={autoLoad}
        dataSource={[
          {
            name: "Dropdown1 - Website",
            data: websiteList,
            selectedItem: selectedWebsiteIdNew,
            isVisible: true,
            label: t("header.filterLabelWebsite"),
            callback: onWebsiteDropdownChange,
            displayText: "WebsiteList.WebsiteName",
          },
          {
            name: "Dropdown2 - website profile",
            data: profilesList,
            selectedItem: selectedProfileIdNew,
            isVisible: true,
            label: t("header.filterLabelProfile"),
            callback: onProfileDropdownChange,
            displayText: "WebsiteProfiles.DisplayName",
          },
        ]}
        title={t("header.visitorAnalyticsTitle")}
        showDate={true}
        applyFilters={applyFilters}
      />
    ),
    [selectedWebsiteIdNew, selectedProfileIdNew, autoLoad]
  );

  const createFilter = useMemo(
    () => (
      <>
        <Filters
          onIconClick={onIconClick}
          iconText={t("header.Icon_Tooltip_Filter_Visitors")}
          applyFilters={applyfilter1}
          cancelFilter={cancelfilter1}
          showHeader={false}
          btnOneText={t("header.VisitorsIconFilterVisitorsButtonFilterText")}
          btnTwoText={t("header.VisitorsIconFilterVisitorsButtonClearFilterText")}
          dataSource={[
            {
              name: "Country",
              data: filterCountryList,
              selectedItem: filterTempSelectedCountryList,
              isVisible: true,
              label: t("header.VisitorsIconFilterVisitorsLabelCountryText"),
              callback: onCountryDropDownChange,
              displayText: "countryName",
            },
            {
              name: "Category",
              data: filterCategoryList,
              isVisible: true,
              label: t("header.VisitorsIconFilterVisitorsLabelCategoryText"),
              selectedItem: filterTempSelectedCategoryList,
              callback: onCategoryDropdownChange,
              displayText: "categoryName"
            },
            {
              name: "Subcategory",
              data: filterSubCategoryList,
              isVisible: filterSubCategoryList.length > 0 ? true : false,
              label: t("header.VisitorsIconFilterVisitorsLabelSubCategoryText"),
              selectedItem: filterTempSelectedSubCategoryList,
              callback: onSubCountryDropDownChange,
              displayText: "categoryName"
            }
          ]}
        ></Filters>
      </>
    ),
    [filterCategoryList, filterCountryList, filterSubCategoryList, filterTempSelectedCategoryList, filterTempSelectedCountryList, filterTempSelectedSubCategoryList]
  );

  return (
    <>
      {(blurLoader || tabdatahide == 0) && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}
      {alertVisible && (<div className="fade modal-backdrop show"></div>)}
      <Alert
        className="toast-custom"
        variant="success"
        show={alertVisible}
      >
        <div className="d-flex justify-content-between">
          <div>
            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
            {t("header.PdfDeliveredToMailMessage")}
          </div>
        </div>
      </Alert>
      <Row>
        <Col>{commonHeader}</Col>
      </Row>
      {tabdatahide == -1 && <div id="visitorAnalyticsContent">
        {(selectedDatePicker.value !== "Custom Range") && <Row>
          <Col lg={12}>
            <VisitorDetails />
          </Col>
        </Row >}
        <Row>
          <Col lg={12}>
            <div className="d-inline-block pt-3 ps-3 w-100">
              <div className="shadow-soft border rounded border-light p-4 ps-2 pe-2 w-100 nested-card-container">
                <Row>
                  <Col>
                    <h5 className="ms-2">{t("header.visitorAnalyticsHeadingText")}</h5>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={12} sm={4} className={localStorage.getItem("isDemoClient") ? "custom-disable mb-1" : "mb-1"}>
                    <div className="ps-2 pe-2 w-100 d-inline-block">
                      {<DropdownWithIconComponent
                        dropdownData={categories}
                        arrayValue="title"
                        selectedDropdown={selectedCategoryId.title ? selectedCategoryId : categories[0]}
                        onChange={onDropdownChange}
                        showLabel={false}
                        showIconInDropdownItem={false}
                        customClassName="full-width text-overflow"
                        isDropdownDisabled={false}
                      />}
                    </div>
                  </Col>
                </Row>

                <div className="">
                  <div className="w-100 nested-card-container  rgl-website-analytics">
                    {children}
                  </div>
                </div>
                <Tab.Container
                  defaultActiveKey="category"

                >
                  <Nav fill variant="pills" className="tab subtab ms-3 mt-5">
                    <Nav.Item>
                      <Nav.Link onClick={() => { setTemplateTypeAI('Category'); AddSelectedTab('Category') }} eventKey="Category" className={templateTypeAI == "Category" ? "mb-sm-3 mb-md-0 active" : "mb-sm-3 mb-md-0"}>
                        {t("header.VisitorsIconFilterVisitorsLabelCategoryText")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={() => { setTemplateTypeAI('Country'); AddSelectedTab('Country') }} eventKey="Country" className={templateTypeAI == "Country" ? "mb-sm-3 mb-md-0 active" : "mb-sm-3 mb-md-0"}>
                        {t("header.VisitorsIconFilterVisitorsLabelCountryText")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={() => { setTemplateTypeAI('Traffic'); AddSelectedTab('Traffic') }} eventKey="Traffic" className={templateTypeAI == "Traffic" ? "mb-sm-3 mb-md-0 active" : "mb-sm-3 mb-md-0"}
                      >
                        {t("header.VisitorsActivityPopTrafficTabText")}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Tab.Container>
                {contextData.websiteId ? (
                  (
                    <Row className="mt-4" hidden={templateTypeAI === "Category" ? false : true}>
                      <div className="d-inline-block p-3 pe-1">
                        <Col md={12}>
                          <Row>
                            <Col md={6}>
                              {visitorCategoryData.slice(0, Math.ceil(visitorCategoryData.length / 2))
                                .map(deserializeItem)
                                .map(dashboardItem)}
                            </Col>
                            <Col md={6}>
                              {visitorCategoryData.slice(Math.ceil(visitorCategoryData.length / 2))
                                .map(deserializeItem)
                                .map(dashboardItem)}
                            </Col>
                          </Row>
                        </Col>
                      </div>
                    </Row>
                  )
                ) : (
                  <div style={{ position: "inherit" }} className="no-data">{t("header.contentUnavailable")}</div>
                )}
                {contextData.websiteId ? (
                  (
                    <Row className="mt-4" hidden={templateTypeAI === "Country" ? false : true}>
                      <div className="d-inline-block p-3 pe-1 ">
                        <Col md={12}>
                          <Row>
                            <Col md={6}>
                              {visitorCountryData.slice(0, Math.ceil(visitorCountryData.length / 2))
                                .map(deserializeItem)
                                .map(dashboardItem)}
                            </Col>
                            <Col md={6}>
                              {visitorCountryData.slice(Math.ceil(visitorCountryData.length / 2))
                                .map(deserializeItem)
                                .map(dashboardItem)}
                            </Col>
                          </Row>
                        </Col>
                      </div>
                    </Row>
                  )
                ) : (
                  <div style={{ position: "inherit" }} className="no-data">{t("header.contentUnavailable")}</div>
                )}
                {contextData.websiteId ? (
                  (
                    <Row className="mt-4" hidden={templateTypeAI === "Traffic" ? false : true}>
                      <div className="d-inline-block p-3 pe-1">
                        <Col md={12}>
                          <Row>
                            <Col md={6}>
                              {visitorTrafficSourceData.slice(0, Math.ceil(visitorTrafficSourceData.length / 2))
                                .map(deserializeItem)
                                .map(dashboardItem)}
                            </Col>
                            <Col md={6}>
                              {visitorTrafficSourceData.slice(Math.ceil(visitorTrafficSourceData.length / 2))
                                .map(deserializeItem)
                                .map(dashboardItem)}
                            </Col>
                          </Row>
                        </Col>
                      </div>
                    </Row>
                  )
                ) : (
                  <div style={{ position: "inherit" }} className="no-data">{t("header.contentUnavailable")}</div>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col lg={12}>
            <div className="d-inline-block p-3 pe-0 w-100 ">
              <div className="shadow-soft border rounded border-light w-100 nested-card-container">
                <Row className="mt-3 mb-2">
                  <Col md={12}>
                    <Tab.Container>
                      <Nav fill variant="pills" className="flex-column flex-sm-row tab border-bottom-0 ms-1 mt-3 mb-2 justify-content-center">
                        <Nav.Item className="pe-0">
                          <Nav.Link onClick={() => {
                            setVistorTabKey("all");
                            setTemplateType('Visitors'); setTemplateSubType('all'); setsearchData("");
                            setFilterCountryList([]); setFilterTempSelectedCategoryList({ categoryId: 0, categoryName: "All" });
                            setFilterTempSelectedCountryList({ countryId: 0, countryName: "All" }); setselectedFilterItem({}); setFilterSubCategoryList([]);
                            if (document.getElementsByClassName('c2 active')[0] != undefined) {
                              document.getElementsByClassName('c2')[0].classList.remove("active");
                            }
                          }} eventKey="Visitors" className={templateType == "Visitors" ? "mb-sm-3 mb-md-0 active" : "mb-sm-3 mb-md-0"}>
                            {t("header.PagesDetailsPopVisitorsTabText")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link onClick={() => {
                            setTemplateType('Pages');
                            setsearchData(""); setVistorTabKey("allpages");
                            if (document.getElementsByClassName('c1 active')[0] != undefined) {
                              document.getElementsByClassName('c1')[0].classList.remove("active");
                            }
                            if (document.getElementsByClassName('c3 active')[0] != undefined) {
                              document.getElementsByClassName('c3')[0].classList.remove("active");
                            }
                          }} aria-selected={templateType != "Visitors" ? true : false} eventKey="Pages" className={templateType != "Visitors" ? "mb-sm-3 mb-md-0 active" : "mb-sm-3 mb-md-0"}>
                            {t("header.InsightsTabPages")}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Tab.Container>
                  </Col>
                </Row>
                <Row hidden={templateType === "Visitors" ? false : true} className="visitor-analytics-inner">
                  <Col md={12}>
                    {<Row className="visitor-search-download-box position-relative">
                      <Col lg={12}>
                        {(successMessage || errorMessageTransaction) && <div className="fade modal-backdrop show"></div>}
                        <Alert
                          className="toast-custom"
                          variant="success"
                          show={successMessage ? true : false}
                          onClose={() => onCloseSuccessMessage()}
                        >
                          <div className="d-flex justify-content-between">
                            <div>
                              <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                              {successMessage}
                            </div>
                            <Button variant="close" size="xs" onClick={() => onCloseSuccessMessage()} />
                          </div>
                        </Alert>
                        <Alert
                          className="toast-custom"
                          variant="danger"
                          show={errorMessageTransaction ? true : false}
                          onClose={() => onCloseErrorMessage()}
                        >
                          <div className="d-flex justify-content-between">
                            <div>
                              <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                              {errorMessageTransaction}
                            </div>
                            <Button variant="close" size="xs" onClick={() => onCloseErrorMessage()} />
                          </div>
                        </Alert>
                        {visitorTabKey === "all" ? (
                          <Row className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
                            <Row className="mb-4">
                              <Col xs={6}>
                                <div className="card-custom-title ps-3"></div>
                              </Col>
                              <Col xs={6} className="text-end icon-nav-list svg-icon-nav-list">
                                <div className="pe-4 d-inline-block pull-right">
                                  {filterCountryList.length > 0 ? createFilter :
                                    <div className="d-inline-block icon-nav icon-nav-filter ms-2">
                                      <div className="slider-dropdown-tigger d-inline-block filter-box-tigger icon-nav-list svg-icon-nav-list">
                                        <span className="icon-nav-box  ms-0 disabled"  >
                                          <svg className="icon-nav">
                                            <title>{t("header.Icon_Tooltip_Filter")}</title>
                                            <use href={iconSprite + `#Filter`} />
                                          </svg>
                                        </span>
                                      </div>
                                    </div>
                                  }
                                  <span className={totalRecords > 0 ? "icon-nav-box ms-2 hide-mobile" : "icon-nav-box hide-mobile disabled"}>
                                    <Dropdown as={ButtonGroup}>
                                      <Dropdown.Toggle split className="card-dropdown-btn p-0 border-0 pdf-download-btn">
                                        <svg className="icon-nav">
                                          <title>{t("header.Icon_Tooltip_Download")}</title>
                                          <use href={iconSprite + `#Download`} />
                                        </svg>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {setDowloadDropdownMenu()}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </span>
                                  <span className="icon-nav-box hide-mobile">
                                    <svg className="icon-nav" onClick={() => sendErrorReport()}>
                                      <title>{t("header.Icon_Tooltip_Report_Error")}</title>
                                      <use href={iconSprite + `#WarningTriangle`} />
                                    </svg>
                                  </span>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} className="text-end">
                                <div className="search-box-container">
                                  <Search
                                    setsearchData={setsearchData}
                                    searchData={searchData}
                                    // updateFormFields={updateFormFields}
                                    searchWaterMark={searchWaterMark}
                                    // searchCriteria={searchCriteria}
                                    // onSearchBoxChange={onSearchBoxChange}
                                    setSearchText={setSearchText}
                                    // formError={formError}
                                    // setFormError={setFormFields}
                                    // formFields={visitorFormFields}
                                    // setFormFields={setVisitorFormFields}
                                    isAutoComplete={true}
                                    setAutoFillForCompany={setAutoFillForCompany}
                                    showAutoFillForCompany={showAutoFillForCompany}
                                    // setSelectedCompany={setSelectedCompany}
                                    // queryValue={queryValue}
                                    // pivotConfigValue={pivotConfigValue}
                                    data={headerFilterData}
                                    type={"client"}
                                    isclear={true}
                                  ></Search>
                                </div>
                              </Col>
                            </Row>
                          </Row>
                        ) : (
                          <Row className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
                            <Row className="mb-4">
                              <Col xs={6}>
                                <div className="card-custom-title ps-3">
                                </div>
                              </Col>
                              <Col xs={6} className="text-end icon-nav-list svg-icon-nav-list">
                                <div className="pe-4 d-inline-block pull-right">
                                  {filterCountryList.length > 0 ? createFilter :
                                    <div className="d-inline-block icon-nav icon-nav-filter ms-2">
                                      <div className="slider-dropdown-tigger d-inline-block filter-box-tigger icon-nav-list svg-icon-nav-list">
                                        <span className="icon-nav-box  ms-0 disabled"  >
                                          <svg className="icon-nav">
                                            <title>{t("header.Icon_Tooltip_Filter")}</title>
                                            <use href={iconSprite + `#Filter`} />
                                          </svg>
                                        </span>
                                      </div>
                                    </div>
                                  }
                                  <div className="d-inline-block icon-nav icon-nav-filter ms-2 mt-0">
                                    <div className="d-inline-block icon-nav icon-nav-filter me-2">
                                      <div className="slider-dropdown-tigger d-inline-block filter-box-tigger icon-nav-list svg-icon-nav-list">
                                        <span className="icon-nav-box  ms-0" onClick={() => {
                                          setAutoFillForCompany(false);
                                          setShowAddWatchlist(!showAddWatchlist);
                                          setListMultiselect([])
                                          setSelectedOption([]);
                                        }}>
                                          <FontAwesomeIcon title={t("header.Icon_Tooltip_AddCompaniesToWatchlist")} icon={faPlusCircle} size="lg" />
                                        </span>
                                      </div>
                                    </div>
                                    {
                                      showAddWatchlist == true &&
                                      <Row className="align-items-center mt-2 mb-3">
                                        <Col>
                                          <div className="date-range-container pt-4 mt-2 me-3"
                                            onMouseLeave={() => {
                                              document.addEventListener("click", onClickOutsideListenerFilters)
                                            }}
                                            onMouseEnter={() => { document.removeEventListener("click", onClickOutsideListenerFilters) }}
                                          >
                                            <Autocomplete
                                              apiurl={baseURL + `/VisitorAnalytics/SearchVisitorsByName`}
                                              placeholder={t("header.Add_Watchlist_Company_Placeholder")}
                                              OnValueSelection={AutoFillSelection}
                                              autoTickEnabled={true}
                                              autoTickData={autoTickData}
                                              setCheckCompanyName={setCheckCompanyName}
                                            >
                                            </Autocomplete>
                                            <MultiSelect
                                              className="mt-3 text-start"
                                              disabled={listMultiselect.length > 0 && checkCompanyName ? false : true}
                                              options={listMultiselect}
                                              value={checkCompanyName ? selectedOption : []}
                                              onChange={setMultiselectOption}
                                              labelledBy="Select"
                                              disableSearch={true}
                                            />
                                            <div className="d-block mt-4 text-start">
                                              <ButtonComponent
                                                dissable={disableAddWatchlistButton}
                                                onClick={(e) => AddWatchlistCompany(e)}
                                                isIcon={false}
                                                isPrimary={true}
                                                btnText={t("header.VisitorsIconAddCompToWatchButtonAddCompanyText")}
                                                btn_class="btn-md mb-3 filter-btn"
                                              />
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    }
                                  </div>
                                  <span className={totalRecords > 0 ? "icon-nav-box ms-2 hide-mobile" : "icon-nav-box hide-mobile disabled"}>
                                    <Dropdown as={ButtonGroup}>
                                      <Dropdown.Toggle split className="card-dropdown-btn p-0 border-0 pdf-download-btn">
                                        <svg className="icon-nav">
                                          <title>{t("header.Icon_Tooltip_Download")}</title>
                                          <use href={iconSprite + `#Download`} />
                                        </svg>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {setDowloadDropdownMenu()}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </span>
                                  <span className="icon-nav-box hide-mobile">
                                    <svg className="icon-nav" onClick={() => sendErrorReport()}>
                                      <title>{t("header.Icon_Tooltip_Report_Error")}</title>
                                      <use href={iconSprite + `#WarningTriangle`} />
                                    </svg>
                                  </span>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} className="text-end watchlist-searchbox-wrapper">
                                <div className="search-box-container">
                                  <Search
                                    setsearchData={setsearchData}
                                    searchData={searchData}
                                    searchWaterMark={searchWaterMark}
                                    setSearchText={setSearchText}
                                    isAutoComplete={true}
                                    setAutoFillForCompany={setAutoFillForCompany}
                                    showAutoFillForCompany={showAutoFillForCompany}
                                    data={headerFilterData}
                                    type={"client"}
                                  ></Search>
                                </div>
                              </Col>
                            </Row>
                          </Row>
                        )}
                      </Col>
                    </Row>}
                    <Row>
                      <Col md={12}>
                        <Tab.Container
                          activeKey={visitorTabKey == "all" ? "all" : "watchlist"}
                          defaultActiveKey="all"
                          onSelect={onchangeVistiorTab}
                        >
                          <Nav fill variant="pills" className="tab subtab ms-3 me-3">
                            <Nav.Item>
                              <Nav.Link eventKey="all" className="mb-sm-3 mb-md-0">
                                {t("header.VisitorsAllVisitorsTabText")}
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="watchlist" className="mb-sm-3 mb-md-0">
                                {t("header.VisitorsWatchlistTabText")}
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Tab.Content className="mt-4">
                            <Tab.Pane eventKey="all" >
                              {
                                visitorTabKey === "all" && contextData.websiteId ? (
                                  VisitorAllComponent
                                ) : (
                                  <div className="no-data">{t("header.contentUnavailable")}</div>
                                )}
                            </Tab.Pane>
                            <Tab.Pane eventKey="watchlist"  >
                              {visitorTabKey === "watchlist" && contextData.websiteId ? (
                                WatchlistComponent
                              ) : (
                                <div className="no-data">{t("header.contentUnavailable")}</div>
                              )}
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row hidden={templateType === "Pages" ? false : true} className="page-analytics-inner">
                  <Col md={12}>
                    {<Row className={localStorage.getItem("isDemoClient") ? "page-analytics-search-download-box position-relative custom-disable" : "page-analytics-search-download-box position-relative"} >
                      <Col lg={12}>
                        {(
                          <>
                            <Row className="mb-4 hide-mobile">
                              <Col xs={6}>
                                <div className="card-custom-title ps-3"></div>
                              </Col>
                              <Col xs={6} className="text-end icon-nav-list svg-icon-nav-list">
                                <div className="pe-4 d-inline-block pull-right">
                                  <span className={totalRecords > 0 ? "icon-nav-box ms-2 hide-mobile" : "icon-nav-box hide-mobile disabled"}>
                                    <Dropdown as={ButtonGroup}>
                                      <Dropdown.Toggle split className="card-dropdown-btn p-0 border-0 pdf-download-btn">
                                        <svg className="icon-nav">
                                          <title>{t("header.Icon_Tooltip_Download")}</title>
                                          <use href={iconSprite + `#Download`} />
                                        </svg>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {setDowloadDropdownMenu()}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </span>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} className="text-end">
                                <div className="search-box-container">
                                  <Search
                                    setsearchData={setsearchData}
                                    searchData={searchData}
                                    setSearchText={setSearchText}
                                    isAutoComplete={false}
                                    setAutoFillForCompany={setAutoFillForCompany}
                                    showAutoFillForCompany={showAutoFillForCompany}
                                    data={headerFilterData}
                                    type={"client"}
                                  ></Search>
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </Col>
                    </Row>}

                    <Tab.Container
                      defaultActiveKey="allpages"
                      onSelect={onchangeVistiorTab}
                    >
                      {selectedCategoryId.sectionId == 0 ?
                        <Nav fill variant="pills" className="tab subtab ms-3 me-3">
                          <Nav.Item>
                            <Nav.Link eventKey="allpages" active={visitorTabKey === "allpages" ? true : false} className="mb-sm-3 mb-md-0">
                              {t("header.PagesAllPagesTabText")}
                            </Nav.Link>
                          </Nav.Item>
                          <div>{isGA4Profile}</div>
                          {
                            isGA4Profile === false ?
                              <Nav.Item>
                                <Nav.Link eventKey="landing" active={visitorTabKey === "landing" ? true : false} className="mb-sm-3 mb-md-0">
                                  {t("header.PagesLandingPagesTabText")}
                                </Nav.Link>
                              </Nav.Item>
                              : ""
                          }
                          {
                            isGA4Profile === false ?
                              <Nav.Item>
                                <Nav.Link eventKey="exit" active={visitorTabKey === "exit" ? true : false} className="mb-sm-3 mb-md-0">
                                  {t("header.PagesExitPagesTabText")}
                                </Nav.Link>
                              </Nav.Item>
                              :
                              ""
                          }
                        </Nav>
                        :
                        <Nav fill variant="pills" className="tab subtab ms-3 me-3">
                          <Nav.Item>
                            <Nav.Link eventKey="allpages" active={visitorTabKey === "allpages" ? true : false} className="mb-sm-3 mb-md-0">
                              {t("header.PagesAllPagesTabText")}
                            </Nav.Link>
                          </Nav.Item></Nav>}
                      <Tab.Content className="mt-4">
                        <Tab.Pane eventKey="allpages" active={visitorTabKey === "allpages" ? true : false}>
                          {visitorTabKey === "allpages" && contextData.websiteId ? (
                            <AllPages allPages={allPagesItems}
                              selectedCategoryId={selectedCategoryId}
                              SearchText={searchData}
                              setTotalRecords={setTotalRecords}
                            >
                            </AllPages>
                          ) : (
                            <div className="no-data">{t("header.contentUnavailable")}</div>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="landing" active={visitorTabKey === "landing" ? true : false}>
                          {visitorTabKey === "landing" && contextData.websiteId ? (
                            <LandingPages landingPages={landingPagesItems}
                              selectedCategoryId={selectedCategoryId}
                              SearchText={searchData}
                              setTotalRecords={setTotalRecords}
                            >
                            </LandingPages>
                          ) : (
                            <div className="no-data">{t("header.contentUnavailable")}</div>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="exit" active={visitorTabKey === "exit" ? true : false}>
                          {visitorTabKey === "exit" && contextData.websiteId ? (
                            <ExitPages exitPages={exitPagesItems}
                              selectedCategoryId={selectedCategoryId}
                              SearchText={searchData}
                              setTotalRecords={setTotalRecords}
                            >
                            </ExitPages>
                          ) : (
                            <div className="no-data">{t("header.contentUnavailable")}</div>
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>}
      {tabdatahide == 1 &&
        <></>
      }
    </>
  );
};

export default withRouter(Visitoranalytics);
