import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from "@themesberg/react-bootstrap";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from 'react-i18next';
import CardMenuComponent from "../components/CardMenu";
import { countryCodes, convertTranslatinString } from '../utils/common'

const CardWithMetrices = ({
  setIsPageLoaderShown,
  itemId,
  children,
  title,
  onCardMenuClick,
  showMenuIems,
  cardMenu,
  enableOption,
  summary,
  chartType,
  hideTitle,
  tooltipText,
  recordsCount,
  customTitle,
  tooltip,
  pageName
}) => {

  const [t, i18n] = useTranslation();

  const renderDashbordItemMenu = (itemId, title) => {
    return (
      enableOption && (
        <CardMenuComponent
          setIsPageLoaderShown={setIsPageLoaderShown}
          title={title}
          itemId={itemId}
          cardMenu={cardMenu}
          onCardMenuClick={onCardMenuClick}
        />
      )
    );
  };
  const showTooltips = (item) => {
    if (item.name == 'Cookies') {
      return "header.cookiesTooltiptext";
    } else if (item.name == 'Visitor Insights') {
      //  return 'header.visitorInsightsTooltiptext';
      return 'header.visitorInsightTooltip';
    } else if (item.name == 'Audience trends') {
      return 'header.audienceTrendsTooltiptext';
    } else if (item.name == 'Watchlist') {
      return 'header.watchlistTooltiptext';
      //return "Watch specific company visits to your website."
    } else if (item.name == 'Traffic source') {
      return 'header.trafficsourceTooltiptext';
    } else if (item.name == 'Search') {
      return 'header.searchTooltiptext';
    } else if (item.name == 'User') {
      return 'header.userTooltiptext';
    } else if (item.name == 'Content Insights') {
      return 'header.contentInsightsTooltiptext';
    } else {
      return "";
    }
  }

  const createTooltip = (t, tooltipKey) => {
    if (tooltipKey !== "") {
      return (
        <OverlayTrigger
          placement="right"
          trigger={["hover", "focus"]}
          overlay={
            <Tooltip>
              <div
                dangerouslySetInnerHTML={{
                  __html: t(tooltipKey, {
                    interpolation: { escapeValue: false },
                  }),
                }}
              ></div>
            </Tooltip>
          }
        >
          <Button variant="white" className="btn-help border-0 p-2">
            <i className="far fa-question-circle lg"></i>
          </Button>
        </OverlayTrigger>
      );
    }
  }

  return (
    <Card
      border="light"

      className={customTitle ? customTitle + "-table " + "shadow-sm" : title?.toLowerCase()?.replace(/ +/g, "") + "-table " + "shadow-sm"}
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      {title && <Card.Header
        className={
          summary !== undefined &&
            Object.keys(summary).length == 0 &&
            chartType !== "table"
            ? "mb-3"
            : ""
        }
      >
        <Row>
          {((hideTitle !== undefined && !hideTitle) ||
            hideTitle === undefined) &&
            title &&
            title.length > 50 && (
              <Col className="col-12">
                {
                  customTitle && customTitle === "eventcalender" ?
                    <div className="mb-3 manage-events-title-list" dangerouslySetInnerHTML={{ __html: title }}  ></div> :
                    <p className="mb-3 title">{title}</p>
                }
              </Col>
            )}

          {((hideTitle !== undefined && !hideTitle) ||
            hideTitle === undefined) &&
            title &&
            title.length < 50 &&
            ((pageName && pageName == "WebsiteInsight") || (pageName && pageName == "AlertStatsPage") ? (
              <Col className="title col-10">
                {t("header.title_" + convertTranslatinString(title), title)}
                {createTooltip(t, tooltip)}
              </Col>
            ) : (
              <Col className="title col-10">
                {t("header.title_" + convertTranslatinString(title), title)}
              </Col>
            ))}

          {showMenuIems && (
            <Col className="text-end col-2">
              {renderDashbordItemMenu(itemId, title)}
            </Col>
          )}
        </Row>
      </Card.Header>}
      <Card.Body id={"card_" + title}>{children}</Card.Body>
    </Card>
  );
};

CardWithMetrices.propTypes = {
  itemId: PropTypes.number,
  children: PropTypes.func,
  title: PropTypes.string,
  onCardMenuClick: PropTypes.func,
  showMenuIems: PropTypes.bool,
};
CardWithMetrices.defaultProps = {
  itemId: 0,
  children: null,
  title: "",
  onCardMenuClick: null,
  showMenuIems: true,
};

export default CardWithMetrices;
