import React, { useEffect, useState, useContext, useMemo } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import WebCastStatistics from '../components/WebCastStatistics';
import WebcastViewers from '../components/WebcastViewers';
import WebcastQuestions from '../components/webcastQuestions';
import { isMobile } from "react-device-detect";
import { tabsContent, webCastchartsVE, webCastquestionsVE, webCastPollsVE, webCastViewersVE, webCastMultiPollsVEList, webCastMultiPollsQuestionVEList, webCastStatsIE, WebcastViewersIL, WebcastQuestionsIL } from "../stubs/ComponentJson/webcast";
import ChartRenderer from '../components/ChartRenderer';
import DashboardItem from '../components/DashboardItem';
import { defaultLayout, defaultLayoutMobile } from "../utils/layoutConfig";
import { apiCharts, apiChartsForIL } from "../stubs/ComponentJson/webcast";
import { TABLE_TOTAL_ROWS } from "../AppConfig";
import AppContext from '../components/AppContext';
import Polls from '../components/Polls';
import Loader from '../components/VoltLoader';
import { Row, Col, Tab, Nav, Image, OverlayTrigger, Tooltip, Button } from '@themesberg/react-bootstrap';
import ChartRendererTable from '../components/ChartRendererTable';
import ApiChartRenderer from '../components/ApiChartRenderer';
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { useTranslation } from "react-i18next";
import { convertTranslatinString } from '../utils/common';
import { decryptData } from '../utils/crypto';
import CommonMessageComponent from '../components/CommonMessageComponent';
const ReactGridLayout = WidthProvider(RGL);

const deserializeItem = (i) => ({
  ...i,
  layout: JSON.parse(i.layout) || {},
  summary: JSON.parse(i.summary) || {},
  vizState: i.vizState ? JSON.parse(i.vizState) : {}
});

export const WebcastStatisticsPage = () => {
  const contextData = useContext(AppContext);
  const { presentationId } = useContext(AppContext);
  const { hasEvents } = useContext(AppContext);
  const { companyId } = useContext(AppContext);
  const { presentation } = useContext(AppContext);
  const [webcastViewers, setwebcastViewers] = useState([]);
  const [webcastQuestions, setwebcastQuestions] = useState([]);
  const [webcastPolls, setwebcastPolls] = useState([]);
  const [webcastMultiPolls, setwebcastMultiPolls] = useState([]);
  const [webcastCharts, setwebcastCharts] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [pageNoPolls, setPageNoPolls] = useState(0);
  const [offsetPolls, setOffsetPolls] = useState(0);
  const [offset, setOffset] = useState(0);
  const [items, setItems] = useState([]);
  const [getTotalResults, setGetTotalResults] = useState(0)

  const [itemsPolls, setItemsPolls] = useState([]);
  const [offsetViewer, setOffsetViewer] = useState(0);
  const [offsetQuestion, setOffsetQuestion] = useState(0);

  const [selectedpresentation, setSelectedpresentation] = useState({});
  const [pollType, setPollType] = useState();
  const [pollId, setPollId] = useState();
  const [multiPollQA, setMultiPollQA] = useState();
  const [selectedPollId, setSelectedPollId] = useState("")
  const [questionsCount, setQuestionsCount] = useState("");
  const [answersCount, setAnswersCount] = useState("");
  const [selectedPublishStatus, setSelectedPublishStatus] = useState();
  const [searchString, setSearchString] = useState("");
  const [isRefresh, setIsRefresh] = useState(false);
  //const [blurLoader, setBlurLoader] = useState(false);
  const [apiResultSet, setApiResultSet] = useState({});
  const [pollsResultSet, setPollsResultSet] = useState({});
  const [pollsData, setPollsData] = useState({});
  const [pollsRefresh, setPollsRefresh] = useState(false)
  const [templateTypeAI, setTemplateTypeAI] = useState("viewers")
  const [selectedtabs, setSelectedTabs] = useState([]);
  const [tabsCard, setTabsCard] = useState([])
  const [oldPresentationId, setOldPresentationId] = useState("");
  const [blurLoader, setBlurLoader] = useState(false);
  const [webcastViewersFilterData, setWebcastViewersFiltersData] = useState({ devicetype: "", domain: "", viewer: "unique", viewertype: "" })

  const [isTabChanged, setIsTabChanged] = useState(false);

  const [summary, setSummary] = useState({ transactionStatus: 0 });

  const [t] = useTranslation();
  function AddSelectedTab(tabname) {
    let arr = selectedtabs;
    if (!arr.includes(tabname)) {
      arr.push(tabname);
    }
    setSelectedTabs(arr)
  }
  useEffect(() => {
    AddSelectedTab("viewers")
  }, []);

  let PresentationId = (selectedpresentation !== null && selectedpresentation !== undefined) ? presentationId : 0;

  let eventType = "viewerQuestion";
  let currentPag = "ondemand";

  let status;
  let approvedFor = "";
  let charts;
  let viewers;
  let polls;
  let questions;
  let multiPolls;
  let multiPollsQuestions;

  useEffect(() => {
    getPollsList(presentationId);
  }, [pollsRefresh])

  useEffect(() => {
    setPollsData(pollsResultSet)
  }, [pollsResultSet])

  useEffect(() => {
    setWebcastViewersFiltersData({ domain: "", viewer: "unique", viewertype: "", devicetype: "" })
  }, [PresentationId]);
  useEffect(() => {
    setWebcastViewersFiltersData({ domain: "", viewer: "unique", viewertype: "", devicetype: "" })
  }, [PresentationId]);
  useEffect(() => {
    if (pageNo === 0) {
      setPageNo(1);
      setPageNoPolls(1);
    }
    let domain = webcastViewersFilterData.domain;
    let viewer = webcastViewersFilterData.viewer;
    let viewertype = webcastViewersFilterData.viewertype;
    let devicetype = webcastViewersFilterData.devicetype;
    // clear old filters and apply default filters
    if (oldPresentationId !== presentationId) {
      domain = ""
      viewer = "unique";
      viewertype = "";
      devicetype = ""
    }
    setOldPresentationId(presentationId)
    setwebcastViewers([])
    if (presentationId !== 0) {


      if (hasEvents == 1) {

        if (selectedPublishStatus === undefined || selectedPublishStatus.id === 1) {
          status = "";
          approvedFor = "";
        }
        else if (selectedPublishStatus.id === 2) {
          status = 0;
          approvedFor = "none";
        }
        else if (selectedPublishStatus.id === 3) {
          status = 1;
          approvedFor = "all";
        }
        else {
          status = 1;
          approvedFor = "presenter";
        }

        viewers = (eventType !== undefined && PresentationId !== undefined && PresentationId !== null && PresentationId !== "") ? webCastViewersVE(domain, viewer, viewertype, eventType, PresentationId, offsetViewer, companyId, presentation["Presentations.clientslug"], presentation["Presentations.slug"]) : null;
        setwebcastViewers(viewers);
        questions = (eventType !== undefined && PresentationId !== undefined && PresentationId !== null && PresentationId !== "") ? webCastquestionsVE(eventType, PresentationId, offsetQuestion, searchString, status, approvedFor, presentation["Presentations.clientslug"], presentation["Presentations.slug"]) : null;
        setwebcastQuestions(questions);
        polls = (eventType !== undefined && PresentationId !== undefined && PresentationId !== null && PresentationId !== "") ? webCastPollsVE(PresentationId) : null;
        setwebcastPolls(polls);
        setwebcastMultiPolls(null);
        setMultiPollQA(null);
        const tabsObject = (eventType !== undefined && PresentationId !== undefined && PresentationId !== null && PresentationId !== "") ? tabsContent() : [];
        setTabsCard(tabsObject);
      } else {
        const tabsObject = (eventType !== undefined && PresentationId !== undefined && PresentationId !== null && PresentationId !== "") ? tabsContent() : [];
        setTabsCard(tabsObject);

        viewers = (eventType !== undefined && PresentationId !== undefined && PresentationId !== null && PresentationId !== "") ? WebcastViewersIL(devicetype, domain, viewer, viewertype, eventType, PresentationId, currentPag, offsetViewer, companyId, presentation["Presentations.clientslug"], presentation["Presentations.slug"]) : null;
        setwebcastViewers(viewers);
        questions = (eventType !== undefined && PresentationId !== undefined && PresentationId !== null && PresentationId !== "") ? WebcastQuestionsIL(eventType, PresentationId, currentPag.replace, offsetQuestion, presentation["Presentations.clientslug"], presentation["Presentations.slug"], searchString) : null;
        setwebcastQuestions(questions);
      }
    }
  }, [presentation, presentationId, searchString, pollId, selectedPublishStatus, companyId, hasEvents, webcastViewersFilterData]);
  // update only webast charts
  useEffect(() => {
    let charts = "";
    setwebcastCharts([])
    const culture = localStorage.getItem("i18nextLng");
    if (presentationId !== 0) {

      if (hasEvents) {
        if (summary.transactionStatus !== 0) {
          setSummary({ transactionStatus: 0 });
        }
        charts = (eventType !== undefined && PresentationId !== undefined && PresentationId !== null && PresentationId !== "") ? webCastchartsVE(eventType, PresentationId, companyId, presentation["Presentations.clientslug"], presentation["Presentations.slug"]) : null;
        setwebcastCharts(charts);
      } else {

        charts = (eventType !== undefined && PresentationId !== undefined && PresentationId !== null && PresentationId !== "") ? webCastStatsIE(eventType, PresentationId, currentPag, companyId, presentation["Presentations.clientslug"], presentation["Presentations.slug"], culture) : null;
        setwebcastCharts(charts);

        if (PresentationId) {
          if (summary.transactionStatus !== 0) {
            setSummary({ transactionStatus: 0 });
          }

          request(baseURL + `/Analytics/GetInvestisLiveWebcastSummary?clientSlug=${presentation["Presentations.clientslug"]}&presentationId=${presentationId}&presentationSlug=${presentation["Presentations.slug"]}&providerName=InvestisLive&companyid=${companyId}`,
            METHODS.GET,
            {},
            {
              Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
              if (res) {
                setSummary({ data: res, transactionStatus: 1 });
              }
              else {
                setSummary({ transactionStatus: 2 });
              }

            })
            .catch((err) => {
              setSummary({ transactionStatus: 3 });
            })
        }

      }
    }
  }, [presentation, presentationId])





  function ConvertSecondsToTime(seconds) {

    if (seconds > 0) {
      return PadTwoDigit(Math.floor(seconds / 3600)) + ":" + PadTwoDigit(Math.floor((seconds %= 3600) / 60)) + ":" + PadTwoDigit(Math.floor(seconds % 60));
    }
    else {
      return "00:00:00";
    }
  }
  function PadTwoDigit(number) {
    return (number < 10 ? '0' : '') + number
  }

  useEffect(() => {
    if (presentationId) {
      getPollsList(presentationId);
    }
    setTemplateTypeAI('viewers');
    AddSelectedTab('viewers')

  }, [presentationId])

  useEffect(() => {
    if (hasEvents == 1) {
      if (selectedPublishStatus === undefined || selectedPublishStatus.id === 1) {
        status = "";
        approvedFor = "";
      }
      else if (selectedPublishStatus.id === 2) {
        status = 0;
      }
      else if (selectedPublishStatus.id === 3) {
        status = 1;
        approvedFor = "all";
      }
      else {
        status = 1;
        approvedFor = "presenter";
      }
      questions = (eventType !== undefined && PresentationId !== undefined && PresentationId !== null && PresentationId !== "") ? webCastquestionsVE(eventType, PresentationId, offsetQuestion, searchString, status, approvedFor, presentation["Presentations.clientslug"], presentation["Presentations.slug"]) : null;
      setwebcastQuestions(questions);
    } else {
      questions = (eventType !== undefined && PresentationId !== undefined && PresentationId !== "") ? WebcastQuestionsIL(eventType, PresentationId, currentPag.replace, offsetQuestion, presentation["Presentations.clientslug"], presentation["Presentations.slug"], searchString) : null;
      setwebcastQuestions(questions);
    }
  }, [isRefresh]);

  const onPageNoChange = (number) => {
    const num = (number - 1) * TABLE_TOTAL_ROWS;
    const page = number;
    setOffset(num);
    setPageNo(page);
  }

  const changeViewerData = () => {
    if (hasEvents == 1) {
      viewers = (eventType !== undefined && PresentationId !== undefined && PresentationId !== "") ? webCastViewersVE(eventType, PresentationId, offsetViewer, companyId, presentation["Presentations.clientslug"], presentation["Presentations.slug"]) : null;
      setwebcastViewers(viewers);
    } else {
      viewers = (eventType !== undefined && PresentationId !== undefined && PresentationId !== "") ? WebcastViewersIL(eventType, PresentationId, currentPag, offsetViewer) : null;
      setwebcastViewers(viewers);
    }
  }

  const getPollsList = (presentationId) => {
    request(baseURL + `/Analytics/GetPollDetail?presentationId=${presentationId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        setPollsResultSet(res)
      })
      .catch((err) => {
      })
  }

  //this function is for last updated date for IL clients.
  const refreshMannual = (data) => {
    setIsTabChanged(data ? true: false)
    let domain = webcastViewersFilterData.domain;
    let viewer = webcastViewersFilterData.viewer;
    let viewertype = webcastViewersFilterData.viewertype;
    let devicetype = webcastViewersFilterData.devicetype;
    let viewers;
    if (contextData.hasEvents === 0) {
      viewers = (eventType !== undefined && PresentationId !== undefined && PresentationId !== null && PresentationId !== "") ? WebcastViewersIL(devicetype, domain, viewer, viewertype, eventType, PresentationId, currentPag, offsetViewer, companyId, presentation["Presentations.clientslug"], presentation["Presentations.slug"], "trigger") : null;
      setwebcastViewers(viewers);
    } else {

      viewers = (eventType !== undefined && PresentationId !== undefined && PresentationId !== null && PresentationId !== "") ? webCastViewersVE(domain, viewer, viewertype, eventType, PresentationId, offsetViewer, companyId, presentation["Presentations.clientslug"], presentation["Presentations.slug"]) : null;
      setwebcastViewers(viewers);
    }
  }

  const changeQuestionData = () => {
    if (hasEvents == 1) {
      let status = "";
      let approvedFor = "";
      if (selectedPublishStatus === undefined || selectedPublishStatus.id === 1) {
        status = "";
        approvedFor = "";
      }
      else if (selectedPublishStatus.id === 2) {
        status = 0;
        approvedFor = "none";
      }
      else if (selectedPublishStatus.id === 3) {
        status = 1;
        approvedFor = "all";
      }
      else {
        status = 1;
        approvedFor = "presenter";
      }
      questions = (eventType !== undefined && PresentationId !== undefined && PresentationId !== "") ? webCastquestionsVE(eventType, PresentationId, offsetQuestion, searchString, status, approvedFor, presentation["Presentations.clientslug"], presentation["Presentations.slug"]) : null;
      setwebcastQuestions(questions);
    } else {
      questions = (eventType !== undefined && PresentationId !== undefined && PresentationId !== "") ? WebcastQuestionsIL(eventType, PresentationId, currentPag.replace, offsetQuestion, presentation["Presentations.clientslug"], presentation["Presentations.slug"], searchString) : null;
      setwebcastQuestions(questions);
    }
  }

  const changePollsData = () => {
    polls = (eventType !== undefined && PresentationId !== undefined && PresentationId !== "") ? webCastPollsVE(eventType, PresentationId, offsetPolls) : null;
    setwebcastPolls(polls);
  }

  const onPageNoChangePolls = (number) => {
    const num = (number - 1) * TABLE_TOTAL_ROWS;
    const page = number;
    setOffsetPolls(num);
    setPageNoPolls(page);
    changePollsData();
  }

  const onPollSelect = (pollId) => {
    setPollId(pollId);
  }

  const onPollChange = (data) => {
    setSelectedPollId(data)
    setPollId(data["PresentationsPolls.pollsId"])
  }

  const renderMultiPollsQuestion = (item, element) => {
    return (<Tab.Pane eventKey={element["PresentationsPolls.pollsId"]}>
      <Row>
        <Col sm={12}>
          <ChartRenderer cardName={item.name} vizState={item.vizState} cardProperty={item.layout} showPagination={item.pagination} />
        </Col>
      </Row>
    </Tab.Pane>)
  }

  const renderMultiPollsQuestionWithNoRecords = (item) => {
    return (<Tab.Pane eventKey="tyuuyg">
      <Row>
        <Col sm={12}>
          <ChartRenderer noRecords={'noRecords'} cardName={item.name} vizState={item.vizState} items={[]} cardProperty={item.layout} showPagination={item.pagination} />
        </Col>
      </Row>
    </Tab.Pane>)
  }

  const renderTabs = (item) =>
  (<>
  </>)


  const questionsAnswersResultSingle = ({ resultSet, error, pivotConfig, item }) => {
    if (error) {
      return <div>{error.toString()}</div>;
    }
    if (!resultSet) {
      return <Loader show={false} />;
    }
    const memberList = resultSet.tablePivot(pivotConfig)
    setQuestionsCount("");
    setAnswersCount("");
    if (memberList !== undefined && memberList !== null && memberList.length > 0) {
      setQuestionsCount(memberList[0]["PresentationPollsQaDetails.countquestions"]);
      setAnswersCount(memberList[0]["PresentationPollsQaDetails.countanswers"]);
    }
    return <>
    </>
  }

  const showTooltips = (item) => {
    if (item.name == 'On demand') {
      return "header.onDemendViewersTooltipText";
    } else if (item.name == 'Live') {
      return 'header.liveViewersTooltipText';
    } else {
      return "";
    }
  }

  const webcastChartsItem = (item) => (
    <>
      {/* <Col md={12} className='col-spacing-bottom'> */}
      <DashboardItem
        key={item.id}
        itemId={item.id}
        title={item.name}
        hidetitle={true}
      >

        {
          (item.isResultShownFromApi && item.chartType && item.chartType != "table") ? <ApiChartRenderer isLegendShown={item.isLegendShown} isSummeryShown={item.isSummeryShown} services={hasEvents ? apiCharts : apiChartsForIL} chartType={item.chartType} legendPercentage={item.legendPercentage} itemId={item.id} vizState={item.vizState} cardProperty={Object.keys(item.layout).length > 0 ? item.layout : item.layout}
            pageNo={pageNo} onPageNoChange={onPageNoChange}
            legends={item.legends} isRefresh={isRefresh} setIsRefresh={setIsRefresh} summaryTooltip={showTooltips(item)} summaryBesideText={t("header.Webcast_Linechart_Metrices_" + convertTranslatinString(item.summaryBesideText))} /> : <></>
        }
        {
          ((hasEvents && item.id == 2) || (item.id == 4 || item.id == 6)) &&
          <ChartRendererTable
            summaryTooltip={item.tooltip}
            showPagination={false}
            apiUrl={item.apiUrl}
            setGetTotalResults={(value) => setGetTotalResults(value)}
          />
        }
      </DashboardItem>
      {/* </Col> */}
    </>
  );

  const tabItem = (item) =>
  (
    <div key={item.id} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)} >
      <DashboardItem
        key={item.id}
        itemId={item.id}
        title={item.name}
        hideTitle={true}
      >
        <Row>
          <Col>
            <Tab.Container
              defaultActiveKey="viewers"
              activeKey={templateTypeAI}
            >
              <Nav fill variant="pills" className="tab subtab ms-3 me-3 mt-1">
                <Nav.Item>
                  <Nav.Link onClick={() => { setTemplateTypeAI('viewers'); AddSelectedTab('viewers'); refreshMannual("NoDateChange"); }} eventKey="viewers" className={templateTypeAI == "viewers" ? "mb-sm-3 mb-md-0 active" : "mb-sm-3 mb-md-0"}>
                    {t("header.VisitorsActivityPopWebcastTabText")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => { setTemplateTypeAI('questions'); AddSelectedTab('questions'); setwebcastViewers([]) }} eventKey="questions" className={templateTypeAI == "questions" ? "mb-sm-3 mb-md-0 active" : "mb-sm-3 mb-md-0"}>
                    {t("header.WebcastViewersQuestionsTab")}
                  </Nav.Link>
                </Nav.Item>
                {hasEvents === 1 &&
                  <Nav.Item>
                    <Nav.Link onClick={() => { setTemplateTypeAI('polls'); AddSelectedTab('polls') }} eventKey="polls" className={templateTypeAI == "polls" ? "mb-sm-3 mb-md-0 active" : "mb-sm-3 mb-md-0"}
                    >
                      {t("header.webcastTabText")}
                    </Nav.Link>
                  </Nav.Item>
                }
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="viewers" className="py-2 viewer">
                  <Row>
                    <Col className="webcast-viewers-data">
                      {selectedtabs.includes("viewers") && templateTypeAI === "viewers" && webcastViewers &&

                        webcastViewersContent

                      }
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="questions" className="py-2 question">
                  <Row>
                    <Col>
                      {selectedtabs.includes("questions") && templateTypeAI === "questions" && webcastQuestions && presentationId &&
                        <WebcastQuestions showLoader={setBlurLoader} selectedPublishStatus={selectedPublishStatus} isRefresh={isRefresh} setIsRefresh={setIsRefresh} setSearchString={setSearchString} selectedpresentation={selectedpresentation} presentationId={presentationId} hasEvents={hasEvents} setSelectedPublishStatus={setSelectedPublishStatus} webcastQuestions={webcastQuestions} changeQuestionData={changeQuestionData}></WebcastQuestions>
                      }
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="polls" className="py-2 polls">
                  <Row>
                    <Col>
                      {selectedtabs.includes("polls") && templateTypeAI === "polls" && hasEvents === 1 &&
                        (webcastPolls && webcastPolls.map(deserializeItem).map(webcastPollsItem))
                      }
                    </Col>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </DashboardItem>
    </div>
  );

  const webcastPollsItem = (item) => {
    return (
      <div className="single-poll-tab-content" key={item.id} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
        <Polls resultSet={pollsData} setPollsRefresh={setPollsRefresh} />
      </div>
    )
  };

  const modifySummary = (result) => {

    let TotalLiveViewers = 0;
    let TotalLiveViewersTime = 0;
    let TotalOnDemandViewers = 0;
    let TotalOnDemandViewersTime = 0;

    for (let row of result.data) {

      if (row.viewersType == "Live" && row.totalTime != "-") {
        TotalLiveViewers += 1

        TotalLiveViewersTime += row.totalTime.split(':').reduce(
          function (acc, time) {
            return (60 * acc) + + time;
          });
      }
      else if (row.viewersType == "OnDemand") {
        TotalOnDemandViewers += 1
        TotalOnDemandViewersTime += row.totalTime.split(':').reduce(
          function (acc, time) {
            return (60 * acc) + + time;
          });
      }
    }

  }
  const setStatsForVE = (result) => {

    if (result && hasEvents) {

      setSummary({
        data: {
          UniqueLiveViewers: result.webcastSummary.uniqueLiveViewers,
          UniqueOnDemandViewers: result.webcastSummary.uniqueOnDemandViewers,
          WebcastDuration: result.webcastSummary.webcastDuration
        }, transactionStatus: 1
      });

    }
    else if (hasEvents) {
      setSummary({ transactionStatus: 2 });
    }
  }
  const createTooltip = (tooltipKey, placement) => {
    if (tooltipKey !== "") {
      return (
        <OverlayTrigger
          placement={placement}
          trigger={["hover", "focus"]}
          overlay={
            // <Tooltip>{t("header.VisitorAnalyticsTooltip")}</Tooltip>
            <Tooltip>
              <div
              // dangerouslySetInnerHTML={{
              //   __html: t("header.VisitorAnalyticsTooltip", {
              //     interpolation: { escapeValue: false },
              //   }),
              // }}
              >{tooltipKey}</div>
            </Tooltip>
          }
        >

          <Button variant="white" className="btn-help border-0 p-1">
            <i className="far fa-question-circle lg"></i>
          </Button>
        </OverlayTrigger>
      );
    }
  }

  let webcastViewersContent = useMemo(() => {
    return (<WebcastViewers onRefresh={refreshMannual} isTabChanged={isTabChanged} showLoader={setBlurLoader} modifySummary={modifySummary} setStatsForVE={setStatsForVE} webcastViewers={webcastViewers} hasEvents={hasEvents} changeViewerData={changeViewerData} setWebcastViewersFiltersData={setWebcastViewersFiltersData}></WebcastViewers>)
  }, [webcastViewersFilterData, refreshMannual])

  return (
    <>
      {blurLoader && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}
      <Row>
        <Col xs={12} className="webcast-analytics">
          <WebCastStatistics selectedpresentation={selectedpresentation} setSelectedpresentation={setSelectedpresentation} setPollType={setPollType}>
          </WebCastStatistics>


          <div className="d-inline-block p-3 pt-0 pe-0 w-100" id="webcastStatistics">



            {
              summary.transactionStatus === 1 &&
              <div class="switch-toggle-component col-spacing-bottom webcast-stat shadow-soft border rounded border-light p-4 ps-2 pe-2 w-100 nested-card-container position-relative"><div class="d-inline-block switch-toggle-component-item">
                <div class="metrics-label-container">
                  <label class="widget-summary-metrics-label d-inline-block text-uppercase">{t("header.WebcastAditionalInsightLiveViewersFilter")} {createTooltip(t("header.WebcastStatisticsSummaryLiveToolTip"), "right")}</label>
                  <div class="widget-summary-metrics">{summary.data.UniqueLiveViewers}</div>
                  <span class="section-label count pod text-center" title={t("header.WebcastSummaryUniqueViewerText")}>{t("header.WebcastSummaryUniqueViewerText")}</span>
                </div>

              </div>
                <div class="d-inline-block switch-toggle-component-item">
                  <div class="metrics-label-container">
                    <label class="widget-summary-metrics-label d-inline-block text-uppercase">{t("header.WebcastAditionalInsightOnDemandFilter")} {createTooltip(t("header.WebcastStatisticsSummaryODToolTip"), "right")}</label>
                    <div class="widget-summary-metrics">{summary.data.UniqueOnDemandViewers}</div>
                    <span class="section-label count pod text-center" title={t("header.WebcastSummaryUniqueViewerText")}>{t("header.WebcastSummaryUniqueViewerText")}</span>
                  </div>

                </div>
                <div class="d-inline-block switch-toggle-component-item">
                  <div class="metrics-label-container">
                    <label class="widget-summary-metrics-label d-inline-block text-uppercase">{t("header.WebcastSummaryTotalWebcastTimeText")} {createTooltip(t("header.WebcastStatisticsSummaryDuration"), "left")}</label>
                    <div class="widget-summary-metrics">{summary.data.WebcastDuration ? summary.data.WebcastDuration : "-"}</div>
                  </div>

                </div>
              </div>
            }
            {
              summary.transactionStatus !== 1 && webcastCharts && webcastCharts.length > 0 &&
              <div class="switch-toggle-component col-spacing-bottom webcast-stat shadow-soft border rounded border-light p-4 ps-2 pe-2 w-100 nested-card-container position-relative">
                <div class="d-inline-block tools-dashboard-card-loader card-spacing-bottom">

                  <CommonMessageComponent transactionStatus={summary.transactionStatus}></CommonMessageComponent>

                </div>
              </div>
            }









            <Row>
              <Col xs={12}>
                {/* {webcastCharts && webcastCharts.length>0 &&
                <>
                  <ReactGridLayout cols={12} rowHeight={50}
                    isDraggable={false} isResizable={false}
                  >
                    {webcastCharts.map(deserializeItem).map(webcastChartsItem)}
                  </ReactGridLayout>
                </>
              } */}

                {webcastCharts && webcastCharts.length > 0 && (
                  <div className="row">
                    {webcastCharts.map((chart, index) => (
                      <div key={index} className="col-md-6 col-spacing-bottom">
                        {/* min-height is just for trial concept you might need to adjust height */}
                        {webcastChartsItem(deserializeItem(chart))}
                      </div>
                    ))}
                  </div>
                )}

              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {/* Change css when react grid item order will be changed or rearranged */}
                {tabsCard.map(deserializeItem).map(tabItem)}
              </Col>
            </Row>
          </div>

        </Col>
      </Row >
    </>);
};


export default WebcastStatisticsPage;