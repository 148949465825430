
// export const cardMenu= {
//     "detail": "Detailed view",
//     "download": "Download",
// }


export const cardMenu= [{title:"Detailed_view", disabledMenu:[2,5]},
{title:"Download",  disabledMenu:[]}]
    
