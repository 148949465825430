import React, { useState, useEffect, useContext, useMemo } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';
import { Row, Col, Button, Form, InputGroup, FormCheck, Image, Modal } from '@themesberg/react-bootstrap';
import DropdownWithIconComponent from "../components/DropdownWithIcons";
import Loader from '../components/VoltLoader';
import { withRouter } from "react-router";
import AppContext from '../components/AppContext';
import CommonHeader from '../components/CommonHeader';
import { DEFAULT_WEBSITE_DASHBORD, DEFAULT_PROFILE_DASHBORD, object_equals, NULL_VALUE_MESSAGE_DROPDOWN } from "../AppConfig";
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import AddEventPages from '../pages/AddEventPages';
import { useTranslation } from 'react-i18next';
import { decryptData } from '../utils/crypto';

const ReactGridLayout = WidthProvider(RGL);

const EventCalendar = ({ children, setIsPageLoad, editData, attachmentView, setBlurLoader, blurLoader, setEditData, dashboardItems, setPageNo, setOffset, selectedWebsiteId, setSelectedWebsiteId, selectedLanguage, setSelectedLanguage, history, location }) => {
  const [t, i18n] = useTranslation();
  const [websiteList, setWebsiteList] = useState([]);
  const contextData = useContext(AppContext);
  const [selectedLaguage, setSelectedLaguage] = useState("");
  const [companyIdValue, setcompanyIdValue] = useState("");
  const [selectedWebsiteIdNew, setSelectedWebsiteIdNew] = useState("");
  const [selectedLanguageNew, setSelectedLanguageNew] = useState("");
  const [autoLoad, setAutoLoad] = useState(false);
  const [updateKey, setUpdateKey] = useState("");

  const [forceUpdate, setForceUpdate] = useState(false);

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const cubejsApi = cubejs(
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJteWFwcGlkIjozLCJpYXQiOjE2MTgyMjkzNDIsInVzZXJfaWQiOjM4MDgxLCJwbGF0Zm9ybV9pZCI6MSwic2VydmljZV9pZCI6OCwiY29tcGFueV9pZCI6OTM0NX0.lYSmPrF55TH3y8HQk96khmXn08XBt5nkUh7vzxfyak4',
    { apiUrl: `${process.env.REACT_APP_CUBESERVERURL}/cubejs-api/v1` }
  );


  const [isApplyDisabled, setIsApplyDisabled] = useState(true);

  // useEffect(() => {
  //   if (contextData.userId > 0 && contextData.companyId > 0) {

  //     //setcompanyIdValue(contextData.companyId);
  //     setSelectedWebsiteId({});
  //     setSelectedLanguage({});

  //     if (localStorage.getItem("IsPageRefresh") == "1") {
  //       setSelectedLanguageNew(JSON.parse(localStorage.getItem("SelectedEventId")));
  //       if (localStorage.getItem("EventCalendarProfileId") != "")
  //         setSelectedLanguageNew(JSON.parse(localStorage.getItem("EventCalendarProfileId")));
  //     }
  //   }
  // }, [contextData.userId, contextData.companyId]);

  const onWebsiteDropdownChange = (data) => {
    setSelectedWebsiteIdNew(data)
  };

  const onLanguageDropdownChange = (data) => {
    setSelectedLanguageNew(data)
  };

  const applyFilters = () => {

    setSelectedLanguage(selectedLanguageNew);
    contextData.changeWebsiteId(selectedWebsiteIdNew["WebsiteList.WebsiteId"]);
    setSelectedWebsiteId(selectedWebsiteIdNew);

    localStorage.setItem("SelectedEventId", JSON.stringify(selectedWebsiteIdNew));// Adding SelectedEventId to local stoarge in case of Page Refresh
    localStorage.setItem("EventCalendarProfileId", JSON.stringify(selectedLanguageNew));// Adding EventCalendarProfileId to local stoarge in case of Page Refresh
  }

  const cancelFilters = () => {
    onWebsiteDropdownChange(JSON.parse(localStorage.getItem("SelectedEventId")))
    onLanguageDropdownChange(JSON.parse(localStorage.getItem("EventCalendarProfileId")))
  }


  const renderLanguage = ({ resultSet, error, pivotConfig, request }) => {
    if (error) {
      return <div>{error.toString()}</div>;
    }

    if (!resultSet) {
      return <Loader show={false} />;
    }
    localStorage.setItem("languageData", JSON.stringify([]))
    if (resultSet !== null && resultSet.tablePivot(pivotConfig).length) {
      localStorage.setItem("languageData", JSON.stringify(resultSet.tablePivot(pivotConfig)))
      if (Object.keys(selectedLanguageNew).length <= 0) {
        onLanguageDropdownChange(resultSet.tablePivot(pivotConfig)[0])
        setAutoLoad(true)
      }

    } else {
      setAutoLoad(true)
    }

    return (
      <></>

    );

  };

  useEffect(() => {
    setBlurLoader(true);
    if(contextData.companyId>0)
    {
      request(baseURL + `/Intelligence/GetToolsWebsiteForClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
        METHODS.GET,
        {},
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        })
        .then((res) => {
          setBlurLoader(false);
          onWebsiteDropdownChange(undefined);

          let webSiteData = [];
          res?.result && Array.isArray(res?.result) && res?.result.length > 0 && res?.result.map((item) => {
            webSiteData.push({
              "ClietConfiguration.otpclientid": 0,
              "WebsiteList.WebsiteId": item.WebsiteId,
              "WebsiteList.WebsiteName": item.WebsiteName
            })
          })
          if (Object.keys(selectedWebsiteIdNew).length <= 0) {
            if (localStorage.getItem("SelectedEventId")) {
              onWebsiteDropdownChange(JSON.parse(localStorage.getItem("SelectedEventId")))
            } else {
              webSiteData.length > 0 ? onWebsiteDropdownChange(webSiteData[0]) : onWebsiteDropdownChange({});
            }

          }
          localStorage.setItem("eventsWebsiteData", JSON.stringify(webSiteData))
          //setAutoLoad(true)
          //setForceUpdate(!forceUpdate)
          setWebsiteList(webSiteData);

        })
        .catch((err) => {
          setBlurLoader(false);
          setWebsiteList([]);
        })

    }

  }, [contextData.userId, contextData.companyId])

  useEffect(() => {
    let lngData = [];
    setIsApplyDisabled(lngData.length > 0 ? false : true)
    let wId = selectedWebsiteIdNew ? selectedWebsiteIdNew["WebsiteList.WebsiteId"] : undefined;
    if (wId) {

      //setBlurLoader(true);
      request(baseURL + `/EventCalendar/GetEventLanguagesByClient?CompanyId=${contextData.companyId}&UserId=${contextData.userId}&WebsiteId=${wId}`,
        METHODS.GET,
        {},
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        })
        .then((res) => {
          let lngData = [];
          setIsApplyDisabled(lngData.length > 0 ? false : true)
          if (typeof res == 'string') {
            localStorage.setItem("languageData", JSON.stringify([]));
            onLanguageDropdownChange({});
            setAutoLoad(true);

          }
          else {
            onLanguageDropdownChange("");

            let lngData = [];
            res?.result && Array.isArray(res?.result) && res?.result.length > 0 && res?.result.map((item) => {
              lngData.push({
                "Languages.Languagecode": item.LanguageCode,
                "Languages.languagename": item.DisplayName
              })
            })

            localStorage.setItem("languageData", JSON.stringify([]))
            if (lngData.length > 0) {
              localStorage.setItem("languageData", JSON.stringify(lngData))
              onLanguageDropdownChange(lngData[0]);
              setAutoLoad(true);
              setIsApplyDisabled(lngData.length > 0 ? false : true)

            } else {
              setAutoLoad(true);

            }
          }

          //setBlurLoader(false);
        })
        .catch((err) => {
          onLanguageDropdownChange({});
          localStorage.setItem("languageData", JSON.stringify([]));
          setAutoLoad(true);
          //setBlurLoader(false);
          //setWebsiteList([]);
        })
    }
    //localStorage.setItem("languageData", JSON.stringify(resultSet.tablePivot(pivotConfig)))
  }, [selectedWebsiteIdNew]);


  const handleClose = () => {
    setIsOpenEditModal(false);
    setEditData(undefined);
  }

  const commonHeader = useMemo(()=>
  <CommonHeader cancelFilters={cancelFilters} forceUpdate={forceUpdate} autoLoad={autoLoad} dataSource={[
    { name: 'Dropdown1 - website', data: JSON.parse(localStorage.getItem("eventsWebsiteData")), selectedItem: selectedWebsiteIdNew, isVisible: true, label: t("header.filterLabelWebsite"), callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" },
    { name: 'Dropdown2 - language', data: JSON.parse(localStorage.getItem("languageData")), selectedItem: selectedLanguageNew, isVisible:selectedLanguageNew ? true : false , label: t("header.ToolsEventCalEventListLanguage"), callback: onLanguageDropdownChange, displayText: "Languages.languagename" },
  ]} title={t("header.ToolsEventCalTabManageEvents")} showDate={false} applyFilters={applyFilters} isApplyDisabled={isApplyDisabled}/>

  ,[selectedWebsiteIdNew, selectedLanguageNew, autoLoad, isApplyDisabled])
  //const commonHeader = useMemo(() => , [selectedWebsiteIdNew, selectedLanguageNew, autoLoad]);
  return (
    <>

      {/* Edit Modal */}
      {editData && <Modal as={Modal.Dialog} size="xl" centered show={true} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h5"><h5>{t("header.ToolsEventCalModEventHeading")}</h5></Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        {/* {t("header.ToolsEventCalTabManageEvents")} */}
        <Modal.Body>
          <AddEventPages
            setIsPageLoad={setIsPageLoad}
            setEditData={setEditData}
            attachmentView={attachmentView}
            isEditMode={true}
            editData={editData}
          />
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>}

      {blurLoader && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}
      <Row>
        <Col>
          {/* <CommonHeader cancelFilters={cancelFilters} forceUpdate={forceUpdate} autoLoad={autoLoad} dataSource={[
            { name: 'Dropdown1 - website', data: JSON.parse(localStorage.getItem("eventsWebsiteData")), selectedItem: selectedWebsiteIdNew, isVisible: true, label: t("header.filterLabelWebsite"), callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" },
            { name: 'Dropdown2 - language', data: JSON.parse(localStorage.getItem("languageData")), selectedItem: selectedLanguageNew, isVisible: !isApplyDisabled, label: t("header.ToolsEventCalEventListLanguage"), callback: onLanguageDropdownChange, displayText: "Languages.languagename" },
          ]} title={t("header.ToolsEventCalTabManageEvents")} showDate={false} applyFilters={applyFilters} isApplyDisabled={isApplyDisabled}/> */}
          {commonHeader}
        </Col>
      </Row>

      {/* <ReactGridLayout cols={12} rowHeight={50}
        isDraggable={false} isResizable={false}
      >
        
      </ReactGridLayout> */}
      {/* <div className="d-inline-block p-3 pe-0 w-100 hide-ipad">
        <div className="shadow-soft border rounded border-light w-100 nested-card-container Notification-container"> */}
      <Row>
        <Col className="manage-events-data">
          {children}
        </Col>
      </Row>
      {/* </div>
      </div> */}
    </>
  );
};

export default withRouter(EventCalendar);
