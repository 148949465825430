import React, { useState, useMemo, useEffect, useContext } from "react";
import { withRouter } from "react-router";
import DashboardItem from "../components/DashboardItem";
import { isMobile } from "react-device-detect";
import ChartRendererTable from "../components/ChartRendererTable";
import { defaultLayout, defaultLayoutMobile } from "../utils/layoutConfig";
import AppContext from "../components/AppContext";
import { Row, Col, Image, Dropdown, ButtonGroup, Alert } from "@themesberg/react-bootstrap";
import ModalComponent from "../components/VoltModal";
import Search from "../components/Search";
import { Nav, Tab } from "@themesberg/react-bootstrap";
import moment from "moment-timezone";
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import axios from "axios";
import { convertBase64 } from "../utils/common";
import { useTranslation } from 'react-i18next';
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { decryptData } from "../utils/crypto";
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';


const deserializeItem = (i) => ({
  ...i,
  layout: i.layout ? JSON.parse(i.layout) : {},
  summary: i.summary ? JSON.parse(i.summary) : {},
  vizState: i.vizState ? JSON.parse(i.vizState) : {},
});
const ExitPages = ({
  selectedCategoryId,
  SearchText,
  setSearchText,
  setVisitorId,
  changeWatchlist,
  setIsRefresh,
  exitPages,
  offsetWatchlist,
  setOffsetWatchlist,
  pageNoWatchlist,
  setPageNoWatchlist,
  setheaderFilterData,
  setTotalRecords
}) => {
  const contextData = useContext(AppContext);
  const [apiUrl, setApiUrl] = useState({});
  const [items1, setItems1] = useState([]);
  const [numberOfRecords, setNumberOfRecords] = useState(0);
  const [pageObjDetails, setPageObjDetails] = useState({});
  const [apiUrlPopup, setapiUrlPopup] = useState({});
  const [showPopup, setshowPopup] = useState(false)
  const [searchData, setsearchData] = useState("");
  const [headerFilterDataPopup, setheaderFilterDataPopup] = useState(null);
  const [showAutoFillForCompanyPopup, setAutoFillForCompanyPopup] = useState(false);
  const [blurLoader, setBlurLoader] = useState(false);
  const [getTotalResults, setGetTotalResults] = useState(0);
  const [t, i18n] = useTranslation();
  const [alertVisible, setAlertVisible] = useState(false);

  useEffect(() => {
    let ms = new Date(Date.parse(contextData.startDate));
    let sdate = ms.getFullYear() + "-" + parseInt(ms.getMonth() + 1) + "-" + ms.getDate()
    let ms2 = new Date(Date.parse(contextData.endDate));
    let sdate2 = ms2.getFullYear() + "-" + parseInt(ms2.getMonth() + 1) + "-" + ms2.getDate()
    setApiUrl(
      {
        url: `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetExitPagesByProfileId?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
        &startDate=${sdate}&endDate=${sdate2}&sortexpression=PageViews&sortdirection=true&companyid=${contextData.companyId}&packageid=5`, type: 'get', count: 0
      }
    )
  }, [contextData.startDate, contextData.endDate, selectedCategoryId.sectionId])

  useEffect(() => {
    setTotalRecords(0)
    setTotalRecords(getTotalResults)
  }, [getTotalResults])

  const onModalClose = (obj) => {
    let ms = new Date(Date.parse(contextData.startDate));
    let sdate = ms.getFullYear() + "-" + parseInt(ms.getMonth() + 1) + "-" + ms.getDate()
    let ms2 = new Date(Date.parse(contextData.endDate));
    let sdate2 = ms2.getFullYear() + "-" + parseInt(ms2.getMonth() + 1) + "-" + ms2.getDate()
    if (obj.data !== undefined) {
      setsearchData("");
      setapiUrlPopup(
        {
          url: `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetVisitorByExitPage?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
          &startdate=${sdate}&enddate=${sdate2}&pageid=${obj.data.pagesId}&companyid=${contextData.companyId}&packageid=5`, type: 'get', count: 0
        }
      )
      setPageObjDetails(obj.data);
    }
    setshowPopup(!showPopup);
  }

  const setDowloadDropdownMenu = () => {
    let ddList = ["Excel", "PDF"];
    return ddList.map(item => {
      return (
        <Dropdown.Item className='dropdown-item-download' onClick={() => downloadGrid(item)}>
          {item}
        </Dropdown.Item>
      );
    })
  }

  const downloadGrid = (item) => {
    if (item === "Excel") {
      downloadExcel()
    }
    else {
      downloadPDF();
    }
  }

  const downloadExcel = async () => {
    setBlurLoader(true)
    const selectedComapnyData = contextData.companies.find(dta => dta.companyId === contextData.companyId);
    let paramsList = {
      type: 3,
      page: pageObjDetails.filterybykey,
      pageid: pageObjDetails.pagesId,
      profileid: contextData.selectedProfile["WebsiteProfiles.ProfileId"],
      profileName: contextData.selectedProfile["WebsiteProfiles.DisplayName"],
      packageid: 5,
      path: pageObjDetails.pagesPagePath,
      startdate: moment(contextData.startDate).format("YYYY-MM-DD"),
      enddate: moment(contextData.endDate).format("YYYY-MM-DD"),
      companyName: selectedComapnyData.companyName,
      webSiteName: contextData.selectedWebsite["WebsiteList.WebsiteName"],
      pageTitle: pageObjDetails.filterybykey,
      pagesectionid: selectedCategoryId.sectionId,
      pageSectionName: selectedCategoryId.title,
      culture: localStorage.getItem('i18nextLng')

    }

    request(baseURL + `/VisitorAnalytics/ExportVisitorsByPageExcelFileWithEPPlus?encodeStr=${convertBase64(JSON.stringify(paramsList))}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
      })
      .then((res) => {
        if (res) {
          let fetchDataModified = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res}`;
          let a = document.createElement("a");
          a.href = fetchDataModified;
          a.download = "Insights_PageVisited.xlsx";
          a.click();
        }
        setBlurLoader(false);
      })
      .catch((err) => {
        setBlurLoader(false);
        console.error('Error generating Excel:', err);
      })
  };

  const downloadPDF = async () => {
    setBlurLoader(true);
    let url = apiUrlPopup.url;
    let fileName = "Insights_Visitors.pdf";
    let filters = (searchData !== "" && searchData !== null) ? "companyName|contains|" + searchData : "";
    let obj = {
      Title: pageObjDetails.filterybykey,
      SubTitle: t("header.PagesExitPagesDetailsPopVisitorsHelpText"),
      PageName: "Visitors",
      Filters: filters,
      Url: url,
      Token: decryptData(localStorage.getItem("token"), contextData.cryptoKey),
      FileName: fileName,
      Email: decryptData(localStorage.getItem("email"), contextData.cryptoKey),
      Name: decryptData(localStorage.getItem("firstName"), contextData.cryptoKey),
      Count: 0,
      CountTitle: "",
      FilterContent: "",
      ClientName: "",
      TableHeaders: []
    }

    await axios.post(`${process.env.REACT_APP_API_KEY}/CommonV2/GeneratePdf`, obj,
      {
        responseType: 'arraybuffer',
        headers: {
          'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
        }
      })
      .then((res) => {
        const contentType = res.headers['content-type'];
        if (res.status === 200) {
          if (contentType?.includes('application/pdf')) {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          }
          else {
            setAlertVisible(true);
            setTimeout(() => {
              setAlertVisible(false);
            }, 3000);
          }
          setBlurLoader(false);
        }
        else {
          setBlurLoader(false);
          console.error('Error generating PDF.');
        }
      })
      .catch((err) => {
        setBlurLoader(false);
        console.error('Error generating PDF:', err);
      })
  };

  const exitPagesItem = (item) => (
    <div
      key={item.id}
      data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}
    >
      <DashboardItem
        key={item.id}
        itemId={item.id}
        title={item.name}
        hideTitle={true}
      >
        <ChartRendererTable
          SearchText={SearchText}
          apiUrl={apiUrl}
          setheaderFilterData={setheaderFilterData}
          totalRecords={numberOfRecords}
          setNumberOfRecords={setNumberOfRecords}
          setVisitorId={setVisitorId}
          setSearchText={setSearchText}
          setIsRefresh={setIsRefresh}
          cardProperty={item.layout}
          legends={item.legends}
          items={items1}
          setItems={setItems1}
          showPagination={item.pagination}
          summaryTooltip={t("header.PagesExitPagesCountHelpTooltipText")}
          summaryBesideText={t("header.exitPageTotalExitPagesNotice")}
          openPopupEvent={onModalClose}
          setGetTotalResults={setGetTotalResults}
        />
      </DashboardItem>
    </div>
  );

  return (
    <>

      <Row>
        <Col md={12}>
          <ModalComponent
            title={pageObjDetails.filterybykey}
            showCloseButton={true}
            open={showPopup}
            subtitle={pageObjDetails.pagesPagePath}
            handleClose={onModalClose}
          >
            <Row className="mb-2">
              <Col md={12}>
                <Row className="position-relative hide-mobile">
                  <Col md={12}>
                    {(
                      <>
                        <Row className="mb-4">
                          <Col md={12} className="text-end">
                            <div className="icon-nav-list svg-icon-nav-list float-end mb-2">
                              <span className={pageObjDetails.pagesPageViews > 0 ? "icon-nav-box ms-2 hide-mobile" : "icon-nav-box hide-mobile disabled"}>
                                <Dropdown as={ButtonGroup}>
                                  <Dropdown.Toggle split className="card-dropdown-btn p-0 border-0 pdf-download-btn">
                                    <svg className="icon-nav">
                                      <title>{t("header.Icon_Tooltip_Download")}</title>
                                      <use href={iconSprite + `#Download`} />
                                    </svg>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {setDowloadDropdownMenu()}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} className="text-end">
                            <div className="search-box-container modal-search-box">
                              <Search
                                setsearchData={setsearchData}
                                searchData={searchData}
                                searchWaterMark={t("header.visitorAllWatermark")}
                                isAutoComplete={true}
                                setAutoFillForCompany={setAutoFillForCompanyPopup}
                                showAutoFillForCompany={showAutoFillForCompanyPopup}
                                data={headerFilterDataPopup}
                                type={"client"}
                              ></Search>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="chart-data-renderer">
                    {<div className={`preloader loader-center-align  ${blurLoader ? "" : "show"}`}>
                      <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                    </div>}
                    {(alertVisible || blurLoader) && (<div className="fade modal-backdrop show"></div>)}
                    <Alert
                      className="toast-custom"
                      variant="success"
                      show={alertVisible}
                    >
                      <div className="d-flex justify-content-between">
                        <div>
                          <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                          {t("header.PdfDeliveredToMailMessage")}
                        </div>
                      </div>
                    </Alert>
                    <Tab.Container
                      defaultActiveKey="visitors"
                    >
                      <Nav fill variant="pills" className="tab subtab">
                        <Nav.Item>
                          <Nav.Link eventKey="visitors" className="mb-sm-3 mb-md-0" >
                            {t("header.exitPageTabVisitors")}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="visitors" className="exit-page-content">

                          {pageObjDetails.pagesPageViews > 0 ? <>
                            <Row className="mt-4 mb-2 mt-6-mobile">
                              <Col md={12}>
                                <div className="alert alert-gray-800 mb-0" role="alert">
                                  <p className="mb-0 text-body fw-bold"><i className="fa fa-info-circle lg"></i> {t("header.PagesExitPagesDetailsPopVisitorsHelpText")}</p>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col md={12}>
                                <ChartRendererTable
                                  SearchText={searchData}
                                  apiUrl={apiUrlPopup}
                                  showCount={false}
                                  setheaderFilterData={setheaderFilterDataPopup}
                                  showNumberOfRows={10}
                                  showPagination={true}
                                />
                              </Col>
                            </Row>
                          </> : <div className="text-center no-data">{t("header.NoDataFound")}</div>}
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalComponent>
        </Col>
      </Row>
      {blurLoader && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}
      {exitPages.map(deserializeItem).map(exitPagesItem)}
    </>
  );
};

export default withRouter(ExitPages);