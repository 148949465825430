import React, { useState } from 'react';
import AppContext from '../components/AppContext';
import moment from "moment-timezone";

const AppState = ({ children }) => {
    const [setting1value, setSetting1value] = useState('Visitor Insights');

    const [setting2value, setSetting2value] = useState(false);
    // const [appContextState, setAppContextState] = useState({
    //     menuvalue: [],
    //     companiesvalue: [],
    //     userId: 0,
    //     companyId: 0,
    //     websiteId: 0,
    //     intelConfigId: 0,
    //     languagePreference: "",
    //     userName: "",
    //     firstName: "",
    //     lastName: "",
    //     userToken: "",
    //     userEmail: ""
    // });


    const [menuvalue, setmenuvalue] = useState([]);
    const [companiesvalue, setCompaniesvalue] = useState([]);
    const [userId, setUserId] = useState(0);
    const [companyId, setCompanyId] = useState(0);
    const [websiteId, setWebsiteId] = useState(0);
    const [sectionId, setSectionId] = useState(0);
    const [intelConfigId, setIntelConfigId] = useState(0);
    const [languagePreference, setLanguagePreference] = useState("");
    const [userName, setUserName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userToken, setUserToken] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [hasEvents, setHasEvents] = useState("");
    const [webcastType, setWebcastType] = useState("");
    const [webcastClientId, setWebcastClientId] = useState("");
    const [presentationId, setPresentationId] = useState("");
    const [presentation, setPresentation] = useState("");
    const [eventId, setEventId] = useState("");
    const [startDate, setStartDateValue] = useState(`${moment().add(-30, 'days')}`);
    const [endDate, setEndDateValue] = useState(`${moment(new Date())}`);
    const [selectedProfile, setSelectedProfileValue] = useState({});
    const [selectedWebsite, setSelectedWebsiteValue] = useState({});
    const [profileData, setProfileDataValue] = useState([]);
    const [token, setToken] = useState("");
    const [selectedDatePicker, setSelectedDatePicker] = useState();
    const [categoryId, setVisitorCategory] = useState(0);
    const [selectedTab, setSelectedTab] = useState("");
    const [alertCategoryId, setAlertCategory] = useState(0);
    const [cryptoKey, setCryptoKey] = useState(null);

    const setMenuData = (menuDta) => {
        setmenuvalue(menuDta);
    };

    const setStartDate = (sDate) => {
        setStartDateValue(sDate);
    };

    const setEndDate = (sDate) => {
        setEndDateValue(sDate);
    };

    const setSelectedProfile = (sProfile) => {
        setSelectedProfileValue(sProfile);
    };
    const setSelectedDatePickr = (sDatePicker) => {
        setSelectedDatePicker(sDatePicker);
    };
    const setVisitorCategories = (data) => {
        setVisitorCategory(data);
    };
    const setAlertCategories = (data) => {
        setAlertCategory(data);
    };
    const setSelectedWebsite = (sWebsite) => {
        setSelectedWebsiteValue(sWebsite);
    };
    
    const setProfileData = (sProfiledata)=>{
        setProfileDataValue(sProfiledata)
    };
    const setCompaniesData = (companyDta) => {
        setCompaniesvalue(companyDta);
    };

    const updateCompanyId = (companyId) => {
        setCompanyId(companyId);
    };

    const updateWebsiteId = (websiteId) => {
        setWebsiteId(websiteId);
    };

    const updateSectionId = (sectionId) => {
        setSectionId(sectionId);
    };

    const updateIntelConfigId = (intelConfigId) => {
        setIntelConfigId(intelConfigId);
    };

    const updateUserId = (usrId) => {
        setUserId(usrId);
    };

    const updateLanguagePref = (languagePref) => {
        setLanguagePreference(languagePref);
    };

    const updateUserName = (userNm) => {
        setUserName(userNm);
    };

    const updateFirstName = (firstNm) => {
        setFirstName(firstNm);
    };

    const updateLastName = (LastNm) => {
        setLastName(LastNm);
    };

    const updateEmail = (email) => {
        setUserEmail(email);
    };


    const updateUserToken = (token) => {
        setUserToken(token);
    };
    const updateToken = (token) => {
        setToken(token);
    };
    const updateHasEvents = (hasEvent) => {
        setHasEvents(hasEvent);
    };
    const updateWebcastType = (WebcastType) => {
        setWebcastType(WebcastType);
    };
    const updateWebcastClientId = (WebcastClientId) => {
        setWebcastClientId(WebcastClientId);
    };
    const updatePresentationId = (presentationId) => {
        setPresentationId(presentationId);
    };
    const updatePresentation = (presentation) => {
        setPresentation(presentation);
    };
    const updateEventId = (eventId) => {
        setEventId(eventId);
    };
    const toggleSetting2 = (setting3) => {
        setting3 ? setSetting1value(true) : setSetting2value(false);
    };
    const updateSelectedTab = (value) => {
        setSelectedTab(value);
    };

    const updateCryptoKey = (value) => {
        setCryptoKey(value);
    };
    // const setContextDataValue = (name, value) => {
    //     const forms = { ...appContextState };
    //     // const forms = appContextState;
    //     forms[name] = value;
    //     setAppContextState(forms);
    // };


    const userSettings = {
        menu: menuvalue,
        setMenu: setMenuData,
        companies: companiesvalue,
        setCompanies: setCompaniesData,
        setting1name: setting1value,
        setting2name: setting2value,
        userId,
        changeUerId: updateUserId,
        startDate,
        changeStartDate : setStartDate,
        endDate,
        changeEndDate : setEndDate,
        selectedProfile,
        changeSelectedProfile : setSelectedProfile,
        selectedDatePicker,
        changeSelectedDatePickr : setSelectedDatePickr,
        categoryId,
        changeVisitorCategory:setVisitorCategories,
        selectedWebsite,
        changeSelectedWebsite : setSelectedWebsite,
        profileData,
        changeProfileData:setProfileData,
        companyId,
        changeCompanyId: updateCompanyId,
        websiteId,
        changeWebsiteId: updateWebsiteId,
        sectionId,
        changeSectionId: updateSectionId,
        intelConfigId,
        changeIntelConfigId: updateIntelConfigId,
        languagePreference,
        changeLanguagePref: updateLanguagePref,
        userName,
        changeUserName: updateUserName,
        userToken,
        changeUserToken: updateUserToken,
        token,
        changeToken: updateToken,
        firstName,
        changeFirstName: updateFirstName,
        lastName,
        changeLastName: updateLastName,
        userEmail,
        changeEmail: updateEmail,
        hasEvents,
        changeHasEvents: updateHasEvents,
        webcastType,
        changeWebcastType: updateWebcastType,
        webcastClientId,
        changeWebcastClientId: updateWebcastClientId,
        presentationId,
        changePresentationId: updatePresentationId,
        presentation,
        changePresentation: updatePresentation,
        eventId,
        changeEventId:updateEventId,    
        setSetting1value,
        setSetting2value,
        toggleSetting2,
        changeSelectedTab:updateSelectedTab,
        selectedTab,
        alertCategoryId,
        changeAlertCategory:setAlertCategories,
        changeCryptoKey:updateCryptoKey,
        cryptoKey,
    };

    return (
        <AppContext.Provider value={userSettings}>
            {children}
        </AppContext.Provider>
    );

};

export default AppState;

