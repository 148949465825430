import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from "react-router";
import RGL, { WidthProvider } from 'react-grid-layout';
import { Col, Row } from '@themesberg/react-bootstrap';
import { useTranslation } from 'react-i18next';
const ReactGridLayout = WidthProvider(RGL);
export const NotificationNew = ({ children }) => {
  const [t, i18n] = useTranslation();
  return (<>
    <Row>
      <Col>
        <Col xs={11} sm={6} xl={8}>
          <h4 className="h2 mb-3 ps-3">{t("header.WONotificationsTitle")}</h4>
        </Col>
      </Col>
    </Row>
    <Row>
      <Col>

        {/* <ReactGridLayout cols={12} rowHeight={60}
          isDraggable={false} isResizable={false} 
        > */}
        <div className="d-inline-block p-3 pe-0 w-100">
          <div className="shadow-soft border rounded border-light w-100 nested-card-container">
            {children}
          </div>
        </div>
        {/* </ReactGridLayout> */}
      </Col>
    </Row>
  </>)
}
export default withRouter(NotificationNew);