import { Col, Image, Nav, Row, Tab, Dropdown, ButtonGroup, Alert } from "@themesberg/react-bootstrap";
import axios from "axios";
import html2pdf from "html2pdf.js";
import moment from "moment-timezone";
import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import request from "../apis/request";
import { METHODS, baseURL } from "../apis/utilities/constant";
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import ApiChartRenderer from '../components/ApiChartRenderer';
import AppContext from "../components/AppContext";
import ChartRendererTable from "../components/ChartRendererTable";
import DashboardItem from "../components/DashboardItem";
import Search from "../components/Search";
import ModalComponent from "../components/VoltModal";
import {
    apiChartsVisitorInsightsService,
    getPageTrafficSourceBarChart,
    getPageTrafficSourceLineChart
} from "../stubs/ComponentJson/Visitoranalytics";
import { convertBase64 } from "../utils/common";
import { defaultLayout, defaultLayoutMobile } from "../utils/layoutConfig";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { decryptData } from "../utils/crypto";

const deserializeItem = (i) => ({
    ...i,
    layout: i.layout ? JSON.parse(i.layout) : {},
    summary: i.summary ? JSON.parse(i.summary) : {},
    vizState: i.vizState ? JSON.parse(i.vizState) : {},
});
const AllPages = ({
    selectedCategoryId,
    SearchText,
    setSearchText,
    setVisitorId,
    changeWatchlist,
    setIsRefresh,
    allPages,
    offsetWatchlist,
    setOffsetWatchlist,
    pageNoWatchlist,
    setPageNoWatchlist,
    setheaderFilterData,
    setTotalRecords
}) => {
    const [t, i18n] = useTranslation();
    const contextData = useContext(AppContext);
    const [apiUrl, setApiUrl] = useState({});
    const [items1, setItems1] = useState([]);
    const [numberOfRecords, setNumberOfRecords] = useState(0);
    const [templateType, setTemplateType] = useState("visitors")
    const [showPopup, setshowPopup] = useState(false)
    const [apiUrlPopup, setapiUrlPopup] = useState({});
    const [apiUrlPopup2, setapiUrlPopup2] = useState({});
    const [searchData, setsearchData] = useState("");
    const [headerFilterDataPopup, setheaderFilterDataPopup] = useState(null);
    const [showAutoFillForCompanyPopup, setAutoFillForCompanyPopup] = useState(false);
    const [visitorTabKey, setVistorTabKey] = useState("");
    const [pageObjDetails, setPageObjDetails] = useState({});
    const [pagePopupBarConfig, setpagePopupBarConfig] = useState({});
    const [pagePopupLineConfig, setpagePopupLineConfig] = useState({});
    const [trafficSource, setTrafficSource] = useState("Organic");
    const [trafficSourceTotal, setTrafficSourceTotal] = useState(0);
    const [trafficSourceText, setTrafficSourceText] = useState(t("header.VisitorsActivityPopTrafficChartOrganicHelpText"));
    const [blurLoader, setBlurLoader] = useState(false);
    const [getTotalResults, setGetTotalResults] = useState(0);
    const [alertVisible, setAlertVisible] = useState(false);

    useEffect(() => {
        setTotalRecords(0)
        setTotalRecords(getTotalResults)
    }, [getTotalResults])

    useEffect(() => {
        setTrafficSourceTotal("");
        setTrafficSourceText("");
        let ms = new Date(Date.parse(contextData.startDate));
        let sdate = ms.getFullYear() + "-" + parseInt(ms.getMonth() + 1) + "-" + ms.getDate()
        let ms2 = new Date(Date.parse(contextData.endDate));
        let sdate2 = ms2.getFullYear() + "-" + parseInt(ms2.getMonth() + 1) + "-" + ms2.getDate()

        if (pageObjDetails.pagesId !== undefined) {
            if (trafficSource === "Organic" || trafficSource === "Referral" || trafficSource === "Social") {
                setapiUrlPopup2(
                    {
                        url: `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetTopDomainByTrafficSourcePage?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
            &startDate=${sdate}&endDate=${sdate2}&companyid=${contextData.companyId}&pageid=${pageObjDetails.pagesId}&traffic=${trafficSource}&packageid=5&sectionid=${selectedCategoryId.sectionId}`, type: 'get', count: 0
                    }
                )
            }
            else {
                setBlurLoader(true)
            }
            request(
                baseURL + `/VisitorAnalytics/GetTrafficSourceByPage?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
        &startdate=${sdate}&enddate=${sdate2}&pageid=${pageObjDetails.pagesId}&companyid=${contextData.companyId}&packageid=5&getvalues=1`,
                METHODS.GET,
                {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                }
            )
                .then((response) => {
                    setBlurLoader(false);
                    if (response != null) {
                        if (!response.organic) {
                            setTrafficSource("Direct")
                        }
                        if (trafficSource === "Organic") {
                            setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartOrganicHelpText"));
                            setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + trafficSource) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.organic);
                        }
                        else if (trafficSource === "Direct") {
                            setTrafficSourceText(t("header.PagesDetailsPopTrafficChartDirectHelpText"));
                            setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + trafficSource) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.direct);
                        }
                        else if (trafficSource === "Referral") {
                            setTrafficSourceText(t("header.PagesDetailsPopTrafficChartReferralHelpText"));
                            setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + trafficSource) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.referral);
                        }
                        else if (trafficSource === "Email") {
                            setTrafficSourceText(t("header.PagesDetailsPopTrafficChartEmailHelpText"));
                            setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + trafficSource) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.email);
                        }
                        else if (trafficSource === "Social") {
                            setTrafficSourceText(t("header.PagesDetailsPopTrafficChartSocialHelpText"));
                            setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + trafficSource) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.social);
                        }
                        else if (trafficSource === "PaidSearch") {
                            setTrafficSourceText(t("header.PagesDetailsPopTrafficChartPaidSearchHelpText"));
                            setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + trafficSource) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.paidSearch);
                        }
                    }
                })
                .catch((error) => {
                    setBlurLoader(false);
                })
        }
    }, [trafficSource, pageObjDetails])

    useEffect(() => {
        let ms = new Date(Date.parse(contextData.startDate));
        let sdate = ms.getFullYear() + "-" + parseInt(ms.getMonth() + 1) + "-" + ms.getDate()
        let ms2 = new Date(Date.parse(contextData.endDate));
        let sdate2 = ms2.getFullYear() + "-" + parseInt(ms2.getMonth() + 1) + "-" + ms2.getDate()
        if (selectedCategoryId.sectionId !== 0) {
            setApiUrl(
                {
                    url: `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetPageBySection?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]} 
          &startDate=${sdate}&endDate=${sdate2}&companyid=${contextData.companyId}&pagesectionid=${selectedCategoryId.sectionId}&packageid=5`, type: 'get', count: 0
                }
            )
        }
        else {
            setApiUrl(
                {
                    url: `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetPagesDataByProfileId?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
        &startDate=${sdate}&endDate=${sdate2}&sortexpression=PageViews&sortdirection=true&companyid=${contextData.companyId}&packageid=5`, type: 'get', count: 0
                }
            )
        }
    }, [contextData.startDate, contextData.endDate, selectedCategoryId.sectionId])

    const setDowloadDropdownMenu = () => {
        let ddList = ["Excel", "PDF"];
        return ddList.map(item => {
            return (
                <Dropdown.Item className='dropdown-item-download' onClick={() => downloadGrid(item)}>
                    {item}
                </Dropdown.Item>
            );
        })
    }

    const downloadGrid = (item) => {
        if (item === "Excel") {
            downloadExcel()
        }
        else {
            downloadPDF();
        }
    }

    const downloadExcel = async () => {
        setBlurLoader(true)
        const selectedComapnyData = contextData.companies.find(dta => dta.companyId === contextData.companyId);
        let paramsList = {
            type: 1,
            page: pageObjDetails.filterybykey,
            pageid: pageObjDetails.pagesId,
            profileid: contextData.selectedProfile["WebsiteProfiles.ProfileId"],
            profileName: contextData.selectedProfile["WebsiteProfiles.DisplayName"],
            packageid: 5,
            path: pageObjDetails.pagesPagePath,
            startdate: moment(contextData.startDate).format("YYYY-MM-DD"),
            enddate: moment(contextData.endDate).format("YYYY-MM-DD"),
            companyName: selectedComapnyData.companyName,
            webSiteName: contextData.selectedWebsite["WebsiteList.WebsiteName"],
            pagesectionid: selectedCategoryId.sectionId,
            pageSectionName: selectedCategoryId.title,
            culture: localStorage.getItem('i18nextLng')

        }

        request(baseURL + `/VisitorAnalytics/ExportVisitorsByPageExcelFileWithEPPlus?encodeStr=${convertBase64(JSON.stringify(paramsList))}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
            })
            .then((res) => {
                if (res) {
                    let fetchDataModified = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res}`;
                    let a = document.createElement("a");
                    a.href = fetchDataModified;
                    a.download = "Insights_PageVisited.xlsx";
                    a.click();
                }
                setBlurLoader(false);
            })
            .catch((err) => {
                setBlurLoader(false);
                console.error('Error generating Excel:', err);
            })
    };

    const downloadPDF = async () => {
        setBlurLoader(true);
        let url = apiUrlPopup.url;
        let fileName = "Insights_Visitors.pdf";
        let filters = (searchData !== "" && searchData !== null) ? "companyName|contains|" + searchData : "";
        let obj = {
            Title: pageObjDetails.filterybykey,
            SubTitle: t("header.PagesDetailsPopVisitorsChartTextPageViewsOf") + pageObjDetails.filterybykey + " " + t("header.PagesDetailsPopVisitorsChartTextBetween") + " " + moment(contextData.startDate).format("DD MMM YYYY") + t("header.PagesDetailsPopVisitorsChartTextTo") + moment(contextData.endDate).format("DD MMM YYYY"),
            PageName: "Visitors",
            Filters: filters,
            Url: url,
            Token: decryptData(localStorage.getItem("token"), contextData.cryptoKey),
            FileName: fileName,
            Email: decryptData(localStorage.getItem("email"), contextData.cryptoKey),
            Name: decryptData(localStorage.getItem("firstName"), contextData.cryptoKey),
            Count: 0,
            CountTitle: "",
            FilterContent: "",
            ClientName: "",
            TableHeaders: []
        }

        await axios.post(`${process.env.REACT_APP_API_KEY}/CommonV2/GeneratePdf`, obj,
            {
                responseType: 'arraybuffer',
                headers: {
                    'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
                }
            })
            .then((res) => {
                const contentType = res.headers['content-type'];
                if (res.status === 200) {
                    if (contentType?.includes('application/pdf')) {
                        const blob = new Blob([res.data], { type: 'application/pdf' });
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                    else {
                        setAlertVisible(true);
                        setTimeout(() => {
                            setAlertVisible(false);
                        }, 3000);
                    }
                    setBlurLoader(false);
                }
                else {
                    setBlurLoader(false);
                    console.error('Error generating PDF.');
                }
            })
            .catch((err) => {
                setBlurLoader(false);
                console.error('Error generating PDF:', err);
            })
    };

    const downloadPageLevelPDF = async (pageName, fileName) => {
        setBlurLoader(true);
        const content = document.createElement("div");
        content.innerHTML = document.querySelector('.tab-content .fade.tab-pane.active.show.' + pageName)?.innerHTML;

        const options = {
            filename: fileName,
            margin: [5, 2, 2, 2],
            image: { type: "jpeg", quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "mm", format: "a4", orientation: "landscape" }
        };
        const combinedNode = document.createElement("div");
        combinedNode.innerHTML = `<div class="row mt-3">
            <div class="col-md-12">
                <div class="ms-2 mb-0 pdf-download-title">${pageObjDetails.filterybykey}</div>
                <div class="ms-2 mb-0 pdf-download-subtitle">${pageObjDetails.pagesPagePath}</div>
            </div>
        </div>`;
        combinedNode.appendChild(content.cloneNode(true));
        combinedNode.querySelectorAll('.pagination-content')?.forEach(btn => {
            btn.style.display = "none";
        });
        if (combinedNode.querySelector(".table-responsive-lg")) {
            combinedNode.querySelector(".table-responsive-lg")?.querySelectorAll("tr").forEach(tr => {
                tr.style.pageBreakInside = "avoid";
            });
        }
        combinedNode.querySelectorAll(".me-1")?.forEach(item => {
            item.style.display = "none";
        });
        combinedNode.querySelectorAll("a").forEach(link => {
            link.removeAttribute("href");
            link.style.pointerEvents = "none";
        });
        combinedNode.innerHTML = await covertSVGToIMG(combinedNode);
        await html2pdf().set(options).from(combinedNode).save().then(() => {
            setBlurLoader(false);
        });
    }

    const loadImageAsync = (img) => {
        return new Promise((resolve, reject) => {
            img.onload = () => resolve(img);
            img.onerror = reject;
        });
    };

    const covertSVGToIMG = async (content) => {
        var dynamicSvgs = content.querySelectorAll('svg:not(.svg-inline--fa):not(.icon-nav)');

        for (let i = 0; i < dynamicSvgs.length; i++) {
            dynamicSvgs[i].querySelectorAll("line").forEach(x => {
                x.setAttribute("stroke", "#dddee2");
            });
            dynamicSvgs[i].querySelectorAll("path.domain[stroke='currentColor']").forEach(x => {
                x.style.display = "none";
            });

            var canvas = document.createElement('canvas');
            const scaleFactor = 2;
            canvas.width = dynamicSvgs[i].width.baseVal.value * scaleFactor;
            canvas.height = dynamicSvgs[i].height.baseVal.value * scaleFactor;

            var context = canvas.getContext('2d');
            context.scale(scaleFactor, scaleFactor);

            context.fillStyle = '#ffffff';
            context.fillRect(0, 0, canvas.width, canvas.height);

            var svgData = new XMLSerializer().serializeToString(dynamicSvgs[i]);
            var img = new window.Image();
            img.src = 'data:image/svg+xml,' + encodeURIComponent(svgData);

            await loadImageAsync(img);

            const scaleWidthFactor = 1;

            context.drawImage(img, 0, 0, img.width * scaleWidthFactor, img.height * scaleWidthFactor);

            var imgSrc = canvas.toDataURL('image/jpeg');
            var dynamicImg = document.createElement('img');
            dynamicImg.src = imgSrc;

            let parent = dynamicSvgs[i].parentNode;
            while (parent && parent !== content) {
                parent = parent.parentNode;
            }

            if (parent === content) {
                dynamicSvgs[i].parentNode.replaceChild(dynamicImg, dynamicSvgs[i]);
            }
        }
        return content.innerHTML;
    };

    const onModalClose = (obj) => {
        let barconfig = getPageTrafficSourceBarChart();
        setpagePopupBarConfig(barconfig);

        let lineconfig = getPageTrafficSourceLineChart();
        setpagePopupLineConfig(lineconfig);

        let ms = new Date(Date.parse(contextData.startDate));
        let sdate = ms.getFullYear() + "-" + parseInt(ms.getMonth() + 1) + "-" + ms.getDate()
        let ms2 = new Date(Date.parse(contextData.endDate));
        let sdate2 = ms2.getFullYear() + "-" + parseInt(ms2.getMonth() + 1) + "-" + ms2.getDate()

        if (obj.data !== undefined) {
            setsearchData("");
            setapiUrlPopup(
                {
                    url: `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetVisitorByPage?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
          &startDate=${sdate}&endDate=${sdate2}&companyid=${contextData.companyId}&pageid=${obj.data.pagesId}&packageid=5`, type: 'get', count: 0
                }
            )

            setapiUrlPopup2(
                {
                    url: `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetTopDomainByTrafficSourcePage?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
            &startDate=${sdate}&endDate=${sdate2}&companyid=${contextData.companyId}&pageid=${obj.data.pagesId}&traffic=${trafficSource}&packageid=5&sectionid=${selectedCategoryId.sectionId}`, type: 'get', count: 0
                }
            )
            setPageObjDetails(obj.data);
        }
        setshowPopup(!showPopup);
        setVistorTabKey("visitors")
    };

    const allPagesItem = (item) => (
        <div
            key={item.id}
            data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}
        >
            <DashboardItem
                key={item.id}
                itemId={item.id}
                title={item.name}
                hideTitle={true}
            >
                <ChartRendererTable
                    SearchText={SearchText}
                    apiUrl={apiUrl}
                    setheaderFilterData={setheaderFilterData}
                    totalRecords={numberOfRecords}
                    setNumberOfRecords={setNumberOfRecords}
                    setVisitorId={setVisitorId}
                    setSearchText={setSearchText}
                    setIsRefresh={setIsRefresh}
                    cardProperty={item.layout}
                    legends={item.legends}
                    items={items1}
                    setItems={setItems1}
                    showPagination={item.pagination}
                    summaryTooltip={t("header.PagesAllPagesCountHelpTooltipText")}
                    summaryBesideText={t("header.allPagesTotalPages")}
                    openPopupEvent={onModalClose}
                    setGetTotalResults={setGetTotalResults}
                />
            </DashboardItem>
        </div>
    );

    const onchangeVistiorTab = (event, d) => {
        if (searchData != "") {
            setsearchData("");
        }
        else if (showAutoFillForCompanyPopup && searchData == "") {
            setsearchData("emptytextbox");
        }
        setAutoFillForCompanyPopup(false);
        setVistorTabKey(event);
    };

    return (
        <>
            <Row>
                <Col md={12}>
                    {<ModalComponent
                        title={pageObjDetails.filterybykey}
                        showCloseButton={true}
                        open={showPopup}
                        subtitle={pageObjDetails.pagesPagePath}
                        handleClose={onModalClose}
                    >
                        <Row className="mb-2">
                            <Col md={12}>
                                <Row className="position-relative hide-mobile" hidden={visitorTabKey == "traffic" ? true : false}>
                                    <Col md={12}>
                                        {(
                                            <Row className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
                                                <Row className="mb-4">
                                                    <Col md={12} className="text-end">
                                                        <div className="icon-nav-list svg-icon-nav-list float-end mb-2">
                                                            <span className={pageObjDetails.pagesPageViews > 0 ? "icon-nav-box ms-2 hide-mobile" : "icon-nav-box hide-mobile disabled"} onClick={() => downloadPageLevelPDF('visitorsContent', 'Insights_Visitors')}>
                                                                <svg className="icon-nav">
                                                                    <title>{t("header.Icon_Tooltip_DownloadPDF")}</title>
                                                                    <use href={iconSprite + `#Download`} />
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </Col>
                                                    <Row className="mb-4">
                                                    </Row>
                                                </Row>
                                                <Row>
                                                    <Col md={7} className="text-end">
                                                        <div className="search-box-container modal-search-box">
                                                            <Search
                                                                setsearchData={setsearchData}
                                                                searchData={searchData}
                                                                searchWaterMark={t("header.visitorAllWatermark")}
                                                                isAutoComplete={true}
                                                                setAutoFillForCompany={setAutoFillForCompanyPopup}
                                                                showAutoFillForCompany={showAutoFillForCompanyPopup}
                                                                data={headerFilterDataPopup}
                                                                type={"client"}
                                                            ></Search>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Row>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="position-relative hide-mobile" hidden={visitorTabKey === "visitors" ? true : false}>
                                    <Col md={12}>
                                        {(
                                            <>
                                                <Row className={localStorage.getItem("isDemoClient") ? "custom-disable mb-4" : "mb-4"}>
                                                    <Col md={12} className="text-end">
                                                        <div className="icon-nav-list svg-icon-nav-list float-end mb-2">
                                                            <span className="icon-nav-box hide-mobile" onClick={() => downloadPageLevelPDF('trafficContent', 'Insights_Traffic_Sources')}>
                                                                <svg className="icon-nav">
                                                                    <title>{t("header.Icon_Tooltip_DownloadPDF")}</title>
                                                                    <use href={iconSprite + `#Download`} />
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Tab.Container
                                            defaultActiveKey="visitors"
                                            onSelect={onchangeVistiorTab}
                                        >
                                            <Nav fill variant="pills" className="tab subtab">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="visitors" className="mb-sm-3 mb-md-0" >
                                                        {t("header.PagesDetailsPopVisitorsTabText")}
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="traffic" className="mb-sm-3 mb-md-0" >
                                                        {t("header.VisitorsActivityPopTrafficTabText")}
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="visitors" className="visitorsContent">
                                                    {visitorTabKey == "visitors" ? (
                                                        <Row>
                                                            <Col md={12} className="chart-data-renderer">
                                                                {<div className={`preloader loader-center-align  ${blurLoader ? "" : "show"}`}>
                                                                    <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                                                                </div>}
                                                                {(alertVisible || blurLoader) && (<div className="fade modal-backdrop show"></div>)}
                                                                <Alert
                                                                    className="toast-custom"
                                                                    variant="success"
                                                                    show={alertVisible}
                                                                >
                                                                    <div className="d-flex justify-content-between">
                                                                        <div>
                                                                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                                                                            {t("header.PdfDeliveredToMailMessage")}
                                                                        </div>
                                                                    </div>
                                                                </Alert>
                                                                <Row className="mt-4">
                                                                    <Col md={12}>
                                                                        <p>{t("header.PagesDetailsPopVisitorsChartTextPageViewsOf")}{pageObjDetails.filterybykey} {t("header.PagesDetailsPopVisitorsChartTextBetween")} {moment(contextData.startDate).format("DD MMM YYYY")} {t("header.PagesDetailsPopVisitorsChartTextTo")} {moment(contextData.endDate).format("DD MMM YYYY")}</p>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="api-chart">
                                                                    <Col md={12}>
                                                                        <ApiChartRenderer isLegendShown={false}
                                                                            cardName={pageObjDetails.name}
                                                                            services={apiChartsVisitorInsightsService} chartType={"line2"} itemId={18} pageId={pageObjDetails.pagesId}
                                                                            cardProperty={pagePopupBarConfig.layout} />
                                                                    </Col>
                                                                </Row>
                                                                {pageObjDetails.pagesPageViews > 0 ? <>
                                                                    <Row className="mb-2 mt-6-mobile">
                                                                        <Col md={12}>
                                                                            <div className="alert alert-gray-800 mb-0" role="alert">
                                                                                <p className="mb-0 text-body fw-bold"><i className="fa fa-info-circle lg"></i> {t("header.PagesAllPagesDetailsPopVisitorsHelpText")}</p>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
                                                                        <Col md={12} className="text-end">
                                                                            <div className="icon-nav-list svg-icon-nav-list float-end mb-2">
                                                                                <span className={pageObjDetails.pagesPageViews > 0 ? "icon-nav-box ms-2 hide-mobile" : "icon-nav-box hide-mobile disabled"}>
                                                                                    <Dropdown as={ButtonGroup}>
                                                                                        <Dropdown.Toggle split className="card-dropdown-btn p-0 border-0 pdf-download-btn">
                                                                                            <svg className="icon-nav">
                                                                                                <title>{t("header.Icon_Tooltip_Download")}</title>
                                                                                                <use href={iconSprite + `#Download`} />
                                                                                            </svg>
                                                                                        </Dropdown.Toggle>
                                                                                        <Dropdown.Menu>
                                                                                            {setDowloadDropdownMenu()}
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                </span>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="mb-2">
                                                                        <Col md={12}>
                                                                            <ChartRendererTable
                                                                                SearchText={searchData}
                                                                                apiUrl={apiUrlPopup}
                                                                                showCount={false}
                                                                                setheaderFilterData={setheaderFilterDataPopup}
                                                                                showNumberOfRows={10}
                                                                                showPagination={true}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </> : <div className="text-center no-data">{t("header.NoDataFound")}</div>}
                                                            </Col>
                                                        </Row>) : (<div></div>)}
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="traffic" className="trafficContent">
                                                    {visitorTabKey == "traffic" ? (
                                                        <Row>
                                                            <Col md={12} className="chart-data-renderer-allpages-popup-trafficsource">
                                                                {<div className={`preloader loader-center-align  ${blurLoader ? "" : "show"}`}>
                                                                    <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                                                                </div>}
                                                                {blurLoader && (<div className="fade modal-backdrop show"></div>)}
                                                                <Row className="mt-4">
                                                                    <Col md={12}>
                                                                        <p>  {t("header.allPagesTrafficSourceByVisitor")} {pageObjDetails.filterybykey} {t("header.PagesDetailsPopVisitorsChartTextBetween")} {moment(contextData.startDate).format("DD MMM YYYY")} {t("header.PagesDetailsPopTrafficChartTextTo")} {moment(contextData.endDate).format("DD MMM YYYY")} </p>
                                                                    </Col>
                                                                </Row>
                                                                {pageObjDetails.pagesPageViews > 0 ? <>
                                                                    <Row className="mb-0 api-chart">
                                                                        <Col md={12}  >
                                                                            <ApiChartRenderer isLegendShown={false} barChartClickEvent={function (d) { setTrafficSource(d.name); }}
                                                                                services={apiChartsVisitorInsightsService} chartType={"bar2"} itemId={17} pageId={pageObjDetails.pagesId}
                                                                                cardProperty={pagePopupBarConfig.layout} hideCardMetrics={true} cardName={"allPagesTabTrafficeSource"}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="mb-0">
                                                                        <Col md={12}>
                                                                            {trafficSourceText &&
                                                                                <div className="alert alert-gray-800" role="alert">
                                                                                    <p className="mb-0 text-body fw-bold"><i className="fa fa-info-circle lg"></i> {trafficSourceText}</p>
                                                                                </div>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="mb-0 mt-0">
                                                                        <Col md={12} >
                                                                            <p className="text-body fw-bold">{trafficSourceTotal} </p>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="mb-2">
                                                                        <Col md={12}>
                                                                            {(trafficSource === "Organic" || trafficSource === "Referral" || trafficSource === "Social") && <ChartRendererTable

                                                                                apiUrl={apiUrlPopup2}
                                                                                showCount={false}
                                                                                showNumberOfRows={10}
                                                                                showPagination={true}
                                                                            />}
                                                                        </Col>
                                                                    </Row>
                                                                </> : <div className="text-center no-data">{t("header.NoDataFound")}</div>}
                                                            </Col>
                                                        </Row>) : (<div></div>)}
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalComponent>
                    }
                </Col>
            </Row>
            {blurLoader && <div id='blurdiv' className="blurbackground">
                <div className={`preloader loader-center-align`}>
                    <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                </div>
            </div>}
            {allPages.map(deserializeItem).map(allPagesItem)}
        </>
    );
};

export default withRouter(AllPages);