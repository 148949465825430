import React, { useState, useEffect, useContext } from 'react';
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { useMutation } from '@apollo/react-hooks';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
// import { GET_DASHBOARD_ITEMS } from '../graphql/queries';
// import { UPDATE_DASHBOARD_ITEM } from '../graphql/mutations';
import { Row, Col, Button, Tooltip, OverlayTrigger, Form, InputGroup, FormCheck } from '@themesberg/react-bootstrap';
import Loader from '../components/VoltLoader';
import DropdownWithIconComponent from "../components/DropdownWithIcons";
import { isMobile } from "react-device-detect";
import { withRouter } from "react-router";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faChevronLeft, faDownload, faEdit, faFilter } from "@fortawesome/free-solid-svg-icons";
import { DEFAULT_WEBSITE_DASHBORD, DEFAULT_PROFILE_DASHBORD, object_equals, NULL_VALUE_MESSAGE_DROPDOWN } from "../AppConfig";
import { dashbordDateRangeList } from "../utils/datetime";
import ButtonComponent from "../components/Buttons";
import AppContext from '../components/AppContext';
import { useTranslation } from 'react-i18next';

import iconSprite from "../assets/Icon/svg-icn-sprite.svg";


const ReactGridLayout = WidthProvider(RGL);

// const CommonHeader (props) = >{
//   return 
// }


const Filters = (props, { children, dashboardItems, selectedProfileId, setSelectedProfileId, selectedWebsiteId, setSelectedWebsiteId, history, location, startDate, setStartDate, endDate, setEndDate }) => {
  const contextData = useContext(AppContext);


  // // const [websiteId, setWebsiteId] = useState(0);
  //const [selectedWebsiteId, setSelectedWebsiteId] = useState(0);
  // // const [profileId, setProfileId] = useState(0);
  //const [selectedProfileId, setSelectedProfileId] = useState(0);
  const [selectionsText, setSelectionsText] = useState([]);
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [showFiltersMenu, setShowFiltersMenu] = useState(false);
  const [datePickerValue, setDatePickerValue] = useState("");
  const [selectedDatePicker, setSelectedDatePicker] = useState({});
  const [datePickerCompareValue, setDatePickerCompareValue] = useState("");
  const [companyIdValue, setcompanyIdValue] = useState("");
  const [toggle, setToggle] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("userlangPref") !== undefined ? localStorage.getItem("userlangPref") : '');
  const [t, i18n] = useTranslation();

  //   const [updateDashboardItem] = useMutation(UPDATE_DASHBOARD_ITEM, {
  //     refetchQueries: [
  //       {
  //         query: GET_DASHBOARD_ITEMS,
  //       },
  //     ],
  //   });

  const showLeftIcon = isMobile ? true : false;

  const cubejsApi = cubejs(
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJteWFwcGlkIjozLCJpYXQiOjE2MTgyMjkzNDIsInVzZXJfaWQiOjM4MDgxLCJwbGF0Zm9ybV9pZCI6MSwic2VydmljZV9pZCI6OCwiY29tcGFueV9pZCI6OTM0NX0.lYSmPrF55TH3y8HQk96khmXn08XBt5nkUh7vzxfyak4',
    { apiUrl: `${process.env.REACT_APP_CUBESERVERURL}/cubejs-api/v1` }
  );

  useEffect(() => {
    const lastThirtyDays = moment(startDate).format("MMM DD,YYYY") + ` - ${moment(endDate).format("MMM DD,YYYY")}`;
    let diffDays = moment(endDate).diff(startDate, 'days');
    const getDropdownPickerDetails = dashbordDateRangeList.find(dta => dta.key === ((diffDays !== 30 && diffDays !== 90 && diffDays !== 180) ? "" : lastThirtyDays));
    setSelectedDatePicker(getDropdownPickerDetails);
    setDatePickerValue(lastThirtyDays);
    setDtPickerCompareValueOnDatePickerChange(startDate, endDate);
  }, []);

  useEffect(() => {
    if (startDate) {
      setDefaultPickerValueOnDateChange("startDate");
    }

  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      setDefaultPickerValueOnDateChange("endDate");
    }
  }, [endDate]);

  useEffect(() => {
    if (contextData.userId > 0 && contextData.companyId > 0) {

      setcompanyIdValue(contextData.companyId);
      // Fetching values from Local storage in case of Page Refresh
      if (localStorage.getItem("IsPageRefresh") == "1") {
        if (localStorage.getItem("WebsiteListWebsiteId")) {
          if (selectedWebsiteId) {
            setSelectedWebsiteId(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")));
          }


        }
        if (localStorage.getItem("WebsiteProfilesIntelConfigId") && localStorage.getItem("WebsiteProfilesIntelConfigId") != "")
          // setSelectedProfileId(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")));
          if (localStorage.getItem("SelectedDatePicker") && localStorage.getItem("SelectedDatePicker") != "")
            setSelectedDatePicker(JSON.parse(localStorage.getItem("SelectedDatePicker")));
      }
    }
  }, [contextData.userId, contextData.companyId]);


  //#region object

  //#endregion



  useEffect(() => {
    //renderWebsiteProfiles(selectedWebsiteId);
  }, [selectedWebsiteId]);

  // const renderWebsiteDropdown = () => {
  //   setShowWebsiteeDropdown(true);

  //   setTimeout(() => {
  //     setShowWebsiteeDropdown(false);
  //   }, [1000]);

  // }

  useEffect(() => {

  }, []);
  const onWebsiteDropdownChange = (data) => {
    if (data !== undefined) {
      if (!object_equals(selectedWebsiteId, data)) {
        //setSelectedProfileId({});
        localStorage.setItem("WebsiteProfilesIntelConfigId", "");// Adding WebsiteProfiles.IntelConfigId to local stoarge in case of Page Refresh
      }
      const websiteId = data["WebsiteList.WebsiteId"];
      contextData.changeWebsiteId(websiteId);
      contextData.changeSelectedWebsite(data);
      //setSelectedWebsiteId(data);
      localStorage.setItem("WebsiteListWebsiteId", JSON.stringify(data));// Adding WebsiteList.WebsiteId to local stoarge in case of Page Refresh
    }
  };

  const onProfileDropdownChange = (data) => {
    const intellConfigId = data["WebsiteProfiles.IntelConfigId"];
    contextData.changeIntelConfigId(intellConfigId);
    contextData.changeSelectedProfile(data);
    setSelectedProfileId(data);
    localStorage.setItem("WebsiteProfilesIntelConfigId", JSON.stringify(data));// Adding WebsiteProfiles.IntelConfigId to local stoarge in case of Page Refresh
  };

  const setDefaultPickerValueOnDateChange = (dateType) => {
    if (datePickerValue.length > 0) {
      const datePickerSplit = datePickerValue.split("-");
      let datePickerVle;
      if (dateType == "startDate") {

        datePickerVle = `${moment(startDate).format("MMM DD,YYYY")} - ${moment(datePickerSplit[1]).format("MMM DD,YYYY")}`;
        setDtPickerCompareValueOnDatePickerChange(startDate, moment(datePickerSplit[1]));
      }
      else if (dateType == "endDate") {
        datePickerVle = `${moment(moment(datePickerSplit[0])).format("MMM DD,YYYY")} - ${moment(endDate).format("MMM DD,YYYY")}`;
        setDtPickerCompareValueOnDatePickerChange(moment(datePickerSplit[0]), endDate);
      }
      setDatePickerValue(datePickerVle);
    }
  };

  const setDtPickerCompareValueOnDatePickerChange = (startDate, endDate) => {
    endDate = moment(endDate);
    startDate = moment(startDate);

    let previousEndDate = moment(endDate).subtract(1, 'years').format("MMM DD,YYYY");
    let previousStartDate = moment(startDate).subtract(1, 'years').format("MMM DD,YYYY");
    let datePickerCmpr = `${previousStartDate} - ${previousEndDate}`;
    setDatePickerCompareValue(datePickerCmpr);

  };
  const cancerFilters = function () {
    setShowFiltersMenu(false);
    if (props.cancelFilter !== undefined) {
      props.cancelFilter();
    }
  }
  const applyFilters = function () {
    props.applyFilters();
    setShowFiltersMenu(false);
  }

  //   const onLayoutChange = (newLayout) => {
  //     newLayout.forEach((l) => {
  //       const item = dashboardItems.find((i) => i.id.toString() === l.i);
  //       const toUpdate = JSON.stringify({
  //         x: l.x,
  //         y: l.y,
  //         w: l.w,
  //         h: l.h,
  //       });

  //       if (item && toUpdate !== item.layout) {
  //         updateDashboardItem({
  //           variables: {
  //             id: item.id,
  //             input: {
  //               layout: toUpdate,
  //             },
  //           },
  //         });
  //       }
  //     });
  //   };

  const rendersite = ({ resultSet, error, pivotConfig, request }) => {
    if (error) {
      return <div>{error.toString()}</div>;
    }

    if (!resultSet) {
      return <Loader show={false} />;
    }


    return (
      // <DropdownComponent
      //   dropdownData={resultSet.tablePivot(pivotConfig)}
      //   arrayKey="WebsiteList.WebsiteId"
      //   arrayValue="WebsiteList.WebsiteName"
      //   dropdownValue={websiteId}
      //   onChange={setWebsiteId}
      //   showLabel={false}
      // />
      <>
        <DropdownWithIconComponent
          dropdownData={resultSet.tablePivot(pivotConfig)}
          arrayValue="WebsiteList.WebsiteName"
          selectedDropdown={selectedWebsiteId}
          onChange={onWebsiteDropdownChange}
          showLabel={false}
          showIconInDropdownItem={false}
          customClassName="full-width text-overflow"
        />

      </>
    );

  };




  //   const renderWebsiteProfiles = ({ resultSet, error, pivotConfig, request }) => {
  //     if (error) {
  //       return <div>{error.toString()}</div>;
  //     }

  //     if (!resultSet) {
  //       return <Loader show={false} />;
  //     }
  //     // if(resultSet){
  //     //   setSelectedProfileId(resultSet.tablePivot(pivotConfig)[0]);
  //     // }

  //     //  setSelectedProfileId(resultSet.tablePivot(pivotConfig)[0]);
  //     // let selProfile = {};
  //     // if(resultSet !== undefined){
  //     // const profileData = resultSet.tablePivot(pivotConfig);
  //     // if(contextData.intelConfigId !== undefined && contextData.intelConfigId !== 0 && profileData !== null && profileData !== undefined && profileData.length > 0){
  //     //   selProfile = profileData.find(x=> x["WebsiteProfiles.IntelConfigId"] == contextData.intelConfigId);
  //     //   onProfileDropdownChange(selProfile);
  //     // }
  //  // }
  //     return (
  //       // <DropdownComponent
  //       //   dropdownData={resultSet.tablePivot(pivotConfig)}
  //       //   arrayKey="WebsiteProfiles.AccountId"
  //       //   arrayValue="WebsiteProfiles.DisplayName"
  //       //   dropdownValue={profileId}
  //       //   onChange={setProfileId}
  //       //   showLabel={false}
  //       // />

  //       <DropdownWithIconComponent
  //         dropdownData={resultSet.tablePivot(pivotConfig)}
  //         arrayValue="WebsiteProfiles.DisplayName"
  //         selectedDropdown={contextData.selectedProfile}
  //         // selectedDropdown={selectedProfileId}
  //         onChange={onProfileDropdownChange}
  //         showLabel={false}
  //         showIconInDropdownItem={false}
  //         customClassName="full-width text-overflow"
  //       />

  //     );

  //   };

  const onDatePickerDropdownChange = (data) => {

    if (data && Object.keys(data).length > 0) {
      if (data.key.length > 0) {
        const datePickerSplit = data.key.split("-");
        contextData.changeStartDate(moment(datePickerSplit[0]));
        contextData.changeEndDate(moment(datePickerSplit[1]));
        if (data.compareOn || data.showDatePicker) {
          const compareOnStartDate = moment(datePickerSplit[0]).add(-data.compareOn, 'days').format("MMM DD,YYYY");
          const compareOnEndDate = moment(datePickerSplit[1]).add(-data.compareOn, 'days').format("MMM DD,YYYY");
          const compareOnValue = `${compareOnStartDate} - ${compareOnEndDate}`;
          setDatePickerValue(`${moment(datePickerSplit[0]).format("MMM DD,YYYY")} - ${moment(datePickerSplit[1]).format("MMM DD,YYYY")}`);
          setDatePickerCompareValue(compareOnValue);
        }
      }

      if (data.showDatePicker)
        setShowCustomDatePicker(true);
      else
        setShowCustomDatePicker(false);
    }
    // if (value) {
    // setDatePickerValue(data.key);
    setSelectedDatePicker(data)
    localStorage.setItem("SelectedDatePicker", JSON.stringify(data));// Adding SelectedDatePicker to local stoarge in case of Page Refresh

    // }

  };

  const validStartDate = (current) => {
    let currentDay = moment(endDate).format("MM/DD/YYYY");
    return current.isBefore(currentDay);
  };

  const validEndDate = (current) => {
    // let currentDay = moment().format();
    let yesterday = moment().subtract(1, 'day');
    return current.isBefore(yesterday);
  };

  const toggleChange = () => {
    setToggle(!toggle);
  };

  const onLeftIconClick = (e) => {
    e.preventDefault();
    history.goBack();
    // window.history.back();
    // history.push(`${location.pathname}`);
  };


  //slider dropdown
  const onClickOutsideListener = () => {
    setShowCustomDatePicker(false);
    document.removeEventListener("click", onClickOutsideListener)
  }
  const onClickOutsideListenerFilters = () => {
    //setShowCustomDatePicker(false);
    setShowFiltersMenu(false)
    document.removeEventListener("click", onClickOutsideListenerFilters)
  }
  useEffect(() => {
    if (showFiltersMenu == true) {
      document.addEventListener('click', onClickOutsideListenerFilters);
    }
  }, [showFiltersMenu]);

  return (
    <>

      <div className="d-inline-block icon-nav icon-nav-filter ms-2">


        <div className="slider-dropdown-tigger d-inline-block filter-box-tigger icon-nav-list svg-icon-nav-list">
          {/* <FontAwesomeIcon className="filter-box-tigger-icon d-inline-block" onClick={()=>{setShowFiltersMenu(true)}} icon={faFilter} /> */}
          <span className="icon-nav-box ms-0" onClick={() => {
            setShowFiltersMenu(!showFiltersMenu)
            if (props.onIconClick) {
              props.onIconClick();
            }
          }}>
            <svg className="icon-nav">
              {props.iconText && <title>{props.iconText}</title>}
              {props.iconText == undefined && <title>{t("header.Icon_Tooltip_Filter")}</title>}
              <use href={iconSprite + `#Filter`} />
            </svg>
          </span>
        </div>

        {showFiltersMenu &&
          <Form className="date-range-container mt-2 filter-container"
            onMouseLeave={() => {
              document.addEventListener("click", onClickOutsideListenerFilters)
            }} onMouseEnter={() => { document.removeEventListener("click", onClickOutsideListenerFilters) }}>

            <Row className="text-end">
              {props.showHeader !== false && <Col xs={12} sm={12} className="text-left">
                <h4 className="mt-4">{t("header.Filters")}<OverlayTrigger
                  placement="right"
                  trigger={['hover', 'focus']}
                  overlay={
                    <Tooltip>{t("header.filtersMessage")}</Tooltip>
                  }>
                  <Button variant="white" className="btn-help border-0 p-2">
                    <i className="far fa-question-circle lg"></i>
                  </Button>
                </OverlayTrigger></h4>

              </Col>}



              <Col xs={12} sm={12} className="mb-1 position-relative">
                {

                  props.dataSource.map((element) => {

                    if (element.isVisible) {
                      return (<div className="text-left pt-2"><span className="d-block mb-1">{element.label}</span><DropdownWithIconComponent
                        dropdownData={element.data}
                        arrayValue={element.displayText}
                        selectedDropdown={element.selectedItem}
                        onChange={element.callback}
                        showLabel={false}
                        showIconInDropdownItem={false}
                        customClassName="full-width text-overflow"
                      /></div>)
                    } else {
                      return <></>
                    }


                  })
                }
                {props.showDate &&
                  <Form>
                    <div className="text-left pt-2"><span>{t("header.filtersDateLabel")}</span></div>
                    <DropdownWithIconComponent
                      dropdownData={dashbordDateRangeList}
                      // arrayKey="key"
                      arrayValue="value"
                      selectedDropdown={selectedDatePicker}
                      onChange={onDatePickerDropdownChange}
                      showLabel={false}
                      showIconInDropdownItem={false}
                      customClassName="full-width text-overflow"
                    />
                  </Form>
                }
                {showCustomDatePicker &&
                  <Form className="date-range-container" onMouseLeave={() => {
                    document.addEventListener("click", onClickOutsideListener)
                  }} onMouseEnter={() => { document.removeEventListener("click", onClickOutsideListener) }}>

                    <Form.Group className="">
                      <Datetime
                        timeFormat={false}
                        closeOnSelect={false}
                        onChange={contextData.changeStartDate}
                        className="mt-2"
                        isValidDate={validStartDate}
                        renderInput={(props, openCalendar) => (
                          <InputGroup>
                            <Form.Control
                              required
                              type="text"
                              value={startDate ? moment(startDate).format("MMM DD,YYYY") : ""}
                              placeholder={t("header.ToolsEventCalEventListColStartDate")}
                              onFocus={openCalendar}
                              onChange={() => { }} />
                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                          </InputGroup>
                        )} />

                      <Datetime
                        timeFormat={false}
                        closeOnSelect={false}
                        onChange={contextData.changeEndDate}
                        isValidDate={validEndDate}
                        className="mt-2"
                        renderInput={(props, openCalendar) => (
                          <InputGroup>
                            <Form.Control
                              required
                              type="text"
                              value={endDate ? moment(endDate).format("MMM DD,YYYY") : ""}
                              placeholder={t("header.ToolsEventCalEventListColEndDate")}
                              onFocus={openCalendar}
                              onChange={() => { }} />
                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                          </InputGroup>
                        )} />
                    </Form.Group>
                  </Form>
                }

              </Col>

              <Col className="text-start" lg={12} xl={12}>
                <div className="d-block mt-4">
                  <ButtonComponent
                    onClick={() => applyFilters()}
                    isIcon={false}
                    isPrimary={true}
                    btnText={props.btnOneText ? props.btnOneText : "Ok"}
                    btn_class="btn-md mb-3 filter-btn"
                  />
                </div>

                <div className={props.btnTwoText ? 'd-block' : 'd-none'}>
                  <ButtonComponent
                    onClick={() => cancerFilters()}
                    isIcon={false}
                    isPrimary={false}
                    btnText={props.btnOneText ? props.btnTwoText : "Cancel"}
                    btn_class="btn-md mb-3 filter-btn"
                  />
                </div>
              </Col>

            </Row>




            {/* <Form.Group className="">
                    <Datetime
                      timeFormat={false}
                      closeOnSelect={false}
                      onChange={contextData.changeStartDate}
                      className="mt-2"
                      isValidDate={validStartDate}
                      renderInput={(props, openCalendar) => (
                        <InputGroup>
                          <Form.Control
                            required
                            type="text"
                            value={startDate ? moment(startDate).format("MMM DD,YYYY") : ""}
                            placeholder={t("header.ToolsEventCalEventListColStartDate")}
                            onFocus={openCalendar}
                            onChange={() => { }} />
                          <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                        </InputGroup>
                      )} />

                    <Datetime
                      timeFormat={false}
                      closeOnSelect={false}
                      onChange={contextData.changeEndDate}
                      isValidDate={validEndDate}
                      className="mt-2"
                      renderInput={(props, openCalendar) => (
                        <InputGroup>
                          <Form.Control
                            required
                            type="text"
                            value={endDate ? moment(endDate).format("MMM DD,YYYY") : ""}
                            placeholder={t("header.ToolsEventCalEventListColEndDate")}
                            onFocus={openCalendar}
                            onChange={() => { }} />
                          <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                        </InputGroup>
                      )} />
                  </Form.Group> */}
          </Form>
        }
      </div>


      {/* Note:  ReactGridLayout 's 
      isDraggable is for the Card Draggable
     isResizable is for the Card Resizing  */}
      {/* {selectedWebsiteId["WebsiteList.WebsiteId"] || selectedProfileId["WebsiteProfiles.ProfileId"] ?
        <ReactGridLayout cols={12} rowHeight={50}
          isDraggable={false} isResizable={false}
        // onLayoutChange={onLayoutChange}
        >
          {children}
        </ReactGridLayout>
        :<div className="p-3  w-100"> 
        <div className="bg-white shadow-soft border rounded border-light w-100 fmh-200 position-relative">
          <div class="card-no-data mt-n3">{t("header.NoDataFound")}</div>
          </div>
          </div>
      } */}
    </>
  );
};

export default withRouter(Filters);
