import React, { useState, useEffect, useContext, useMemo} from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';
import { withRouter } from "react-router";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faChevronLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Button, Image, Form, InputGroup, FormCheck } from '@themesberg/react-bootstrap';
import DropdownWithIconComponent from "../components/DropdownWithIcons";
import Loader from '../components/VoltLoader';
import AppContext from '../components/AppContext';
import { dashbordDateRangeList } from "../utils/datetime";
import { DEFAULT_WEBSITE_DASHBORD, DEFAULT_PROFILE_DASHBORD, object_equals, NULL_VALUE_MESSAGE_DROPDOWN } from "../AppConfig";
import CommonHeader from '../components/CommonHeader';
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { useTranslation } from 'react-i18next';
import { decryptData } from '../utils/crypto';

const ReactGridLayout = WidthProvider(RGL);

const WebcastStatistics = ({ children, dashboardItems, selectedpresentation, setSelectedpresentation,setPollType, setSelectedWebsiteId, history, location }) => {
  
  const [t, i18n] = useTranslation();
  const contextData = useContext(AppContext);
  const [selectedevent, setSelectedevent] = useState({});
  const [updateKey,setUpdateKey] = useState("")
  const [autoLoad,setAutoLoad] = useState(false);
  const [selectedPresentionNew,setSelectedPresentionNew] = useState("");
  const [selectedEventNew,setSelectedEventNew] = useState({})
  const [eventPresentationsList,setEventPresentationsList] = useState([]);
  const [eventPresentationsData,setEventPresentationsData] = useState([]);
  const [blurLoader, setBlurLoader] = useState(false);

  const cubejsApi = cubejs(
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJteWFwcGlkIjozLCJpYXQiOjE2MTgyMjkzNDIsInVzZXJfaWQiOjM4MDgxLCJwbGF0Zm9ybV9pZCI6MSwic2VydmljZV9pZCI6OCwiY29tcGFueV9pZCI6OTM0NX0.lYSmPrF55TH3y8HQk96khmXn08XBt5nkUh7vzxfyak4',
    { apiUrl: `${process.env.REACT_APP_CUBESERVERURL}/cubejs-api/v1` }
  );

  // useEffect(() => {

  //   setSelectedpresentation({});

  //   setSelectedevent({});

  // }, [contextData.companyId]);

  useEffect(() => {
    // Fetching values from Local storage in case of Page Refresh
    if (localStorage.getItem("IsPageRefresh") == "1") {
      setSelectedPresentionNew(JSON.parse(localStorage.getItem("PresentationId")));
      if (contextData && contextData.hasEvents == 1) {
        setSelectedEventNew(JSON.parse(localStorage.getItem("webcastEventId")));
      }else{
        setSelectedPresentionNew(JSON.parse(localStorage.getItem("PresentationId")));
      }
      
    }
    // setSelectedEventNew({});
    // setSelectedPresentionNew({});
    
    if(autoLoad){
      setAutoLoad(false);
    }
  }, []);
  useEffect(()=>{

    if(eventPresentationsData != null && eventPresentationsData.length){
      let preesentationsData = eventPresentationsData.filter((e)=>{ return e["Presentations.eventid"] === selectedEventNew["Events.id"]}); 
      //let presentationsUpdated = preesentationsData.map((e)=>({["Presentations.name"]:e.Name,["Presentations.id"]:e.Slug,["Presentations.clientslug"]:e.ClientId,["Presentations.slug"]:e.Url,["Presentations.eventid"]:e.EventId}));
      //if(selectedWebsiteIdNew["WebsiteList.WebsiteId"]){
        if(contextData.hasEvents === 1){          
          renderWebsiteProfiles({resultSet:preesentationsData})
        }else{
          renderWebsiteProfiles({resultSet:eventPresentationsData})
        }
        
      //}
      
    }else{
      setSelectedPresentionNew({})
      renderWebsiteProfiles({resultSet:[]})
    }
 
    //renderWebsiteProfiles({resultSet:eventPresentationsList})
},[selectedEventNew,eventPresentationsData])
  useEffect(()=>{
    
    if(contextData.hasEvents === 0){
        //renderWebsiteProfiles({resultSet:eventPresentationsList})
        setSelectedEventNew({})
        setSelectedPresentionNew({})
      // let presentationDataNew = localStorage.getItem("presentationData")
      // if(presentationDataNew !== null){
      //   presentationDataNew = JSON.parse(presentationDataNew)
      //   onPresentationDropdownChange(presentationDataNew[0])
      // }
    }
  },[contextData.hasEvents]);
  // useEffect(()=>{
  //   if(autoLoad){
  //     setAutoLoad(false);
  //   }
  // },[autoLoad])
  useEffect(()=>{
    setSelectedEventNew({})
    setSelectedPresentionNew({})
    if(autoLoad){
      setAutoLoad(false);
    }
    //setEventPresentationsList([{'werwqerw':'qwerewq'},{'werwqerw':'qwerewq'},{'werwqerw':'qwerewq'}])
  },[contextData.companyId]);
  // useEffect(()=>{
  //   setSelectedPresentionNew({})
  // },[localStorage.getItem("presentationData")]);
  const onEventsDropdownChange = (data) => {
    
    if (!object_equals(selectedevent, data)) {
      setSelectedPresentionNew({})
    }
    //contextData.changeEventId(data["Events.id"]);

   contextData.changeEventId(data["Presentations.eventid"]);
   setSelectedEventNew(data);
   
   
    setUpdateKey(Math.random())
  };
  useEffect(() => {
    setEventPresentationsData([])
    getWebsites();
    //setSelectedProfileIdNew({})
  }, [contextData.userId,contextData.companyId]);
  const getWebsites = () => {
    setBlurLoader(true)
    //setEventPresentationsList([])
    
    request(baseURL + `/Common/GetWebcastPresentationByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
        METHODS.GET,
        {},
        {
            Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        })
        .then((res) => {
          setBlurLoader(false)
            let resultData = [];
            let presentationsData = [];
            if(res !== null){
              resultData=res.Events.map((e)=>({["Events.title"]:e.EventTitle,["Events.id"]:e.EventId}))
              presentationsData=res.EventPresentations.map((e)=>({["Presentations.name"]:e.Name,["Presentations.id"]:e.Slug,["Presentations.clientslug"]:e.ClientId,["Presentations.slug"]:e.Url,["Presentations.eventid"]:e.EventId,["Presentations.url"]:e.Url,["Presentations.clientid"]:e.ClientId}))
              rendersite({resultSet:resultData})
              setEventPresentationsData(presentationsData)
              // setEventPresentationsList([{'werwqerw':'qwerewq'},{'werwqerw':'qwerewq'},{'werwqerw':'qwerewq'}])
            }
            
            
        })
        .catch((err) => {
          
        })
        
        // request(baseURL + `/Intelligence/GetWebsites?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
        // METHODS.GET,
        // {},
        // {
        //     Authorization: `Bearer ${localStorage.getItem("token")}`, "content-type": "application/json"
        // })
        // .then((res) => {
        //     setProfilesList(res.result)
        // })
        // .catch((err) => {
          
        // })
  }

  const onPresentationDropdownChange = (data) => {
    setSelectedPresentionNew(data)
  };

  const compareItem = (items,compareItem)=>{
    if(items.length > 0){
      for(let i=0; i<=items.length; i++){
        if(object_equals(items[i],compareItem)){
          return true;
        }
      }
    }
  }
  const applyFilters = ()=>{
    setBlurLoader(false)
    localStorage.setItem("pageNo",1)
    setSelectedpresentation(selectedPresentionNew);

    //setSelectedevent(JSON.parse(localStorage.getItem("EventId")));
    //setPollType(selectedPresentionNew["Presentations.polltype"]);
    contextData.changePresentationId(selectedPresentionNew["Presentations.id"]);
    contextData.changePresentation(selectedPresentionNew);
    localStorage.setItem("webcastEventId", JSON.stringify(selectedEventNew));// Adding EventId to local stoarge in case of Page Refreshpresentation(selectedPresentionNew);
    localStorage.setItem("PresentationId", JSON.stringify(selectedPresentionNew)); // Adding PresentationId  to local stoarge in case of Page Refresh
    
    //contextData.changeEventId(selectedevent["Events.id"]);
    //contextData.changeSelectionsText("werwq");
     // return "Website: "+contextData.selectedWebsite["WebsiteList.WebsiteName"]+" | Website profile: "+contextData.selectedProfile["WebsiteProfiles.DisplayName"]
    }
    const cancelFilters = ()=>{
      onEventsDropdownChange(JSON.parse(localStorage.getItem("webcastEventId")))
      onPresentationDropdownChange(JSON.parse(localStorage.getItem("PresentationId")))
      }
  const rendersite = ({ resultSet, error, pivotConfig, request }) => {

    // if (error) {
    //   return <div>{error.toString()}</div>;
    // }
    // if (!resultSet) {
    //   return <Loader show={false} />;
    // }
    localStorage.setItem("eventsData",JSON.stringify([]));    
    if(resultSet !== null && resultSet.length){
      //if(selectedEventNew !== null && Object.keys(selectedEventNew).length <= 0){
        localStorage.setItem("eventsData",JSON.stringify(resultSet));      
        
        if(JSON.parse(localStorage.getItem("webcastEventId")) !== null && Object.keys(JSON.parse(localStorage.getItem("webcastEventId"))).length > 0){
          onEventsDropdownChange(JSON.parse(localStorage.getItem("webcastEventId")))
        }else{
          onEventsDropdownChange(resultSet[0])     
        }
        setUpdateKey(JSON.stringify(Math.random()))
        
        contextData.changeEventId(resultSet[0]["Events.id"]);
      //}
      
      
      //setAutoLoad(true)
    }
    
    return (
      <></>
      // <DropdownWithIconComponent
      //   dropdownData={resultSet.tablePivot(pivotConfig)}
      //   arrayValue="Events.title"
      //   selectedDropdown={selectedevent}
      //   onChange={onEventsDropdownChange}
      //   showLabel={false}
      //   showIconInDropdownItem={false}
      //   customClassName="full-width"
      // />

    );

  };
  const renderWebsiteProfiles = ({ resultSet, error, pivotConfig, request }) => {

    // if (error) {
    //   return <div>{error.toString()}</div>;
    // }
    // if (!resultSet) {
    //   return <Loader show={false} />;
    // }
    localStorage.setItem("presentationData",JSON.stringify([]));
    localStorage.setItem("presentationData",JSON.stringify(resultSet))
    if(resultSet !== null && resultSet.length){
      if(selectedPresentionNew !== null && Object.keys(selectedPresentionNew).length<=0){
        if(compareItem(resultSet,JSON.parse(localStorage.getItem("PresentationId"))) === true){
          onPresentationDropdownChange(JSON.parse(localStorage.getItem("PresentationId")));
        }else{
          onPresentationDropdownChange(resultSet[0]);  
        }
        //setSelectedPresentionNew(resultSet.tablePivot(pivotConfig)[0])
        //contextData.changePresentationId(resultSet.tablePivot(pivotConfig)[0]["Presentations.id"]);
        
        //localStorage.setItem("presentationData",JSON.stringify(resultSet.tablePivot(pivotConfig)))
      }else{
      //    if (!object_equals(selectedPresentionNew,resultSet.tablePivot(pivotConfig))[0]) {
      //   //setSelectedPresentionNew(resultSet.tablePivot(pivotConfig)[0]);
      // } 
      }
      
      
      // onPresentationDropdownChange(resultSet.tablePivot(pivotConfig)[0]); 
      // for(let item in resultSet.tablePivot(pivotConfig)){
      //   if (!object_equals(selectedPresentionNew, item)) {
      //     onPresentationDropdownChange(resultSet.tablePivot(pivotConfig)[0]);  
          
      //   } 
      // }
      setUpdateKey(JSON.stringify(Math.random()))
      if(Object.keys(selectedPresentionNew).length<=0){
        
        
        setAutoLoad(true)
      }
    }
   
    
    return (

<></>
      // <DropdownWithIconComponent
      //   dropdownData={resultSet.tablePivot(pivotConfig)}
      //   arrayValue="Presentations.name"
      //   selectedDropdown={selectedpresentation}
      //   onChange={onPresentationDropdownChange}
      //   showLabel={false}
      //   showIconInDropdownItem={false}
      //   customClassName="full-width text-overflow"
      // />

    );
  };
  const commonHeader = useMemo(()=><CommonHeader cancelFilters={cancelFilters} channel="webcast" autoLoad={autoLoad} dataSource={[
    {name:'Dropdown1 - Events',data:JSON.parse(localStorage.getItem("eventsData")),selectedItem:selectedEventNew,isVisible:contextData && contextData.hasEvents == 1?true:false,label:t("header.filterEventLabel"),callback:onEventsDropdownChange,displayText:"Events.title"},
    {name:'Dropdown2 - Presentation',data:JSON.parse(localStorage.getItem("presentationData")),selectedItem:selectedPresentionNew ,isVisible:true,label:t("header.filterPresentationLabel"),callback:onPresentationDropdownChange,displayText:"Presentations.name"}]}
     title={t("header.WebcastStatisticsTopHeader")} showDate={false} applyFilters={applyFilters} />,[updateKey,selectedEventNew,selectedPresentionNew,autoLoad,contextData.companyId,contextData.hasEvents]);
  return (
    <>
            {blurLoader && <div id='blurdiv' className="blurbackground">
                <div className={`preloader loader-center-align`}>
                    <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                </div>
            </div>}
    {commonHeader}
    {
      contextData && contextData.hasEvents == 0 &&
      <Row className="ps-3">
        <Col xs={12} sm={12}>
          <p>{t("header.webcastStatisticsMessage")}</p>
        </Col>
      </Row>
    }
      
        {/* {(contextData && contextData.hasEvents == 1) &&
          
            <QueryRenderer
              query={{
                "dimensions": [
                  "Events.title",
                  "Events.id"
                ],
                "timeDimensions": [],
                "order": {
                  "events.title": "asc"
                },
                "filters": [
                  {
                    "member": "Events.clientid",
                    "operator": "equals",
                    "values": [`${contextData.webcastClientId}`]
                  },
                  {
                    "member": "EventsPresentationids.count",
                    "operator": "gt",
                    "values": [
                      "0"
                    ]
                  }
                ],
              }}
              cubejsApi={cubejsApi}
              resetResultSetOnChange={false}
              render={(props) => rendersite({
                ...props,
                chartType: 'dropdown',
                pivotConfig: {
                  "x": [
                    "Events.title",
                    "Events.id"
                  ],
                  "y": ["events.title"],
                  "fillMissingDates": true,
                  "joinDateRange": false
                }
              })}
            />
          
        } */}
        {/* // {(selectedEventNew && Object.keys(selectedEventNew).length > 0 && contextData && contextData.hasEvents == 1) &&
          
        //     <QueryRenderer
        //       query={{
        //         "dimensions": [
        //           "Presentations.name",
        //           "Presentations.id",
        //           "Presentations.polltype",
        //           "Presentations.clientslug",
        //           "Presentations.slug"
        //         ],
        //         "timeDimensions": [],
        //         "order": {
        //           "Presentations.name": "asc"
        //         },
        //         "filters": [
        //           {
        //             "member": "Presentations.eventid",
        //             "operator": "contains",
        //             "values": [selectedEventNew["Events.id"]]
        //           },
        //           {
        //             "member": "Presentations.myinvestis_flag",
        //             "operator": "equals",
        //             "values": [
        //               "1"
        //             ]
        //           }

        //         ]
        //       }}
        //       cubejsApi={cubejsApi}
        //       resetResultSetOnChange={true}
        //       render={(props) => renderWebsiteProfiles({
        //         ...props,
        //         chartType: 'dropdown',
        //         pivotConfig: {
        //           "x": [
        //             "Presentations.name",
        //             "Presentations.id",
        //             "Presentations.polltype",
        //             "Presentations.clientslug",
        //             "Presentations.slug"
        //           ],
        //           "y": ["Presentations.name"],
        //           "fillMissingDates": true,
        //           "joinDateRange": false
        //         }
        //       })}
        //     />

          
        // }
        // {(contextData && contextData.webcastClientId && contextData.hasEvents == 0) &&
        // <>
        
          
        //     <QueryRenderer
        //       query={{
        //         "dimensions": [
        //           "Presentations.name",
        //           "Presentations.id",
        //           "Presentations.polltype",
        //             "Presentations.clientslug",
        //             "Presentations.slug"
        //         ],
        //         "timeDimensions": [],
        //         "order": {
        //           "Presentations.name": "asc"
        //         },
        //         "filters": [
        //           {
        //             "member": "Presentations.clientid",
        //             "operator": "equals",
        //             "values": [`${contextData.webcastClientId}`]
        //           },
        //           {
        //             "member": "Presentations.myinvestis_flag",
        //             "operator": "equals",
        //             "values": [
        //               "1"
        //             ]
        //           }

        //         ],
        //       }}
        //       cubejsApi={cubejsApi}
        //       resetResultSetOnChange={true}
        //       render={(props) => renderWebsiteProfiles({
        //         ...props,
        //         chartType: 'dropdown',
        //         pivotConfig: {
        //           "x": [
        //             "Presentations.name",
        //             "Presentations.id",
        //             "Presentations.pollType",
        //             "Presentations.clientslug",
        //             "Presentations.slug"

        //           ],
        //           "y": ["Presentations.name"],
        //           "fillMissingDates": true,
        //           "joinDateRange": false
        //         }
        //       })}
        //     />

          
        //   </>
        // } */}
        
     
    </>
  );
};

export default withRouter(WebcastStatistics);
