import { Form, InputGroup, Button } from "@themesberg/react-bootstrap";
import React, { useState, useEffect, useContext, useRef } from "react";
import { withRouter } from "react-router";
import { QueryRenderer } from '@cubejs-client/react';
import cubejs from '@cubejs-client/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AppContext from '../components/AppContext';
import {
    isEmpty, isLessThanMinLength,
    isGreaterThanMaxLength,
    customDurationIds
} from "../AppConfig";
import { data } from "jquery";

const Search = (props) => {
    const eventRef = useRef();
    const [temCompanyName, setTemCompanyName] = useState("");
    const [temList, settemList] = useState([]);

    const [enableClear, setEnableClear] = useState(false);

    const contextData = useContext(AppContext);
    const [t, i18n] = useTranslation();

    const [shallClearAutoselectTextbox, setShallClearAutoselectTextbox] = useState(true);

    const searchData = (e) => {
        e.preventDefault();
        let value = e.target.value;
        // value?.length > 0 ? setEnableClear(true) : setEnableClear(false);
        if (e.key === "Enter" && value.trim() != "") {
            value?.length > 0 ? setEnableClear(true) : setEnableClear(false);
            props.setsearchData(value);
        }
        else if (e.key === "Enter" && value == "") {
            value?.length > 0 ? setEnableClear(true) : setEnableClear(false);
            props.setsearchData(value);
        }
    }

    const suggestionSelectedValue = (value) => {
        value?.length > 2 ? setEnableClear(true) : setEnableClear(false);
        setTemCompanyName(value);
        setShallClearAutoselectTextbox(false);
        props.setsearchData(value);
        props.setAutoFillForCompany(false);
    }


    const onTextChanged = (e) => {
        const { value, name } = e.target;
        const showAutoFill = value.length > 2 ? true : false;
        // value?.length > 2 ? setEnableClear(true) : setEnableClear(false);
        setTemCompanyName(value);

        const resultData = [];
        if (props.data) {
            props?.data && Array.isArray(props?.data) && props?.data?.forEach((element) => {
                if (element.match(new RegExp(value, 'gi'))) {
                    resultData.push(element)
                }
            });
        }
        setShallClearAutoselectTextbox(true);
        settemList(resultData);
        props.setAutoFillForCompany(showAutoFill);
        // props.setAutoFillForCompany(resultData.length > 0 ? showAutoFill : false);
        if (value.length === 0) {
            value?.length > 2 ? setEnableClear(true) : setEnableClear(false);
            props.setsearchData("");
        }

    };
    useEffect(() => {
        if (!props.searchData || props.searchData === "emptytextbox") {

            if (!props.isAutoComplete) {
                //document.getElementById("txtfreesearch").value = "";
                eventRef.current.value = '';
            }
            else {
                setTemCompanyName("")

            }
        } else if (!props.isAutoComplete && props.searchData) {
            //document.getElementById("txtfreesearch").value = props.searchData;
            eventRef.current.value = props.searchData;
        }
        if (!props.searchData) {
            setEnableClear(false);
        }
    }, [props.searchData, props.emptyTextBox]);

    useEffect(() => {

        if (props.showAutoFillForCompany == false && temCompanyName.length > 2 && props.searchData === "") {
            setTemCompanyName("");
        }
    }, [props.showAutoFillForCompany]);

    return (
        <>
            {props.isAutoComplete === false ?
                <div className="table-search-box">
                    <InputGroup>
                        <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                        <Form.Control type="text"
                            ref={eventRef}
                            //value={eventRef.current}
                            onKeyUp={(e) => { searchData(e, true) }} className="form-control input-search" autoComplete="off" id="txtfreesearch" name="companyName" placeholder={props.searchWaterMark ? props.searchWaterMark : t("header.VisitorsActivityPopPagesIconSearchTooltip")}>
                        </Form.Control>

                        {enableClear ? <span variant="white" className="btn-search-reset border-0 p-2" onClick={() => { props.setsearchData(""); setEnableClear(false); }}>
                            <FontAwesomeIcon icon={faTimes} />
                        </span> : <></>}

                        {/* <Form.Control type="text" value={searchText} id="txtfreesearch" onChange={(e)=>setSearchText(e.target.value)} className="form-control input-search" name="companyName" placeholder="search" onKeyUp={(e) => { filterData(e) }}></Form.Control> */}
                    </InputGroup>
                </div> :

                <div className="table-search-box pe-3">
                    <InputGroup>
                        <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                        <Form.Control type="text" className="form-control input-search" id="txtauto" name="companyName" placeholder={props.searchWaterMark} value={temCompanyName} onChange={(e) => onTextChanged(e)} autoComplete="off" />
                        {enableClear ? <span variant="white" className="btn-search-reset border-0 p-2" onClick={() => { props.setsearchData(""); setEnableClear(false) }}>
                            <FontAwesomeIcon icon={faTimes} />
                        </span> : <></>}
                        {
                            props.showAutoFillForCompany && props.type === "client" &&
                            // <div className="col-md-12 justify-content-md-center">
                            <div className="auto-dropdown">
                                {temCompanyName ? <ul className="list-unstyled auto-dropdown-menu">
                                    {temList.length > 0 && temList.map((value, index) =>
                                        <li className="auto-dropdown-menu-item mt-1 mb-1" key={index} onClick={() => suggestionSelectedValue(value)}>{value}</li>
                                    )}
                                    {
                                        temList.length === 0 && <li style={{ color: '#cc493f' }} className="mt-1 mb-1">{t("header.NoDataFound")}</li>
                                    }
                                </ul>:<></>}
                                {(temList.length == 0 || !props.searchData) && enableClear ? <ul className="list-unstyled auto-dropdown-menu">
                                    {
                                        <li style={{ color: '#cc493f' }} className="mt-1 mb-1">{t("header.NoDataFound")}</li>
                                    }
                                </ul>:<></>}
                            </div>

                            // </div>
                        }
                    </InputGroup>


                </div>}
        </>
    );
};
Search.propTypes = {
    isAutoComplete: PropTypes.bool

};
Search.defaultProps = {
    isAutoComplete: false

}

export default withRouter(Search);
