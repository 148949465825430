import React, { useEffect, useState, useContext } from 'react';
import moment from "moment-timezone";
import AlertSummary from '../components/AlertSummary';
import { isMobile } from "react-device-detect";
import { alertSummary, apiAlertSummaryService } from "../stubs/ComponentJson/alerts";
import ChartRenderer from '../components/ChartRenderer';
import ChartSummary from '../components/CardSummary';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DashboardItem from '../components/DashboardItem';
import { Button, Alert, Image } from '@themesberg/react-bootstrap'
import { defaultLayout, defaultLayoutMobile } from "../utils/layoutConfig";
import { TABLE_TOTAL_ROWS } from "../AppConfig";
import SummaryRenderer from '../components/SummaryRenderer';
import AppContext from '../components/AppContext';
import ApiChartRenderer from '../components/ApiChartRenderer';
import ChartRendererTable from '../components/ChartRendererTable';
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import axios from "axios";
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { convertBase64 } from "../utils/common";
import { useTranslation } from 'react-i18next';
import { decryptData } from '../utils/crypto';

const deserializeItem = (i) => ({
    ...i,
    layout: i.layout ? JSON.parse(i.layout) : {},
    summary: i.summary ? JSON.parse(i.summary) : {},
    vizState: i.vizState ? JSON.parse(i.vizState) : {},
    apiUrl: i.apiUrl ? i.apiUrl : undefined,
    isResultShownFromApi: i.isResultShownFromApi ? i.isResultShownFromApi : undefined,
    chartType: i.chartType ? i.chartType : undefined
});

export const AlertSummaryPage = () => {
    const [t, i18n] = useTranslation();
    const [selectedWebsiteId, setSelectedWebsiteId] = useState({});
    const [alertSummaryData, setAlertSummaryData] = useState([]);
    const [selectedOtpclientid, setSelectedOtpclientid] = useState({});
    const [selectedYear, setSelectedYear] = useState({});
    const [pageNo, setPageNo] = useState(0);
    const [offset, setOffset] = useState(0);
    const [startDate, setStartDate] = useState(`${moment().add(-20, 'months')}`);
    const [endDate, setEndDate] = useState(`${moment(new Date())}`);
    const [items, setItems] = useState([]);
    const [blurLoader, setBlurLoader] = useState(false);
    const [alertSummaryApi, setAlertSummaryApi] = useState({ url: "http://localhost:3004/traffic_source/", type: "get", count: 0 });
    const contextData = useContext(AppContext);
    const [getTotalResults, setGetTotalResults] = useState(0);

    useEffect(() => {
        const websiteId = contextData.websiteId;
        const userId = contextData.userId;
        const companyId = contextData.companyId
        const syear = selectedYear && selectedYear.year ? selectedYear.year : new Date().getFullYear();

        if (websiteId > 0 && syear == selectedYear.year && contextData.selectedTab === "Alerts") {
            const otpClient = (selectedOtpclientid !== undefined && selectedOtpclientid !== null && selectedOtpclientid["ClietConfiguration.otpclientid"] !== undefined) ? selectedOtpclientid["ClietConfiguration.otpclientid"] : 29;
            let dta = (syear && otpClient && otpClient) ?
                alertSummary(otpClient, offset, new Date(syear, 0, 1), new Date(syear, 11, 31), websiteId, syear, companyId, userId) : null;
            setAlertSummaryData(dta);
            // if (dta !== null) {
            // }
        }
        else {
            setAlertSummaryData([]);
        }

    }, [contextData.selectedProfile["WebsiteProfiles.ProfileId"], contextData.startDate, contextData.endDate, offset, pageNo, contextData.websiteId, contextData.userId, contextData.companyId, selectedYear, contextData.selectedTab]);

    const onPageNoChange = (number) => {
        const num = (number - 1) * TABLE_TOTAL_ROWS;
        const page = number;
        setOffset(num);
        setPageNo(page);
    }
    const DownloadManageUsersExcelFileWithEPPLus = async () => {
        setBlurLoader(true)

        let paramsList = {
            WebsiteId: selectedWebsiteId,
            year: selectedYear.year,
            companyId: contextData.companyId,
            userId: contextData.userId,
            culture: localStorage.getItem('i18nextLng')
        }
        request(baseURL + `/Intelligence/ExportAlertStatisticsReportTableEPPlus?encodeStr=${convertBase64(JSON.stringify(paramsList))}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
            })
            .then((res) => {
                if (res) {
                    let fetchDataModified = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res}`;
                    let a = document.createElement("a");
                    a.href = fetchDataModified;
                    a.download = 'Statistics' + '.xlsx';
                    a.click();
                }
                setBlurLoader(false);
            })
            .catch((err) => {
                setBlurLoader(false);
                console.error('Error generating Excel:', err);
            })
    };
    const dashboardItem = (item) => (
        <div key={item.id} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
            <DashboardItem
                key={item.id}
                itemId={item.id}
                title={getTotalResults > 0 && t("header.AlertSummaryDescription")}
                customTitle={"alertsummery"}
            >
                {(item.isResultShownFromApi && item.chartType && item.chartType != "table") ?
                    <ApiChartRenderer isLegendShown={item.isLegendShown} isSummeryShown={item.isSummeryShown} services={apiAlertSummaryService} chartType={item.chartType} itemId={item.id} vizState={item.vizState} cardProperty={Object.keys(item.layout).length > 0 ? item.layout : item.layout}
                        pageNo={pageNo} onPageNoChange={onPageNoChange}
                        legends={item.legends}
                        year={selectedYear && selectedYear.year ? selectedYear.year : new Date().getFullYear()}
                    />
                    : <></>}
                {
                    item.apiUrl !== undefined &&
                    <>
                        <div className="d-inline-block float-end mt-2 me-4">

                            {getTotalResults > 0 && <div className="icon-nav-list svg-icon-nav-list">

                                <span title='Export to Excel' className={getTotalResults > 0 ? "icon-nav-box hide-mobile custom-select" : "icon-nav-box hide-mobile disabled custom-select"} onClick={() => DownloadManageUsersExcelFileWithEPPLus()}>
                                    <svg className="icon-nav">
                                        <use href={iconSprite + `#Download`} />
                                    </svg>
                                </span>
                            </div>}
                        </div>
                        <h5 class="ms-3 mt-2">Monthly Alert Summary Statistics</h5>
                        <ChartRendererTable
                            showPagination={false}
                            apiUrl={item.apiUrl}
                            setGetTotalResults={(value) => setGetTotalResults(value)}
                            showCount={false}
                        //summaryBesideText={"Monthly Alert Summary Statistics"}
                        />
                    </>
                }
                {/* {Object.keys(item.summary).length > 0 &&
                    <SummaryRenderer vizState={item.summary} title={item.name} />
                }

                <ChartRenderer apiUrl={undefined} vizState={item.vizState} cardProperty={item.layout} legends={item.legends} setPageNo={setPageNo} pageNo={pageNo} onPageNoChange={onPageNoChange} showPagination={item.pagination}
                    offset={offset} setOffset={setOffset} /> */}
            </DashboardItem>
        </div>
    );

    const Empty = () => (
        <div
            style={{
                textAlign: 'center',
                padding: 12,
            }}
        >
            <h2>{t("header.noChartsonDashboard")}</h2>
            {/* <Link to="/explore"> */}
            <Button variant="secondary" className="m-1">
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                {t("header.addChart")}
            </Button>
            {/* </Link> */}
        </div>
    );

    return alertSummaryData || alertSummaryData.length > 0 ? (
        <>
            {blurLoader && <div id='blurdiv' className="blurbackground">
                <div className={`preloader loader-center-align`}>
                    <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                </div>
            </div>}
            <AlertSummary dashboardItems={alertSummaryData}
                selectedWebsiteId={selectedWebsiteId}
                selectedOtpclientid={selectedOtpclientid}
                selectedYear={selectedYear}
                setSelectedWebsiteId={setSelectedWebsiteId}
                setSelectedOtpclientid={setSelectedOtpclientid}
                setSelectedYear={setSelectedYear}
                setPageNo={setPageNo}
                setOffset={setOffset}
            >
                {alertSummaryData.map(deserializeItem).map(dashboardItem)}
            </AlertSummary>
        </>
    ) : (
        <Empty />
    );
};


export default AlertSummaryPage;