export const hobbiesList = [
    {
    id: 1,
    key:"hobbies-1",
    name: "Hobbie 1"
},
{
    id: 2,
    key:"hobbies-2",
    name: "Hobbie 2"
},
{
    id: 3,
    key:"hobbies-3",
    name: "Hobbie 3"
},
{
    id: 4,
    key:"hobbies-4",
    name: "Hobbie 4"
},

];