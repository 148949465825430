import { getWebsiteURL } from "../../utils/common"
import request from "../request"
import { baseURL, CHART_TYPE, COMPONENT, METHODS } from "../utilities/constant"
import { decryptData } from "../../utils/crypto"

export function getCookiesCardDataPieChart(cryptoKey, payload, callBackFn) {
    let websiteUrl = payload.websiteUrl;
    if (websiteUrl === "https://tokmanni-v2.stage-euw1.investis.com/") {
        websiteUrl = "https://ir.tokmanni.fi/";
    }
    else if (websiteUrl === "https://inspecs-group-plc-v2.pid2-e1.investis.com/") {
        websiteUrl = "https://www.inspecs.com/";
    }
    request(
        baseURL + `/CookieManagerV2/GetCookiePieChartData?WebsiteURL=${websiteUrl}&StartDate=${payload.startDate}&EndDate=${payload.endDate}`,
        METHODS.GET,
        {},
        { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json" }
    )
        .then((response) => {
            if (payload.profileId)
                callBackFn && callBackFn(response?.result, payload.id);
            else
                callBackFn && callBackFn(undefined, payload.id);
        })
        .catch((error) => {
            callBackFn && callBackFn(undefined, payload.id);
        })
}
export function getCardDataFromGA(cryptoKey, matrix, payload, callBackFn, component, isShort) {
    request(
        baseURL + `/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=${payload.startDate}&EndDate=${payload.endDate}&profileID=${payload.profileId}&Matrix=${matrix}&Dimensions=date&Component=${component}&isShort=${isShort}`,
        METHODS.GET,
        {},
        { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json" }
    )
        .then((response) => {
            if (payload.profileId)
                callBackFn && callBackFn(response, payload.id);
            else
                callBackFn && callBackFn(undefined, payload.id);
        })
        .catch((error) => {
            callBackFn && callBackFn(undefined, payload.id);
        })
}
export function getCardDataFromGAMap(cryptoKey, matrix, payload, callBackFn, component) {
    request(
        baseURL + `/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=${payload.startDate}&EndDate=${payload.endDate}&profileID=${payload.profileId}&Matrix=users&Dimensions=country&Component=${component}&culture=${payload.culture}`,
        METHODS.GET,
        {},
        { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json" }
    )
        .then((response) => {
            if (payload.profileId)
                callBackFn && callBackFn(response, payload.id);
            else
                callBackFn && callBackFn(undefined, payload.id);
        })
        .catch((error) => {
            callBackFn && callBackFn(undefined, payload.id);
        })
}
export function getCardDataFromGAMultiline(cryptoKey, payload, callBackFn) {
    request(
        //  baseURL + `/WebsiteAnalytics/GetCardDataFromGA?StartDate=${payload.startDate}&EndDate=${payload.endDate}&profileID=${payload.profileId}&Matrix=pageviews,users&Dimensions=date&Component=${CHART_TYPE.multilinechart}`,
        baseURL + `/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=${payload.startDate}&EndDate=${payload.endDate}&profileID=${payload.profileId}&Matrix=pageviews,users&Dimensions=date&Component=${CHART_TYPE.multilinechart}`,

        METHODS.GET,
        {},
        { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json" }
    )
        .then((response) => {
            if (payload.profileId)
                callBackFn && callBackFn(response, payload.id);
            else
                callBackFn && callBackFn(undefined, payload.id);
        })
        .catch((error) => {
            callBackFn && callBackFn(undefined, payload.id);
        })
}

export function getCookieDetailsView(cryptoKey, payload, callBackFn) {
    let websiteUrl = payload.websiteUrl;
    if (websiteUrl === "https://tokmanni-v2.stage-euw1.investis.com/") {
        websiteUrl = "https://ir.tokmanni.fi/";
    }
    else if (websiteUrl === "https://inspecs-group-plc-v2.pid2-e1.investis.com/") {
        websiteUrl = "https://www.inspecs.com/";
    }
    request(
        baseURL + `/CookieManagerV2/GetCookieMultilineChartData?WebsiteURL=${websiteUrl}&StartDate=${payload.startDate}&EndDate=${payload.endDate}`,
        METHODS.GET,
        {},
        { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json" }
    )
        .then((response) => {
            if (payload.profileId)
                callBackFn && callBackFn(response, payload.id);
            else
                callBackFn && callBackFn(undefined, payload.id);
        })
        .catch((error) => {
            callBackFn && callBackFn(undefined, payload.id);
        })
}

export function getCardDataFromGABar(cryptoKey, payload, callBackFn, component) {
    request(
        baseURL + `/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=${payload.startDate}&EndDate=${payload.endDate}&profileID=${payload.profileId}&Matrix=sessions&Dimensions=date,channelGrouping&Component=${component}`,
        METHODS.GET,
        {},
        { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json" }
    )
        .then((response) => {
            if (payload.profileId)
                callBackFn && callBackFn(response, payload.id);
            else
                callBackFn && callBackFn(undefined, payload.id);
        })
        .catch((error) => {
            callBackFn && callBackFn(undefined, payload.id);
        })
}
