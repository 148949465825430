import React, { useEffect, useState } from 'react'
import { Card, Col, OverlayTrigger, Row, Button, Tooltip, Nav, Tab } from '@themesberg/react-bootstrap'
import { useTranslation } from 'react-i18next';
import CommonMessageComponent from '../CommonMessageComponent.js';
import { DataStatusMessage } from "../../utils/constant";
import DataTableRenderer from '../DataTableRenderer.js';
import DataChartRenderer from '../DataChartRenderer.js';
import DropdownWithIconsComponent from '../../components/DropdownWithIcons';
import { METHODS, baseURL } from '../../apis/utilities/constant.js';
import request from '../../apis/request.js';
import { decryptData } from '../../utils/crypto.js';
import { TnfTableHeaders } from '../../utils/constant';
import moment from 'moment';


export const InsightsCharts = ({ cryptoKey, profileID, startDate, endDate, toolID, intelConfigID }) => {


    const [multiline, setMultiline] = useState({ transactionStatus: DataStatusMessage.Loading });

    const [tabCategory, setTabCategory] = useState(1);

    const [categoryPieChart, setCategoryPieChart] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [categoryTableChart, setCategoryTableChart] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [countryPieChart, setCountryPieChart] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [countryTableChart, setCountryTableChart] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [trafficPieChart, setTrafficPieChart] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [trafficTableChart, setTrafficTableChart] = useState({ transactionStatus: DataStatusMessage.Loading });

    const [t, i18n] = useTranslation();



    useEffect(() => {

        if (multiline.transactionStatus !== DataStatusMessage.Loading) {

            setCategoryPieChart({ transactionStatus: DataStatusMessage.Loading })
            setCountryPieChart({ transactionStatus: DataStatusMessage.Loading })
            setTrafficPieChart({ transactionStatus: DataStatusMessage.Loading })

            setCategoryTableChart({ transactionStatus: DataStatusMessage.Loading })
            setCountryTableChart({ transactionStatus: DataStatusMessage.Loading })
            setTrafficTableChart({ transactionStatus: DataStatusMessage.Loading })

            setMultiline({ transactionStatus: DataStatusMessage.Loading });

        }

        const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
        const selectedEndDate = moment(endDate).format("YYYY-MM-DD");

        request(`${process.env.REACT_APP_API_KEY_NEW}/VisitorAnalytics/GetIntelligencePageViewSeriesByProfileId?profileId=` + profileID + `&startDate=` + selectedStartDate + `&endDate=` + selectedEndDate
            + `&isTF=1&toolid=` + toolID + `&intelconfigid=` + intelConfigID,
            METHODS.GET, {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                "content-type": "application/json"
            })
            .then((response) => {

                if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                    setMultiline({ result: response.result, transactionStatus: response.transactionStatus })
                }
                else {
                    setMultiline({ result: [], transactionStatus: response ? response.transactionStatus : DataStatusMessage.ErrorOccured });
                }
            })
            .catch(() => {
                setMultiline({ result: [], transactionStatus: DataStatusMessage.ErrorOccured });
            })

    }, [toolID, startDate, endDate]);

    useEffect(() => {

        const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
        const selectedEndDate = moment(endDate).format("YYYY-MM-DD");
        if (multiline.transactionStatus !== DataStatusMessage.Loading) {
            if (tabCategory === 2 && countryPieChart.transactionStatus === DataStatusMessage.Loading) {
                request(`${process.env.REACT_APP_API_KEY_NEW}/VisitorAnalytics/GetCountryWisePageViews?type=piechart&profileid=` + profileID + `&startDate=` + selectedStartDate + `&endDate=` + selectedEndDate
                    + `&isTF=1&toolid=` + toolID + `&culture=en-US` + `&intelconfigid=` + intelConfigID,
                    METHODS.GET, {},
                    {
                        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`
                        ,
                        "content-type": "application/json"
                    })
                    .then((response) => {
                        if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                            // let dataObj = [];
                            // response.result.map((obj) => {
                            //     dataObj.push({ Title: obj.title, Count: obj.TotalOpenCount })
                            // });
                            let dataObj = [];
                            let remaining = {};
                            response.result.map((obj) => {
                                if (obj.title != 'Remaining') {
                                    dataObj.push({ Title: obj.title, Count: obj.TotalOpenCount })
                                }
                                else {
                                    remaining = { Title: 'Remaining', Count: obj.TotalOpenCount }
                                }
                            });
                            dataObj.push(remaining);
                            setCountryPieChart({ result: response.result, transactionStatus: DataStatusMessage.DataAvailable });
                            setCountryTableChart({
                                header: TnfTableHeaders.CountryPieChartTable, data: dataObj, transactionStatus: DataStatusMessage.DataAvailable
                            });
                        }
                        else {
                            setCountryPieChart({ transactionStatus: response ? response.transactionStatus : DataStatusMessage.ErrorOccured })
                        }
                    })
                    .catch(() => {
                        setCountryPieChart({ result: [], transactionStatus: DataStatusMessage.ErrorOccured });
                    })

            }
            else if (tabCategory === 3 && trafficPieChart.transactionStatus === DataStatusMessage.Loading) {
                request(`${process.env.REACT_APP_API_KEY_NEW}/VisitorAnalytics/GetTrafficSourceByProfileId?type=piechart&profileid=` + profileID + `&startDate=` + selectedStartDate + `&endDate=` + selectedEndDate
                    + `&toolid=` + toolID + `&isTF=1&culture=en-US` + `&intelconfigid=` + intelConfigID,
                    METHODS.GET, {},
                    {
                        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`
                        ,
                        "content-type": "application/json"
                    })
                    .then((response) => {
                        if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                            let dataObj = [];
                            response.result.map((obj) => {
                                dataObj.push({ Title: obj.title, Count: obj.TotalOpenCount })
                            });
                            setTrafficPieChart({ result: response.result, transactionStatus: DataStatusMessage.DataAvailable });
                            setTrafficTableChart({
                                header: TnfTableHeaders.TrafficPieChartTable, data: dataObj, transactionStatus: DataStatusMessage.DataAvailable
                            });


                        }
                        else {
                            setTrafficPieChart({ transactionStatus: response ? response.transactionStatus : DataStatusMessage.ErrorOccured })
                        }
                    })
                    .catch(() => {
                        setTrafficPieChart({ transactionStatus: DataStatusMessage.ErrorOccured });
                    })

            }
            else if (tabCategory === 1 && categoryPieChart.transactionStatus === DataStatusMessage.Loading) {
                request(`${process.env.REACT_APP_API_KEY_NEW}/VisitorAnalytics/GetCategoryWisePageViews?type=piechart&profileId=` + profileID + `&startDate=` + selectedStartDate + `&endDate=` + selectedEndDate
                    + `&isTF=1&toolid=` + toolID + `&intelconfigid=` + intelConfigID,
                    METHODS.GET, {},
                    {
                        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`
                        ,
                        "content-type": "application/json"
                    })
                    .then((response) => {
                        if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                            let dataObj = [];

                            let remaining = {};
                            response.result.map((obj) => {
                                if (obj.title != 'Remaining') {
                                    dataObj.push({ Title: obj.title, Count: obj.TotalOpenCount })
                                }
                                else {
                                    remaining = { Title: 'Remaining', Count: obj.TotalOpenCount }
                                }

                            });
                            dataObj.push(remaining);


                            setCategoryPieChart({ result: response.result, transactionStatus: response.transactionStatus });
                            setCategoryTableChart({
                                header: TnfTableHeaders.CategoryPieChartTable, data: dataObj, transactionStatus: response.transactionStatus
                            });


                        }
                        else {
                            setCategoryPieChart({ transactionStatus: response ? response.transactionStatus : DataStatusMessage.ErrorOccured })
                        }
                    })
                    .catch(() => {
                        setCategoryPieChart({ result: [], transactionStatus: DataStatusMessage.ErrorOccured });
                    })

            }
        }

    }, [tabCategory, multiline.transactionStatus]);


    const createTooltip = (tooltipKey, placement) => {
        if (tooltipKey !== "") {
            return (
                <OverlayTrigger
                    placement={placement}
                    trigger={["hover", "focus"]}
                    overlay={

                        <Tooltip>
                            <div>{tooltipKey}</div>
                        </Tooltip>
                    }
                >

                    <Button variant="white" className="btn-help border-0 p-2">
                        <i className="far fa-question-circle lg"></i>
                    </Button>
                </OverlayTrigger>
            );
        }
    }


    return (
        <>
            <div className="d-inline-block pt-3 ps-3 w-100">
                <div className="shadow-soft border rounded border-light pt-4 w-100 nested-card-container">
                    <Row>
                        <Col>
                            <h5 className="ms-3">{toolID > 0 ? <>Visitor Insights</> : <>{t("header.VisitorInsightByTool")}</>}</h5>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="tools-dashboard-card-loader">
                                <Card border="light" className="bg-white shadow-sm ms-1 p-2">
                                    <Card.Body>
                                        {
                                            multiline.transactionStatus === DataStatusMessage.DataAvailable && <DataChartRenderer
                                                data={
                                                    multiline.result
                                                }
                                                chartType="multiline"
                                                customAttributes={{ isDefaultMultilineValuesEnabled: true, titleAboveToggles: "Visitor Companies", tooltipText: "header.VisitorInsightsCardNameTooltipForTnf" }} />
                                        }
                                        {
                                            multiline.transactionStatus !== DataStatusMessage.DataAvailable &&
                                            <CommonMessageComponent transactionStatus={multiline.transactionStatus}></CommonMessageComponent>
                                        }
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col>
                            <DataChartRenderer
                                chartType="areachart"
                            />
                        </Col>
                    </Row> */}

                    <Row>
                        <Col>
                            <div className="tools-dashboard-card-loader">
                                <Card border="light" className="bg-white shadow-sm">
                                    <Card.Body>
                                        <Tab.Container defaultActiveKey="Category">
                                            <Nav fill variant="pills" className="tab subtab ms-3 me-3 mt-4">
                                                <Nav.Item className="pe-0">
                                                    <Nav.Link eventKey="Category" onClick={() => { setTabCategory(1) }} className="mb-sm-3 mb-md-0">
                                                        <span className="d-inline-block w-100 text-left tools-tab-label-text">
                                                            {t("header.VisitorsIconFilterVisitorsLabelCategoryText")}
                                                            {/* {createTooltip(t("header.linechart_summary_views_tooltip"), "right")} */}

                                                        </span>
                                                        {/* <span className="d-inline-block w-100 text-left tools-tab-count">111</span> */}
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="Country" onClick={() => { setTabCategory(2) }} className="mb-sm-3 mb-md-0">
                                                        <span className="d-inline-block w-100 text-left tools-tab-label-text">
                                                            {t("header.VisitorsIconFilterVisitorsLabelCountryText")}
                                                            {/* {createTooltip(t("header.linechart_summary_users_tooltip"), "right")} */}
                                                        </span>
                                                        {/* <span className="d-inline-block w-100 text-left tools-tab-count">222</span> */}
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="TrafficSource" onClick={() => { setTabCategory(3) }} className="mb-sm-3 mb-md-0">
                                                        <span className="d-inline-block w-100 text-left tools-tab-label-text">
                                                            {t("header.VisitorsActivityPopTrafficTabText")}
                                                            {/* {createTooltip(t("header.linechart_summary_events_tooltip"), "right")} */}
                                                        </span>
                                                        {/* <span className="d-inline-block w-100 text-left tools-tab-count">333</span> */}
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="Category" className="mt-4" >
                                                    {tabCategory === 1 && <>
                                                        {
                                                            categoryPieChart.transactionStatus === DataStatusMessage.DataAvailable &&
                                                            <Row>
                                                                <Col md={6}>
                                                                    <DataChartRenderer
                                                                        data={
                                                                            categoryPieChart.result
                                                                        }
                                                                        cardProperty={{ "x": 0, "y": 0, "w": 6, "h": 8 }} chartType="pie" />
                                                                </Col>
                                                                <Col md={6}>
                                                                    {
                                                                        categoryTableChart.transactionStatus === DataStatusMessage.DataAvailable && <DataTableRenderer
                                                                            header={categoryTableChart.header}
                                                                            dataSet={categoryTableChart.data}
                                                                            transactionStatus={categoryTableChart.transactionStatus}
                                                                            customAttributes={{ displayRowsPerPage: 15, showPagination: true }}
                                                                        ></DataTableRenderer>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {
                                                            categoryPieChart.transactionStatus !== DataStatusMessage.DataAvailable &&
                                                            <Row>
                                                                <Col md={12}>
                                                                    <CommonMessageComponent transactionStatus={categoryPieChart.transactionStatus}></CommonMessageComponent>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </>}

                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Country" className="mt-4">
                                                    {tabCategory === 2 && <>
                                                        {
                                                            countryPieChart.transactionStatus === DataStatusMessage.DataAvailable && <Row>
                                                                <Col md={6}>
                                                                    <DataChartRenderer
                                                                        data={
                                                                            countryPieChart.result
                                                                        }
                                                                        cardProperty={{ "x": 0, "y": 0, "w": 6, "h": 8 }} chartType="pie" />
                                                                </Col>
                                                                <Col md={6}>
                                                                    {
                                                                        countryTableChart.transactionStatus === DataStatusMessage.DataAvailable && <DataTableRenderer
                                                                            header={countryTableChart.header}
                                                                            dataSet={countryTableChart.data}
                                                                            transactionStatus={countryTableChart.transactionStatus}
                                                                            customAttributes={{ displayRowsPerPage: 15, showPagination: true }}
                                                                        ></DataTableRenderer>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {
                                                            countryPieChart.transactionStatus !== DataStatusMessage.DataAvailable &&
                                                            <Row>
                                                                <Col md={12}>
                                                                    <CommonMessageComponent transactionStatus={countryPieChart.transactionStatus}></CommonMessageComponent>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </>}
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="TrafficSource" className="mt-4">
                                                    {tabCategory === 3 && <>
                                                        {
                                                            trafficPieChart.transactionStatus === DataStatusMessage.DataAvailable && <Row>
                                                                <Col md={6}>
                                                                    <DataChartRenderer
                                                                        data={
                                                                            trafficPieChart.result
                                                                        }
                                                                        cardProperty={{ "x": 0, "y": 0, "w": 6, "h": 8 }} chartType="pie" />
                                                                </Col>
                                                                <Col md={6}>
                                                                    {
                                                                        trafficTableChart.transactionStatus === DataStatusMessage.DataAvailable && <DataTableRenderer
                                                                            header={trafficTableChart.header}
                                                                            dataSet={trafficTableChart.data}
                                                                            transactionStatus={trafficTableChart.transactionStatus}
                                                                            customAttributes={{ displayRowsPerPage: 15, showPagination: true }}
                                                                        ></DataTableRenderer>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {
                                                            trafficPieChart.transactionStatus !== DataStatusMessage.DataAvailable &&
                                                            <Row>
                                                                <Col md={12}>
                                                                    <CommonMessageComponent transactionStatus={trafficPieChart.transactionStatus}></CommonMessageComponent>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </>}
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>

                    {/* <Row>
                        <Col lg={12}>
                        </Col>
                    </Row> */}



                </div>
            </div>

        </>
    )

}
