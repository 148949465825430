export const userWebCastDigestList = (userId,companyId) => {
    let webcastDigestList = [
        {
            apiUrl: {url: `${process.env.REACT_APP_API_KEY}/User/GetUserWebcastDigestByUserId?CompanyId=${companyId}&UserId=${userId}`, type: 'get', count: 0},
            id: "1",
            layout: "{\"x\":0,\"y\":1,\"w\":16,\"h\":12}",
            name: "",
            summary: {},
            pagination:{show:false, query:`{}`},
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false}, \"query\":{\"measures\":[\"Videoanalytics.count\"],\"timeDimensions\":[],\"order\":{},\"dimensions\":[],\"filters\":[{\"member\":\"Videoanalytics.presentationid\",\"operator\":\"contains\",\"values\":[\"${PresentationId}\"]}]}}`,
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\": [\"Userwebcastdigest.frequency\"],\"y\": [\"measures\"],\"fillMissingDates\": true,\"joinDateRange\": false},\"query\":{\"measures\": [\"Userwebcastdigest.Finalstatus\"], \"timeDimensions\":[],\"order\":{\"Userwebcastdigest.frequency\": \"asc\"},\"dimensions\": [\"Userwebcastdigest.frequency\"],\"filters\": [{\"member\": \"Userwebcastdigest.companyid\",\"operator\": \"equals\",\"values\":[\"${companyId}"]},{\"member\": \"Userwebcastdigest.userid\",\"operator\": \"equals\",\"values\":[\"${userId}"]}]}, \"widjetName\":\"UserWecastdigest\"}`
        } 
    ];   
  return webcastDigestList;
};