import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route } from 'react-router-dom';
import App from './App';
import ScrollToTop from "./components/scrollToTop";
import './i18n';

ReactDOM.render(
  <Router>
    <ScrollToTop />
    <Suspense fallback="">
    <App />
    </Suspense>
   
    {/* <App>
      <Route key="index" exact path={["/","/dashbord"]} component={DashboardPage} />
      <Route key="form" path="/form" component={FormPage} />
    </App> */}
  </Router>, // eslint-disable-next-line no-undef
  document.getElementById('root')
);
