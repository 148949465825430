import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import AppContext from '../components/AppContext';
import { withRouter } from 'react-router-dom';
import ConnectIDLogo from "../assets/img/ConnectIDIntelligence.svg";
import { isEmpty } from "../AppConfig";
import DropdownWithIconsComponent from "../components/DropdownWithIcons";
import { header_languages } from "../AppConfig";
import { menuItemsDetais } from "../utils/sidebar";
import { useTranslation } from 'react-i18next';
import '../i18n';
import { Card, Row, Col, Form, Alert, Button, Image } from "@themesberg/react-bootstrap";
import ButtonComponent from "../components/Buttons";
import captchaImg from "../assets/img/captcha.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import LoginCountdownTimer from '../components/LoginCountdownTimer';
import { useLocation, useParams } from "react-router-dom";
import { ERROR_TIMEOUT } from "../AppConfig"
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { isNumeric } from "../AppConfig";
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import { encryptData, decryptData } from "../utils/crypto";

const LgnPage = (props) => {
    const contextData = useContext(AppContext);

    const [email, setEmail] = useState("");
    const [forgotpasswordemail, setForgotpasswordemail] = useState("");
    const [emailvalidationmessage, setEmailvalidationmessage] = useState("");
    const [forgotpasswordemailvalidationmessage, setForgotpasswordemailvalidationmessage] = useState("");
    const [password, setPassword] = useState("");
    const [passwordvalidationmessage, setPasswordvalidationmessage] = useState("");
    const [newpasswordvalidationmessage, setNewpasswordvalidationmessage] = useState("");
    const [showLoginPasswordPolicyinRed, setShowLoginPasswordPolicyinRed] = useState(false);
    const [confirmpasswordvalidationmessage, setConfirmpasswordvalidationmessage] = useState("");
    const [captchavalidationmessage, setCaptchavalidationmessage] = useState("");
    const [resetvalidationmessage, setResetvalidationmessage] = useState("");
    const [newpassword, setNewpassword] = useState("");
    const [confirmpassword, setConfirmpassword] = useState("");
    const [captcha, setCaptcha] = useState("");
    const [captchavalue, setCaptchavalue] = useState("");
    const [showMeRedirect, setShowMeRedirect] = useState(false);
    const [showMePassword, setShowMePassword] = useState(false);
    const [showMeLogin, setShowMeLogin] = useState(false);
    const [showMeInvalid, setShowMeInvalid] = useState(false);
    const [invalidcredentials, setInvalidcredentials] = useState("");
    const [showMeDetails, setShowMeDetails] = useState(false);
    const [showMeForgotpassword, setshowMeForgotpassword] = useState(false);
    const [showMeResetpassword, setshowMeResetpassword] = useState(false);
    const [showMeLoginScreen, setshowMeLoginScreen] = useState(true);
    const [selectedLanguage, setSelectedLanguage] = useState({});
    const [isLanguageChanged, setIsLanguageChanged] = useState(false);
    const [showMeMFAVerification, setShowMeMFAVerification] = useState(false);
    const [showMeOTPVerification, setShowMeOTPVerification] = useState(false);
    const [showExpireOTPMessage, setShowExpireOTPMessage] = useState(false);
    const [otpValue, setOtpValue] = useState("");
    const [otpValidationMessage, setOtpValidationMessage] = useState("");
    const [tempallvalues, setAllValues] = useState({
        token: null,
        userId: null,
        firstName: null,
        lastName: null,
        userName: null,
        companies: null,
        email: null,
        langPref: null,
        companyId: null,
        userlangPref: null,
    });
    const [tokenalive, settokenalive] = useState(null);
    const [custommessage, setCustomMessage] = useState("");
    const [reload, setReload] = useState(false);
    const [successMessage, setSuccessMessage] = useState(undefined);
    const [errorMessageTransaction, setErrorMessageTransaction] = useState(undefined);
    const [blurLoader, setBlurLoader] = useState(false);
    const [t, i18n] = useTranslation();

    const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
    const validPassword = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$');
    // let isssoLogin = false;
    // const { pathname } = useLocation();
    // const parts = pathname.split('/');
    // const emailid = parts[5];
    // const ipaddr = parts[4];
    // const ssoToken = parts[3];
    let { token } = useParams();
    const captchaRef = useRef(null);
    const passwordRef = useRef(null);
    const OTPRef = useRef(null);

    //const issso = () => {
    //    isssoLogin = true;
    //    axios
    //        .post(
    //            `${process.env.REACT_APP_API_KEY}/Home/BypassLogin`, parameter
    //        )
    //        .then(response => {
    //            if (response.data) {
    //                contextData.changeUerId(response.data.userid);
    //                contextData.changeCompanyId(response.data.companyDetail.companyId);
    //                handleSubmitbySSO();
    //            }
    //        })
    //        .catch(error => {
    //            console.log("login error from sso", error);
    //        });
    //    return <></>
    //}

    useEffect(() => {

        if (localStorage.getItem("token")) {
            localStorage.clear();
            sessionStorage.clear();
            props.history.push(localStorage.getItem("path"));
        }
        if (localStorage.getItem("mode") && localStorage.getItem("mode") === "forgotpassword") {
            ForgetPasswordClick();
            localStorage.removeItem("mode");
        }
    }, [])

    useEffect(() => {
        if (token === undefined || token === null) {
            if (contextData.userId > 0 && contextData.companyId > 0) {
                {
                    getMenu();
                }
            }
            getSelectedLanguage();
            generateString(6);
        }
        else {
            CheckToken()
            ResetPassword();
        }
    }, [contextData.userId, contextData.companyId]);


    //#region Captcha
    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const generateString = (length) => {
        let result = '';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        setCaptcha(result);
    }
    const refreshCaptcha = () => {
        let result = '';
        let length = 6;
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        setCaptcha(result);
    }
    //#endregion 

    const changeEmail = (mail) => {
        setEmail(mail);
        setShowMePassword(false);
        setShowMeLogin(false);
    }

    const onLanguageDropdownChange = (data) => {
        setSelectedLanguage(data);
        setIsLanguageChanged(true);
        i18n.changeLanguage(data.id);

        if (otpValidationMessage) {
            if (showMeMFAVerification)
                setOtpValidationMessage(t("header.LoginAuthCodeRequiredMessage"));
            else
                setOtpValidationMessage(t("header.LoginOTPRequiredMessage"));
        }
    }

    const getSelectedLanguage = () => {
        let language;
        if (i18n.language) {
            const selectedLanguagData = header_languages.find(dta => dta.id === i18n.language);
            if (selectedLanguagData && Object.keys(selectedLanguagData).length > 0) {
                language = selectedLanguagData;
                setSelectedLanguage(selectedLanguagData);
                setIsLanguageChanged(true);
            }
        }
        else {
            language = header_languages[0];
            if (contextData.languagePreference) {
                const selectedLanguagData = header_languages.find(dta => dta.id === contextData.languagePreference);
                if (selectedLanguagData && Object.keys(selectedLanguagData).length > 0)
                    language = selectedLanguagData;
            }
        }
        setSelectedLanguage(language);
    };

    const handleBlur = (event) => {
        setEmailvalidationmessage('');
        if (!email) {
            setEmailvalidationmessage(t("header.LoginEmailRequiredMessage"));
        }
        else if (!validEmail.test(email)) {
            setEmailvalidationmessage(t("header.LoginEmailInvalidMessage"));
        }
        else {
            setBlurLoader(true);
            axios.get(
                `${process.env.REACT_APP_API_KEY}/Auth/GetClientURL`,
                {
                    params: {
                        strEmail: email
                    }
                },
            )
                .then(response => {
                    setBlurLoader(false);
                    if (response.data) {
                        if (response.data.ssourl) {
                            setShowMeRedirect(true);
                            setShowMePassword(false);
                            setShowMeLogin(false);
                            setTimeout(function () {
                                window.location.href = response.data.ssourl;
                            }, 5000);
                        }
                        else {
                            setShowMeRedirect(false);
                            setShowMePassword(true);
                            setShowMeLogin(true);
                            contextData.changeUerId(response.data.userid);
                            passwordRef.current.focus();
                        }
                    }

                })
                .catch(error => {
                    setBlurLoader(false);
                    console.log("login error", error);
                });
            event.preventDefault();
        }
    }

    const ForgetPasswordClick = (event) => {
        setshowMeForgotpassword(true);
        setshowMeLoginScreen(false);
        setshowMeResetpassword(false);
        refreshCaptcha();
        setForgotpasswordemailvalidationmessage('');
        setCaptchavalidationmessage('');
        setForgotpasswordemail('');
        setCaptchavalue('');
        if (showMeResetpassword) {
            localStorage.setItem("mode", "forgotpassword");
            props.history.push("/#/login");
        }
    }

    const BacktoLogin = (event) => {
        setResetvalidationmessage('');
        setCustomMessage('');
        setshowMeForgotpassword(false);
        setshowMeLoginScreen(true);
        setshowMeResetpassword(false);
        setShowMeMFAVerification(false);
        setShowMeOTPVerification(false);
        setEmailvalidationmessage('');
        setPasswordvalidationmessage('');
        setInvalidcredentials('');
    }

    const ResetPassword = () => {
        setshowMeForgotpassword(false);
        setshowMeLoginScreen(false);
        setshowMeResetpassword(true);
        setShowLoginPasswordPolicyinRed(false);
        setNewpasswordvalidationmessage('');
        setConfirmpasswordvalidationmessage('');
        setResetvalidationmessage('');
        setNewpassword('');
        setConfirmpassword('');
    }

    const ReSendOTP = async (event) => {
        setBlurLoader(true);
        await axios.get(
            `${process.env.REACT_APP_API_KEY}/Auth/GenerateUserOTP`,
            {
                headers: {
                    'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
                },
                params: {
                    userid: tempallvalues.userId,
                    culture: i18n.language
                }
            },
        ).then(response2 => {
            setShowExpireOTPMessage(false);
            setBlurLoader(false);
            if ((response2.data.isOTPRequired == true || response2.data.isMFA === 1)) {
                GenerateUserOTP(response2);
                setTimeout(() => {
                    if (showMeMFAVerification) {
                        setSuccessMessage(t("header.ResendCodeSuccessMessage"));
                    }
                    else {
                        setSuccessMessage(t("header.ResendOtpCodeSuccessMessage"));
                    }
                }, 500);
                setTimeout(() => {
                    setSuccessMessage(undefined);
                }, ERROR_TIMEOUT);
                setReload(!reload);
            }
        }).catch(error => {
            setTimeout(() => {
                setErrorMessageTransaction(t("header.AccountSomethingWentWrong"));
            }, ERROR_TIMEOUT);
            setTimeout(() => {
                setErrorMessageTransaction(undefined);
            }, ERROR_TIMEOUT);
            setBlurLoader(false);
        });
    }

    const SubmitOTP = (event) => {

        if (!otpValue) {
            if (showMeMFAVerification)
                setOtpValidationMessage(t("header.LoginAuthCodeRequiredMessage"));
            else
                setOtpValidationMessage(t("header.LoginOTPRequiredMessage"));
        }
        else {
            const params = { IPAddress: "", OTP: otpValue, userId: tempallvalues.userId };
            const authAxios = axios.create({
                baseURL: `${process.env.REACT_APP_API_KEY}`,
                headers: {
                    'Authorization': `Bearer ${tempallvalues.token}`
                },
            });

            setBlurLoader(true);
            authAxios
                .post(
                    `/Auth/ValidateOTP`, params
                )
                .then(response => {

                    if (response.data) {
                        if (response.data.otpStatus && response.data.token && response.data.otpStatus == 1) {
                            setOtpValidationMessage('');
                            localStorage.setItem("sessionTime", new Date());
                            localStorage.setItem("token", encryptData(response.data.token, contextData.cryptoKey));
                            localStorage.setItem("userId", encryptData(tempallvalues.userId, contextData.cryptoKey));
                            localStorage.setItem("firstName", encryptData(tempallvalues.firstName, contextData.cryptoKey));
                            localStorage.setItem("lastName", encryptData(tempallvalues.lastName, contextData.cryptoKey));
                            localStorage.setItem("userName", encryptData(tempallvalues.userName, contextData.cryptoKey));
                            localStorage.setItem("email", encryptData(tempallvalues.email, contextData.cryptoKey));
                            localStorage.setItem("companies", tempallvalues.companies);
                            localStorage.setItem("langPref", tempallvalues.langPref);
                            localStorage.setItem("companyId", encryptData(tempallvalues.companyId, contextData.cryptoKey));
                            localStorage.setItem("userlangPref", tempallvalues.languagePreference);

                            contextData.changeFirstName(decryptData(localStorage.getItem("firstName"), contextData.cryptoKey));
                            contextData.changeLastName(decryptData(localStorage.getItem("lastName"), contextData.cryptoKey));
                            contextData.changeUserName(decryptData(localStorage.getItem("userName"), contextData.cryptoKey));
                            contextData.changeEmail(decryptData(localStorage.getItem("email"), contextData.cryptoKey));
                            contextData.changeUserToken(decryptData(localStorage.getItem("token"), contextData.cryptoKey));
                            contextData.changeUerId(decryptData(localStorage.getItem("userId"), contextData.cryptoKey));
                            contextData.changeLanguagePref(localStorage.getItem("langPref"));
                            contextData.changeCompanyId(decryptData(localStorage.getItem("companyId"), contextData.cryptoKey));
                            contextData.setCompanies(localStorage.getItem("companies"));
                            if (tempallvalues.companyId == 27181) {
                                localStorage.setItem("isDemoClient", 1);
                            }
                        }
                        else {
                            setBlurLoader(false);
                            if (showMeMFAVerification) {
                                setShowMeMFAVerification(true);
                                setOtpValidationMessage(t("header.LoginInvalidAuthCode"));
                            }
                            else {
                                setShowMeOTPVerification(true);
                                setOtpValidationMessage(t("header.LoginInvalidOTP"));
                            }
                        }
                    }
                    //setBlurLoader(false);
                })
                .catch(error => {
                    setBlurLoader(false);
                    if (showMeMFAVerification) {
                        setShowMeMFAVerification(true);
                        setOtpValidationMessage(t("header.LoginInvalidAuthCode"));
                    }
                    else {
                        setShowMeOTPVerification(true);
                        setOtpValidationMessage(t("header.LoginInvalidOTP"));
                    }
                });
        }
    }

    const GenerateUserOTP = (response) => {
        if (response.data) {
            if (response.data.isOTPRequired || response.data.isMFA === 1) {
                setshowMeForgotpassword(false);
                setshowMeLoginScreen(false);
                setshowMeResetpassword(false);
                response.data.isMFA == 1 ? setShowMeMFAVerification(true) : setShowMeMFAVerification(false);
                if (response.data.isMFA == 1) {
                    setShowMeOTPVerification(false);
                    OTPRef.current.focus();
                }
                else {
                    response.data.isOTPRequired ? setShowMeOTPVerification(true) : setShowMeOTPVerification(false);
                }
                setShowExpireOTPMessage(false);
                setOtpValue('');
                setOtpValidationMessage('');
            }
        }
    }

    const ClearExpireMessage = () => {
        setShowExpireOTPMessage(true);
    }

    const handleSubmit = async (event) => {
        setEmailvalidationmessage('');
        setPasswordvalidationmessage('');
        setInvalidcredentials('');
        if (!email) {
            setEmailvalidationmessage(t("header.LoginEmailRequiredMessage"));
        }
        else if (!validEmail.test(email)) {
            setEmailvalidationmessage(t("header.LoginEmailInvalidMessage"));
        }
        else if (!password) {
            setPasswordvalidationmessage(t("header.AlertErrorPasswordIsRequired"));
        }
        else {
            let EmailAddress = email;
            let Password = password;
            const UserCrdentials = { EmailAddress: EmailAddress, Password: Password };
            const response = await axios.post(`${process.env.REACT_APP_API_KEY}/Auth/ValidateLogin`, UserCrdentials);
            if (response && response.data && response.data.userId > 0) {

                const { userToken, userId, languagePreference, firstName, lastName, email, cryptoKey } = response.data;
                contextData.changeCryptoKey(cryptoKey);
                const companies = response.data.resultData;
                let companyId = companies && Array.isArray(companies) && companies.length > 0 ? companies[0].companyId : 0;
                let userName = (!isEmpty(firstName) && !isEmpty(lastName)) ? firstName.concat(lastName) : "";
                let langPref;
                if (isLanguageChanged) {
                    langPref = (selectedLanguage && Object.keys(selectedLanguage).length > 0) ? selectedLanguage.id : languagePreference;
                }
                else {
                    langPref = languagePreference;
                }
                setBlurLoader(true);

                await axios.get(
                    `${process.env.REACT_APP_API_KEY}/Auth/GenerateUserOTP`,
                    {
                        params: {
                            userid: response.data.userId,
                            culture: i18n.language
                        }
                    },
                ).then(response2 => {
                    if (response2.data) {
                        GenerateUserOTP(response2)

                        if ((response2.data.isOTPRequired == true || response2.data.isMFA == 1)) {
                            setBlurLoader(false);
                            setAllValues({
                                ...tempallvalues, token: userToken, userId: userId, firstName: firstName, lastName: lastName,
                                companies: JSON.stringify(companies), email: email, langPref: langPref, companyId: companyId, languagePreference: languagePreference
                            })
                        }
                        else {
                            localStorage.setItem("sessionTime", new Date());
                            localStorage.setItem("token", encryptData(response2.data.token, cryptoKey));
                            localStorage.setItem("userId", encryptData(userId, cryptoKey));
                            localStorage.setItem("firstName", encryptData(firstName, cryptoKey));
                            localStorage.setItem("lastName", encryptData(lastName, cryptoKey));
                            localStorage.setItem("userName", encryptData(userName, cryptoKey));
                            localStorage.setItem("email", encryptData(email, cryptoKey));
                            localStorage.setItem("companies", JSON.stringify(companies));
                            localStorage.setItem("companyId", encryptData(companyId, cryptoKey));
                            localStorage.setItem("langPref", langPref);
                            localStorage.setItem("userlangPref", languagePreference);
                            contextData.changeFirstName(firstName);
                            contextData.changeLastName(lastName);
                            contextData.changeUserName(userName);
                            contextData.changeEmail(email);
                            contextData.changeUserToken(userToken);
                            contextData.changeUerId(userId);
                            contextData.changeLanguagePref(langPref);
                            contextData.changeCompanyId(companyId);
                            contextData.setCompanies(companies);
                            if (companyId == 27181) {
                                localStorage.setItem("isDemoClient", 1);
                            }
                        }
                    }
                }).catch(error => {
                    setBlurLoader(false);
                    console.log("login error", error);
                });
            }
            else {
                setShowMeRedirect(false);
                setShowMePassword(true);
                setShowMeLogin(true);
                setShowMeInvalid(true);
                setShowMeDetails(false);
                if (response && response.data) {
                    if (response.data.loginStatus && response.data.loginStatus == 1) //Invalid login
                    {

                        if (response.data.invalidAttempt && response.data.invalidAttempt >= 3 && response.data.invalidAttempt < 5) {
                            let attempts = 5 - response.data.invalidAttempt;
                            setInvalidcredentials(t("header.LoginInvalidCredentialsWarningNew1") + attempts + t("header.LoginInvalidCredentialsWarningNew2"));
                        }
                        else {
                            setInvalidcredentials(t("header.LoginInvalidCredentials"));
                        }
                    }
                    if (response.data.loginStatus == 2) //Account Blocked
                    {
                        setInvalidcredentials(t("header.LoginAccountBlocked"));
                    }
                }

            }
        }
    }

    //const handleSubmitbySSO = async () => {
    //    let Password = '';
    //    let EmailAddress = emailid;
    //    const UserCrdentials = { EmailAddress: EmailAddress, Password: Password, isssoLogin: isssoLogin };
    //    const response = await axios.post(`${process.env.REACT_APP_API_KEY}/Auth/ValidateLogin`, UserCrdentials);
    //    const { userToken, userId, languagePreference, firstName, lastName, email, cryptoKey } = response.data;
    //    const companies = response.data.resultData;
    //    let companyId = companies && Array.isArray(companies) && companies.length > 0 ? companies[0].companyId : 0;
    //    let userName = (!isEmpty(firstName) && !isEmpty(lastName)) ? firstName.concat(lastName) : "";
    //    let langPref;
    //    if (isLanguageChanged) {
    //        langPref = (selectedLanguage && Object.keys(selectedLanguage).length > 0) ? selectedLanguage.id : languagePreference;
    //    }
    //    else {
    //        langPref = languagePreference;
    //    }
    //    contextData.changeCryptoKey(cryptoKey);
    //    localStorage.setItem("sessionTime", new Date());
    //    localStorage.setItem("token", encryptData(userToken, contextData.cryptoKey));
    //    localStorage.setItem("userId", encryptData(userId, contextData.cryptoKey));
    //    localStorage.setItem("firstName", encryptData(firstName, contextData.cryptoKey));
    //    localStorage.setItem("lastName", encryptData(lastName, contextData.cryptoKey));
    //    localStorage.setItem("userName", encryptData(userName, contextData.cryptoKey));
    //    localStorage.setItem("companies", JSON.stringify(companies));
    //    localStorage.setItem("email", encryptData(email, contextData.cryptoKey));
    //    localStorage.setItem("langPref", langPref);
    //    localStorage.setItem("userlangPref", languagePreference);
    //    contextData.changeFirstName(firstName);
    //    contextData.changeLastName(lastName);
    //    contextData.changeUserName(userName);
    //    contextData.changeEmail(email);
    //    contextData.changeUserToken(userToken);
    //    contextData.changeUerId(userId);
    //    contextData.changeLanguagePref(langPref);
    //    contextData.changeCompanyId(companyId);
    //    contextData.setCompanies(companies);
    //}

    const clickEmailLink = () => {
        setForgotpasswordemailvalidationmessage('');
        setCaptchavalidationmessage('');
        if (!forgotpasswordemail) {
            setForgotpasswordemailvalidationmessage(t("header.LoginEmailRequiredMessage"));
        }
        else if (!validEmail.test(forgotpasswordemail)) {
            setForgotpasswordemailvalidationmessage(t("header.LoginEmailInvalidMessage"));
        }
        else if (!captchavalue) {
            setCaptchavalidationmessage(t("header.LoginCaptchaRequiredMessage"));
        }
        else if (captchavalue != captcha) {
            setCaptchavalidationmessage(t("header.LoginInvalidCaptchaMessage"));
        }
        else {
            setBlurLoader(true);
            axios
                .get(
                    `${process.env.REACT_APP_API_KEY}/Auth/ForgotPassword`,
                    {
                        params: {
                            EmailAddress: forgotpasswordemail,
                            culture: selectedLanguage.id,
                        }
                    },
                )
                .then(response => {
                    if (response) {
                        setForgotpasswordemail('');
                        setCaptchavalue('');
                        if (response.data.status) {
                            setSuccessMessage(t("header.ResetPasswordSuccessMessage"));
                            setTimeout(() => {
                                setSuccessMessage(undefined);
                                BacktoLogin();
                            }, ERROR_TIMEOUT);
                        }
                        else if (response.data.isBlocked) {
                            setErrorMessageTransaction(t("header.ResetPasswordUserBlockedMessage"));
                            setTimeout(() => {
                                setErrorMessageTransaction(undefined);
                            }, ERROR_TIMEOUT);
                        }
                        else {
                            setErrorMessageTransaction(t("header.ResetPasswordUserNotExistMessage"));
                            setTimeout(() => {
                                setErrorMessageTransaction(undefined);
                            }, ERROR_TIMEOUT);
                        }
                    }
                    else {
                        setErrorMessageTransaction(t("header.AlertSomethingWentWrong"));
                        setTimeout(() => {
                            setErrorMessageTransaction(undefined);
                        }, ERROR_TIMEOUT);
                    }
                    refreshCaptcha();
                    setBlurLoader(false);
                })
                .catch(error => {
                    setErrorMessageTransaction(t("header.AlertSomethingWentWrong"));
                    setTimeout(() => {
                        setErrorMessageTransaction(undefined);
                    }, ERROR_TIMEOUT);
                    refreshCaptcha();
                    setBlurLoader(false);
                });
        }
    }

    const PasswordReset = () => {
        setNewpasswordvalidationmessage('');
        setConfirmpasswordvalidationmessage('');
        setResetvalidationmessage('');
        setShowLoginPasswordPolicyinRed(false);
        if (!newpassword) {
            setNewpasswordvalidationmessage(t("header.ForgetNewPwdRequiredMessage"));
        }
        else if (!validPassword.test(newpassword)) {
            setNewpasswordvalidationmessage(t("header.ResetPasswordErrorMessage1"))
        }
        else if (!confirmpassword) {
            setConfirmpasswordvalidationmessage(t("header.ForgetConfirmPwdRequiredMessage"));
        }
        else if (!validPassword.test(confirmpassword)) {
            setConfirmpasswordvalidationmessage(t("header.ResetPasswordErrorMessage2"))
        }
        else if (newpassword != confirmpassword) {
            setConfirmpasswordvalidationmessage(t("header.ForgetPwdMatchMessage"));
        }
        else {
            setBlurLoader(true);
            const UserCrdentials = {
                Token: token,
                Password: newpassword
            };
            axios.post(
                `${process.env.REACT_APP_API_KEY}/Auth/ChangePasswordWithoutLogin`,
                UserCrdentials
            )
                .then(response => {
                    if (response && response.data) {
                        setNewpassword('');
                        setConfirmpassword('');
                        if (response.data.status) {
                            setSuccessMessage(t("header.LoginPasswordResetSuccess"));
                            setTimeout(() => {
                                setSuccessMessage(undefined);
                                props.history.push("/#/login");
                            }, ERROR_TIMEOUT);
                        }
                        else {
                            if (response.data.message == 'samePass') {
                                setErrorMessageTransaction(t("header.LoginPasswordSameWarning"));
                            } else {
                                setErrorMessageTransaction(t("header.LoginPasswordResetFailure"));
                            }
                            setTimeout(() => {
                                setErrorMessageTransaction(undefined);
                            }, ERROR_TIMEOUT);
                        }
                    }
                    setBlurLoader(false);
                })
                .catch(error => {
                    setErrorMessageTransaction(t("header.LoginPasswordResetFailure"));
                    setTimeout(() => {
                        setErrorMessageTransaction(undefined);
                    }, ERROR_TIMEOUT);
                    setBlurLoader(false);
                });
        }
    }

    const getMenu = () => {
        //1stroundchange change in whole method
        // setBlurLoader(true)
        axios
            .get(
                `${process.env.REACT_APP_API_KEY}/UserV2/GetMainMenuByClientUser`,
                {
                    params: {
                        userId: contextData.userId,
                        companyid: contextData.companyId,
                        websiteid: contextData.websiteId
                    },
                    headers: {
                        'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
                    }
                },
            )
            .then(response => {
                if (response && response.data && response.data.resultData.menuDetails) {
                    //let menuDta = getMenusAndSubMenuDetails(response.data.resultData.menuDetails);
                    // contextData.setMenu(menuDta);
                    let menuDta = response.data.resultData.menuDetails;
                    menuDta = menuDta.filter((x) => { return x.id !== 4 && x.id !== 13 && x.id !== 14 })



                    if (Array.isArray(menuDta) && menuDta.length > 0) {
                        let defaultMenuData = menuDta.filter(dta => dta.isActive === true)[0];

                        if (defaultMenuData) {
                            let settingDta = {
                                id: 0,
                                dispIndex: 13,
                                isActionPoint: false,
                                isActive: true,
                                subMenu: [],
                                subSections: null,
                                title: "Settings"
                            };
                            menuDta.push(settingDta);
                            contextData.setMenu(menuDta);
                        }
                        else {
                            let submenuarray = [{ actionParam: "", actionType: 1, dispIndex: 0, id: 1, isActionPoint: true, isActive: true, isEnabled: true, title: "My account" },
                            { actionParam: "", actionType: 1, dispIndex: 1, id: 2, isActionPoint: true, isActive: true, isEnabled: true, title: "My documents" },
                            { actionParam: "", actionType: 1, dispIndex: 2, id: 3, isActionPoint: true, isActive: true, isEnabled: true, title: "Digest subscriptions" }];

                            let settingDta = {
                                id: 0,
                                dispIndex: 13,
                                isActionPoint: false,
                                isActive: true,
                                subMenu: submenuarray,
                                subSections: null,
                                title: "Settings"
                            };
                            menuDta.push(settingDta);

                            // newobject.subMenu = submenuarray;
                            contextData.setMenu(menuDta);

                            localStorage.setItem("selectedTab", "Settings")
                            contextData.changeSelectedTab("Settings");
                            props.history.push(`/settings/myaccount-page`);
                        }



                        if (defaultMenuData.id === 1) {
                            localStorage.setItem("selectedTab", "Website")
                            contextData.changeSelectedTab("Website");
                            props.history.push(`/website`);
                        }
                        else if (defaultMenuData.id === 8) {
                            request(baseURL + `/Intelligence/GetAlertsChannelWebsiteByClientUser?companyid=` + contextData.companyId,
                                METHODS.GET,
                                {},
                                {
                                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                                })
                                .then((res) => {

                                    if (res && res.result.length > 0) {

                                        let wid = res.result[0].WebsiteId;
                                        request(baseURL + `/UserV2/GetSubMenuByClientUser?companyId=${contextData.companyId}&sectionid=` + defaultMenuData.id + `&websiteid=${wid}`,
                                            METHODS.GET,
                                            {},
                                            {
                                                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                                            })
                                            .then((res) => {
                                                let menuDta = res[0].subSections;

                                                let subMenuDetails = menuItemsDetais.find(men => men.title === menuDta[0].actions[0].title);

                                                if (subMenuDetails.link) {
                                                    localStorage.setItem("selectedTab", "Alerts")
                                                    contextData.changeSelectedTab("Alerts");
                                                    props.history.push(subMenuDetails.link);
                                                }
                                                else {
                                                    props.history.push(`/website`);
                                                }
                                            })
                                            .catch((err) => {

                                            })

                                    }
                                    else {
                                        localStorage.setItem("selectedTab", "Alerts")
                                        contextData.changeSelectedTab("Alerts");
                                        props.history.push("/alert-marketing");
                                    }
                                })
                                .catch((err) => {

                                })
                        }
                        else if (defaultMenuData.id === 5) {

                            request(baseURL + `/UserV2/GetSubMenuByClientUser?companyId=${contextData.companyId}&sectionid=` + defaultMenuData.id + `&websiteid=0`,
                                METHODS.GET,
                                {},
                                {
                                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                                })
                                .then((res) => {
                                    let menuDta = res[0].subSections;

                                    let subMenuDetails = menuItemsDetais.find(men => men.title === menuDta[0].actions[0].title);

                                    if (subMenuDetails.link) {
                                        localStorage.setItem("selectedTab", "Webcasting & Video")
                                        contextData.changeSelectedTab("Webcasting & Video");
                                        props.history.push(subMenuDetails.link);
                                    }
                                    else {
                                        props.history.push(`/website`);
                                    }
                                })
                                .catch((err) => {

                                })


                        }
                        else if (defaultMenuData.id === 6) {
                            request(baseURL + `/Intelligence/GetToolsWebsiteForClientUser?companyid=` + contextData.companyId,
                                METHODS.GET,
                                {},
                                {
                                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                                })
                                .then((res) => {
                                    if (res && res.result.length > 0) {

                                        let wid = res.result[0].WebsiteId;
                                        request(baseURL + `/UserV2/GetSubMenuByClientUser?companyId=${contextData.companyId}&sectionid=` + defaultMenuData.id + `&websiteid=${wid}`,
                                            METHODS.GET,
                                            {},
                                            {
                                                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                                            })
                                            .then((res) => {
                                                let menuDta = res[0].subSections;

                                                let subMenuDetails = menuItemsDetais.find(men => men.title === menuDta[0].actions[0].title);

                                                if (subMenuDetails.link) {
                                                    localStorage.setItem("selectedTab", "Tools")
                                                    contextData.changeSelectedTab("Tools");
                                                    props.history.push(subMenuDetails.link);
                                                }
                                                else {
                                                    props.history.push(`/website`);
                                                }
                                            })
                                            .catch((err) => {

                                            })

                                    }
                                    else {
                                        localStorage.setItem("selectedTab", "Alerts")
                                        contextData.changeSelectedTab("Alerts");
                                        props.history.push("/tools-marketing");
                                    }

                                })
                                .catch((err) => {

                                })
                        }
                        else if (defaultMenuData.id === 10) {
                            localStorage.setItem("selectedTab", "Reports")
                            contextData.changeSelectedTab("Reports");
                            props.history.push(`/reports`);
                        }
                        else if (defaultMenuData.id === 11) {
                            localStorage.setItem("selectedTab", "Service Centre")
                            contextData.changeSelectedTab("Service Centre");
                            props.history.push(`/service-centre-pages`);
                        }


                        // if (Array.isArray(defaultMenuData.subMenu) && defaultMenuData.subMenu.length > 1) {
                        //     let defaultSubMenuData = defaultMenuData.subMenu.filter(dta => dta.isActive === true)[0];
                        //     const subMenuDetails = menuItemsDetais.find(dta => dta.title === defaultSubMenuData.title);
                        //     if (subMenuDetails && Object.keys(subMenuDetails).length > 0)
                        //         props.history.push(`${subMenuDetails.link}`);
                        // }
                        // else if (defaultMenuData) {
                        //     const menuDta = menuItemsDetais.find(dta => dta.title === defaultMenuData.title);
                        //     if (menuDta && Object.keys(menuDta).length > 0)
                        //         props.history.push(`${menuDta.link}`);
                        // }
                    }
                }
                else {
                    setShowMeRedirect(false);
                    setShowMePassword(true);
                    setShowMeLogin(true);
                    setShowMeInvalid(true);
                    setShowMeDetails(false);
                }
            })
            .catch(error => {
                console.log("login error", error);
            });
    }

    const CheckToken = () => {
        const tokenobj = {
            QueryStringToken: token
        };
        axios.post(
            `${process.env.REACT_APP_API_KEY}/Home/ValidateForgotPasswordToken`,
            tokenobj
        )
            .then(response => {

                if (response && response.data.isValidToken === true) {
                    settokenalive(true);
                }
                else {
                    settokenalive(false);
                }
                ResetPassword();
                getSelectedLanguage();
            })
            .catch(error => {
                console.log("login error", error);
            });
    }

    const onCloseSuccessMessage = () => {
        setSuccessMessage(undefined)
    };

    const onCloseErrorMessage = () => {
        setErrorMessageTransaction(undefined)
    };

    const contactUS = () => {
        window.open(
            "mailto:" +
            "clients@idx.inc" +
            "?cc=" +
            "&subject=Query for Connect.ID Intelligence" +
            "&body=" +
            ""
        );
    };

    const countdownTimer = useMemo(
        () => (
            <LoginCountdownTimer reload={reload} timeoutExpired={ClearExpireMessage} ></LoginCountdownTimer>
        ),
        [reload]
    );

    return (
        // parts[2] == 'isSSO' ? issso() :
        <section className="d-flex align-items-center">
            <div className="container">
                <Row>
                    <Col>
                        {blurLoader && <div id='blurdiv' className="blurbackground">
                            <div className={`preloader loader-center-align`}>
                                <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                            </div>
                        </div>}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {(successMessage || errorMessageTransaction) && <div className="fade modal-backdrop show"></div>}
                        <Alert
                            className="toast-custom"
                            variant="success"
                            show={successMessage ? true : false}
                            onClose={() => onCloseSuccessMessage()}
                        >
                            <div className="d-flex justify-content-between">
                                <div>
                                    <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                                    {successMessage}
                                </div>
                                <Button variant="close" size="xs" onClick={() => onCloseSuccessMessage()} />
                            </div>
                        </Alert>
                        <Alert
                            className="toast-custom"
                            variant="danger"
                            show={errorMessageTransaction ? true : false}
                            onClose={() => onCloseErrorMessage()}
                        >
                            <div className="d-flex justify-content-between">
                                <div>
                                    <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                                    {errorMessageTransaction}
                                </div>
                                <Button variant="close" size="xs" onClick={() => onCloseErrorMessage()} />
                            </div>
                        </Alert>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xs={12} className="d-flex align-items-center justify-content-center">
                        <div className="shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500 login-box">
                            <div className="text-center text-md-center mb-4 mt-md-0">
                                <img src={ConnectIDLogo} className="login-page-logo" />
                                {showMeLoginScreen ? <p className="login-page-title mt-4 mb-4">{t("header.LoginButtonTitleNew")}</p> : null}
                                {showMeForgotpassword ? <p className="login-page-title mt-4 mb-4 hide-element">{t("header.LoginForgetMainTitle")}</p> : null}
                                {showMeResetpassword ? <p className="login-page-title mt-4 mb-4 hide-element">{t("header.LoginResetMainTitle")}</p> : null}
                                {(showMeMFAVerification || showMeOTPVerification) ? <p className="login-page-title mt-4 mb-4 hide-element">{t("header.LoginVerificationTitle")}</p> : null}
                            </div>
                            <div className="mt-4">
                                {(showMeLoginScreen || showMeMFAVerification || showMeOTPVerification || showMeForgotpassword || (showMeResetpassword && tokenalive)) ?
                                    <Form.Group className="mb-3">
                                        <DropdownWithIconsComponent
                                            dropdownData={header_languages}
                                            arrayValue="title"
                                            primaryIcon="Globe"
                                            primaryIconType="icon"
                                            primaryIconCustom={true}
                                            selectedDropdown={selectedLanguage}
                                            onChange={onLanguageDropdownChange}
                                            showLabel={false}
                                            showIconInDropdownItem={false}
                                            isPrimaryImage={true}
                                            customClassName="full-width"
                                        />
                                    </Form.Group> : null}
                                {showMeForgotpassword ?
                                    <Form.Group className="mb-3 hide-element">
                                        <Form.Label className="mb-0">{t("header.LoginInputEmailMessage")}</Form.Label>
                                    </Form.Group> : null}
                                {showMeLoginScreen ?
                                    <Form.Group>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            placeholder={t("header.LoginEmailPlaceholder")}
                                            value={email}
                                            onChange={(e) => changeEmail(e.target.value)}
                                            required
                                            autocomplete="on"
                                            onKeyPress={(event) => {
                                                if (event.key === 'Enter') {
                                                    handleBlur(event);
                                                }
                                            }
                                            }
                                        />
                                        <div className="invalid-feedback d-block">
                                            {emailvalidationmessage}
                                        </div>
                                        {showMePassword === false &&
                                            <ButtonComponent
                                                isIcon={false}
                                                isPrimary={true}
                                                btnText={t("header.LoginNext")}
                                                type="submit"
                                                btn_class="w-100 mt-3 hide-element"
                                                btn_size="lg"
                                                onClick={handleBlur}
                                            />}
                                    </Form.Group> : null}
                                {showMeForgotpassword ?
                                    <Form.Group>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            placeholder={t("header.LoginEmailPlaceholder")}
                                            value={forgotpasswordemail}
                                            onChange={(e) => setForgotpasswordemail(e.target.value)}
                                            required
                                            autocomplete="off"
                                            onKeyPress={(event) => {
                                                if (event.key === 'Enter') {
                                                    // Move focus to the next input field if email has a value
                                                    if (forgotpasswordemail) {
                                                        captchaRef.current.focus();
                                                    } else {
                                                        clickEmailLink(event);
                                                    }
                                                }
                                            }}
                                        />
                                        <div className="invalid-feedback d-block">
                                            {forgotpasswordemailvalidationmessage}
                                        </div>
                                    </Form.Group> : null}
                                {showMeForgotpassword ?
                                    <Form.Group>
                                        <Form.Control
                                            type="text"
                                            name="captcha"
                                            placeholder={t("header.LoginCaptchaPlaceholder")}
                                            value={captchavalue}
                                            onChange={(e) => setCaptchavalue(e.target.value)}
                                            required
                                            autocomplete="off"
                                            className="mt-3 hide-element"
                                            ref={captchaRef}
                                            onKeyPress={(event) => {
                                                if (event.key === 'Enter') {
                                                    clickEmailLink(event);
                                                }
                                            }}
                                        />
                                        <div className="invalid-feedback d-block">
                                            {captchavalidationmessage}
                                        </div>
                                    </Form.Group> : null}
                                {showMeForgotpassword ?
                                    <Form.Group>
                                        <h4 id="captcha" style={{ marginTop: "25px", marginLeft: "25px", position: "absolute" }}>{captcha}</h4>
                                        <img src={captchaImg} className="mt-3 mb-3" height="50" />
                                        &nbsp; <FontAwesomeIcon icon={faSync} onClick={refreshCaptcha} />
                                    </Form.Group> : null}

                                {showMeMFAVerification ?
                                    <Form.Group id="MFA-Verification" className="mt-2 text-left hide-element">
                                        <Form.Label>
                                            {t("header.LoginMFAuthMessage")} {!showExpireOTPMessage && t("header.LoginMFAuthLine2Message")}<br></br>
                                            {!showExpireOTPMessage ?
                                                <p className="invalid-feedback d-block">{t("header.LoginOTPExpiringInText")} {countdownTimer} </p>
                                                : <p className="invalid-feedback d-block">{t("header.LoginMFAExpiredMessage")}</p>
                                            }
                                        </Form.Label>
                                    </Form.Group> : null}

                                {showMeOTPVerification ?
                                    <Form.Group id="OTP-Verification" className="mt-2 text-left hide-element">
                                        <Form.Label>
                                            {t("header.LoginOTSessionPwdMessage")} {!showExpireOTPMessage && t("header.LoginOTSessionPwdLine2Message")}<br></br>
                                            {!showExpireOTPMessage ?
                                                <p className="invalid-feedback d-block">{t("header.LoginOTPExpiringInText")} {countdownTimer} </p>
                                                : <p className="invalid-feedback d-block">{t("header.LoginMFAExpiredMessage")}</p>
                                            }
                                        </Form.Label>
                                    </Form.Group> : null}

                                {(showMeMFAVerification || showMeOTPVerification) ?
                                    <Form.Group>
                                        <Form.Control
                                            type="text"
                                            name="otp"
                                            maxLength={6}
                                            placeholder={showMeMFAVerification ? t("header.LoginAuthCodePlaceholderNew") : t("header.LoginOTPMessageNew")}
                                            value={otpValue}
                                            onChange={(e) => {
                                                if (isNumeric(e.target.value)) {
                                                    setOtpValue(e.target.value)
                                                }
                                            }}
                                            required
                                            autocomplete="off"
                                            ref={OTPRef}
                                            onKeyPress={(event) => {
                                                if (event.key === 'Enter') {
                                                    SubmitOTP(event);
                                                }
                                            }}
                                        />
                                        <div className="invalid-feedback d-block">
                                            {otpValidationMessage}
                                        </div>
                                    </Form.Group> : null}

                                {
                                    showMeRedirect ?
                                        <div>
                                            <Form.Group id="email" className="mb-3">
                                                <Form.Label>{t("header.redirectToSSOPage")}</Form.Label>
                                            </Form.Group>
                                        </div>
                                        : null
                                }
                                {
                                    showMePassword && showMeLoginScreen ?
                                        <div>

                                            <Form.Group className="mt-3 mb-3">
                                                <Form.Control
                                                    type="password"
                                                    name="password"
                                                    placeholder={t("header.LoginPasswordPlaceholder")}
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    autoComplete="off"
                                                    required
                                                    ref={passwordRef}
                                                    onKeyPress={(event) => {
                                                        if (event.key === 'Enter') {
                                                            handleSubmit(event);
                                                        }
                                                    }}
                                                />
                                                <div className="invalid-feedback d-block">
                                                    {passwordvalidationmessage}
                                                </div>
                                            </Form.Group>
                                        </div>
                                        : null
                                }
                                {
                                    showMeResetpassword && tokenalive ?
                                        <div>

                                            <Form.Group className="mt-3 mb-3">
                                                <Form.Control
                                                    type="password"
                                                    name="newpassword"
                                                    placeholder={t("header.ForgetNewPwdPlaceholder")}
                                                    value={newpassword}
                                                    onChange={(e) => setNewpassword(e.target.value)}
                                                    required
                                                />
                                                <div className="invalid-feedback d-block">
                                                    {newpasswordvalidationmessage}
                                                </div>
                                            </Form.Group>
                                        </div>
                                        : null
                                }
                                {
                                    showMeResetpassword && tokenalive ?
                                        <div>

                                            <Form.Group className="mt-3 mb-3">
                                                <Form.Control
                                                    type="password"
                                                    name="confirmpassword"
                                                    placeholder={t("header.ForgetConfirmPwdPlaceholder")}
                                                    value={confirmpassword}
                                                    onChange={(e) => setConfirmpassword(e.target.value)}
                                                    required
                                                />
                                                <div className="invalid-feedback d-block">
                                                    {confirmpasswordvalidationmessage}
                                                </div>
                                            </Form.Group>
                                        </div>
                                        : null
                                }
                                {showMeLogin && showMeLoginScreen ?
                                    <div>
                                        <ButtonComponent
                                            isIcon={false}
                                            isPrimary={true}
                                            btnText={t("header.LoginButtonTitleNew")}
                                            type="submit"
                                            btn_class="w-100"
                                            btn_size="lg"
                                            onClick={(e) => handleSubmit(e)}
                                        />
                                        < div className="invalid-feedback d-block">
                                            {invalidcredentials}
                                        </div>
                                    </div> : null
                                }
                                {showMeResetpassword && tokenalive ?
                                    <div>
                                        <ButtonComponent

                                            isIcon={false}
                                            isPrimary={true}
                                            btnText={t("header.LoginChangePwdButtonReset")}
                                            type="submit"
                                            btn_class="w-100 mt-3 hide-element"
                                            btn_size="lg"
                                            onClick={(e) => PasswordReset(e)}
                                        />
                                        < div className="invalid-feedback d-block">
                                            {resetvalidationmessage}
                                        </div>
                                    </div> : null
                                }
                                {showMeForgotpassword ?
                                    <div> <ButtonComponent

                                        isIcon={false}
                                        isPrimary={true}
                                        btnText={t("header.LoginEmailLinkTitleNew")}
                                        type="submit"
                                        btn_class="w-100 mt-3 hide-element"
                                        btn_size="lg"
                                        onClick={(e) => clickEmailLink()}
                                    />  < div className="invalid-feedback d-block">
                                            {resetvalidationmessage}
                                        </div>
                                    </div> : null}
                                {(showMeMFAVerification || showMeOTPVerification) ?
                                    <ButtonComponent
                                        isIcon={false}
                                        isPrimary={true}
                                        btnText={t("header.LoginSubmitTitle")}
                                        type="submit"
                                        btn_class="w-100 mt-3 hide-element"
                                        btn_size="lg"
                                        onClick={(e) => SubmitOTP(e)}
                                    /> : null}
                                {showMeResetpassword ?
                                    <div >
                                        {tokenalive === true && <Form.Group id="forgot-pswd" className={showLoginPasswordPolicyinRed ? 'p-3 mb-2 bg-danger text-white' : 'mt-5 text-left hide-element'}>
                                            <Form.Label>
                                                {t("header.LoginPasswordPolicyNew")}
                                            </Form.Label>
                                        </Form.Group>}
                                        {tokenalive === false && <div className="p-3 mb-2 bg-danger text-white" role="alert">
                                            {t("header.SMPublishErrorTokenExpired") + "..!!"}
                                        </div>}    </div> : null}
                                {(showMeMFAVerification) ?
                                    <Form.Group id="back-to-login" className="mt-2 text-center hide-element">
                                        <a onClick={(e) => ReSendOTP()} className="text-center">{t("header.LoginAuthCodeResendMessage")}</a>
                                    </Form.Group> : null}
                                {(showMeOTPVerification) ?
                                    <Form.Group id="back-to-login" className="mt-2 text-center hide-element">
                                        <a onClick={(e) => ReSendOTP()} className="text-center">{t("header.LoginOTPResendMessage")}</a>
                                    </Form.Group> : null}
                                {(showMeForgotpassword || (showMeResetpassword && (resetvalidationmessage === t("header.LoginPasswordResetSuccess") || !tokenalive)) || (showMeMFAVerification || showMeOTPVerification)) ?
                                    <Form.Group id="back-to-login" className="mt-2 text-center hide-element">
                                        <a onClick={(e) => showMeResetpassword && !tokenalive ? ForgetPasswordClick() : BacktoLogin()} className="text-center">{showMeResetpassword && !tokenalive ? t("header.LoginPasswordResetBackToForgotPassword") : t("header.LoginPasswordResetBackToLogin")}</a>
                                    </Form.Group> : null}
                                {showMeLoginScreen ?
                                    <Form.Group id="forgot-pswd" className="mt-6 text-center">
                                        <a onClick={(e) => ForgetPasswordClick()} className="text-center">{t("header.LoginForgetTitle")}</a>
                                    </Form.Group> : null}
                                {showMeLoginScreen ?
                                    <Form.Group id="forgot-pswd" className="mt-2 text-center">
                                        <Form.Label>
                                            {t("header.LoginTroubleMessage")}
                                            <Card.Link className="ms-1" onClick={contactUS}>{t("header.ContactUs")}</Card.Link>
                                        </Form.Label>
                                    </Form.Group> : null}
                                {custommessage !== '' &&
                                    <div className="text-center invalid-feedback d-block">
                                        {custommessage}
                                    </div>
                                }
                                {
                                    showMeDetails ?
                                        <div className="mt-2">
                                            <input type="textarea"
                                                name="textValue"
                                                className="form-control"
                                            />
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section >
    );
};

export default withRouter(LgnPage);