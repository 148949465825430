export const urls = [
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetIntelligenceTrafficSourceByVisitor?profileid=312109640%20%20%20%20%20%20&startdate=2024-3-8&enddate=2024-9-3&countryid=404&companyid=435076&selectedcompanyid=27181&getvalues=1",
    "https://intelligence-qa-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2024-03-08&EndDate=2024-09-03&profileID=312109640&Matrix=users&Dimensions=date&Component=users&isShort=0",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetTrafficSourceByPage?profileid=312109640%20%20%20%20%20%20%20%20&startdate=2024-3-8&enddate=2024-9-3&pageid=97469240&companyid=27181&packageid=5&getvalues=1",
    "https://intelligence-qa-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2024-03-08&EndDate=2024-09-03&profileID=312109640&Matrix=sessions&Dimensions=date&Component=audiencetrend&isShort=1",
    "https://intelligence-qa-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2024-03-08&EndDate=2024-09-03&profileID=312109640&Matrix=users&Dimensions=date&Component=users&isShort=1",
    "https://intelligence-qa-api.connectid.dev/Analytics/GetInvestisLiveFilterData?device=&domain=&viewer=unique&viewerType=&clientSlug=investis-digital&presentationId=5f69d4b0d3673f10000a071b&presentationSlug=think-future&fetchLatest=true&isExport=false&generateLatest=false&companyid=9345&clienttype=VE",
    "https://intelligence-qa-api.connectid.dev/Analytics/GetInvestisLiveDomainDetail?domainType=1&viewerType=all&isEvent=false&clientSlug=investis-digital&presentationId=5f69d4b0d3673f10000a071b&presentationSlug=think-future&fetchLatest=true&isExport=false&generateLatest=false&companyid=9345&clienttype=VE&type=table",
    "https://intelligence-qa-api.connectid.dev/Analytics/GetInvestisLiveDomainDetail?domainType=1&viewerType=all&isEvent=false&clientSlug=investis-digital&presentationId=5f69d4b0d3673f10000a071b&presentationSlug=think-future&fetchLatest=true&isExport=false&generateLatest=false&companyid=9345&clienttype=VE&type=piechart",
    "https://intelligence-qa-api.connectid.dev/Analytics/GetPollDetail?presentationId=5f69d4b0d3673f10000a071b",
    "https://intelligence-qa-api.connectid.dev/Analytics/GetInvestisLiveQuetions?clientSlug=investis-digital&presentationId=5f69d4b0d3673f10000a071b&presentationSlug=think-future&skip=0&providerName=InvestisLive&searchString=&clienttype=VE&approvedFor=",
    "https://intelligence-qa-api.connectid.dev/Analytics/GetRegisteredUsers?eventId=5f69cb59fe4b5e0a000c315e",
    "https://intelligence-qa-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2024-03-03&EndDate=2024-08-29&profileID=312109640&Matrix=screenPageViews,averageSessionDuration,bounceRate,totalUsers&Dimensions=pageTitle,pagePath&Component=table,contentinsights,website",
    "https://intelligence-qa-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2024-03-03&EndDate=2024-08-29&profileID=312109640&Matrix=users&Dimensions=country&Component=table,country&culture=en-US",
    "https://intelligence-qa-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2024-03-03&EndDate=2024-08-29&profileID=312109640&Matrix=users&Dimensions=date&Component=users",
    "https://intelligence-qa-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2024-03-03&EndDate=2024-08-29&profileID=312109640&Matrix=searchResultViews&Dimensions=searchKeyword&Component=table,search",
    "https://intelligence-qa-api.connectid.dev/CookieManagerV2/GetCookiePieChartData?WebsiteURL=https://www.justgroupplc.co.uk/&StartDate=2024-03-03&EndDate=2024-08-29",
    "https://intelligence-qa-api.connectid.dev/WebsiteAnalytics/GetWatchedVisitorByWebsite?profileid=312109640&intellConfigId=31721%20%20%20%20%20%20%20%20%20%20%20%20&startDate=2024-03-03&endDate=2024-08-29&companyid=9345&packageid=5&isshort=1",
    "https://intelligence-qa-api.connectid.dev/WebsiteAnalytics/GetVisitorWithWatchlistByProfileId?profileid=312109640&intellConfigId=31721%20%20%20%20%20%20%20%20%20%20%20%20&startDate=2024-03-03&endDate=2024-08-29&companyid=9345&packageid=5&isshort=1",
    "https://intelligence-qa-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2024-03-03&EndDate=2024-08-29&profileID=312109640&Matrix=sessions&Dimensions=date,channelGrouping&Component=trafficsource",
    "https://intelligence-qa-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2024-03-03&EndDate=2024-08-29&profileID=312109640&Matrix=sessions&Dimensions=date&Component=audiencetrend",
    "https://intelligence-qa-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2024-03-03&EndDate=2024-08-29&profileID=312109640&Matrix=pageviews,users&Dimensions=date&Component=multilinechart",
    "https://intelligence-qa-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2024-03-03&EndDate=2024-08-29&profileID=312109640&Matrix=screenPageViews,averageSessionDuration,bounceRate,totalUsers&Dimensions=pageTitle,pagePath&Component=table,contentinsights",
    "https://intelligence-qa-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2024-03-03&EndDate=2024-08-29&profileID=312109640&Matrix=users&Dimensions=country&Component=table,country&culture=en-US",
    "https://intelligence-qa-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2024-03-03&EndDate=2024-08-29&profileID=312109640&Matrix=users&Dimensions=date&Component=users",
    "https://intelligence-qa-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2024-03-03&EndDate=2024-08-29&profileID=312109640&Matrix=searchResultViews,pageviews&Dimensions=searchKeyword&Component=table,search",
    "https://intelligence-qa-api.connectid.dev/CookieManagerV2/GetCookieAdditionalData?WebsiteURL=https://www.justgroupplc.co.uk/&StartDate=2024-03-03&EndDate=2024-08-29",
    "https://intelligence-qa-api.connectid.dev/CookieManagerV2/GetCookieMultilineChartData?WebsiteURL=https://www.justgroupplc.co.uk/&StartDate=2024-03-03&EndDate=2024-08-29",
    "https://intelligence-qa-api.connectid.dev/WebsiteAnalytics/GetNotification?profileId=312109640&packageid=5&companyid=9345&intellconfigid=31721",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetResolvedUnresolvedVisitorsCountByProfileId?endDate=2024-9-1&startDate=2024-3-6&profileId=312109640&companyid=9345",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetWebsiteInsightSummaryByProfileId?%20%20%20%20%20%20endDate=2024-9-1&isAnalyticsRequired=false&profileId=312109640&startDate=2024-3-6",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetIntelligencePageViewSeriesByProfileId?profileId=312109640&sectionid=0&startDate=2024-03-06&endDate=2024-09-01&packageid=5",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetCategoryWisePageViews?type=piechart&profileid=312109640&startDate=2024-03-06&endDate=2024-09-01&sectionId=0&packageid=5",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetCategoryWisePageViews?type=table&profileid=312109640&startDate=2024-03-06&endDate=2024-09-01&sectionId=0&packageid=5",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetCountryWisePageViews?type=piechart&profileid=312109640&startDate=2024-03-06&endDate=2024-09-01&sectionId=0&packageid=5&culture=en-US",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetCountryWisePageViews?type=table&profileid=312109640&startDate=2024-03-06&endDate=2024-09-01&sectionId=0&packageid=5&culture=en-US",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetTrafficSourceByProfileId?type=piechart&profileid=312109640&startDate=2024-03-06&endDate=2024-09-01&sectionId=0&companyid=9345&packageid=5",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetTrafficSourceByProfileId?type=table&profileid=312109640&startDate=2024-03-06&endDate=2024-09-01&sectionid=0&packageid=5&culture=en-US",
    "https://intelligence-qa-api.connectid.dev/WebsiteAnalytics/GetVisitorWithWatchlistByProfileId?profileid=312109640&intellConfigId=31721%20%20%20%20%20%20%20%20%20%20&startDate=2024-3-6&endDate=2024-9-1&companyid=9345&packageid=5",
    "https://intelligence-qa-api.connectid.dev/WebsiteAnalytics/GetWatchedVisitorByWebsite?profileid=312109640&intellConfigId=31721%20%20%20%20%20%20&startDate=2024-3-6&endDate=2024-9-1&companyid=9345&packageid=5",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetPagesDataByProfileId?profileid=312109640%20%20%20%20%20%20%20%20&startDate=2024-3-6&endDate=2024-9-1&sortexpression=PageViews&sortdirection=true&companyid=9345&packageid=5",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetPageViewVisitsWithPRByPage?profileid=312109640&startdate=2024-03-06&enddate=2024-09-01&pageid=97469240&companyid=9345&packageid=5",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetVisitorByPage?profileid=312109640%20%20%20%20%20%20%20%20%20%20&startDate=2024-3-6&endDate=2024-9-1&companyid=9345&pageid=97469240&packageid=5",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetTrafficSourceByPage?profileid=312109640&startdate=2024-03-06&enddate=2024-09-01&pageid=97469240&companyid=9345&packageid=5",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetTopDomainByTrafficSourcePage?profileid=312109640%20%20%20%20%20%20%20%20%20%20%20%20&startDate=2024-3-6&endDate=2024-9-1&companyid=9345&pageid=97469240&traffic=Organic&packageid=5&sectionid=0",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetPageViewVisitByVisitor?profileid=312109640&startdate=2024-03-06&enddate=2024-09-01&visitorid=435076&countryid=404&sectionid=0&packageid=5&selectedcompanyid=9345",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetIntelligencePagesByVisitor?profileid=312109640%20%20%20%20%20%20%20%20%20%20&startDate=2024-3-6&endDate=2024-9-1&countryid=404&visitorid=435076",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetIntelligenceTrafficSourceByVisitor?profileid=312109640&startdate=2024-03-06&enddate=2024-09-01&companyid=435076&countryid=404&selectedcompanyid=9345&sectionid=0&packageid=5",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetTopSourceMediumDomainsByTrafficSourceAndVisitor?profileid=312109640%20%20%20%20%20%20%20%20%20%20%20%20&startdate=2024-3-6&enddate=2024-9-1&selectedcompanyid=9345&countryid=404&companyid=435076&traffic=Organic",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetPageViewVisitByVisitor?profileid=312109640&startdate=2024-03-06&enddate=2024-09-01&visitorid=440698&countryid=404&sectionid=0&packageid=5&selectedcompanyid=9345",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetIntelligencePagesByVisitor?profileid=312109640%20%20%20%20%20%20%20%20%20%20&startDate=2024-3-6&endDate=2024-9-1&countryid=404&visitorid=440698",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetIntelligenceTrafficSourceByVisitor?profileid=312109640&startdate=2024-03-06&enddate=2024-09-01&companyid=440698&countryid=404&selectedcompanyid=9345&sectionid=0&packageid=5",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetTopSourceMediumDomainsByTrafficSourceAndVisitor?profileid=312109640%20%20%20%20%20%20%20%20%20%20%20%20&startdate=2024-3-6&enddate=2024-9-1&selectedcompanyid=9345&countryid=404&companyid=440698&traffic=Organic",
    "https://intelligence-qa-api.connectid.dev/TnfAnalytics/GetGA4DataTnfToolsOverview?StartDate=2024-03-06&EndDate=2024-09-01&profileID=426562393&Matrix=totalUsers&Dimensions=date,pagePath,pageTitle&Component=multilinechart&intelConfigId=40101",
    "https://intelligence-qa-api.connectid.dev/TnfAnalytics/GetGA4DataTnfToolsOverview?StartDate=2024-03-06&EndDate=2024-09-01&profileID=426562393&Matrix=screenPageViews&Dimensions=pageTitle,pagePath&Component=piechart&intelConfigId=40101",
    "https://intelligence-qa-api.connectid.dev/TnfAnalytics/GetGA4DataTnfToolsOverview?StartDate=2024-05-09&EndDate=2024-11-04&profileID=426562393&Matrix=eventCount&Dimensions=country,eventName,pagePath&Component=map&Culture=en-US&intelConfigId=40521",
    "https://intelligence-qa-api.connectid.dev/TnfAnalytics/GetGA4DataTnfToolsOverview?StartDate=2024-05-09&EndDate=2024-11-04&profileID=426562393&Matrix=eventCount,screenPageViews,sessionsPerUser,totalUsers,userEngagementDuration&Dimensions=eventName,pagePath,pageTitle&Component=table&intelConfigId=40521",
    "https://intelligence-qa-api.connectid.dev/TnfAnalytics/GetGA4DataTnfComponents?ToolId=5&StartDate=2024-05-09&EndDate=2024-11-04&profileID=426562393&Matrix=eventCount,screenPageViews,totalUsers,userEngagementDuration&Dimensions=country,date,eventName,pagePath&intelConfigId=40101",
    "https://intelligence-qa-api.connectid.dev/TnfAnalytics/GetGA4DataTnfComponents?ToolId=1&StartDate=2024-05-09&EndDate=2024-11-04&profileID=426562393&Matrix=eventCount,screenPageViews,totalUsers,userEngagementDuration&Dimensions=country,date,eventName,pagePath&intelConfigId=40101",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetIntelligencePageViewSeriesByProfileId?profileId=426562393&startDate=2024-03-06&endDate=2024-09-01&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetResolvedUnresolvedVisitorsCountByProfileId?startDate=2024-03-06&endDate=2024-09-01&profileId=426562393&companyid=12421&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetCategoryWisePageViews?type=piechart&profileId=426562393&startDate=2024-03-06&endDate=2024-09-01&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetCountryWisePageViews?type=piechart&profileid=426562393&startDate=2024-03-06&endDate=2024-09-01&isTF=1&toolid=0&culture=en-US&intelconfigid=40101",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetTrafficSourceByProfileId?type=piechart&profileid=426562393&startDate=2024-03-06&endDate=2024-09-01&toolid=0&isTF=1&culture=en-US&intelconfigid=40101",
    "https://intelligence-qa-api.connectid.dev/WebsiteAnalytics/GetVisitorWithWatchlistByProfileId?profileid=426562393&intellConfigId=40101&startDate=2024-03-06&endDate=2024-09-01&companyid=12421&isTF=1&toolid=0",
    "https://intelligence-qa-api.connectid.dev/WebsiteAnalytics/GetWatchedVisitorByWebsite?endDate=2024-09-01&startDate=2024-03-06&profileId=426562393&intellConfigId=40101&companyid=12421&isTF=1&toolid=0",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetCategory",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetPagesDataByProfileId?profileid=426562393&startDate=2024-03-06&endDate=2024-09-01&companyid=12421&isTF=1&toolid=0&sortexpression=PageViews&sortdirection=true&intelconfigid=40101",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetPageViewVisitByVisitor?profileid=426562393&startdate=2024-03-06&enddate=2024-09-01&visitorid=25902&countryid=352&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetIntelligencePagesByVisitor?profileid=426562393&startDate=2024-03-06&endDate=2024-09-01&countryid=352&visitorid=25902&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetIntelligenceTrafficSourceByVisitor?profileid=426562393&startdate=2024-03-06&enddate=2024-09-01&companyid=25902&countryid=352&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetIntelligenceTrafficSourceByVisitor?profileid=426562393&startdate=2024-03-06&enddate=2024-09-01&companyid=25902&countryid=352&isTF=1&getvalues=1&toolid=0&intelconfigid=40101",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetTopSourceMediumDomainsByTrafficSourceAndVisitor?profileid=426562393&startdate=2024-03-06&enddate=2024-09-01&countryid=352&companyid=25902&traffic=Organic&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetPageViewVisitByVisitor?profileid=426562393&startdate=2024-03-06&enddate=2024-09-01&visitorid=25902&countryid=352&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetIntelligencePagesByVisitor?profileid=426562393&startDate=2024-03-06&endDate=2024-09-01&countryid=352&visitorid=25902&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetIntelligenceTrafficSourceByVisitor?profileid=426562393&startdate=2024-03-06&enddate=2024-09-01&companyid=25902&countryid=352&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetIntelligenceTrafficSourceByVisitor?profileid=426562393&startdate=2024-03-06&enddate=2024-09-01&companyid=25902&countryid=352&isTF=1&getvalues=1&toolid=0&intelconfigid=40101",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetTopSourceMediumDomainsByTrafficSourceAndVisitor?profileid=426562393&startdate=2024-03-06&enddate=2024-09-01&countryid=352&companyid=25902&traffic=Organic&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetPageViewVisitsWithPRByPage?profileid=426562393&startdate=2024-03-06&enddate=2024-09-01&pageid=97631489&companyid=12421&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetVisitorByPage?profileid=426562393&startDate=2024-03-06&endDate=2024-09-01&companyid=12421&pageid=97631489&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetTrafficSourceByPage?profileid=426562393&startdate=2024-03-06&enddate=2024-09-01&pageid=97631489&companyid=12421&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetTrafficSourceByPage?profileid=426562393&startdate=2024-03-06&enddate=2024-09-01&pageid=97631489&companyid=12421&isTF=1&getvalues=1&toolid=0&intelconfigid=40101",
    "https://intelligence-qa-api.connectid.dev/VisitorAnalytics/GetTopDomainByTrafficSourcePage?profileid=426562393&startDate=2024-03-06&endDate=2024-09-01&companyid=12421&pageid=97631489&traffic=Organic&packageid=5&sectionid=0&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-qa-api.connectid.dev/WebsiteAnalytics/GetNotification?profileId=426562393&packageid=5&companyid=12421&intellconfigid=40101&isTF=1"
];

