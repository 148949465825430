import React, { useEffect, useState, useContext, useMemo } from 'react';
import AlertSubscribers from '../components/ManageSubscriptions';
import { isMobile } from "react-device-detect";
import { manageSubscriptions } from "../stubs/ComponentJson/alerts";
import ChartRenderer from '../components/ChartRenderer';
import ChartSummary from '../components/CardSummary';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DashboardItem from '../components/DashboardItem';
import { Row, Col, Button, Alert, Modal, Image } from '@themesberg/react-bootstrap'
import ButtonComponent from "../components/Buttons";
import { CARD_MOBILE_LAYOUT, DEFAULT_PAGE_NO, TABLE_TOTAL_ROWS } from "../AppConfig";
import AddSubscriberModal from '../components/AddSubscriberModal';
import { isEmpty, customDurationIds, DEFAULT_TIMEOUT } from "../AppConfig";
import { faCheckCircle, faExclamationTriangle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Loader from '../components/VoltLoader';
import { useTranslation } from "react-i18next";
import axios from "axios";
import ModalComponent from "../components/VoltModal";
import AppContext from '../components/AppContext';
import ChartRendererTable from '../components/ChartRendererTable';
import Search from "../components/Search";
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import { convertBase64 } from '../utils/common';
import Filters from "../components/Filters";
import { decryptData } from '../utils/crypto';

const deserializeItem = (i) => ({
    ...i,
    layout: i.layout ? JSON.parse(i.layout) : {},
    summary: i.summary ? JSON.parse(i.summary) : {},
    vizState: i.vizState ? JSON.parse(i.vizState) : {},
    apiUrl: i.apiUrl ? i.apiUrl : undefined,
    isResultShownFromApi: i.isResultShownFromApi ? i.isResultShownFromApi : false,
    chartType: i.chartType ? i.chartType : undefined
});

const defaultLayout = (i) => ({
    x: i.layout.x || 0,
    y: i.layout.y || 0,
    w: i.layout.w || 4,
    h: i.layout.h || 15,
    minW: 4,
    minH: 15,
});

const defaultLayoutMobile = (i) => ({
    x: i.layout.x || 0,
    y: i.layout.y || 0,
    w: 12,
    // w: i.layout.w || 4,
    h: i.layout.h + 2 || 12,
    minW: 4,
    minH: 8,
});

const initialState = {
    RNSAllStatus: false,
    email: "",
    country: "",
    firstName: "",
    lastName: "",
    occupation: "",
    category: ""
}

export const ManageSubscriptionPage = () => {

    const [languageApiCall, setLanguageApiCall] = useState([]);
    const [titleApiCall, setTitleApiCall] = useState([]);
    const [occupationApiCall, setOccupationApiCall] = useState([]);
    const [customCatApiCall, setCustomCatApiCall] = useState([]);
    const [rnsCatApiCall, setRnsCatApiCall] = useState([]);
    //const [isDeleteSuccessfully, setIsDeleteSuccessfully] = useState(false);

    const [language, setLanguage] = useState([]);
    const [title, setTitle] = useState([]);
    const [occupation, setOccupation] = useState([]);
    const [customCat, setCustomCat] = useState([]);
    const [rnsCat, setRnsCat] = useState([]);
    const [rnsCatMaster, setRnsCatMaster] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState(undefined);

    const [selectedWebsiteId, setSelectedWebsiteId] = useState({});
    const [alertSubscriberData, setAlertSubscriberData] = useState([]);
    const [selectedOtpclientid, setSelectedOtpclientid] = useState({});
    const [pageNo, setPageNo] = useState(0);
    const [offset, setOffset] = useState(0);
    const [items, setItems] = useState([]);
    const [showPlusModal, setShowPlusModal] = useState(false);
    const [showSuccess, setshowSuccess] = useState(false);
    const [showDeleteError, setshowDeleteError] = useState(false);
    const [failedMessageErr, setSaveFailedError] = useState(false);
    const [showDeleteSuccess, setshowDeleteSuccess] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [subscriberEmail, setSubscriberEmail] = useState("");
    const [subscriberId, setSubscriberId] = useState(0);
    const [subscriberClientId, setSubscriberClientId] = useState("");
    const [numberOfRecords, setNumberOfRecords] = useState(0);
    const [isRefresh, setIsRefresh] = useState(false);
    const [formFields, setFormFields] = useState(initialState);
    const contextData = useContext(AppContext);
    const [blurLoader, setBlurLoader] = useState(false);
    const [showConfirmationBox, setShowConfirmationBox] = useState(false);
    const [getTotalResults, setGetTotalResults] = useState(0);
    const [alertClientActiveRes, setAlertClientActiveRes] = useState(undefined);
    const [isRns, setIsRns] = useState(undefined);
    const [searchData, setSearchData] = useState("");
    const [consentDropdownData, setConsentDropdownData] = useState([{ label: "All" }, { label: "Yes" }, { label: "Pending" }]);
    const [consentDropdownSelectedItemTemp, setConsentDropdownSelectedItemTemp] = useState({ label: "All" });
    const [consentDropdownSelectedItem, setConsentDropdownSelectedItem] = useState([]);
    const [selectedFilterItem, setselectedFilterItem] = useState({});
    const [subscriberDeleted, setSubscriberDeleted] = useState(false);
    const [filterApplied, setFilterApplied] = useState(false)


    const [t] = useTranslation();

    const onCloseAlert = () => {
        setshowSuccess(false);
    }

    const closeAlert = () => {
        setshowDeleteSuccess(false);
        setshowDeleteError(false);
    }

    const onCloseConfirmation = () => {
        setShowConfirmationBox(false)
    }

    const [formError, setFormError] = useState({
        email: "",
        country: "",
        firstName: "",
    });

    const [isFormSubmit, setIsFormSubmit] = useState(false);
    const [showError, setShowError] = useState(false);

    //Get Dropdown Data
    useEffect(() => {
        setSearchData("");
        setFilterApplied(false)
        setselectedFilterItem({})
        setConsentDropdownSelectedItemTemp({ label: "All" })
        if (contextData.websiteId > 0 && contextData.selectedTab == "Alerts") {
            getLanguageData();
            GetAlertClientActiveByWebsite();
        }
    }, [contextData.websiteId, contextData.selectedTab]);

    useEffect(() => {
        if (selectedLanguage) {
            getTitleData();
            getOccupationData();
            getCustomCategories();
            //getRNSCategories();
        }
    }, [selectedLanguage])

    const onLanguageDrpChange = (data) => {
        setSelectedLanguage(data);
    }

    const getCustomCategories = () => {
        setBlurLoader(true);
        subscriberClientId > 0 && setCustomCatApiCall(false);
        request(baseURL + `/Intelligence/GetAlertCustomCategoryByWebsite?WebsiteId=${contextData.websiteId}&LangaugeCode=${selectedLanguage.LanguageCode}&companyid=${contextData.companyId}&userid=${contextData.userId}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
                setBlurLoader(false);
                const newarr = res?.result.filter(function (item) {
                    return item.AlertId != 1;
                })
                setCustomCat(newarr);
                setCustomCatApiCall(true);
            })
            .catch((err) => {
                setBlurLoader(false);
                setCustomCatApiCall(true);
                setShowLoader(false);
            })
        setBlurLoader(true);
        request(baseURL + `/Intelligence/GetAlertCustomCategoryByWebsite?WebsiteId=${contextData.websiteId}&AlertId=${1}&LangaugeCode=${"en-GB"}&companyid=${contextData.companyId}&userid=${contextData.userId}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
                setBlurLoader(false);
                setCustomCatApiCall(true);
                if (res?.result && Array.isArray(res?.result) && res?.result.length > 0) {
                    setIsRns(res?.result[0]);
                    getRNSCategories();
                }
            })
            .catch((err) => {
                setBlurLoader(false);
                setCustomCatApiCall(true);
                setShowLoader(false);
            })
    }

    const getRNSCategories = () => {
        subscriberClientId > 0 && setRnsCatApiCall(false);
        request(baseURL + `/Intelligence/GetAlertRNSCategoryByWebsite?WebsiteId=${contextData.websiteId}&LangaugeCode=${selectedLanguage.LanguageCode}&companyid=${contextData.companyId}&userid=${contextData.userId}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
                setShowLoader(false);
                let rnsCategories = [];

                res?.result && res?.result.map((item) => {
                    item.isChecked = false;
                    rnsCategories.push(item);
                })
                setRnsCatMaster(rnsCategories);
                setRnsCat(rnsCategories);

                setRnsCatApiCall(true);
            })
            .catch((err) => {
                setRnsCatApiCall(true);
                setShowLoader(false);
            })
    }

    const GetAlertClientActiveByWebsite = () => {
        //subscriberClientId > 0 && setLanguageApiCall(false);
        request(baseURL + `/Intelligence/GetAlertClientActiveByWebsite?websiteId=${contextData.websiteId}&companyId=${contextData.companyId}&userid=${contextData.userId}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
                setAlertClientActiveRes(res?.result);
                setShowLoader(false);
            })
            .catch((err) => {
                setShowLoader(false);
            })
    }

    const getLanguageData = () => {
        subscriberClientId > 0 && setLanguageApiCall(false);
        request(baseURL + `/Intelligence/GetAlertClientLanguagesByWebsite?WebsiteId=${contextData.websiteId}&companyid=${contextData.companyId}&userid=${contextData.userId}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
                setLanguage([]);
                let defaultLanguage = res?.result && res?.result && res?.result[0];
                setLanguage(res?.result);
                //setSelectedLanguageNew(defaultLanguage)
                //setAutoLoad(true)
                setSelectedLanguage(defaultLanguage);
                localStorage.setItem("EventCalendarProfileId", JSON.stringify(defaultLanguage));// Adding EventCalendarProfileId to local stoarge in case of Page Refresh
                setLanguageApiCall(true);
            })
            .catch((err) => {
                setLanguageApiCall(true);
                setShowLoader(false);
            })
    }

    const getTitleData = () => {
        subscriberClientId > 0 && setTitleApiCall(false);
        request(baseURL + `/Intelligence/GetAlertSubscriberTitle?WebsiteId=${contextData.websiteId}&LangaugeCode=${selectedLanguage.LanguageCode}&companyid=${contextData.companyId}&userid=${contextData.userId}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
                setTitle(res?.result?.DropDownList);
                setTitleApiCall(true);
            })
            .catch((err) => {
                setTitleApiCall(true);
                setShowLoader(false);
            })
    }

    const getOccupationData = () => {
        subscriberClientId > 0 && setOccupationApiCall(false);
        request(baseURL + `/Intelligence/GetAlertSubscriberOccupation?WebsiteId=${contextData.websiteId}&LangaugeCode=${selectedLanguage.LanguageCode}&companyid=${contextData.companyId}&userid=${contextData.userId}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
                setOccupation(res?.result?.DropDownList);
                setOccupationApiCall(true);
            })
            .catch((err) => {
                setOccupationApiCall(true);
                setShowLoader(false);
            })
    }

    useEffect(() => {
        setIsRefresh(false);
        // if (pageNo === 0) {
        //     setPageNo(1);
        // }

        let websiteId = contextData.websiteId;
        let newobj = JSON.parse(localStorage.getItem("alertWebsiteId"));
        if (newobj == null) {
            websiteId = 0;
        }
        if (newobj != null && contextData.websiteId != newobj["WebsiteList.WebsiteId"]) {
            websiteId = newobj["WebsiteList.WebsiteId"];
        }
        const userId = contextData.userId;
        const companyId = contextData.companyId;
        const otpClientId = selectedOtpclientid && selectedOtpclientid["ClietConfiguration.otpclientid"] != undefined ? selectedOtpclientid["ClietConfiguration.otpclientid"] : 29;

        if (contextData.websiteId && companyId && userId && contextData.selectedTab === "Alerts") {
            let dta = manageSubscriptions(websiteId, companyId, userId);
            setAlertSubscriberData(dta);
        }
        else {
            setAlertSubscriberData([]);
        }
    },
        [contextData.selectedProfile["WebsiteProfiles.ProfileId"], contextData.websiteId, contextData.companyId, subscriberDeleted, contextData.selectedTab, isRefresh]
        //[contextData.startDate, contextData.endDate, contextData.websiteId, isRefresh]
    );

    const ValidateTextInputs = (orgData) => {
        var containsBadInput = false;
        if (orgData != undefined) {
            var filteredData = orgData.replace(/\<|\>|\"|\%|\$|\;|\(|\)|\&|\+|\'|\~|\`|--/g, "");
            containsBadInput = (orgData == filteredData) ? false : true;
        }
        return containsBadInput;
    }

    const DownloadManageSubscribersExcelFileWithEPPLus = async () => {
        setBlurLoader(true)
        setShowConfirmationBox(false)
        const paramsList = {
            WebsiteId: selectedWebsiteId,
            companyId: contextData.companyId,
            userId: contextData.userId,
            culture: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : localStorage.getItem('langPref')
        }
        request(baseURL + `/Intelligence/ExportAlertSubscriberUsingEPPlus?encodeStr=${convertBase64(JSON.stringify(paramsList))}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
            })
            .then((res) => {
                if (res) {
                    let fetchDataModified = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res}`;
                    let a = document.createElement("a");
                    a.href = fetchDataModified;
                    a.download = 'Demo_Subscriber' + '.xlsx';
                    a.click();
                }
                setBlurLoader(false);
            })
            .catch((err) => {
                setBlurLoader(false);
                console.error('Error generating Excel:', err);
            })
    };

    function ValidateCommaSeparatedEmails(emailsToValidate) {
        var regexEmail = /^([\w-']+(?:\.[\w-']+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,66}(?:\.[a-z]{2})?)$/i;
        var isValidCommaSeparatedEmails = true;
        var strInvalidEmails = "";
        if (emailsToValidate === "NoSubscriber,")
            return strInvalidEmails;
        try {
            if (emailsToValidate != undefined
                && emailsToValidate != ''
                && emailsToValidate != null) {

                var emails = emailsToValidate.split(',');

                for (const i = 0; i < emails.length; i++) {
                    if (emails[i] && isValidCommaSeparatedEmails && !regexEmail.test(emails[i].trim())) {
                        isValidCommaSeparatedEmails = false;
                        strInvalidEmails = emails[i].trim();
                    }
                };
            }

            return strInvalidEmails;

        } catch (e) {

        }
    }

    const [showServiceModal, setshowServiceModal] = useState(false);
    const [InvalidRequestHoursvalidationmessage, setInvalidRequestHoursvalidationmessage] = useState("");

    const [isFormSubmittedSucessfully, setIsFormSubmittedSucessfully] = useState(false);
    const [showeditModal, setShoweditModal] = useState(false);
    const [showDefault, setShowDefault] = useState(true);
    const handleClose = () => setShowDefault(true);

    const onModalClose = () => {
        setShowDeleteModal(false);
    };

    const deleteClientSubscriber = (e) => {
        e.preventDefault();
        request(baseURL + `/Intelligence/DeleteSubscriber?WebsiteId=${contextData.websiteId}&emailId=${contextData.emailId}&companyid=${contextData.companyId}&userid=${contextData.userId}`,
            METHODS.POST,
            {
                // emailId: contextData.emailId,
                // CompanyId: contextData.companyId,
                // UserId: contextData.userId,
                // WebsiteId: contextData.websiteId
            },
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            }
        )
            .then((res) => {
                if (res.result > 0) {
                    setIsRefresh(true);
                    setIsFormSubmittedSucessfully(true);
                    setTimeout(function () {
                        setIsFormSubmittedSucessfully(false);
                    }, DEFAULT_TIMEOUT);
                    setShowDeleteModal(false);
                    setshowDeleteSuccess(true);
                    setTimeout(function () {
                        //window.location.reload();
                    }, DEFAULT_TIMEOUT);
                    setSubscriberDeleted(!subscriberDeleted);
                }
                else {
                    setshowDeleteError(true);
                }
            })
            .catch((err) => {
                setshowDeleteError(true);
            })
    }

    const deleteSubscriber = (e, email, clientId) => {
        contextData.emailId = email;
        contextData.clientId = clientId;
        setShowDeleteModal(true);
        setShowPlusModal(false);
    }

    const editSubscriber = (e, email, clientId, subscriberId, lan) => {
        //onLanguageDrpChange(undefined);
        //setSelectedLanguage();
        onLanguageDrpChange(language.find(x => x.LanguageCode == lan));
        setSubscriberEmail(email);
        setSubscriberClientId(clientId);
        setShowPlusModal(true);
        setSubscriberId(subscriberId);
    }

    const onPageNoChange = (number) => {

        const num = (number - 1) * TABLE_TOTAL_ROWS;
        const page = number;
        setOffset(num);
        setPageNo(page);
    }

    const onItemChange = (item) => {
        if (item === undefined) {
            item = [];
        }
        return () => setItems(item);
    }

    const handlePlusButtonClick = () => {
        resetFormElement();
        setSubscriberEmail("");
        setSubscriberClientId("");
        setShowPlusModal(true);
        setSubscriberId(0)
    };

    const renderSummary = (cardSummary) => {
        return (
            <ChartSummary
                summaryPrimary={cardSummary.summaryPrimary}
                summarySecondary={cardSummary.summarySecondary}
                isCarouselCard={cardSummary.isCarouselCard}
                showPercentageForPrimary={cardSummary.showPercentageForPrimary}
                showPositiveOrNegativeForPrimary={cardSummary.showPositiveOrNegativeForPrimary}
                showPercentageForSecoundary={cardSummary.showPercentageForSecoundary}
                showPositiveOrNegativeForSecoundary={cardSummary.showPositiveOrNegativeForSecoundary}
                carousalMainSummary={cardSummary.carousalMainSummary}
            />
        );

    };

    const checkApiCallingProcess = () => {
        return !titleApiCall || !occupationApiCall || !languageApiCall || !rnsCatApiCall || !customCatApiCall;
    }


    function applyfilter1() {
        setFilterApplied(true)
        setConsentDropdownSelectedItem(consentDropdownSelectedItemTemp)
        var filterfs = {};
        filterfs["AlertysTable.ConsentProvided"] = consentDropdownSelectedItemTemp.label;
        setselectedFilterItem(filterfs)
    }

    function cancelfilter1() {
        var filterfs = {};
        setselectedFilterItem(filterfs)
        setConsentDropdownSelectedItemTemp(({ label: "All" }));
    }

    function onConsentDropDownChange(data) {
        if (data !== undefined && data !== null) {
            setConsentDropdownSelectedItemTemp(data)
        }
    }

    const createFilter = useMemo(
        () => (
            <>
                <Filters
                    iconText={t("header.Icon_Tooltip_Filter_Subscribers")}
                    applyFilters={applyfilter1}
                    cancelFilter={cancelfilter1}
                    showHeader={false}
                    btnOneText={t("header.AlertManageSubscribersConsentFilterText")}
                    btnTwoText={t("header.AlertManageSubscribersConsentClearFilterText")}
                    dataSource={[
                        {
                            name: "Consent Provided",
                            data: consentDropdownData,
                            selectedItem: consentDropdownSelectedItemTemp,
                            isVisible: true,
                            label: t("header.AlertManageSubscribersConsentProvidedText"),
                            callback: onConsentDropDownChange,
                            displayText: "label",
                        }]}
                ></Filters>
            </>
        ),
        [consentDropdownData, consentDropdownSelectedItemTemp]
    );


    const dashboardItem = (item) => (
        <div className="manage-subscription-table-data position-relative " key={item.id} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)} >
            <DashboardItem
                key={item.id}
                itemId={item.id}
                title={t("header.AlertManageSubscribersInstructionPart2")}
                customTitle={"managesubscription"}
            >
                {Object.keys(item.summary).length > 0 && renderSummary(item.summary)}
                {<Row className="mb-2">

                    <Col xs={12} className="text-end icon-nav-list svg-icon-nav-list">
                        <div className="pe-4 d-inline-block pull-right">
                            <div className={filterApplied ? "icon-nav-box hide-mobile" : getTotalResults > 0 ? "icon-nav-box hide-mobile" : "icon-nav-box hide-mobile disabled"}>
                                {createFilter}
                            </div>

                            <span className={getTotalResults > 0 ? "icon-nav-box hide-mobile" : "icon-nav-box hide-mobile disabled"} onClick={() => setShowConfirmationBox(true)}>
                                <svg className="icon-nav">
                                    <title>{t("header.Icon_Tooltip_DownloadExcel")}</title>
                                    <use href={iconSprite + `#Download`} />
                                </svg>
                            </span>
                            <span className="icon-nav-box" onClick={(e) => handlePlusButtonClick(e)}>
                                <FontAwesomeIcon icon={faPlusCircle} title={t("header.Icon_Tooltip_AddSubscriber")} className="fa-lg" />
                            </span>
                        </div>
                    </Col>

                </Row>}
                {<Row>
                    <Col xs={12} className="text-end position-relative">
                        <div className="search-box-container">
                            <Search searchWaterMark={t("header.searchPlaceHolder")} setsearchData={setSearchData} searchData={searchData} isAutoComplete={false} type={'client'}></Search>
                        </div>
                    </Col>
                </Row>}

                <ChartRendererTable
                    apiUrl={item.apiUrl}
                    subscriberEmail={subscriberEmail}
                    clientId={subscriberClientId}
                    vizState={item.vizState}
                    cardProperty={item.layout}
                    items={items}
                    setItems={setItems}
                    onItemChange={onItemChange}
                    legends={item.legends}
                    setPageNo={setPageNo}
                    pageNo={pageNo}
                    onPageNoChange={onPageNoChange}
                    showPagination={true}
                    minimumRows={10}
                    title={item.title}
                    deleteSubscriber={deleteSubscriber}
                    editSubscriber={editSubscriber}
                    handlePlusButtonClick={handlePlusButtonClick}
                    showPlusModal={showPlusModal}
                    setShowPlusModal={setShowPlusModal}
                    totalRecords={numberOfRecords}
                    setNumberOfRecords={setNumberOfRecords}
                    showNumberOfRows={10}
                    summaryBesideText={t("header.manageSubscriptionsSummaryText")}
                    setGetTotalResults={(value) => setGetTotalResults(value)}
                    SearchText={searchData}
                    setSearchText={setSearchData}
                    FilterParams={selectedFilterItem}

                />
            </DashboardItem>
        </div>
    );

    const Empty = () => (
        <div
            style={{
                textAlign: 'center',
                padding: 12,
            }}
        >
            <h2>{t("header.noChartsonDashboard")}</h2>
            {/* <Link to="/explore"> */}
            <Button variant="secondary" className="m-1">
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                {t("header.addChart")}
            </Button>
            {/* </Link> */}
        </div>
    );

    const validateFormData = (name, value) => {
        let errorObj = formError;
        switch (name) {
            case "email":
                errorObj.email = "";
                if (isEmpty(value)) {
                    errorObj.email = t("header.manageSubscribersEmailValidationMessage");
                }
                break;
        }

        setFormError(errorObj);
    }

    const resetFormElement = () => {
        setSelectedLanguage(language[0]);
    }

    const closePopup = () => {
        setSelectedLanguage(undefined);
        setSubscriberClientId("");
        //resetFormElement();
        setShowPlusModal(false);
        setShowDeleteModal(false);
        setFormFields(initialState);
        setFormError({});
        setIsFormSubmit(false);
        setCustomCat([]);
        setRnsCat([]);
    }

    return (
        <>
            {checkApiCallingProcess() &&
                <div id='blurdiv' className="blurbackground">
                    <div className={`preloader loader-center-align`}>
                        <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                    </div>
                </div>
            }
            {
                showLoader &&
                <Loader show={showLoader} />
            }
            <Alert
                variant="success"
                show={showSuccess}>

                <div className="d-flex justify-content-between mb-0">
                    <div>
                        <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                        {subscriberId > 0 ? t("header.AlertMessageSubscriberUpdated") : t("header.AlertMessageSubscriberAddedCAS2")}
                    </div>
                    <Button variant="close" size="xs" onClick={() => onCloseAlert("success")} />
                </div>
            </Alert>

            <Alert
                variant="success"
                show={showDeleteSuccess}>

                <div className="d-flex justify-content-between mb-0">
                    <div>
                        <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                        {t("header.AlertMessageSubscriberDeleted")}
                    </div>
                    <Button variant="close" size="xs" onClick={() => closeAlert("success")} />
                </div>
            </Alert>
            <Alert
                variant="danger"
                show={showDeleteError}>

                <div className="d-flex justify-content-between mb-0">
                    <div>
                        <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                        {t("header.manageSubscribersDeleteWentWrong")}
                    </div>
                    <Button variant="close" size="xs" onClick={() => closeAlert("error")} />
                </div>
            </Alert>

            <Alert
                variant="danger"
                show={failedMessageErr ? true : false}>

                <div className="d-flex justify-content-between mb-0">
                    <div>
                        <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                        {failedMessageErr}
                    </div>
                    <Button variant="close" size="xs" onClick={() => closeAlert("error")} />
                </div>
            </Alert>

            <Modal as={Modal.Dialog} size="md" centered show={showDeleteModal} onHide={handleClose}>
                <Modal.Header >
                    <Modal.Title className="h5" >{t("header.deleteSubscriber")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("header.AlertUnsubscribeConfirmationMessage")}</p>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonComponent
                        isIcon={false}
                        isPrimary={true}
                        btnText={t("header.AlertUnsubscribeConfirmationYes")}
                        onClick={(e) => deleteClientSubscriber(e)}
                        btn_class="me-3" />
                    <ButtonComponent
                        isIcon={false}
                        isPrimary={false}
                        btnText={t("header.AlertUnsubscribeConfirmationNo")}
                        onClick={(e) => onModalClose(e)}
                        btn_class="mb-0" />
                </Modal.Footer>
            </Modal>

            <Modal as={Modal.Dialog} size="md" centered show={showConfirmationBox} onHide={handleClose}>
                <Modal.Header >
                    <Modal.Title className="h5" ></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("header.AlertExcelDownloadConfirmation")}</p>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonComponent
                        isIcon={false}
                        isPrimary={true}
                        btnText={t("header.USTimeOutButtonContinue")}
                        onClick={(e) => DownloadManageSubscribersExcelFileWithEPPLus()}
                        btn_class="me-3" />
                    <ButtonComponent
                        isIcon={false}
                        isPrimary={false}
                        btnText={t("header.AlertConfirmButtonCancel")}
                        onClick={(e) => onCloseConfirmation(e)}
                        btn_class="mb-0" />
                </Modal.Footer>
            </Modal>

            {showPlusModal && titleApiCall && occupationApiCall && languageApiCall && rnsCatApiCall && customCatApiCall &&
                <AddSubscriberModal
                    setIsRefreshParant={setIsRefresh}
                    setSaveFailedError={setSaveFailedError}
                    isRnsCat={isRns}
                    alertClientActiveRes={alertClientActiveRes}
                    subscriberId={subscriberId}
                    subscriberEmail={subscriberEmail}
                    clientId={subscriberClientId}
                    showLoader={showLoader}
                    closePopup={closePopup}
                    setShowLoader={setShowLoader}
                    showSuccess={showSuccess}
                    setshowSuccess={setshowSuccess}
                    languageParent={language}
                    customCatParent={customCat}
                    titleParent={title}
                    occupationParent={occupation}
                    rnsCatParent={rnsCat}
                    selectedLanguageParent={selectedLanguage}
                    onLanguageDrpChange={(item) => { onLanguageDrpChange(item) }}
                    selectedWebsiteId={selectedWebsiteId}
                    showError={showError}
                    setShowError={setShowError}
                    selectedOtpclientid={selectedOtpclientid}
                    formFields={formFields}
                    setFormFields={setFormFields}
                    isFormSubmit={isFormSubmit}
                    setIsFormSubmit={setIsFormSubmit}
                    formError={formError}
                    setFormError={setFormError}>
                </AddSubscriberModal>
            }
            {(alertSubscriberData || alertSubscriberData.length > 0) &&
                <>
                    {blurLoader && <div id='blurdiv' className="blurbackground">
                        <div className={`preloader loader-center-align`}>
                            <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                        </div>
                    </div>}
                    <AlertSubscribers
                        dashboardItems={alertSubscriberData}
                        selectedWebsiteId={selectedWebsiteId}
                        selectedOtpclientid={selectedOtpclientid}
                        setSelectedWebsiteId={setSelectedWebsiteId}
                        setSelectedOtpclientid={setSelectedOtpclientid}
                        setPageNo={setPageNo}
                        setOffset={setOffset}
                        showPlusModal={showPlusModal}
                        alertSubscriberData={alertSubscriberData}
                        handlePlusButtonClick={handlePlusButtonClick}
                    >
                        {alertSubscriberData.map(deserializeItem).map(dashboardItem)}
                    </AlertSubscribers></>}
        </>

    );

};


export default ManageSubscriptionPage;