import { Row, Col, Card } from '@themesberg/react-bootstrap';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import '../../i18n';
function ToolsMarketingPages() {
    const [t, i18n] = useTranslation();
    return (
        <div className="px-3">
            {/* <Row className="mb-3">
            <Col lg={12} xl={12}>
                <h2 className="h2 mb-0">{t("header.ToolsMarketingTitle")}</h2>
            </Col>
        </Row> */}
            <Row className="mb-3">
                <Col lg={12}>
                    <div className="bg-white shadow-soft border rounded border-light p-4 w-100 marketing-content">

                        <h2>{t("header.ToolsMarketingTitle")}</h2>
                        <h5>{t("header.ToolsMarketingContent1")}</h5>

                        <h5>{t("header.ToolsMarketingContent2")}</h5>
                        <p className='paragraph'>{t("header.ToolsMarketingContent3")}</p>

                        <h5>{t("header.ToolsMarketingContent4")}</h5>
                        <p className='paragraph'>{t("header.ToolsMarketingContent5")}</p>

                        <h5>{t("header.ToolsMarketingContent6")}</h5>
                        <p className='paragraph'>{t("header.ToolsMarketingContent7")}</p>

                        <>
                            {/* <p>{t("header.ToolsMarketingContent1")}</p>
                        <h6 className="h6 mt-4">
                             {t("header.ToolsMarketingContent2")}
                        </h6>

                        <p><em>{t("header.MarketingQuestion")}</em> {t("header.ToolsMarketingContent3")}</p>
                        <h6 className="h6 mt-4">
                            {t("header.ToolsMarketingContent4")}
                            </h6>

                        <p>
                        <em>{t("header.MarketingQuestion")}</em>    {t("header.ToolsMarketingContent5")}
                        </p>

                        
                        <p className="mb-0 mt-3">
                            <a href="#" className="link-primary">{t("header.ToolsMarketingContent6")}</a>  {t("header.ToolsMarketingContent7")}
                        </p> */}
                        </>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ToolsMarketingPages