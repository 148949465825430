import { Row, Col  } from '@themesberg/react-bootstrap';
import React, { useEffect, useState,useContext } from 'react';
import { useTranslation } from "react-i18next";

function VideoAnalyticsPage() {
    const [t] = useTranslation();
    return (
        <div className="px-3">
        <Row className="mb-3">
            <Col lg={12}>
                <h2 className="h2 mb-0">{t("header.videoAnalyticsTitle")}</h2>
            </Col>
        </Row>
        <Row className="mb-3">
                <Col lg={12}>
                    <div className="bg-white shadow-soft border rounded border-light p-4 w-100">
                        <h6 className="h6">{t("header.WebsiteMarketingContent4")}</h6>
                        <p className="mb-0">
                            <em>{t("header.MarketingQuestion")}</em>{t("header.WebsiteMarketingContent5")}
                            
                        </p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default VideoAnalyticsPage