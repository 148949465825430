import { GetAlertStatisticsByClientMultilineChart,GetAlertSummeryBarChartData,GetAlertStatisticsByClientForDomain,GetAlertStatisticsByClientForCountry, GetAlertStatisticsByClientForDevice,GetTopDevicesByMessage,GetTopCountriesByMessage } from "../../apis/services/alertPageService";

export const apiAlertSummaryService = [
    {
        id: 1,
        service: (cryptoKey, payload, callBackFn) => { GetAlertSummeryBarChartData(cryptoKey, payload, callBackFn) },
    }
]

export const apiChartsAlertService = [
    {
        id: 1,
        service: (cryptoKey, payload, callBackFn) => { GetAlertStatisticsByClientMultilineChart(cryptoKey, payload, callBackFn) },
    },
    {
        id: 2,
        service: (cryptoKey, payload, callBackFn) => { GetAlertStatisticsByClientForDomain(cryptoKey, payload, callBackFn) },
    },
    {
        id: 4,
        service: (cryptoKey, payload, callBackFn) => { GetAlertStatisticsByClientForDevice(cryptoKey, payload, callBackFn) },
    },
    {
        id: 6,
        service: (cryptoKey, payload, callBackFn) => { GetAlertStatisticsByClientForCountry(cryptoKey, payload, callBackFn) },
    },
    // {
    //     id: 3,
    //     service: (payload, callBackFn) => { GetAlertStatisticsByClientForDomain(payload, callBackFn) },
    // },
    // {
    //     id: 5,
    //     service: (payload, callBackFn) => { GetAlertStatisticsByClientForDevice(payload, callBackFn) },
    // },
    // {
    //     id: 7,
    //     service: (payload, callBackFn) => { GetAlertStatisticsByClientForCountry(payload, callBackFn) },
    // },
    {
        id: 44,
        service: (cryptoKey, payload, callBackFn) => { GetTopDevicesByMessage(cryptoKey, payload, callBackFn) },
    },
    {
        id: 45,
        service: (cryptoKey, payload, callBackFn) => { GetTopCountriesByMessage(cryptoKey, payload, callBackFn) },
    }
]


export const alertStats = (countryId, startDate, endDate, offset, websiteId, companyId, userId, categoryId, alertType, culture) => {
    let alertStatsList = [
        {
            isSummeryShown: true,
            isLegendShown: false,
            isResultShownFromApi: true,
            chartType: "multiline",
            id: "1",
            layout: "{\"x\":0,\"y\":1,\"w\":12,\"h\":10}",
            name: "",
            summary: "{}",
            cardName:"alertAnalyticsMulitline",
            //vizState: `{\"chartType\":\"multiLine\",\"pivotConfig\":{\"x\":[\"Alertanalytics.Date.week\"],\"y\":[ \"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[ \"Alertanalytics.deliver\", \"Alertanalytics.Open\", \"Alertanalytics.Click\"],\"timeDimensions\":[{\"dimension\": \"Alertanalytics.Date\", \"granularity\": \"week\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Alertanalytics.Date\":\"asc\"},\"filters\":[{\"member\": \"Alertanalytics.clientid\",\"operator\": \"equals\",\"values\":[\"${countryId}\"]}],\"dimensions\":[]}}`
        },
        {
            isResultShownFromApi: true,
            chartType: "pie",
            id: "2",
            legendPercentage: true,
            layout: "{\"x\":0,\"y\":2,\"w\":6,\"h\":8}",
            name: "Additional Insight Domains",
            summary: "{}",
            showOthers:true,
            showOthersColumeName:"Remaining_Domains",
            showOthersColume:"TotalOpenCount"
            //vizState: `{\"chartType\":\"pie\",\"pivotConfig\":{\"x\":[\"Additionalinsights.Domain\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Additionalinsights.sumopencount\"],\"timeDimensions\":[{\"dimension\": \"Additionalinsights.Processedtime\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Additionalinsights.sumclickcount\":\"desc\"},\"filters\":[{\"member\": \"Additionalinsights.ClientID\", \"operator\": \"equals\", \"values\": [\"${countryId}\"]},{\"member\": \"Additionalinsights.Domain\", \"operator\": \"set\"},{\"member\": \"Additionalinsights.sumopencount\", \"operator\": \"notEquals\", \"values\": [\"0\"]}],\"dimensions\":[\"Additionalinsights.Domain\"]}}`
        },
        {
            apiUrl: {url:`${process.env.REACT_APP_API_KEY_NEW}/Intelligence/GetDomainDetailByClientTable?WebsiteId=${websiteId}&startDate=${startDate}&endDate=${endDate}&categoryId=${categoryId}&companyid=${companyId}&userid=${userId}&alertType=${alertType ? alertType : ""}`,type:"get",count:""},
            isResultShownFromApi: true,
            chartType: "table",
            id: "3",
            layout: "{\"x\":6,\"y\":3,\"w\":6,\"h\":8}",
            name: "Additional Insight Domains",
            summary: "{}",
            tooltip:"header.domainsCardTooltip",
            showOthersColume:{showOthers:true,showOthersColumeName:["Domain.CompanyDomains","Remaining_Domains"], showOthersColume:["Domain.TotalClickCount","Domain.TotalOpenCount"]},
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Additionalinsights.Domain\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Additionalinsights.sumopencount\",\"Additionalinsights.sumclickcount\"],\"timeDimensions\":[{\"dimension\": \"Additionalinsights.Processedtime\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Additionalinsights.sumclickcount\":\"desc\"},\"filters\":[{\"member\": \"Additionalinsights.ClientID\", \"operator\": \"equals\", \"values\": [\"${countryId}\"]},{\"member\": \"Additionalinsights.Domain\", \"operator\": \"set\"},{\"member\": \"Additionalinsights.sumopencount\", \"operator\": \"notEquals\", \"values\": [\"0\"]}],\"dimensions\":[\"Additionalinsights.Domain\"]}}`
        },
        {
            isResultShownFromApi: true,
            chartType: "pie",
            id: "4",
            legendPercentage: true,
            layout: "{\"x\":0,\"y\":4,\"w\":6,\"h\":8}",
            name: "Additional Insight Device",
            summary: "{}",
            //tooltip:"header.deviceCardTooltip"
            //vizState: `{\"chartType\":\"pie\",\"pivotConfig\":{\"x\":[\"Additionalinsights.Device\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Additionalinsights.sumclickcount\",\"Additionalinsights.sumopencount\"],\"timeDimensions\":[{\"dimension\": \"Additionalinsights.Processedtime\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Additionalinsights.sumclickcount\":\"desc\"},\"filters\":[{\"member\": \"Additionalinsights.ClientID\", \"operator\": \"equals\", \"values\": [\"${countryId}\"]},{\"member\": \"Additionalinsights.Device\", \"operator\": \"set\"},{\"member\": \"Additionalinsights.sumopencount\", \"operator\": \"notEquals\", \"values\": [\"0\"]}],\"dimensions\":[\"Additionalinsights.Device\"]}}`
        },
        {
            apiUrl: {url:`${process.env.REACT_APP_API_KEY_NEW}/Intelligence/GetDeviceDetailByClientTable?WebsiteId=${websiteId}&startDate=${startDate}&endDate=${endDate}&categoryId=${categoryId}&companyid=${companyId}&userid=${userId}&alertType=${alertType ? alertType : ""}&culture=${culture}`,type:"get",count:""},
            isResultShownFromApi: true,
            chartType: "table",
            id: "5",
            layout: "{\"x\":6,\"y\":5,\"w\":6,\"h\":8}",
            name: "Additional Insight Device",
            summary: "{}",
            tooltip:"header.deviceCardTooltip"
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Additionalinsights.Device\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Additionalinsights.sumopencount\",\"Additionalinsights.sumclickcount\"],\"timeDimensions\":[{\"dimension\": \"Additionalinsights.Processedtime\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Additionalinsights.sumclickcount\":\"desc\"},\"filters\":[{\"member\": \"Additionalinsights.ClientID\", \"operator\": \"equals\", \"values\": [\"${countryId}\"]},{\"member\": \"Additionalinsights.Device\", \"operator\": \"set\"}],\"dimensions\":[\"Additionalinsights.Device\"]}}`
        },
        {
            isResultShownFromApi: true,
            chartType: "pie",
            id: "6",
            legendPercentage: true,
            layout: "{\"x\":0,\"y\":6,\"w\":6,\"h\":8}",
            name: "Additional Insight Country",
            summary: "{}",
            showOthers:true,
            showOthersColumeName:"Remaining_Countries",
            showOthersColume:"TotalOpenCount"
            //vizState: `{\"chartType\":\"pie\",\"pivotConfig\":{\"x\":[\"Additionalinsights.Country\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Additionalinsights.countryclickcount\",\"Additionalinsights.countryopencount\"],\"timeDimensions\":[{\"dimension\": \"Additionalinsights.Processedtime\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Additionalinsights.countryclickcount\":\"desc\"},\"filters\":[{\"member\": \"Additionalinsights.ClientID\", \"operator\": \"equals\", \"values\": [\"${countryId}\"]},{\"member\": \"Additionalinsights.Country\", \"operator\": \"set\"}],\"dimensions\":[\"Additionalinsights.Country\"]}}`
        },
        {
            apiUrl: {url:`${process.env.REACT_APP_API_KEY_NEW}/Intelligence/GetTopCountriesByClientTable?WebsiteId=${websiteId}&startDate=${startDate}&endDate=${endDate}&categoryId=${categoryId}&companyid=${companyId}&userid=${userId}&alertType=${alertType ? alertType : ""}&culture=${culture}`,type:"get",count:""},
            isResultShownFromApi: true,
            chartType: "table",
            id: "7",
            layout: "{\"x\":6,\"y\":7,\"w\":6,\"h\":8}",
            name: "Additional Insight Country",
            summary: "{}",
            tooltip:"header.countriesCardTooltip",
            showOthersColume:{showOthers:true,showOthersColumeName:["Country.TopCountries","Remaining_Countries"], showOthersColume:["Country.TotalClickCount","Country.TotalOpenCount"]},
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Additionalinsights.Country\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Additionalinsights.countryopencount\",\"Additionalinsights.countryclickcount\"],\"timeDimensions\":[{\"dimension\": \"Additionalinsights.Processedtime\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Additionalinsights.countryclickcount\":\"desc\"},\"filters\":[{\"member\": \"Additionalinsights.ClientID\", \"operator\": \"equals\", \"values\": [\"${countryId}\"]},{\"member\": \"Additionalinsights.Country\", \"operator\": \"set\"}],\"dimensions\":[\"Additionalinsights.Country\"]}}`
        },
        //executing joins combined table query from seperate table
        // original cube
        
        // executing joins combined table query
        // {
        //     apiUrl: {url:`${process.env.REACT_APP_API_KEY_NEW}/Intelligence/GetAlertAnalyticsTable?WebsiteId=${websiteId}&startDate=${startDate}&endDate=${endDate}&categoryId=0&companyid=${companyId}&userid=${userId}`,type:"get",count:""},
        //     isResultShownFromApi: true,
        //     chartType: "table",
        //     id: "8",
        //     layout: "{\"x\":0,\"y\":8,\"w\":12,\"h\":18}",
        //     name: "Alerts",
        //     //pagination: { show: true, query: `{\"chartType\":\"number\",\"pivotConfig1\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Alertanalytics.count\"],\"filters\":[{\"member\": \"Alertanalytics.clientid\",\"operator\": \"equals\",\"values\":[\"${countryId}\"]}],\"timeDimensions\":[{\"dimension\": \"Alertanalytics.publishtime\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{},\"dimensions\":[]}}` },
        //     //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Alertanalytics.count\"],\"filters\":[{\"member\": \"Alertanalytics.clientid\",\"operator\": \"equals\",\"values\":[\"${countryId}\"]}],\"timeDimensions\":[{\"dimension\": \"Alertanalytics.publishtime\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{},\"dimensions\":[]}}`,
        //     //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Alertanalytics.AlertDetails\",\"Alertanalytics.Date\",\"Alertanalytics.delivered\",\"Alertanalytics.OpenCountval\",\"Alertanalytics.OpenRate\",\"Alertanalytics.ClickCount\",\"Alertanalytics.CountClickThroughRate\"],\"y\":[],\"fillMissingDates\":true,\"joinDateRange\":false,\"limit\": 15},\"query\":{\"measures\":[],\"timeDimensions\":[{\"dimension\": \"Alertanalytics.publishtime\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Alertanalytics.messagesubject\":\"asc\"},\"filters\":[{\"member\": \"Alertanalytics.clientid\",\"operator\": \"equals\",\"values\":[\"${countryId}\"]}],\"limit\": 15, \"offset\": ${offset},\"dimensions\":[\"Alertanalytics.AlertDetails\",\"Alertanalytics.Date\",\"Alertanalytics.delivered\",\"Alertanalytics.OpenCountval\",\"Alertanalytics.OpenRate\",\"Alertanalytics.ClickCount\", \"Alertanalytics.CountClickThroughRate\"]}}`
        // }
    ];
    return alertStatsList;
};

export const alertStatsTable = (countryId, startDate, endDate, offset, websiteId, companyId, userId, filterType, categoryId, alertType)=>{
    let alertStatsTable = [
        {
            apiUrl: {url:`${process.env.REACT_APP_API_KEY_NEW}/Intelligence/GetAlertAnalyticsTable?WebsiteId=${websiteId}&startDate=${startDate}&endDate=${endDate}&categoryId=${categoryId}&companyid=${companyId}&userid=${userId}&filterType=${filterType}&alertType=${alertType ? alertType : ""}`,type:"get",count:""},
            isResultShownFromApi: true,
            chartType: "table",
            id: "8",
            layout: "{\"x\":0,\"y\":8,\"w\":12,\"h\":18}",
            name: "Alerts",
            //pagination: { show: true, query: `{\"chartType\":\"number\",\"pivotConfig1\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Alertanalytics.count\"],\"filters\":[{\"member\": \"Alertanalytics.clientid\",\"operator\": \"equals\",\"values\":[\"${countryId}\"]}],\"timeDimensions\":[{\"dimension\": \"Alertanalytics.publishtime\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{},\"dimensions\":[]}}` },
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Alertanalytics.count\"],\"filters\":[{\"member\": \"Alertanalytics.clientid\",\"operator\": \"equals\",\"values\":[\"${countryId}\"]}],\"timeDimensions\":[{\"dimension\": \"Alertanalytics.publishtime\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{},\"dimensions\":[]}}`,
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Alertanalytics.AlertDetails\",\"Alertanalytics.Date\",\"Alertanalytics.delivered\",\"Alertanalytics.OpenCountval\",\"Alertanalytics.OpenRate\",\"Alertanalytics.ClickCount\",\"Alertanalytics.CountClickThroughRate\"],\"y\":[],\"fillMissingDates\":true,\"joinDateRange\":false,\"limit\": 15},\"query\":{\"measures\":[],\"timeDimensions\":[{\"dimension\": \"Alertanalytics.publishtime\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Alertanalytics.messagesubject\":\"asc\"},\"filters\":[{\"member\": \"Alertanalytics.clientid\",\"operator\": \"equals\",\"values\":[\"${countryId}\"]}],\"limit\": 15, \"offset\": ${offset},\"dimensions\":[\"Alertanalytics.AlertDetails\",\"Alertanalytics.Date\",\"Alertanalytics.delivered\",\"Alertanalytics.OpenCountval\",\"Alertanalytics.OpenRate\",\"Alertanalytics.ClickCount\", \"Alertanalytics.CountClickThroughRate\"]}}`
        }
    ]
    return alertStatsTable;
}

export const alertSummary = (otpclientid, offset, startDate, endDate, websiteId, year, companyId, userId) => {
    let alertSummary = [
        {
            isLegendShown: false,
            isResultShownFromApi: true,
            chartType: "bar2",
            id: "1",
            layout: "{\"x\":0,\"y\":2,\"w\":12,\"h\":7}",
            name: "Alert Counts",
            //summary: "{}",
            //vizState: `{\"chartType\":\"bar\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Alertsummary.SMS\",\"Alertsummary.HTMLEmail\",\"Alertsummary.TextEmail\"],\"timeDimensions\":[{\"dimension\": \"Alertsummary.ProcessedTime\",\"granularity\":\"month\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{},\"filters\":[{\"member\": \"Alertsummary.ClinetID\", \"operator\": \"equals\", \"values\": [\"${otpclientid}\"]}],\"dimensions\":[]}}`
        },
        {
            apiUrl: {url:`${process.env.REACT_APP_API_KEY_NEW}/Intelligence/GetAlertStatisticsReportTable?WebsiteId=${websiteId}&year=${year}&companyid=${companyId}&userid=${userId}`,type:"get",count:""},
            isResultShownFromApi: true,
            chartType: "table",
            id: "2",
            layout: "{\"x\":0,\"y\":2,\"w\":12,\"h\":14}",
            name: "Below you will find a summary of the number of Emails and SMS messages that have been sent from your website over the stated time period.",
            summary: "{}",
            //pagination: { show: true, query: `{\"chartType\":\"number\",\"pivotConfig1\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Alertsummary.Totalmessage\"],\"filters\":[{\"member\": \"Alertsummary.ClinetID\",\"operator\": \"equals\",\"values\":[\"${otpclientid}\"]}],\"timeDimensions\":[{\"dimension\": \"Alertsummary.ProcessedTime\",\"granularity\":\"month\",\"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{},\"dimensions\":[]}}` },
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Alertsummary.SMS\",\"Alertsummary.HTMLEmail\",\"Alertsummary.TextEmail\",\"Alertsummary.Total\"],\"timeDimensions\":[{\"dimension\": \"Alertsummary.ProcessedTime\",\"granularity\":\"month\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{},\"limit\": 15, \"offset\": ${offset},\"filters\":[{\"member\": \"Alertsummary.ClinetID\", \"operator\": \"equals\", \"values\": [\"${otpclientid}\"]}],\"dimensions\":[]}}`
        }
    ];
    return alertSummary;
};

export const manualAlerts = (countryId, startDate, endDate, offset, websiteId, companyId, userId) => {

    let manualAlertsList = [
        {
            apiUrl: {url:`${process.env.REACT_APP_API_KEY_NEW}/Intelligence/GetAlertManualByWebsiteTable?WebsiteId=${websiteId}&startDate=${startDate}&endDate=${endDate}&categoryId=0&companyid=${companyId}&userid=${userId}`,type:"get",count:""},
            isResultShownFromApi: true,
            chartType: "table",
            id: "1",
            // layout: "{\"x\":0,\"y\":16,\"w\":12,\"h\":8}",
            layout: "{\"x\":0,\"y\":24,\"w\":12}",
            name: "The manual alerts listed below need to be approved before they are sent to your subscribers. If you don’t want to send the manual alert, select Cancel.",
            summary: "{}",
            //pagination: { show: true, query: `{\"chartType\":\"number\",\"pivotConfig1\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Customalerts.Totalalerts\"],\"filters\":[{\"member\": \"CustomalertsCategories.ClientID\",\"operator\": \"equals\",\"values\":[\"${countryId}\"]}],\"timeDimensions\":[],\"order\":{},\"dimensions\":[]}}` },
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Customalerts.Totalalerts\"],\"filters\":[{\"member\": \"CustomalertsCategories.ClientID\", \"operator\": \"equals\", \"values\": [\"${countryId}\"]}],\"timeDimensions\":[],\"order\":{},\"dimensions\":[]}}`,
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"CustomalertsCategories.AlertName\",\"Customalerts.Language\",\"Customalerts.AlertType\",\"CustomalertsCategories.PublishDateTime\"],\"y\":[],\"fillMissingDates\":true,\"joinDateRange\":false, \"limit\": 15},\"query\":{\"measures\":[],\"timeDimensions\":[],\"order\":{\"CustomalertsCategories.AlertName\":\"asc\"},\"filters\":[{\"member\": \"CustomalertsCategories.ClientID\", \"operator\": \"equals\", \"values\": [\"${countryId}\"]}],\"limit\": 15, \"offset\": ${offset},\"dimensions\":[\"CustomalertsCategories.AlertName\", \"Customalerts.Language\", \"Customalerts.AlertType\", \"CustomalertsCategories.PublishDateTime\"]}}`
        }
    ];

    return manualAlertsList;

};

export const archieveAlerts = (countryId, offset, websiteId, userId, pageNo) => {
    let archieveAlertsList = [
        {
            apiUrl: {url: `${process.env.REACT_APP_API_KEY}/Website/GetArchivedAlertsByWebsite?messageid=0&websiteid=${websiteId}&companyid=${userId}&page=${pageNo ? pageNo : 1}`, type: 'get', count: 0},
            id: "1",
            layout: "{\"x\":0,\"y\":24,\"w\":12,\"h\":15}",
            name: "List of all the email alerts sent. You can download the detailed list of subscribers in Excel friendly format.",
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"Orders.status\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Orders.status\"],\"filters\":[{\"member\": \"Archivealerts1.AlertType\",\"operator\": \"set\"},{\"member\": \"Orders.status\", \"operator\": \"equals\", \"values\": [\"${countryId}\"]}],\"timeDimensions\":[],\"order\":{\"Orders.status\":\"desc\"},\"dimensions\":[]}}`,
            //pagination: { show: true, query: `{\"chartType\":\"number\",\"pivotConfig1\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"dimensions\": [\"Orders.status\"],\"timeDimensions\": [],\"order\": {\"Orders.status\": \"asc\"},\"filters\": [{\"member\": \"Orders.status\",\"operator\": \"equals\",\"values\": [\"1\"]}]}}` },
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Orders.status\"],\"y\":[],\"fillMissingDates\":true,\"joinDateRange\":false, \"limit\": 15},\"query\":{\"dimensions\": [\"Orders.status\"],\"timeDimensions\": [],\"order\": {\"Orders.status\": \"asc\"},\"filters\": [{\"member\": \"Orders.status\",\"operator\": \"equals\",\"values\": [\"1\"]}]}}`
        }
    ];

    return archieveAlertsList;

};

export const manageSubscriptions = (websiteId, companyId, userId) => {
    let manageSubscriptions = [
        //     {
        //         id: "1",
        //         // layout: "{\"x\":0,\"y\":16,\"w\":12,\"h\":8}",
        //         layout: "{\"x\":0,\"y\":24,\"w\":12,\"h\":15}",
        //         name: "**All subscribers who have not confirmed their subscription within 48 hours timeframe will be removed so you always have GDPR compliant data.",
        //          summary: "{}",
        //         pagination:{show:true,query:`{\"chartType\":\"number\",\"pivotConfig1\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Subscribers.count\"],\"filters\":[{\"member\": \"Subscribers.ClientId\",\"operator\": \"equals\",\"values\":[\"${otpclientid}\"]}],\"timeDimensions\":[],\"order\":{\"Subscribers.UpdateDt\":\"desc\"},\"dimensions\":[]}}`},
        //         //summary: "{\"isCarouselCard\": false,\"carousalMainSummary\": \"Sessions\", \"summaryPrimary\": \"\", \"showPercentageForPrimary\" : false, \"showPositiveOrNegativeForPrimary\" : false,\"summarySecondary\": \"\",\"showPercentageForSecoundary\" : false, \"showPositiveOrNegativeForSecoundary\" :false}",
        //         vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Subscribers.Email\",\"Subscribers.LanguageCode\",\"Subscribers.UpdateDt\",\"Subscribers.Consentprovided\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false, \"limit\": 15},\"query\":{\"measures\":[],\"timeDimensions\":[],\"order\":{\"Subscribers.UpdateDt\":\"desc\"},\"filters\":[{\"member\": \"Subscribers.ClientId\", \"operator\": \"equals\", \"values\": [\"${otpclientid}\"]}],\"limit\": 15, \"offset\": ${offset},\"dimensions\":[\"Subscribers.Email\",\"Subscribers.LanguageCode\",\"Subscribers.UpdateDt\",\"Subscribers.Consentprovided\"]}}`,
        //    title:"Subscribers"
        //     },
        {
            apiUrl: {url:`${process.env.REACT_APP_API_KEY_NEW}/Intelligence/GetAlertAllSubscribersByWebsite?WebsiteId=${websiteId}&companyid=${companyId}&userid=${userId}`,type:"get",count:""},
            
            isResultShownFromApi: true,
            chartType: "table",
            id: "2",
            // layout: "{\"x\":0,\"y\":16,\"w\":12,\"h\":8}",
            layout: "{\"x\":0,\"y\":24,\"w\":12,\"h\":15}",
            name: "**All subscribers who have not confirmed their subscription within 48 hours timeframe will be removed so you always have GDPR compliant data.",
            summary: "{}",
            //pagination: { show: true, query: `{\"chartType\":\"number\",\"pivotConfig1\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Subscribers.count\"],\"filters\":[{\"member\": \"Subscribers.ClientId\",\"operator\": \"equals\",\"values\":[\"${otpclientid}\"]}],\"timeDimensions\":[],\"order\":{\"Subscribers.UpdateDt\":\"desc\"},\"dimensions\":[]}}` },
            //summary: "{\"isCarouselCard\": false,\"carousalMainSummary\": \"Sessions\", \"summaryPrimary\": \"\", \"showPercentageForPrimary\" : false, \"showPositiveOrNegativeForPrimary\" : false,\"summarySecondary\": \"\",\"showPercentageForSecoundary\" : false, \"showPositiveOrNegativeForSecoundary\" :false}",
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Subscribers.Email\",\"Subscribers.LanguageCode\",\"Subscribers.UpdateDt\",\"Subscribers.Consentprovided\",\"Subscribers.Edit\",\"Subscribers.Delete\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false, \"limit\": 15},\"query\":{\"measures\":[],\"timeDimensions\":[],\"order\":{\"Subscribers.UpdateDt\":\"desc\"},\"filters\":[{\"member\": \"Subscribers.ClientId\", \"operator\": \"equals\", \"values\": [\"${otpclientid}\"]}],\"limit\": 15, \"offset\": ${offset},\"dimensions\":[\"Subscribers.Email\",\"Subscribers.LanguageCode\",\"Subscribers.UpdateDt\",\"Subscribers.Consentprovided\",\"Subscribers.Edit\",\"Subscribers.Delete\"]}}`,
            title: "Subscribers"
        }

    ];
    return manageSubscriptions;
};
export const alertStatsActivityPopupDevices = (countryId, startDate, endDate, offset, websiteId, companyId, userId, filterType, categoryId, alertType)=>{
    
    let alertStatsTable = [
        {
            isResultShownFromApi: true,
            chartType: "pie",
            id: "4",
            legendPercentage: true,
            layout: "{\"x\":0,\"y\":4,\"w\":6,\"h\":8}",
            name: "Additional Insight Device",
            summary: "{}",
            tooltip:"header.deviceCardTooltip"
            //vizState: `{\"chartType\":\"pie\",\"pivotConfig\":{\"x\":[\"Additionalinsights.Device\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Additionalinsights.sumclickcount\",\"Additionalinsights.sumopencount\"],\"timeDimensions\":[{\"dimension\": \"Additionalinsights.Processedtime\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Additionalinsights.sumclickcount\":\"desc\"},\"filters\":[{\"member\": \"Additionalinsights.ClientID\", \"operator\": \"equals\", \"values\": [\"${countryId}\"]},{\"member\": \"Additionalinsights.Device\", \"operator\": \"set\"},{\"member\": \"Additionalinsights.sumopencount\", \"operator\": \"notEquals\", \"values\": [\"0\"]}],\"dimensions\":[\"Additionalinsights.Device\"]}}`
        },
        {
            
            apiUrl: {url:'https://intelligence-api.connectid.dev/Intelligence/GetDomainDetailByClientTable?WebsiteId=0&startDate=2023-11-15&endDate=2023-12-15&categoryId=0&companyid=9345&userid=156521&alertType=undefined',type:"get",count:""},
            // apiUrl: {url:`${process.env.REACT_APP_API_KEY_NEW}/Intelligence/GetAlertAnalyticsTable?WebsiteId=${websiteId}&startDate=${startDate}&endDate=${endDate}&categoryId=${categoryId}&companyid=${companyId}&userid=${userId}&filterType=${filterType}&alertType=${alertType}`,type:"get",count:""},
            isResultShownFromApi: true,
            chartType: "table",
            id: "8",
            layout: "{\"x\":0,\"y\":8,\"w\":12,\"h\":18}",
            name: "Alerts",
            //pagination: { show: true, query: `{\"chartType\":\"number\",\"pivotConfig1\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Alertanalytics.count\"],\"filters\":[{\"member\": \"Alertanalytics.clientid\",\"operator\": \"equals\",\"values\":[\"${countryId}\"]}],\"timeDimensions\":[{\"dimension\": \"Alertanalytics.publishtime\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{},\"dimensions\":[]}}` },
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Alertanalytics.count\"],\"filters\":[{\"member\": \"Alertanalytics.clientid\",\"operator\": \"equals\",\"values\":[\"${countryId}\"]}],\"timeDimensions\":[{\"dimension\": \"Alertanalytics.publishtime\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{},\"dimensions\":[]}}`,
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Alertanalytics.AlertDetails\",\"Alertanalytics.Date\",\"Alertanalytics.delivered\",\"Alertanalytics.OpenCountval\",\"Alertanalytics.OpenRate\",\"Alertanalytics.ClickCount\",\"Alertanalytics.CountClickThroughRate\"],\"y\":[],\"fillMissingDates\":true,\"joinDateRange\":false,\"limit\": 15},\"query\":{\"measures\":[],\"timeDimensions\":[{\"dimension\": \"Alertanalytics.publishtime\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Alertanalytics.messagesubject\":\"asc\"},\"filters\":[{\"member\": \"Alertanalytics.clientid\",\"operator\": \"equals\",\"values\":[\"${countryId}\"]}],\"limit\": 15, \"offset\": ${offset},\"dimensions\":[\"Alertanalytics.AlertDetails\",\"Alertanalytics.Date\",\"Alertanalytics.delivered\",\"Alertanalytics.OpenCountval\",\"Alertanalytics.OpenRate\",\"Alertanalytics.ClickCount\", \"Alertanalytics.CountClickThroughRate\"]}}`
        }
    ]
    return alertStatsTable;
}
