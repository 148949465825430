import {convertBase64} from "../../utils/common"
export const userMyDocumentsList = (companyId, offset) => {
    let MyDocumentsList = [
        {
            apiUrl: {url:`${process.env.REACT_APP_API_KEY_NEW}/CommonV2/GetDocumentsByClientUser?CompanyId=${convertBase64(String(companyId))}`,type:"get",count:""},
            id: "1",
            layout: "{\"x\":0,\"y\":1,\"w\":16,\"h\":12}",
            name: "Documents",
            pagination: { show: true, query: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\": [],\"y\": [ \"measures\"],\"fillMissingDates\": true,\"joinDateRange\": false},\"query\":{\"measures\": [\"Midocuments.count\"], \"timeDimensions\":[],\"order\":{},\"dimensions\": [],\"filters\": [{\"member\": \"Midocuments.companyid\",\"operator\": \"equals\",\"values\":[\"${companyId}"]},{\"member\": \"Midocuments.userid\",\"operator\": \"notSet\"}]}}` },
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\": [],\"y\": [ \"measures\"]},\"query\":{\"measures\": [\"Midocuments.count\"],\"filters\": [{\"member\": \"Midocuments.companyid\",\"operator\": \"equals\",\"values\":[\"${companyId}"]},{\"member\": \"Midocuments.userid\",\"operator\": \"notSet\"}],\"timeDimensions\":[],\"order\":{},\"dimensions\":[]}}`,
            // summary: "{\"layoutType\": \"plain\", \"summaryPrimary\":  139, \"showPercentageForPrimary\" : false, \"showPositiveOrNegativeForPrimary\" : true,\"summarySecondary\": \"watchlist visits\",\"showPercentageForSecoundary\" : false, \"showPositiveOrNegativeForSecoundary\" :false}",
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\": [\"Midocuments.Documentcheck\",\"Midocuments.docname\",\"Midocuments.Uploadedby\",\"Midocuments.uploadeddt\",\"Midocuments.Actions\"],\"y\": [],\"fillMissingDates\": true,\"joinDateRange\": false},\"query\":{\"measures\": [], \"timeDimensions\":[],\"limit\": 15,\"offset\": ${offset},\"order\":{\"Midocuments.uploadeddt\": \"desc\"},\"dimensions\": [\"Midocuments.Documentcheck\",\"Midocuments.docname\",\"Midocuments.Uploadedby\",\"Midocuments.uploadeddt\",\"Midocuments.Actions\"],\"filters\": [{\"member\": \"Midocuments.companyid\",\"operator\": \"equals\",\"values\":[\"${companyId}"]},{\"member\": \"Midocuments.userid\",\"operator\": \"notSet\"}]}}`
        }
    ];
    return MyDocumentsList;
};