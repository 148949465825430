import React, { useState, useEffect, useContext, useMemo } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';
import { withRouter } from "react-router";
import { Row, Col, Button, Form, Image, InputGroup, FormCheck } from '@themesberg/react-bootstrap';
import DropdownWithIconComponent from "../components/DropdownWithIcons";
import Loader from '../components/VoltLoader';
import AppContext from '../components/AppContext';
import CommonHeader from '../components/CommonHeader';
import ChartRendererTable from "../components/ChartRendererTable";
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { useTranslation } from "react-i18next";
import { decryptData } from '../utils/crypto';

const ReactGridLayout = WidthProvider(RGL);

const ArchiveAlerts = ({ children, dashboardItems, setPageNo, setOffset, selectedWebsiteId, selectedOtpclientid, setSelectedWebsiteId, setSelectedOtpclientid, history, location }) => {
  const contextData = useContext(AppContext);
  const [selectedWebsiteIdNew, setSelectedWebsiteIdNew] = useState("");
  const [autoLoad, setAutoLoad] = useState(false);
  const [blurLoader, setBlurLoader] = useState(false);
  const [t, i18n] = useTranslation();
  
  const cubejsApi = cubejs(
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJteWFwcGlkIjozLCJpYXQiOjE2MTgyMjkzNDIsInVzZXJfaWQiOjM4MDgxLCJwbGF0Zm9ybV9pZCI6MSwic2VydmljZV9pZCI6OCwiY29tcGFueV9pZCI6OTM0NX0.lYSmPrF55TH3y8HQk96khmXn08XBt5nkUh7vzxfyak4',
    { apiUrl: `${process.env.REACT_APP_CUBESERVERURL}/cubejs-api/v1` }
  );
  useEffect(() => {
    if (localStorage.getItem("IsPageRefresh") == "1") {
      setSelectedWebsiteIdNew(JSON.parse(localStorage.getItem("alertWebsiteId")));
      setSelectedOtpclientid(JSON.parse(localStorage.getItem("alertWebsiteId")));
    }

  }, []);
  const onWebsiteDropdownChange = (data) => {
    setSelectedWebsiteIdNew({});
    setSelectedWebsiteIdNew(data)
  };

  const applyFilters = () => {
    setBlurLoader(false);
    const websiteId = selectedWebsiteIdNew["WebsiteList.WebsiteId"];
    setSelectedWebsiteId(websiteId);
    contextData.changeWebsiteId(websiteId);
    setSelectedWebsiteId(websiteId);
    setSelectedOtpclientid(selectedWebsiteIdNew);
    localStorage.setItem("alertWebsiteId", JSON.stringify(selectedWebsiteIdNew)); // Adding ArchiveAlertWebsiteId to local stoarge in case of Page Refresh
    // return contextData.selectedWebsite["WebsiteList.WebsiteName"]
    //return contextData.selectedWebsite["WebsiteList.WebsiteName"]+" - "+contextData.selectedProfile["WebsiteProfiles.DisplayName"]
  }
  
  const cancelFilters = () => {
    onWebsiteDropdownChange(JSON.parse(localStorage.getItem("alertWebsiteId")))
  }
  useEffect(() => {
    getWebsites();
  }, [contextData.userId,contextData.companyId]);

  const getWebsites = () => {
    setBlurLoader(true);
    request(baseURL + `/Intelligence/GetAlertsChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
        METHODS.GET,
        {},
        {
            Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        })
        .then((res) => {
            let resultData = [];
            if(res.result !== null && res.result.length){
              resultData = res.result.map((e)=>({["WebsiteList.WebsiteName"]:e.WebsiteName,["WebsiteList.WebsiteId"]:e.WebsiteId,["WebsiteList.WebsiteUrl"]:e.WebsiteUrl,["ClietConfiguration.otpclientid"]:e.OTPClientId, ["WebsiteList.AlertTrackingDate"]: e.AlertTrackingDate}))
            }
          rendersite({resultSet:resultData})
        })
        .catch((err) => {
          
        })
        
        // request(baseURL + `/Intelligence/GetWebsites?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
        // METHODS.GET,
        // {},
        // {
        //     Authorization: `Bearer ${localStorage.getItem("token")}`, "content-type": "application/json"
        // })
        // .then((res) => {
        //     setProfilesList(res.result)
        // })
        // .catch((err) => {
          
        // })
  }
  const rendersite = ({ resultSet, error, pivotConfig, request }) => {

    // if (error) {
    //   return <div>{error.toString()}</div>;
    // }

    // if (!resultSet) {
    //   return <Loader show={false} />;
    // }
    if (resultSet !== undefined && resultSet.length) {
      if (selectedWebsiteIdNew !== undefined && Object.keys(selectedWebsiteIdNew).length <= 0) {
        if (localStorage.getItem("alertWebsiteId")) {
          onWebsiteDropdownChange(JSON.parse(localStorage.getItem("alertWebsiteId")))
        } else {
          onWebsiteDropdownChange(resultSet[0])
        }

      }
      localStorage.setItem("archivedAlertsData", JSON.stringify(resultSet))
      setAutoLoad(true)
    }

    return (
      <></>
      // <DropdownComponent
      //   dropdownData={resultSet.tablePivot(pivotConfig)}
      //   arrayKey="WebsiteList.WebsiteId"
      //   arrayValue="WebsiteList.WebsiteName"
      //   dropdownValue={websiteId}
      //   onChange={setWebsiteId}
      //   showLabel={false}
      // />



      // <DropdownWithIconComponent
      //   dropdownData={resultSet.tablePivot(pivotConfig)}
      //   arrayValue="WebsiteList.WebsiteName"
      //   selectedDropdown={selectedWebsiteId}
      //   onChange={onWebsiteDropdownChange}
      //   showLabel={false}
      //   showIconInDropdownItem={false}
      //   customClassName="full-width"
      // />
    );

  };
  const commonHeader = useMemo(() => <CommonHeader cancelFilters={cancelFilters} channel="alert" autoLoad={autoLoad} dataSource={[
    { name: 'Dropdown1 - website', data: JSON.parse(localStorage.getItem("archivedAlertsData")), selectedItem: selectedWebsiteIdNew, isVisible: true, label: t("header.alertSummaryFiltersLabelWebsite"), callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" },
  ]} title={t("header.archivedAlertTitle")} showDate={false} applyFilters={applyFilters} />, [selectedWebsiteIdNew,autoLoad]);

  return (
    <>
            {blurLoader && <div id='blurdiv' className="blurbackground">
                <div className={`preloader loader-center-align`}>
                    <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                </div>
            </div>}
      <Row>
        <Col sm={12}>
          {commonHeader}
        </Col>
      </Row>


      <Row className="ps-3">
        <Col sm={8}>
          {/* <h2 className="h2 mb-2">Archived Alert</h2> */}
        </Col>
        <Col sm={4} className="mb-2">
          {/* <QueryRenderer
            query={{
              "dimensions": [
                "ClietConfiguration.otpclientid",
                "WebsiteList.WebsiteId",
                "WebsiteList.WebsiteName"
              ],
              "timeDimensions": [],
              "order": {
                "WebsiteList.WebsiteId": "asc"
              },
              "filters": [
                {
                  "member": "ClietConfiguration.userid",
                  "operator": "equals",
                  "values": [`${contextData.userId}`]
                },
                {
                  "member": "ClietConfiguration.companyid",
                  "operator": "equals",
                  "values": [`${contextData.companyId}`]
                }

              ],
            }}
            cubejsApi={cubejsApi}
            resetResultSetOnChange={false}
            render={(props) => rendersite({
              ...props,
              chartType: 'dropdown',
              pivotConfig: {
                "x": [
                  "ClietConfiguration.otpclientid",
                  "WebsiteList.WebsiteId",
                  "WebsiteList.WebsiteName"

                ],
                "y": ["WebsiteList.WebsiteName"],
                "fillMissingDates": true,
                "joinDateRange": false
              }
            })}
          /> */}

        </Col>
        {/* <Row>
            <Col  xs={12} sm={12} xl={12} className="mb-4">
            <p>The manual alerts listed below need to be approved before they are sent to your subscribers. 
                If you don’t want to send the manual alert, select Cancel.
                </p>
            </Col>
        </Row> */}

      </Row>

      {/* Change css when react grid item order will be changed or rearranged */}
      <Row>
        <Col xs={12}>
          <ReactGridLayout cols={12} rowHeight={50}
            isDraggable={false} isResizable={false} className="rgl-archive-alerts"
          // onLayoutChange={onLayoutChange}
          >
            {children}
          </ReactGridLayout>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(ArchiveAlerts);
