import React, { useState,useContext,useMemo, useEffect } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { withRouter } from "react-router";
import { Row, Col, Form, FormCheck } from '@themesberg/react-bootstrap';
import DashboardItem from '../components/DashboardItem';
import SummaryRenderer from '../components/SummaryRenderer';
import { isMobile } from "react-device-detect";
import {defaultLayout,defaultLayoutMobile} from "../utils/layoutConfig";
import ChartRenderer from '../components/ChartRenderer';
import { TABLE_TOTAL_ROWS } from "../AppConfig";
import Filters from "../components/Filters";
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import AppContext from '../components/AppContext';
import Search from "../components/Search";
import ChartRendererTable from '../components/ChartRendererTable';
import DropdownWithIconsComponent from "../components/DropdownWithIcons";
import Progress from "../components/Progress";
import { pageValuesByKey } from "../utils/constant";
import { useTranslation } from "react-i18next";
const ReactGridLayout = WidthProvider(RGL);
const deserializeItem = (i) => ({
    ...i,
    layout: JSON.parse(i.layout) || {},
    summary: JSON.parse(i.summary) || {},
    vizState: JSON.parse(i.vizState)
});

const Polls = ({selectedPublishStatus, isRefresh, setIsRefresh, setSearchString, selectedpresentation, presentationId, hasEvents,setSelectedPublishStatus,webcastQuestions,changeQuestionData,resultSet,setPollsRefresh}) => {
  const [questionsData, setQuestionsData] = useState([]);
  const [pollItems,setPollItemes] = useState([]);
  const [selectedPoll,setSelectedPoll] = useState({});
  const [pollName,setPollName] = useState("");
  const [totalQuestions,setTotalQuestions] = useState(0);
  const [totalSubmissions,setTotalSubmissions] = useState(0)
  const [t, i18n] = useTranslation();
  const [showMessage,setShowMessage] = useState(false);
  const [updatedQuestionsData,setUpdatedQuestionsData] = useState([])
  useEffect(()=>{
    let items = [];
    if(resultSet !== undefined && resultSet.ResultData !== null){
      setPollItemes([]);
      setPollName("");
      setSelectedPoll({})
      setQuestionsData(resultSet.ResultData)
      setShowMessage(false)
    }else if(resultSet !== undefined && resultSet.MultiPollResultData !== null){
      if(resultSet.MultiPollResultData.length){
        resultSet.MultiPollResultData.forEach(element => {
          items.push({id:element._id,statusName:element.name,submissions:element.responseCount})
        });
      }
      
      setPollItemes(items);
      setShowMessage(false)
    }else{
      setPollItemes([]);
      setQuestionsData(resultSet.ResultData)
      setShowMessage(true)
    }
  },[resultSet])
  
  useEffect(()=>{
    let questions = 0;
    let count=0;
      if(questionsData && questionsData.length > 0){
        questionsData.map((element)=>{
          questions++;
          if(element.answers.length){
            //let maxValue = Math.max(...element.answers.map(e=>e.percentage),0)

            let duplicate = [...element.answers];
            duplicate = duplicate.sort((a,b)=>{return b.percentage-a.percentage})
            let firstMax = "";
            let secondMax = "";
            firstMax = duplicate[0].percentage
            
            if(duplicate[1] !== undefined && duplicate[1].percentage){
              secondMax = duplicate[1].percentage
            }
            if(firstMax !== secondMax){
              element.maxValue = firstMax
            }
            
           
              count = 0;
              element.answers.map((submissionValue)=>{
                if(submissionValue.percentage >0){
                  count++;
                }
              })
          }
        });
        
      }
      
      setTotalQuestions(questions)
      setUpdatedQuestionsData(questionsData)
      //setTotalSubmissions(submissions);
    },[questionsData]);
  useEffect(()=>{
    if(pollItems.length){
      onPollDropdownChange(pollItems[0])
    }
    
    
  },[pollItems]);
  const Refresh = ()=>{
    setPollsRefresh(Math.random())
  }
  const onPollDropdownChange = (e)=>{
    setSelectedPoll(e)
    setPollName(e.statusName)
    setTotalSubmissions(e.submissions)
    for(let i = 0;i<pollItems.length;i++){
      if(resultSet.MultiPollResultData[i]._id === e.id){
        setQuestionsData(resultSet.MultiPollResultData[i].questions)
      break;
      }
    }
  
  }
  return (
    <>
       <div className="p-3">
        
       
        
          <Row>
            
              <Col md={12}>
                  <div className={`d-inline-block pt-3 pb-3 mb-4 w-100 ${pollItems && pollItems.length>0?'border-top border-bottom':''}`}>
                  <Row>
                  <Col sm={4}>
              {
                pollItems && pollItems.length>0 &&
                <DropdownWithIconsComponent
                    dropdownData={pollItems}
                    arrayValue="statusName"
                    onChange={(e) => onPollDropdownChange(e)}
                    showLabel={false}
                    showIconInDropdownItem={false}
                    selectedDropdown={selectedPoll}
                    isPrimaryImage={false}
                    customClassName="full-width text-overflow"
                  />
              }
            </Col>


            <Col sm={3}><span className="mt-2 d-inline-block">{pollItems && pollItems.length === 0?'':totalQuestions+' '+t("header.WebcastViewersQuestions")} </span></Col>
            <Col sm={3}><span className="mt-2 d-inline-block">{pollItems && pollItems.length === 0?'':totalSubmissions+' '+t("header.pollsSubmissions") }</span></Col>
            <Col sm={2} className="text-end mt-2">
            <div className="me-2 float-end polls-refresh-button">

                          
                          <div className="icon-nav-list svg-icon-nav-list">  
                                <span className={"icon-nav-box"}   onClick={() => Refresh()}>
                                  <svg className="icon-nav">
                                    <title>{t("header.Icon_Tooltip_Refresh")}</title>
                                    <use  href={iconSprite + `#Sync`} />
                                  </svg>
                                </span>
                          </div>
                        </div>
            </Col>
                  </Row>
                  </div>
              </Col>
            
            
          </Row>
       
        <Row>
          <Col md={12}>
            <div className="question-container">
              {
                updatedQuestionsData && updatedQuestionsData.length > 0 &&
                updatedQuestionsData.map((element,index)=><>
                <Row className="poll-question-block">
                    <Col><span className="d-inline-block poll-question-label mb-2 w-100">{'Question '+(index+1)}</span> <span className="d-inline-block fw-bold poll-question-txt">{element.question}</span></Col>
                    <Col>
                      {
                        element.answers && element.answers.length &&
                        element.answers.map((submission)=><><Row>
                          <Col>
                          <Progress maxValue={element.maxValue===submission.percentage?Math.round(submission.percentage):''} label={submission.value} value={Math.round(submission.percentage)} />
                          </Col>
                          </Row></>)
                      }
                      
                    </Col>
                </Row>
                </>)
                
                }
            </div>
            {
              ((questionsData && questionsData.length <=0) || (showMessage)) &&
            
               <div className="text-center no-data">{ t("header.NoDataFound")}</div>
            
            }
          </Col>
        </Row>
        

       </div>
     
       
    </>
  );
};

export default withRouter(Polls);
