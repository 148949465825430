import { Row, Col, Card  } from '@themesberg/react-bootstrap';
import React, { useEffect, useState,useContext } from 'react';
import { useTranslation } from 'react-i18next';
import '../../i18n';
function AppMarketingPage() {
    const [t, i18n] = useTranslation();
    return (
        <div className="px-3">
        <Row className="mb-3">
            <Col lg={12} xl={12}>
                <h2 className="h2 mb-0">{t("header.AppsMarketingTitle")}</h2>
            </Col>
        </Row>
        <Row className="mb-3">
                <Col lg={12}>
                    <div className="bg-white shadow-soft border rounded border-light p-4 w-100">
                       
                    <p> {t("header.AppsMarketingContent1")}</p>
                    <h6 className="h6 mt-4">
                            {t("header.AppsMarketingContent2")}
                            </h6>

                            <p>{t("header.MarketingQuestion")} {t("header.AppsMarketingContent3")} </p>
                        
                        <h6 className="h6 mt-4">  {t("header.AppsMarketingContent4")}</h6>
                       

                        <p className="mb-0">{t("header.MarketingQuestion")} {t("header.AppsMarketingContent5")} </p>
                        
                        <h6 className="h6 mt-4">
                             {t("header.AppsMarketingContent6")}
                            </h6>
                       
                        <p className="mb-0">
                        {t("header.MarketingQuestion")} {t("header.AppsMarketingContent7")}
                        </p>
                        <h6 className="h6 mt-4">
                           
                            </h6>
                        <p className="mb-0">
                            <a href="#">{t("header.AppsMarketingContent8")}</a> {t("header.AppsMarketingContent9")}
                        </p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default AppMarketingPage