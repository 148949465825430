import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router";
import DashboardItem from "../components/DashboardItem";
import { isMobile } from "react-device-detect";
import ChartRendererTable from "../components/ChartRendererTable";
import { defaultLayout, defaultLayoutMobile } from "../utils/layoutConfig";
import AppContext from "../components/AppContext";
import { Button, OverlayTrigger, Col, Row, FormCheck, Form, InputGroup, Popover, Alert, Image, Nav, Tab, Dropdown, ButtonGroup } from "@themesberg/react-bootstrap";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import request from "../apis/request";
import { baseURL, METHODS } from "../apis/utilities/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import ButtonComponent from "../components/Buttons";
import { useTranslation } from "react-i18next";
import ModalComponent from "../components/VoltModal";
import { ERROR_TIMEOUT } from "../AppConfig"
import ApiChartRenderer from '../components/ApiChartRenderer';
import Search from "../components/Search";
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import {
  getPagesViewedLineChart, apiChartsVisitorInsightsService, getAllPopupTrafficSourceBarChart
} from "../stubs/ComponentJson/Visitoranalytics";
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import axios from "axios";
import { convertBase64 } from "../utils/common";
import html2pdf from "html2pdf.js";
import { convertTranslatinString } from '../utils/common';
import { decryptData } from "../utils/crypto";

const deserializeItem = (i) => ({
  ...i,
  layout: JSON.parse(i.layout) || {},
});

const Watchlist = ({
  selectedCategoryId,
  SearchText,
  setSearchText,
  watchList,
  setheaderFilterData,
  setTotalRecords,
  setCountryListForFilter,
  FilterParams,
  reloadwatchlist,
}) => {

  const contextData = useContext(AppContext);
  const [apiUrl, setApiUrl] = useState({});
  const [items1, setItems1] = useState([]);
  const [numberOfRecords, setNumberOfRecords] = useState(0);
  const [notificationPopupObj, setNotificationPopupObj] = useState({
    operation: '', companyid: 0, companyname: '', notificationname: '', threshold: '', country: '', countrydata: '', companyurl: '', moreurls: [], startdate: null, enddate: null, durationId: 0,
    companynameisvalid: true, notificationnameisvalid: true, thresholdisvalid: true, startdateisvalid: true, enddateisvalid: true, durationidisvalid: true
  });
  const [showaddeditPopup, setshowaddeditshowPopup] = useState(false);
  const [temList, settemList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [t, i18n] = useTranslation();
  const [showAutoFillForCompany, setAutoFillForCompany] = useState(false);
  const [duration, setDuration] = useState([]);
  const [notificationObjDetails, setNotificationObjDetails] = useState({});
  const [successMessage, setSuccessMessage] = useState(undefined);
  const [errorMessageTransaction, setErrorMessageTransaction] = useState(undefined);
  const [showPopup, setshowPopup] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [pageObjDetails, setPageObjDetails] = useState({});
  const [visitorTabKey, setVistorTabKey] = useState("");
  const [trafficSourceTotal, setTrafficSourceTotal] = useState(0);
  const [trafficSourceText, setTrafficSourceText] = useState(t("header.PagesDetailsPopTrafficChartOrganicHelpText"));
  const [searchData, setsearchData] = useState("");
  const [blurLoader, setBlurLoader] = useState(false);
  const [trafficSource, setTrafficSource] = useState("");
  const [apiUrlPopup, setapiUrlPopup] = useState({});
  const [apiUrlPopup2, setapiUrlPopup2] = useState({});
  const [pageViewedPopupLineConfig, setpageViewedPopupLineConfig] = useState({});
  const [trafficSourcePopupBarConfig, setTrafficSourcePopupBarConfig] = useState({});
  const [headerFilterDataPopup, setheaderFilterDataPopup] = useState(null);
  const [showAutoFillForCompanyPopup, setAutoFillForCompanyPopup] = useState(false);
  const [getTotalResults, setGetTotalResults] = useState(0);
  const [alertVisible, setAlertVisible] = useState(false);

  useEffect(() => {
    if (contextData.selectedProfile["WebsiteProfiles.ProfileId"]) {
      let ms = new Date(Date.parse(contextData.startDate));
      let sdate = ms.getFullYear() + "-" + parseInt(ms.getMonth() + 1) + "-" + ms.getDate()
      let ms2 = new Date(Date.parse(contextData.endDate));
      let sdate2 = ms2.getFullYear() + "-" + parseInt(ms2.getMonth() + 1) + "-" + ms2.getDate()
      if (selectedCategoryId.sectionId !== 0) {
        setApiUrl(
          {
            url: `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetWatchedVisitorBySection?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}&intellConfigId=${contextData.selectedProfile["WebsiteProfiles.IntelConfigId"]}
        &startDate=${sdate}&endDate=${sdate2}&companyid=${contextData.companyId}&sectionid=${selectedCategoryId.sectionId}&packageid=5`, type: 'get', count: 0
          }
        )
      }
      else {
        setApiUrl(
          {
            url: `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetWatchedVisitorByWebsite?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}&intellConfigId=${contextData.selectedProfile["WebsiteProfiles.IntelConfigId"]}
      &startDate=${sdate}&endDate=${sdate2}&companyid=${contextData.companyId}&packageid=5${localStorage.getItem('refreshCache') ? "&refreshCache=" + localStorage.getItem('refreshCache') : ""}`, type: 'get', count: 0
          }
        )
      }
    }
  }, [contextData.startDate, contextData.endDate, selectedCategoryId.sectionId, contextData.selectedProfile, reloadwatchlist]);

  useEffect(() => {
    setTotalRecords(0)
    setTotalRecords(getTotalResults)
  }, [getTotalResults]);

  useEffect(() => {
    if (isRefresh && contextData.selectedProfile["WebsiteProfiles.ProfileId"]) {
      let ms = new Date(Date.parse(contextData.startDate));
      let sdate = ms.getFullYear() + "-" + parseInt(ms.getMonth() + 1) + "-" + ms.getDate()
      let ms2 = new Date(Date.parse(contextData.endDate));
      let sdate2 = ms2.getFullYear() + "-" + parseInt(ms2.getMonth() + 1) + "-" + ms2.getDate()
      if (selectedCategoryId.sectionId !== 0) {
        setApiUrl(
          {
            url: `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetWatchedVisitorBySection?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}&intellConfigId=${contextData.selectedProfile["WebsiteProfiles.IntelConfigId"]}
          &startDate=${sdate}&endDate=${sdate2}&companyid=${contextData.companyId}&sectionid=${selectedCategoryId.sectionId}&packageid=5`, type: 'get', count: 0
          }
        )
      }
      else {
        setApiUrl(
          {
            url: `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetWatchedVisitorByWebsite?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}&intellConfigId=${contextData.selectedProfile["WebsiteProfiles.IntelConfigId"]}
        &startDate=${sdate}&endDate=${sdate2}&companyid=${contextData.companyId}&packageid=5${localStorage.getItem('refreshCache') ? "&refreshCache=" + localStorage.getItem('refreshCache') : ""}`, type: 'get', count: 0
          }
        )
      }
      setIsRefresh(false);
    }
  }, [isRefresh]);

  useEffect(() => {
    let ms = new Date(Date.parse(contextData.startDate));
    let sdate = ms.getFullYear() + "-" + parseInt(ms.getMonth() + 1) + "-" + ms.getDate()
    let ms2 = new Date(Date.parse(contextData.endDate));
    let sdate2 = ms2.getFullYear() + "-" + parseInt(ms2.getMonth() + 1) + "-" + ms2.getDate()
    if (pageObjDetails.CompanyId !== undefined) {
      if (selectedCategoryId.sectionId === 0) {
        if (trafficSource === "Organic" || trafficSource === "Referral" || trafficSource === "Social") {
          setapiUrlPopup2(
            {
              url: `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetTopSourceMediumDomainsByTrafficSourceAndVisitor?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
            &startdate=${sdate}&enddate=${sdate2}&selectedcompanyid=${contextData.companyId}&countryid=${pageObjDetails.CountryId}&companyid=${pageObjDetails.CompanyId}&traffic=${trafficSource}`, type: 'get', count: 0
            }
          )
        }
        else {
          setBlurLoader(true)
        }
        request(
          baseURL + `/VisitorAnalytics/GetIntelligenceTrafficSourceByVisitor?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
      &startdate=${sdate}&enddate=${sdate2}&countryid=${pageObjDetails.CountryId}&companyid=${pageObjDetails.CompanyId}&selectedcompanyid=${contextData.companyId}&getvalues=1`,
          METHODS.GET,
          {},
          {
            Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
          }
        )
          .then((response) => {
            setBlurLoader(false);
            if (response != null) {
              if (!response.organic) {
                setTrafficSource("Direct")
              }
              if (trafficSource === "Organic") {
                setTrafficSourceText(t("header.PagesDetailsPopTrafficChartOrganicHelpText"));
                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + trafficSource) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.organic);
              }
              else if (trafficSource === "Direct") {
                setTrafficSourceText(t("header.InsightsTrafficSourceDirectHelpTooltipText"));
                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + trafficSource) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.direct);
              }
              else if (trafficSource === "Referral") {
                setTrafficSourceText(t("header.InsightsTrafficSourceReferralHelpTooltipText"));
                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + trafficSource) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.referral)
              }
              else if (trafficSource === "Email") {
                setTrafficSourceText(t("header.InsightsTrafficSourcesEmailHelpTooltipText"));
                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + trafficSource) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.email);
              }
              else if (trafficSource === "Social") {
                setTrafficSourceText(t("header.WOTrafficSourcesSocialHelpTooltipText"));
                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + trafficSource) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.social);
              }
              else if (trafficSource === "PaidSearch") {
                setTrafficSourceText(t("header.InsightsTrafficSourcesPaidSearchHelpTooltipText"));
                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + trafficSource) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.paidSearch);
              }
            }
          }).catch((error) => {
            setBlurLoader(false);
          })
      }
      else {
        if (trafficSource === "Organic" || trafficSource === "Referral" || trafficSource === "Social") {
          setapiUrlPopup2(
            {
              url: `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetTopSourceMediumDomainsByTrafficSourceVisitorSection?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
          &startdate=${sdate}&enddate=${sdate2}&selectedcompanyid=${contextData.companyId}&countryid=${pageObjDetails.CountryId}&companyid=${pageObjDetails.CompanyId}&traffic=${trafficSource}&packageid=5&sectionid=${selectedCategoryId.sectionId}`, type: 'get', count: 0
            }
          )
        }
        else {
          setBlurLoader(true)
        }
        request(
          baseURL + `/VisitorAnalytics/GetIntelligenceTrafficSourceByVisitor?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
          &startdate=${sdate}&enddate=${sdate2}&countryid=${pageObjDetails.CountryId}&companyid=${pageObjDetails.CompanyId}&selectedcompanyid=${contextData.companyId}&getvalues=1&packageid=5&sectionid=${selectedCategoryId.sectionId}`,
          METHODS.GET,
          {},
          {
            Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
          }
        )
          .then((response) => {
            setBlurLoader(false);
            if (response != null) {
              if (!response.organic) {
                setTrafficSource("Direct")
              }
              if (trafficSource === "Organic") {
                setTrafficSourceText(t("header.PagesDetailsPopTrafficChartOrganicHelpText"));
                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + t("header.barchart_Xaxis_Below_" + trafficSource) + t("header.allPagesTrafficSourceTotal2") + response.organic);
              }
              else if (trafficSource === "Direct") {
                setTrafficSourceText(t("header.InsightsTrafficSourceDirectHelpTooltipText"));
                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + t("header.barchart_Xaxis_Below_" + trafficSource) + t("header.allPagesTrafficSourceTotal2") + response.direct);
              }
              else if (trafficSource === "Referral") {
                setTrafficSourceText(t("header.InsightsTrafficSourceReferralHelpTooltipText"));
                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + t("header.barchart_Xaxis_Below_" + trafficSource) + t("header.allPagesTrafficSourceTotal2") + response.referral);
              }
              else if (trafficSource === "Email") {
                setTrafficSourceText(t("header.InsightsTrafficSourcesEmailHelpTooltipText"));
                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + t("header.barchart_Xaxis_Below_" + trafficSource) + t("header.allPagesTrafficSourceTotal2") + response.email);
              }
              else if (trafficSource === "Social") {
                setTrafficSourceText("Website traffic from social media websites");
                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + t("header.barchart_Xaxis_Below_" + trafficSource) + t("header.allPagesTrafficSourceTotal2") + response.social);
              }
              else if (trafficSource === "PaidSearch") {
                setTrafficSourceText(t("header.InsightsTrafficSourcesPaidSearchHelpTooltipText"));
                setTrafficSourceTotal(t("header.allPagesTrafficSourceTotal1") + t("header.barchart_Xaxis_Below_" + trafficSource) + t("header.allPagesTrafficSourceTotal2") + response.paidSearch);
              }
            }
          }).catch((error) => {
            setBlurLoader(false);
          })
      }
    }
  }, [trafficSource]);

  const OpenNotification = (row) => {
    setNotificationObjDetails(row);

    let curl = "";
    let moreurls = [];
    request(
      baseURL + `/WebsiteAnalytics/GetIntelligenceDomainByVisitor?visitorid=` + row.CompanyId + `&countryid=0`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      }
    ).then((response) => {
      if (response?.resultData?.length > 0) {
        for (let i = 0; i < response.resultData.length; i++) {
          if (response.resultData[i].domainUrl != "" && curl == "") {
            curl = response.resultData[i].domainUrl;
          }
          else if (response.resultData[i].domainUrl != "") {
            moreurls.push(response.resultData[i].domainUrl)
          }
          else if (i == 0 && response.resultData.length == 1) {
            curl = "-"
          }
        }
      }
      setNotificationPopupObj(prevState => ({
        ...prevState,
        companyname: row.companyName,
        country: row.countryName,
        notificationname: row.notificationName,
        companyurl: curl,
        moreurls: moreurls,
        thresholdisvalid: true,
        notificationnameisvalid: true,
        startdateisvalid: true,
        enddateisvalid: true,
        operation: row.ruleId == 0 ? "add" : "edit",
        durationId: row.ruleId == 0 ? 4 : row.durationId,
        threshold: row.pageViewsCount > 0 ? "" + row.pageViewsCount : "",
        startdate: row.durationId == 3 ? row.fromDate : null,
        enddate: row.durationId == 3 ? row.toDate : null,
      }));
      setshowaddeditshowPopup(!showPopup)
      setErrorMessage("");
    });
    if (duration.length == 0) {
      request(
        baseURL + `/WebsiteAnalytics/GetNotificationDuration?packageid=5`,
        METHODS.GET,
        {},
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        }
      ).then((response) => {
        if (response?.resultData) {
          setDuration(response.resultData)
        }
      });
    }
  }

  const onFormElementsChange = (event) => {
    const { name, value, id, checked } = event.target;
    const forms = { ...notificationPopupObj };
    let formVlue;
    if (name == "durationId") {
      formVlue = id;
    }
    else if (name === "confirm" || name === "toggle")
      formVlue = checked;
    else if (name === "threshold") {
      formVlue = value.replace(/\D/g, "");

      if (formVlue == "0" || formVlue.trim().length == 0) {
        forms['thresholdisvalid'] = false;
      }
      else {
        forms['thresholdisvalid'] = true;
      }
    }
    else if (name === "companyname") {
      formVlue = value;
      onTextChanged(value);
    }
    else if (name == "notificationname") {
      formVlue = value.replace(/[&+()$~%'":;<>`]/g, '');
    }
    else
      formVlue = value;
    forms[name] = formVlue;
    if (formVlue.trim().length > 0 && name !== "threshold") {
      forms[name + 'isvalid'] = true;
    }
    else if (name !== "threshold") {
      forms[name + 'isvalid'] = false;
    }
    setNotificationPopupObj && setNotificationPopupObj(forms);
  };

  const validateFormElement = (e) => {
    e.preventDefault();
    let iserror = 0;
    if (notificationPopupObj.companyname.trim().length == 0) {
      setNotificationPopupObj(prevState => ({
        ...prevState,
        companynameisvalid: false
      }));
      iserror = 1;
    }
    if (notificationPopupObj.notificationname.trim().length == 0) {
      setNotificationPopupObj(prevState => ({
        ...prevState,
        notificationnameisvalid: false
      }));
      iserror = 1;
    }

    if (notificationPopupObj.threshold.trim().length == 0 || notificationPopupObj.threshold.trim() == "0 or more") {
      setNotificationPopupObj(prevState => ({
        ...prevState,
        thresholdisvalid: false
      }));
      iserror = 1;
    }
    if (notificationPopupObj.durationId == 3) {
      if (notificationPopupObj.startdate === null) {
        setErrorMessage("Enter custom date range")
        setNotificationPopupObj(prevState => ({
          ...prevState,
          startdateisvalid: false
        }));
        iserror = 1;
      }
      if (notificationPopupObj.enddate === null) {
        setErrorMessage("Enter custom date range")
        setNotificationPopupObj(prevState => ({
          ...prevState,
          enddateisvalid: false
        }));
        iserror = 1;
      }
      if (moment(notificationPopupObj.enddate) < moment(notificationPopupObj.startdate)) {
        setErrorMessage(t("header.dateValidationErrorNew"))
        setNotificationPopupObj(prevState => ({
          ...prevState,
          enddateisvalid: false
        }));
        iserror = 1;
      }
    }
    if (iserror == 0) {
      onAddEditSave();
    }
  }

  const CloseAddeditPopup = () => {
    setshowaddeditshowPopup(!showaddeditPopup)
  }

  const onTextChanged = (value) => {
    const showAutoFill = value.trim().length > 2 ? true : false;
    setNotificationPopupObj(prevState => ({
      ...prevState,
      companyname: value
    }));

    if (showAutoFill) {
      request(
        baseURL + `/WebsiteAnalytics/SearchCompanyListByName?searchtext=` + value.trim(),
        METHODS.GET,
        {},
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        }
      ).then((response) => {
        if (response.resultData !== null) {
          settemList(response.resultData)
        }
        else {
          settemList([])
        }
        setAutoFillForCompany(showAutoFill);
      });
    }
    else {
      setAutoFillForCompany(showAutoFill);
    }
  }

  const validStartDate = (current) => {
    let yesterday = moment().subtract(1, 'day');;
    return current.isAfter(yesterday);
  };

  const validEndDate = (current) => {
    let yesterday = moment().subtract(1, 'day');;
    return current.isAfter(yesterday);
  };

  const onAddEditSave = () => {
    let notificationd = {}

    if (notificationPopupObj.operation == "edit") {
      notificationd = {
        CompanyId: notificationObjDetails.CompanyId,
        CountryId: notificationObjDetails.CountryId,
        DurationId: notificationPopupObj.durationId,
        EmailAlert: 1,
        FromDate: notificationPopupObj.durationId == 3 ? notificationPopupObj.startdate : "0001-01-01T00:00:00",
        ToDate: notificationPopupObj.durationId == 3 ? notificationPopupObj.enddate : "0001-01-01T00:00:00",
        IntellConfigId: notificationObjDetails.intellConfigId,
        IsActive: 1,
        NotificationName: notificationPopupObj.notificationname,
        PageViewsCount: notificationPopupObj.threshold.replace(" or more", ""),
        RuleId: notificationObjDetails.ruleId,
        SMSAlert: 0
      }
    }
    else {
      notificationd = {
        CompanyId: notificationObjDetails.CompanyId,
        CountryId: notificationObjDetails.CountryId,
        DurationId: notificationPopupObj.durationId,
        EmailAlert: 1,
        FromDate: notificationPopupObj.durationId == 3 ? notificationPopupObj.startdate : "0001-01-01T00:00:00",
        ToDate: notificationPopupObj.durationId == 3 ? notificationPopupObj.enddate : "0001-01-01T00:00:00",
        IntellConfigId: JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId"))["WebsiteProfiles.IntelConfigId"],
        IsActive: 1,
        NotificationName: notificationPopupObj.notificationname,
        PageViewsCount: notificationPopupObj.threshold.replace(" or more", ""),
        RuleId: 0,
        SMSAlert: 0
      }
    }

    request(
      baseURL + `/WebsiteAnalytics/SetNotification?companyid=` + contextData.companyId + `&packageid=` + 5,
      METHODS.POST,
      notificationd,
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      }
    ).then((response) => {
      if (response?.resultData && response.resultData?.ruleId > 0) {
        setSuccessMessage(t("header.Message_" + convertTranslatinString(response.resultData.message)));
        setTimeout(() => {
          setSuccessMessage(undefined);
        }, ERROR_TIMEOUT);

        setshowaddeditshowPopup(false);
        setIsRefresh(true);
      }
      else {
        setErrorMessageTransaction(response.resultData.message.length > 0 ? t("header.Message_" + convertTranslatinString(response.resultData.message)) : t("header.Something_went_wrong"));
        setTimeout(() => {
          setErrorMessageTransaction(undefined);
        }, ERROR_TIMEOUT);
      }
    }).catch((error) => {
      setErrorMessageTransaction(t("header.Something_went_wrong"));
      setTimeout(() => {
        setErrorMessageTransaction(undefined);
      }, ERROR_TIMEOUT);
    });
  }

  const companyUrlClick = (event, url) => {
    event.preventDefault();
    window.open(url, "_blank");
  };

  const changeStartDate = (current) => {
    if (errorMessage == "Enter custom date range" || (errorMessage == t("header.dateValidationErrorNew") && moment(notificationPopupObj.enddate) > moment(current))) {
      setErrorMessage("");
    }
    setNotificationPopupObj(prevState => ({
      ...prevState,
      startdate: current
    }));
  }

  const changeEndDate = (current) => {
    if (errorMessage == "Enter custom date range" || (errorMessage == t("header.dateValidationErrorNew") && moment(current) > moment(notificationPopupObj.startdate))) {
      setErrorMessage("");
    }
    setNotificationPopupObj(prevState => ({
      ...prevState,
      enddate: current
    }));
  }

  const visitorItem = (item) => (
    <div
      key={item.id}
      data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}
    >
      <DashboardItem
        key={item.id}
        itemId={item.id}
        title={item.name}
        hideTitle={true}
      >
        <ChartRendererTable
          SearchText={SearchText}
          apiUrl={apiUrl}
          setheaderFilterData={setheaderFilterData}
          totalRecords={numberOfRecords}
          setNumberOfRecords={setNumberOfRecords}
          setSearchText={setSearchText}
          cardProperty={item.layout}
          legends={item.legends}
          items={items1}
          setItems={setItems1}
          showPagination={item.pagination}
          summaryTooltip={t("header.VisitorsWatchlistCountHelpTooltipText")}
          summaryBesideText={t("header.watchlistTotalWatchlist")}
          openPopupEvent={popupOperation}
          setGetTotalResults={setGetTotalResults}
          Exportdata={setCountryListForFilter}
          FilterParams={FilterParams}
          setIsRefresh={setIsRefresh}
        />
      </DashboardItem>
    </div>
  );

  const onCloseSuccessMessage = () => {
    setSuccessMessage(undefined)
  };

  const onCloseErrorMessage = () => {
    setErrorMessageTransaction(undefined)
  };

  const ModalOperation = (obj) => {
    let lineconfig = getPagesViewedLineChart();
    setpageViewedPopupLineConfig(lineconfig);
    let barconfig = getAllPopupTrafficSourceBarChart();
    setTrafficSourcePopupBarConfig(barconfig);
    let ms = new Date(Date.parse(contextData.startDate));
    let sdate = ms.getFullYear() + "-" + parseInt(ms.getMonth() + 1) + "-" + ms.getDate()
    let ms2 = new Date(Date.parse(contextData.endDate));
    let sdate2 = ms2.getFullYear() + "-" + parseInt(ms2.getMonth() + 1) + "-" + ms2.getDate()
    if (obj !== undefined && obj.CompanyId !== undefined) {
      setsearchData("");
      if (selectedCategoryId.sectionId !== 0) {
        setapiUrlPopup(
          {
            url: `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetIntelligencePageByVisitorSection?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
          &startDate=${sdate}&endDate=${sdate2}&visitorcountryid=${obj.CountryId}&visitorid=${obj.CompanyId}&pagesectionid=${selectedCategoryId.sectionId}&packageid=5&selectedcompanyid=${contextData.companyId}`, type: 'get', count: 0
          })
        setapiUrlPopup2(
          {
            url: `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetTopSourceMediumDomainsByTrafficSourceVisitorSection?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
              &startdate=${sdate}&enddate=${sdate2}&selectedcompanyid=${contextData.companyId}&countryid=${obj.CountryId}&companyid=${obj.CompanyId}&traffic=${trafficSource}&packageid=5&sectionid=${selectedCategoryId.sectionId}`, type: 'get', count: 0
          }
        )
      }
      else {
        setapiUrlPopup(
          {
            url: `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetIntelligencePagesByVisitor?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
          &startDate=${sdate}&endDate=${sdate2}&countryid=${obj.CountryId}&visitorid=${obj.CompanyId}`, type: 'get', count: 0
          })
        setapiUrlPopup2(
          {
            url: `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetTopSourceMediumDomainsByTrafficSourceAndVisitor?profileid=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
              &startdate=${sdate}&enddate=${sdate2}&selectedcompanyid=${contextData.companyId}&countryid=${obj.CountryId}&companyid=${obj.CompanyId}&traffic=${trafficSource}`, type: 'get', count: 0
          }
        )
      }
      setPageObjDetails(obj);
      setTrafficSource("Organic");
    }
    else {
      setPageObjDetails({});
      setTrafficSource("");
    }
    setshowPopup(!showPopup);
    setVistorTabKey("visitors")
    setAutoFillForCompanyPopup(false);
  }

  const onchangeVistiorTab = (event, d) => {
    if (searchData != "") {
      setsearchData("");
    }
    else if (showAutoFillForCompanyPopup && searchData == "") {
      setsearchData("emptytextbox");
    }
    setAutoFillForCompanyPopup(false);
    setVistorTabKey(event);
  };

  const popupOperation = (dataobj) => {
    if (dataobj.type === "display") {
      ModalOperation(dataobj.data)
    }
    else {
      OpenNotification(dataobj);
    }
  }

  const setDowloadDropdownMenu = () => {
    let ddList = ["Excel", "PDF"];
    return ddList.map(item => {
      return (
        <Dropdown.Item className='dropdown-item-download' onClick={() => downloadGrid(item)}>
          {item}
        </Dropdown.Item>
      );
    })
  }

  const downloadGrid = (item) => {
    if (item === "Excel") {
      downloadExcel()
    }
    else {
      downloadPDF();
    }
  }

  const downloadExcel = async () => {
    setBlurLoader(true)
    const selectedComapnyData = contextData.companies.find(dta => dta.companyId === contextData.companyId);
    let paramsList = {
      profileid: contextData.selectedProfile["WebsiteProfiles.ProfileId"],
      profileName: contextData.selectedProfile["WebsiteProfiles.DisplayName"],
      visitorid: pageObjDetails.CompanyId,
      countryid: pageObjDetails.CountryId,
      startDate: moment(contextData.startDate).format("YYYY-MM-DD"),
      endDate: moment(contextData.endDate).format("YYYY-MM-DD"),
      visitor: pageObjDetails.filterybykey,
      companyName: selectedComapnyData.companyName,
      pagesectionid: selectedCategoryId.sectionId,
      pageSectionName: selectedCategoryId.title,
      packageid: 5,
      companyid: contextData.companyId,
      webSiteName: contextData.selectedWebsite["WebsiteList.WebsiteName"],
      culture: localStorage.getItem('i18nextLng')
    }

    const tempVar = await axios.get(
      `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/ExportVisitorPageViewExcelFileWithEPPlus`,
      {
        params: { encodeStr: convertBase64(JSON.stringify(paramsList)) },
        headers: {
          'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
        }
      }
    );
    if (tempVar !== undefined && tempVar !== null) {
      setBlurLoader(false)
      let fetchDataModified = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${tempVar.data}`;
      let a = document.createElement("a");
      a.href = fetchDataModified;
      a.download = 'Insights_Visitor.xlsx';
      a.click();
    }
  };

  const downloadPDF = async () => {
    setBlurLoader(true);
    let url = apiUrlPopup.url;
    let fileName = "Insights_Pages_Viewed.pdf";
    let filters = (searchData !== "" && searchData !== null) ? "pagesTitle|contains|" + searchData : "";
    let obj = {
      Title: pageObjDetails.companyName,
      SubTitle: t("header.visitorAllMessageText1") + pageObjDetails.companyName + t("header.visitorAllMessageText2") + moment(contextData.startDate).format("MMM DD, YYYY") + t("header.visitorAllMessageText3") + moment(contextData.endDate).format("MMM DD, YYYY"),
      PageName: "Pages Viewed",
      Filters: filters,
      Url: url,
      Token: decryptData(localStorage.getItem("token"), contextData.cryptoKey),
      FileName: fileName,
      Email: decryptData(localStorage.getItem("email"), contextData.cryptoKey),
      Name: decryptData(localStorage.getItem("firstName"), contextData.cryptoKey),
      Count: 0,
      CountTitle: "",
      FilterContent: "",
      ClientName: "",
      TableHeaders: []
    }

    await axios.post(`${process.env.REACT_APP_API_KEY}/CommonV2/GeneratePdf`, obj,
      {
        responseType: 'arraybuffer',
        headers: {
          'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
        }
      })
      .then((res) => {
        const contentType = res.headers['content-type'];
        if (res.status === 200) {
          if (contentType?.includes('application/pdf')) {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          }
          else {
            setAlertVisible(true);
            setTimeout(() => {
              setAlertVisible(false);
            }, 3000);
          }
          setBlurLoader(false);
        }
        else {
          setBlurLoader(false);
          console.error('Error generating PDF.');
        }
      })
      .catch((err) => {
        setBlurLoader(false);
        console.error('Error generating PDF:', err);
      })
  };

  const downloadPageLevelPDF = async (pageName, fileName) => {
    setBlurLoader(true);
    const content = document.createElement("div");
    content.innerHTML = document.querySelector('.tab-content .fade.tab-pane.active.show.' + pageName)?.innerHTML;
    const options = {
      filename: fileName,
      margin: [5, 2, 2, 2], //[top, right, bottom, left]
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "landscape" }
    };
    const combinedNode = document.createElement("div");
    combinedNode.innerHTML = `<div class="row mt-3">
            <div class="col-md-12">
                <div class="ms-2 mb-0 pdf-download-title">${pageObjDetails.companyName}</div>
            </div>
        </div>`;
    combinedNode.appendChild(content.cloneNode(true));
    combinedNode.querySelectorAll('.pagination-content')?.forEach(btn => {
      btn.style.display = "none";
    });
    if (combinedNode.querySelector(".table-responsive-lg")) {
      combinedNode.querySelector(".table-responsive-lg")?.querySelectorAll("tr").forEach(tr => {
        tr.style.pageBreakInside = "avoid";
      });
    }
    combinedNode.querySelectorAll(".me-1")?.forEach(item => {
      item.style.display = "none";
    });
    combinedNode.querySelectorAll("a").forEach(link => {
      link.removeAttribute("href");
      link.style.pointerEvents = "none";
    });
    combinedNode.innerHTML = await covertSVGToIMG(combinedNode);
    await html2pdf().set(options).from(combinedNode).save().then(() => {
      setBlurLoader(false);
    });
  }

  const loadImageAsync = (img) => {
    return new Promise((resolve, reject) => {
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  };

  const covertSVGToIMG = async (content) => {
    var dynamicSvgs = content.querySelectorAll('svg:not(.svg-inline--fa):not(.icon-nav)');

    for (let i = 0; i < dynamicSvgs.length; i++) {
      dynamicSvgs[i].querySelectorAll("line").forEach(x => {
        x.setAttribute("stroke", "#dddee2");
      });
      dynamicSvgs[i].querySelectorAll("path.domain[stroke='currentColor']").forEach(x => {
        x.style.display = "none";
      });

      var canvas = document.createElement('canvas');
      const scaleFactor = 2;
      canvas.width = dynamicSvgs[i].width.baseVal.value * scaleFactor;
      canvas.height = dynamicSvgs[i].height.baseVal.value * scaleFactor;

      var context = canvas.getContext('2d');
      context.scale(scaleFactor, scaleFactor);

      context.fillStyle = '#ffffff';
      context.fillRect(0, 0, canvas.width, canvas.height);

      var svgData = new XMLSerializer().serializeToString(dynamicSvgs[i]);
      var img = new window.Image();
      img.src = 'data:image/svg+xml,' + encodeURIComponent(svgData);

      await loadImageAsync(img);

      const scaleWidthFactor = 1;

      context.drawImage(img, 0, 0, img.width * scaleWidthFactor, img.height * scaleWidthFactor);

      var imgSrc = canvas.toDataURL('image/jpeg');
      var dynamicImg = document.createElement('img');
      dynamicImg.src = imgSrc;

      let parent = dynamicSvgs[i].parentNode;
      while (parent && parent !== content) {
        parent = parent.parentNode;
      }

      if (parent === content) {
        dynamicSvgs[i].parentNode.replaceChild(dynamicImg, dynamicSvgs[i]);
      }
    }
    return content.innerHTML;
  };

  return (
    <>
      <Row>
        <Col>
          <ModalComponent title={t("header.notificationSetting")}
            showCloseButton={true}
            open={showaddeditPopup}
            handleClose={CloseAddeditPopup}
          >
            <Row>
              <Col md={12}>
                <p className='fw-bold'>{t("header.WONotificationnotifiedText")}</p>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={3}>
                {t("header.WONotificationSetupCName")}   {notificationPopupObj.operation == "add" && <span style={{ color: "red" }}>*</span>}
              </Col>
              <Col md={4}>
                {notificationPopupObj.companyname}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={3}>
                {t("header.WONotificationSetupCountry")}
              </Col>
              <Col md={4}>
                {notificationPopupObj.country}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={3}>
                {t("header.WONotificationSetupCompURL")}
              </Col>
              <Col md={9}>
                {notificationPopupObj.companyurl !== "-" ? <a className='link-primary' onClick={(e) => companyUrlClick(e, notificationPopupObj.companyurl)} >{notificationPopupObj.companyurl}</a> : "-"}

                {notificationPopupObj.moreurls.length > 0 && <>
                  <OverlayTrigger
                    placement="bottom"
                    trigger="click"
                    overlay={
                      <Popover className="notification-url-popover">
                        <Popover.Content>
                          <ul className="list-unstyled auto-dropdown-menu mb-0">
                            {notificationPopupObj.moreurls.map((item) =>

                              <li className="auto-dropdown-menu-item mt-1 mb-1 link-primary" onClick={(e) => companyUrlClick(e, item)} >
                                {item}
                              </li>
                            )
                            }
                          </ul>
                        </Popover.Content>
                      </Popover>
                    }>
                    <Button variant="white" size="sm" className="m-2">{t("header.WONotificationSetupMore")}</Button>
                  </OverlayTrigger></>}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={3}>
                {t("header.WONotificationSetupName")} <span style={{ color: "red" }}>*</span>
              </Col>
              <Col md={4}>
                {
                  (notificationPopupObj.notificationnameisvalid ? <Form.Control maxLength={100} type="text" value={notificationPopupObj.notificationname} onChange={(e) => onFormElementsChange(e)} name="notificationname" autocomplete="off" /> :
                    <Form.Control isInvalid maxLength={100} value={notificationPopupObj.notificationname} type="text" onChange={(e) => onFormElementsChange(e)} name="notificationname" autocomplete="off" />)
                }
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={12}>
                <b>
                  {t("header.notificationNofifyMessage")}
                </b><br />
                {t("header.notificationsProcessingMessage")}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={6}>
                <Form.Group>
                  {duration.map((type, i) => (
                    <FormCheck
                      checked={type.durationId == notificationPopupObj.durationId ? true : false}
                      type="radio"
                      value={notificationPopupObj.durationId}
                      label={t("header." + convertTranslatinString(type.description))}
                      name="durationId"
                      id={type.durationId}
                      onChange={(e) => onFormElementsChange(e)}
                    />
                  ))}
                </Form.Group>
              </Col>
              {notificationPopupObj.durationId == 3 && <>
                <Col md={6}>
                  <Row>
                    <Col md={6}>
                      <Datetime
                        closeOnClickOutside={false}
                        timeFormat={false}
                        closeOnSelect={true}
                        onChange={changeStartDate}
                        className="mt-2"
                        isValidDate={validStartDate}
                        renderInput={(props, openCalendar) => (
                          <InputGroup>
                            <Form.Control
                              required
                              type="text"
                              value={notificationPopupObj.startdate ? moment(notificationPopupObj.startdate).format("MMM DD,YYYY") : ""}
                              placeholder={t("header.notificationsFrom")}
                              onFocus={openCalendar}
                            />
                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                          </InputGroup>
                        )} />
                    </Col>
                    <Col md={6}>
                      <Datetime
                        closeOnClickOutside={false}
                        timeFormat={false}
                        closeOnSelect={true}
                        onChange={changeEndDate}
                        isValidDate={validEndDate}
                        className="mt-2"
                        renderInput={(props, openCalendar) => (
                          <InputGroup>
                            <Form.Control
                              required
                              type="text"
                              value={notificationPopupObj.enddate ? moment(notificationPopupObj.enddate).format("MMM DD,YYYY") : ""}
                              placeholder={t("header.notificationsTo")}
                              onFocus={openCalendar}
                              onChange={() => { }} />
                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                          </InputGroup>
                        )} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8}>
                      <div className="invalid-feedback d-block" style={{ textAlign: "left" }}>{errorMessage ? errorMessage : ''}</div>
                    </Col>
                  </Row>
                </Col>
              </>}
            </Row>
            <Row className="mt-4">
              <Col md={3}>
                {t("header.WONotificationSetupPVThreshold")} <span style={{ color: "red" }}>*</span>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  {
                    (notificationPopupObj.thresholdisvalid ? <Form.Control maxLength={8} onBlur={() => {
                      if (notificationPopupObj.threshold.length > 0) {
                        setNotificationPopupObj(prevState => ({
                          ...prevState,
                          threshold: notificationPopupObj.threshold + " or more"
                        }));
                      }
                    }} onFocus={() => {
                      if (notificationPopupObj.threshold.length > 0) {
                        setNotificationPopupObj(prevState => ({
                          ...prevState,
                          threshold: notificationPopupObj.threshold.replace(" or more", "")
                        }))
                      }
                    }} type="text" value={notificationPopupObj.threshold} onChange={(e) => onFormElementsChange(e)} name="threshold" autocomplete="off" /> :
                      <Form.Control isInvalid maxLength={8} onBlur={() => {
                        if (notificationPopupObj.threshold.length > 0) {
                          setNotificationPopupObj(prevState => ({
                            ...prevState,
                            threshold: notificationPopupObj.threshold + " or more"
                          }));
                        }
                      }} onFocus={() => {
                        if (notificationPopupObj.threshold.length > 0) {
                          setNotificationPopupObj(prevState => ({
                            ...prevState,
                            threshold: notificationPopupObj.threshold.replace(" or more", "")
                          }))
                        }
                      }}
                        value={notificationPopupObj.threshold} type="text" onChange={(e) => onFormElementsChange(e)} name="threshold" autocomplete="off" />)
                  }
                </Form.Group>
                {notificationPopupObj.threshold == "0 or more"
                  && <div className="invalid-feedback d-block">{t("header.WONotificationSetupPageViewsThreshold")}</div>
                }
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={3}>
              </Col>
              <Col md={3}>
                <ButtonComponent variant="secondary" btnText={t("header.Btn_Save")} isIcon={false} isPrimary={true} btn_class="me-3" onClick={(e) => validateFormElement(e)}>
                </ButtonComponent>
                <ButtonComponent variant="btn-outline-primary" btnText={t("header.Btn_Cancel")} isIcon={false} isPrimary={false} btn_class="mb-0" onClick={(e) => CloseAddeditPopup(e)} >
                </ButtonComponent>
              </Col>
            </Row>
          </ModalComponent>
        </Col>
      </Row>
      <Row>
        <Col>
          {(successMessage || errorMessageTransaction) && <div className="fade modal-backdrop show"></div>}
          <Alert
            className="toast-custom"
            variant="success"
            show={successMessage ? true : false}
            onClose={() => onCloseSuccessMessage()}
          >
            <div className="d-flex justify-content-between">
              <div>
                <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                {successMessage}
              </div>
              <Button variant="close" size="xs" onClick={() => onCloseSuccessMessage()} />
            </div>
          </Alert>
          <Alert
            className="toast-custom"
            variant="danger"
            show={errorMessageTransaction ? true : false}
            onClose={() => onCloseErrorMessage()}
          >
            <div className="d-flex justify-content-between">
              <div>
                <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                {errorMessageTransaction}
              </div>
              <Button variant="close" size="xs" onClick={() => onCloseErrorMessage()} />
            </div>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <ModalComponent
            title={pageObjDetails.companyName}
            showCloseButton={true}
            open={showPopup}
            handleClose={ModalOperation}
            className="visitor-analytics-modal"
          >
            <Row>
              <Col>
                <Row className="position-relative hide-mobile"
                  hidden={visitorTabKey == "traffic" ? true : false}
                >
                  <Col md={12}>
                    {(
                      <Row className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
                        <Row className="mb-4">
                          <Col md={12} className="text-end">
                            <div className="icon-nav-list svg-icon-nav-list float-end mb-2">
                              <span className={pageObjDetails["Watchlist.pgviews"] > 0 ? "icon-nav-box hide-mobile" : "icon-nav-box hide-mobile disabled"} onClick={() => downloadPageLevelPDF('visitorsContent', 'Insights_Pages_Viewed')}>
                                <svg className="icon-nav">
                                  <title>{t("header.Icon_Tooltip_DownloadPDF")}</title>
                                  <use href={iconSprite + `#Download`} />
                                </svg>
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} className="text-end">
                            <div className="search-box-container modal-search-box">
                              <Search
                                setsearchData={setsearchData}
                                searchData={searchData}
                                searchWaterMark={t("header.visitorAllWatermark")}
                                isAutoComplete={true}
                                setAutoFillForCompany={setAutoFillForCompanyPopup}
                                showAutoFillForCompany={showAutoFillForCompanyPopup}
                                data={headerFilterDataPopup}
                                type={"client"}
                              ></Search>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                    )}
                  </Col>
                </Row>
                <Row className="position-relative hide-mobile"
                  hidden={visitorTabKey == "visitors" ? true : false}
                >
                  <Col md={12}>
                    {(
                      <>
                        <Row className={localStorage.getItem("isDemoClient") ? "custom-disable mb-4" : "mb-4"}>
                          <Col md={12} className="text-end">
                            <div className="icon-nav-list svg-icon-nav-list float-end mb-2">
                              <span className="icon-nav-box hide-mobile" onClick={() => downloadPageLevelPDF('trafficContent', 'Insights_Traffic_Sources')}>
                                <svg className="icon-nav">
                                  <title>{t("header.Icon_Tooltip_DownloadPDF")}</title>
                                  <use href={iconSprite + `#Download`} />
                                </svg>
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <Tab.Container
                  defaultActiveKey="visitors"
                  onSelect={onchangeVistiorTab}
                >
                  <Nav fill variant="pills" className="tab subtab">
                    <Nav.Item>
                      <Nav.Link eventKey="visitors" className="mb-sm-3 mb-md-0" >
                        {t("header.VisitorsActivityPopPagesTabText")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="traffic" className="mb-sm-3 mb-md-0" >
                        {t("header.VisitorsActivityPopTrafficTabText")}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="visitors" className="visitorsContent">
                      {visitorTabKey == "visitors" ? (<Row>
                        <Col md={12} className="chart-data-renderer">
                          {<div className={`preloader loader-center-align  ${blurLoader ? "" : "show"}`}>
                            <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                          </div>}
                          {(alertVisible || blurLoader) && (<div className="fade modal-backdrop show"></div>)}
                          <Alert
                            className="toast-custom"
                            variant="success"
                            show={alertVisible}
                          >
                            <div className="d-flex justify-content-between">
                              <div>
                                <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                                {t("header.PdfDeliveredToMailMessage")}
                              </div>
                            </div>
                          </Alert>
                          <Row className="mt-4">
                            <Col md={12}>
                              <p>{t("header.visitorAllMessageText1")} {pageObjDetails.companyName} {t("header.visitorAllMessageText2")} {moment(contextData.startDate).format("DD MMM YYYY")} {t("header.visitorAllMessageText3")}  {moment(contextData.endDate).format("DD MMM YYYY")}</p>
                            </Col>
                          </Row>
                          {pageObjDetails["Watchlist.pgviews"] > 0 ? <>
                            <Row className="api-chart">
                              <Col md={12}>
                                <ApiChartRenderer isLegendShown={false}
                                  cardName={pageViewedPopupLineConfig.name} selectedCategoryId={selectedCategoryId}
                                  services={apiChartsVisitorInsightsService} chartType={"line2"} itemId={21}
                                  cardProperty={pageViewedPopupLineConfig.layout} countryid={pageObjDetails.CountryId}
                                  visitorid={pageObjDetails.CompanyId} />
                              </Col>
                            </Row>
                            <Row className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
                              <Col md={12} className="text-end">
                                <div className="icon-nav-list svg-icon-nav-list float-end mb-2">
                                  <span className={pageObjDetails["Watchlist.pgviews"] > 0 ? "icon-nav-box ms-2 hide-mobile" : "icon-nav-box hide-mobile disabled"}>
                                    <Dropdown as={ButtonGroup}>
                                      <Dropdown.Toggle split className="card-dropdown-btn p-0 border-0 pdf-download-btn">
                                        <svg className="icon-nav">
                                          <title>{t("header.Icon_Tooltip_Download")}</title>
                                          <use href={iconSprite + `#Download`} />
                                        </svg>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {setDowloadDropdownMenu()}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </span>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col md={12}>
                                <ChartRendererTable
                                  SearchText={searchData}
                                  apiUrl={apiUrlPopup}
                                  showCount={false}
                                  setheaderFilterData={setheaderFilterDataPopup}
                                  showNumberOfRows={10}
                                  showPagination={true}
                                />
                              </Col>
                            </Row>
                          </> : <div className="text-center no-data">{t("header.NoDataFound")}</div>}

                        </Col>
                      </Row>) : (<div></div>)}
                    </Tab.Pane>
                    <Tab.Pane eventKey="traffic" className="trafficContent">
                      {visitorTabKey == "traffic" ? (<Row>
                        <Col md={12} className="chart-data-renderer">
                          {<div className={`preloader loader-center-align  ${blurLoader ? "" : "show"}`}>
                            <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                          </div>}
                          {blurLoader && (<div className="fade modal-backdrop show"></div>)}
                          <Row className="mt-4">
                            <Col md={12}>
                              <p>{t("header.visitorAllTrafficMessageText1")} {pageObjDetails.companyName} {t("header.watchlistMessageText")} {moment(contextData.startDate).format("DD MMM YYYY")} {t("header.visitorAllTrafficMessageText2")} {moment(contextData.endDate).format("DD MMM YYYY")}</p>
                            </Col>
                          </Row>

                          {pageObjDetails["Watchlist.pgviews"] > 0 ? <>
                            <Row className="mb-0 api-chart">
                              <Col md={12}  >
                                <ApiChartRenderer isLegendShown={false} selectedCategoryId={selectedCategoryId}
                                  services={apiChartsVisitorInsightsService} chartType={"bar2"} itemId={22}
                                  barChartClickEvent={function (d) { setTrafficSource(d.name); }}
                                  countryid={pageObjDetails.CountryId} visitorid={pageObjDetails.CompanyId}
                                  cardProperty={trafficSourcePopupBarConfig.layout} hideCardMetrics={true} cardName={"watchlistTabTrafficeSource"}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-0">
                              <Col md={12}>
                                {trafficSourceText &&
                                  <div className="alert alert-gray-800" role="alert">
                                    <p className="mb-0 text-body fw-bold"><i className="fa fa-info-circle lg"></i> {trafficSourceText}</p>
                                  </div>
                                }
                              </Col>
                            </Row>
                            <Row className="mb-0 mt-0">
                              <Col md={12} >
                                <p className="text-body fw-bold">{trafficSourceTotal} </p>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col md={12}>
                                {(trafficSource === "Organic" || trafficSource === "Referral" || trafficSource === "Social") && <ChartRendererTable
                                  apiUrl={apiUrlPopup2}
                                  showCount={false}
                                  showNumberOfRows={10}
                                  showPagination={true}
                                />}
                              </Col>
                            </Row>
                          </> : <div className="text-center no-data">{t("header.NoDataFound")}</div>}
                        </Col>
                      </Row>) : (<div></div>)}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </Row>
          </ModalComponent>
        </Col>
      </Row>
      {blurLoader && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}
      {watchList.map(deserializeItem).map(visitorItem)}
    </>
  );
};

export default withRouter(Watchlist);
