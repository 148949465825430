import React, { useContext, useEffect, useState } from "react";
import PropTypes, { string } from "prop-types";
import { useCubeQuery } from "@cubejs-client/react";
import { Col, Row } from "@themesberg/react-bootstrap";
import Loader from "../components/VoltLoader";
import ChartsComponent from "../components/Charts";
import TableComponent from "../components/Table";
import {
  tbRowWithCheckBox,
  tbRowWithToggle,
  checkArrayElementsAreSame,
  tbHeaderWithSorting,
  tbHeaderReplace,
} from "../AppConfig";
import { repplaceHeaderData, SessionTimeoutWarningTime } from "../utils/common";
import AppContext from "../components/AppContext";
import axios from "axios";
import { json } from "d3";
import { decryptData } from "../utils/crypto";
//import apiData from "./jsonfile"
//`${process.env.REACT_APP_API_KEY}/Home/ValidateLogin`
// {
//   params: {
//     EmailAddress: email,
//     Password: password
//   }
//}


const ChartRenderer = ({
  vizState,
  cardProperty,
  items,
  setItems,
  onItemChange,
  pageNo,
  setPageNo,
  onPageNoChange,
  showSearchTextBox,
  searchText,
  setSearchText,
  resultSet,
  handlePlusButtonClick,
  onSearchBoxChange,
  legends,
  totalRecords,
  showPagination,
  deleteSubscriber,
  editSubscriber,
  showPaginationSummary,
  offset,
  setOffset,
  isRefresh,
  setIsRefresh,
  setd3,
  minimumRows,
  setShowModal,
  setSelectedItem,
  cardName,
  isSubscribe,
  title,
  downloadExcel,
  showPlusModal,
  setShowPlusModal,
  apiUrl,
  setNumberOfRecords
}) => {
  const [apiResponse, setApiResponse] = useState("")
  useEffect(() => {
    if (apiUrl !== "" && apiUrl !== undefined) {
      const config = {
        headers: {
          'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
        }
      }
      if (apiUrl.type === "get") {

        axios.get(apiUrl.url, config).then((response) => {
          setApiResponse(response.data);

          setNumberOfRecords(response.data.count)
          if (response.data.count !== undefined) {

            apiUrl.count = response.data.count;

          }

          // if(response.data[0].count !== undefined){

          //   apiUrl.count=response.data[0].count ;
          //   setNumberOfRecords(response.data.loadResponses[0].count)
          // }

        }).catch((e) => console.log(e));
      } else {
        axios.post(apiUrl.url, apiUrl.data, config).then((response) => {
          setApiResponse(response.data);

          // if(response.data.loadResponses[0].count !== undefined){
          //   setNumberOfRecords(response.data.loadResponses[0].count)
          // }
        }).catch((e) => console.log(e));
      }
    }


  }, [apiUrl]);

  const { widjetName, query, chartType, pivotConfig, tableCaption } = vizState;
  const TypeToChartComponent = {
    line: (props) => {

      return <ChartsComponent apiUrl={apiUrl} resultSetNew={apiResponse} type="line" {...props} />;
    },
    multiLine: (props) => {
      return <ChartsComponent apiUrl={apiUrl} resultSetNew={apiResponse} type="multiLine" {...props} />;
    },
    bar: (props) => {
      return <ChartsComponent type="bar" resultSetNew={apiResponse} {...props} />;
    },
    multiBar: (props) => {
      return <ChartsComponent type="multiBar" {...props} />;
    },
    area: (props) => {
      return <ChartsComponent type="area" {...props} />;
    },
    pie: (props) => {
      return <ChartsComponent type="pie" {...props} />;
    },
    donet: (props) => {
      if (apiResponse.data !== undefined) {
        return <ChartsComponent apiUrl={apiUrl} resultSetNew={apiResponse} type="donet" {...props} />;
      }
    },
    map: (props) => {
      return <ChartsComponent type="map" apiUrl={apiUrl} resultSetNew={apiResponse} {...props} />;
    },
    scatter: (props) => {
      return <ChartsComponent type="scatter"  {...props} />;
    },
    number: ({ resultSet }) => {
      return (
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{
            height: "100%",
          }}
        >
          <Col>
            <h4
              style={{
                textAlign: "center",
              }}
            >
              {resultSet.seriesNames().map((s) => resultSet.totalRow()[s.key])}
            </h4>
          </Col>
        </Row>
      );
    },
    table: ({
      pageNo,
      setPageNo,
      setItems,
      items,
      onPageNoChange,
      onItemChange,
      searchText,
      setSearchText,
      onSearchBoxChange,
      resultSet,
      totalRecords,
      showPagination,
      showPaginationSummary,
      showSearchTextBox,
      isRefresh,
      setIsRefresh,
      widjetName,
      queryArray,
      pivotConfig,
      contextData,
      tableCaption,
      minimumRows,
      setShowModal,
      setSelectedItem,
      cardName,
      title,
      downloadExcel,
      handlePlusButtonClick,
      showPlusModal,
      setShowPlusModal,
      deleteSubscriber,
      editSubscriber,
      apiUrl
    }) => {
      // if(apiUrl !== "" || apiUrl !== undefined){

      //   let data = {...resultSet}
      //   let dataRows = [{
      //     "userId": 1,
      //     "id": 1,
      //     "title": "sunt aut facere repellat provident occaecati excepturi optio reprehenderit",
      //     "body": "quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto"
      // }]
      //   //let dataResult = data.loadResponses[0].annotation.dimensions["Archivealerts1.AlertDetails"].shortTitle;
      //   if(resultSet.loadResponses[0].annotation.dimensions["Archivealerts1.AlertDetails"] !== undefined){
      //     resultSet.loadResponses[0].annotation.dimensions["Archivealerts1.AlertDetails"].shortTitle="new"
      //   }

      //   resultSet.loadResponses[0].data = dataRows;
      //   loadApi(apiUrl)
      //   //resultSet=data;

      // }

      return renderTable(
        pageNo,
        setPageNo,
        setItems,
        items,
        onPageNoChange,
        onItemChange,
        searchText,
        setSearchText,
        onSearchBoxChange,
        resultSet,
        totalRecords,
        showPagination,
        showPaginationSummary,
        showSearchTextBox,
        isRefresh,
        setIsRefresh,
        widjetName,
        queryArray,
        pivotConfig,
        contextData,
        tableCaption,
        minimumRows,
        setShowModal,
        setSelectedItem,
        cardName,
        title,
        downloadExcel,
        handlePlusButtonClick,
        showPlusModal,
        setShowPlusModal,
        deleteSubscriber,
        editSubscriber,
        apiUrl
      );
    },
  };

  const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
    .map((key) => ({
      [key]: React.memo(TypeToChartComponent[key]),
    }))
    .reduce((a, b) => ({ ...a, ...b }));
  const component = TypeToMemoChartComponent[chartType];
  const contextData = useContext(AppContext);
  let queryArray = [];
  if (Array.isArray(query)) {
    query[0].offset = offset;
    queryArray = query;
  } else {
    query.offset = offset;
    queryArray.push(query);
  }
  const Executingquery = Array.isArray(query) ? query[0] : query;

  const renderProps = useCubeQuery(Executingquery, { subscribe: isSubscribe === true });

  // if(renderProps){

  // }else{

  // }

  var totalRecords = totalRecords;
  var show = false;

  //api call
  if (apiUrl !== "" && showPagination) {

    show = showPagination.show;
  } else {





    if (showPagination) {
      // const comdetails = CompanyDetails(cmpquery);
      show = showPagination.show;

      const o = Page(showPagination);

      if (o) {
        totalRecords = o.series().map((x) => x.series[0].value)[0];
        //totalRecords=o && o!=null?o.series().series()[0].value:0;
      }
      // const queryObject = JSON.parse(showPagination.query);
      // if(queryObject.widjetName !== undefined && renderProps.resultSet !== null && queryObject.widjetName === "WatchlistCount"){
      //   totalRecords = GetWatchListCategoryWisePage(renderProps.resultSet, queryObject.query);
      // }
      // else{
      //     const o = Page(showPagination);

      //     if (o) {
      //       totalRecords = o.series().map((x) => x.series[0].value)[0];
      //       //totalRecords=o && o!=null?o.series().series()[0].value:0;
      //     }
      //   }
    }
  }

  const renderTable = (
    pageNo,
    setPageNo,
    setItems,
    items,
    onPageNoChange,
    onItemChange,
    searchText,
    setSearchText,
    onSearchBoxChange,
    resultSet,
    totalRecords,
    showPagination,
    showPaginationSummary,
    showSearchTextBox,
    isRefresh,
    setIsRefresh,
    widjetName,
    queryArray,
    pivotConfig,
    contextData,
    tableCaption,
    minimumRows,
    setShowModal,
    setSelectedItem,
    cardName,
    title,
    downloadExcel,
    handlePlusButtonClick,
    showPlusModal,
    setShowPlusModal,
    deleteSubscriber,
    editSubscriber,
    apiUrl
  ) => {

    let tableHeader = [];
    if (apiUrl !== "" && apiResponse) {



      resultSet = apiResponse
      //tableHeader = Object.keys(resultSet.data[0]);
      tableHeader = resultSet.headers
    } else {
      tableHeader = [...resultSet.tableColumns(pivotConfig)];
    }


    let tableData = [];
    if (widjetName === "UserWecastdigest") {
      tableData = [...getUserWebcastdigestData(resultSet, contextData)];
    } else if (widjetName === "Userappdigest") {
      tableData = [...getUserAppdigestData(resultSet, queryArray)];
    } else if (widjetName === "Watchlistdetail") {
      tableData = getWatchlistDetailsData(resultSet, queryArray, contextData);
    } else if (widjetName === "Watchlist") {
      tableData = [...getWatchlistCardata(resultSet, queryArray, contextData)];
    } else if (widjetName === "VisitorDetails") {
      tableData = [...getVisitorDetails(resultSet, queryArray)];
    } else if (cardName === "Polls") {
      tableData = [...getPollsCardata(resultSet)];
    } else if (cardName === "Multipoll Questions") {
      tableData = [...getMultiPollsCardata(resultSet)];
    } else {
      if (apiUrl !== "" && apiResponse) {
        resultSet = apiResponse
        tableData = resultSet.data;
      } else {
        tableData = [...resultSet.tablePivot(pivotConfig)];
      }

      //let tableData = [...resultSet.tablePivot(pivotConfig)];
    }
    //let tableData1 = [...resultSet.timeDimensionBackwardCompatibleData()]
    //let tableData1 = [...resultSet.rawData()];
    if (tableHeader !== undefined && tableHeader.length > 0 && apiUrl == "") {
      tableHeader.forEach((data, index) => {
        let sordingData = tbHeaderWithSorting.some((dta) => dta === data.key);
        data.isSortable = sordingData;
        if (sordingData) {
          data.isSortDesc = false;
          data.isSortAscAndDesc = true;
        }
        data.shortTitle = tbHeaderReplace.some((dta) => dta === data.key)
          ? repplaceHeaderData(data.key)
          : data.shortTitle;
      });
    }
    if (tableData !== undefined && tableData.length > 0) {
      tableData.forEach((data, index) => {
        let hasToggle = checkArrayElementsAreSame(
          Object.keys(data),
          tbRowWithToggle
        );
        let hasCheckbox = checkArrayElementsAreSame(
          Object.keys(data),
          tbRowWithCheckBox
        );
        if (hasToggle) {
          data.isToggle = false;
        }

        if (hasCheckbox) {
          data.isCheckBox = false;
        }

        // tableHeader.forEach((headerDt) => {
        // });
      });
    }
    return (
      <TableComponent
        tableHeaderData={tableHeader}
        tableBodyData={tableData}
        pageNo={pageNo}
        setPageNo={setPageNo}
        onPageNoChange={onPageNoChange}
        onItemChange={onItemChange}
        showPagination={showPagination}
        showPaginationSummary={showPagination}
        searchText={searchText}
        setSearchText={setSearchText}
        showSearchTextBox={showSearchTextBox}
        onSearchBoxChange={onSearchBoxChange}
        totalRecords={totalRecords}
        isRefresh={isRefresh}
        setIsRefresh={setIsRefresh}
        setItems={setItems}
        items={items}
        tableCaption={tableCaption}
        minimumRows={minimumRows}
        setShowReportDetailModal={setShowModal}
        setSelectedItem={setSelectedItem}
        cardName={cardName}
        title={title}
        downloadExcel={downloadExcel}
        handlePlusButtonClick={handlePlusButtonClick}
        showPlusModal={showPlusModal}
        setShowPlusModal={setShowPlusModal}
        deleteSubscriber={deleteSubscriber}
        editSubscriber={editSubscriber}
      />
    );
  };

  const getUserWebcastdigestData = (resultSet, contextData) => {
    let userWebcastdigestData = resultSet.loadResponses[0].data;
    if (userWebcastdigestData.length === 0 && contextData.webcastClientId) {
      userWebcastdigestData.push({
        "Userwebcastdigest.Finalstatus": "1",
        "Userwebcastdigest.frequency": "Live Webcast Insight Summary",
      });
      userWebcastdigestData.push({
        "Userwebcastdigest.Finalstatus": "1",
        "Userwebcastdigest.frequency": "Quarterly Webcast Insight Summary",
      });
      return userWebcastdigestData;
    }
    return userWebcastdigestData;
  };

  const getUserAppdigestData = (resultSet, queryArray) => {
    let userAppdigestData = resultSet.loadResponses[0].data;
    if (userAppdigestData.length === 0) {
      return userAppdigestData;
    }
    let fluryData = [];
    const resResponse = ExecuteQueryObject(queryArray[1]);
    if (resResponse !== null) {
      fluryData = resResponse.loadResponses[0].data;
      if (
        fluryData !== null &&
        fluryData !== undefined &&
        userAppdigestData !== null &&
        userAppdigestData !== undefined
      ) {
        userAppdigestData.forEach((element) => {
          let appdigest = fluryData.find(
            (x) =>
              x["Userappdigest.flurryanalyticid"] ===
              element["Flurryanalytic.id"]
          );
          if (appdigest !== null && appdigest !== undefined) {
            element["WebsitesforAppdigest.monthly"] =
              "" + appdigest["Userappdigest.active"] + "";
          } else {
            element["WebsitesforAppdigest.monthly"] = "1";
          }
        });
      }
    }
    return userAppdigestData;
  };

  const getWatchlistDetailsData = (resultSet, queryArray, contextData) => {
    let watchListDatatable = [...resultSet.loadResponses[0].data];
    let tempFinalWatchlist = [];

    if (watchListDatatable.length === 0) {
      return watchListDatatable;
    }

    let visitorids = [];
    let visitorcontryids = [];

    watchListDatatable.forEach((element) => {
      visitorids.push('"' + element["Watchlist.Visitorid"] + '"');
    });

    watchListDatatable.forEach((element) => {
      visitorcontryids.push('"' + element["Watchlist.Visitorcountryid"] + '"');
    });

    var cmpquery = `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"dimensions\":[\"Companycontrydetails.companycountry\",\"Companycontrydetails.categorynames\",\"Companycontrydetails.Visitorid\",\"Companycontrydetails.Visitorcountryid\"],\"timeDimensions\":[],\"order\":{\"Companycontrydetails.companycountry\":\"asc\"},\"filters\":[{\"member\":\"Companycontrydetails.Visitorid\",\"operator\":\"equals\",\"values\":[${visitorids}]},{\"member\":\"Companycontrydetails.Visitorcountryid\",\"operator\":\"equals\",\"values\":[${visitorcontryids}]},{\"member\":\"Visitor_Pageviews.ProfileId\",\"operator\":"equals\",\"values\":[\"${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}\"]}],\"measures\":[]}}`;
    // var cmpquery = `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"dimensions\":[\"Companycontrydetails.companycountry\",\"Companycontrydetails.categorynames\",\"Companycontrydetails.Visitorid\",\"Companycontrydetails.Visitorcountryid\"],\"timeDimensions\":[],\"order\":{\"Companycontrydetails.companycountry\":\"asc\"},\"filters\":[{\"member\":\"Companycontrydetails.cca_id\",\"operator\":\"notEquals\",\"values\":["59"]},{\"member\":\"Companycontrydetails.Visitorid\",\"operator\":\"equals\",\"values\":[${visitorids}]},{\"member\":\"Companycontrydetails.Visitorcountryid\",\"operator\":\"equals\",\"values\":[${visitorcontryids}]},{\"member\":\"Visitor_Pageviews.ProfileId\",\"operator\":"equals\",\"values\":[\"${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}\"]}],\"measures\":[]}}`;

    const companyDataTable = ExecuteQuery(cmpquery);

    const notificationDataTable = ExecuteQueryObject(queryArray[1]);
    const pageViewsDataTable = ExecuteQueryObject(queryArray[2]);

    watchListDatatable.forEach((element) => {
      element["Watchlist.pgviews"] = "0";
      element["Watchlist.delete"] =
        element["Watchlist.delete"] + "||" + element["Watchlist.active"];
      if (
        notificationDataTable != null &&
        notificationDataTable.loadResponses[0].data != null &&
        notificationDataTable.loadResponses[0].data.length > 0
      ) {
        let obgnot = notificationDataTable.loadResponses[0].data.find(
          (x) =>
            x["Notifications.Aicompanyid"] == element["Watchlist.Visitorid"] &&
            x["Notifications.Aicountryid"] ==
            element["Watchlist.Visitorcountryid"]
        );
        if (obgnot != null && obgnot !== undefined) {
          element["Watchlist.active"] = obgnot["Notifications.Active"];
          element["Watchlist.delete"] = true;
          element["Watchlist.vistorcomp"] =
            obgnot["Notifications.VisitorandCountry"];
        }
      }

      if (
        companyDataTable != null &&
        companyDataTable.loadResponses[0].data != null &&
        companyDataTable.loadResponses[0].data.length > 0
      ) {
        let obgcomp = companyDataTable.loadResponses[0].data.find(
          (x) =>
            x["Companycontrydetails.Visitorid"] ==
            element["Watchlist.Visitorid"] &&
            x["Companycontrydetails.Visitorcountryid"] ==
            element["Watchlist.Visitorcountryid"]
        );
        if (obgcomp != null && obgcomp !== undefined) {
          element["Watchlist.cmp_name"] =
            obgcomp["Companycontrydetails.companycountry"];
          element["Watchlist.cat_name"] =
            obgcomp["Companycontrydetails.categorynames"].split("|")[0];

        }
      }

      if (
        pageViewsDataTable != null &&
        pageViewsDataTable.loadResponses[0].data != null &&
        pageViewsDataTable.loadResponses[0].data.length > 0
      ) {
        let obgvisitor = pageViewsDataTable.loadResponses[0].data.find(
          (x) =>
            x["Visitors_country.cmp_id"] == element["Watchlist.Visitorid"] &&
            x["Visitor_Pageviews.countryid"] == element["Watchlist.Visitorcountryid"]
        );
        if (obgvisitor != null && obgvisitor !== undefined && element["Watchlist.cat_name"] !== "ISP Provider") {
          element["Watchlist.pgviews"] = obgvisitor["Visitor_Pageviews.pageviewcount"];
          tempFinalWatchlist.push(element);
        }
      }
    });
    return tempFinalWatchlist;
  };

  const getWatchlistCardata = (resultSet, queryArray, contextData) => {
    let watchListDatatable = resultSet.loadResponses[0].data;
    if (watchListDatatable.length === 0) {
      return watchListDatatable;
    }
    let visitorids = [];
    let visitorcontryids = [];

    watchListDatatable.forEach((element) => {
      visitorids.push('"' + element["Watchlist.Visitorid"] + '"');
    });
    watchListDatatable.forEach((element) => {
      visitorcontryids.push('"' + element["Watchlist.Visitorcountryid"] + '"');
    });

    var cmpquery = `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"dimensions\":[\"Companycontrydetails.companycountry\",\"Companycontrydetails.categorynames\",\"Companycontrydetails.Visitorid\",\"Companycontrydetails.Visitorcountryid\"],\"timeDimensions\":[],\"order\":{\"Companycontrydetails.companycountry\":\"asc\"},\"filters\":[{\"member\":\"Companycontrydetails.Visitorid\",\"operator\":\"equals\",\"values\":[${visitorids}]},{\"member\":\"Companycontrydetails.Visitorcountryid\",\"operator\":\"equals\",\"values\":[${visitorcontryids}]},{\"member\":\"Companycontrydetails.cca_use\",\"operator\":\"equals\",\"values\":[\"1\"]},{\"member\":\"Visitor_Pageviews.ProfileId\",\"operator\":"equals\",\"values\":[\"${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}\"]}],\"measures\":[]}}`;
    const companyDataTable = ExecuteQuery(cmpquery);

    const pageViewsDataTable = ExecuteQueryObject(queryArray[1]);

    watchListDatatable.forEach((element) => {
      element["Watchlist.pgviews"] = "0";
      element["Watchlist.active"] = "0";

      if (
        companyDataTable != null &&
        companyDataTable.loadResponses[0].data != null &&
        companyDataTable.loadResponses[0].data.length > 0
      ) {
        let obgcomp = companyDataTable.loadResponses[0].data.find(
          (x) =>
            x["Companycontrydetails.Visitorid"] ==
            element["Watchlist.Visitorid"] &&
            x["Companycontrydetails.Visitorcountryid"] ==
            element["Watchlist.Visitorcountryid"]
        );
        if (obgcomp != null && obgcomp !== undefined) {
          element["Watchlist.cmp_name"] =
            obgcomp["Companycontrydetails.companycountry"];
          element["Watchlist.cat_name"] =
            obgcomp["Companycontrydetails.categorynames"].split("|")[0];
        }
      }
      if (
        pageViewsDataTable != null &&
        pageViewsDataTable.loadResponses[0].data != null &&
        pageViewsDataTable.loadResponses[0].data.length > 0
      ) {
        let obgvisitor = pageViewsDataTable.loadResponses[0].data.find(
          (x) =>
            x["Visitors_country.cmp_id"] == element["Watchlist.Visitorid"] &&
            x["Visitor_Pageviews.countryid"] ==
            element["Watchlist.Visitorcountryid"]
        );
        if (obgvisitor != null && obgvisitor !== undefined) {
          element["Watchlist.pgviews"] =
            obgvisitor["Visitor_Pageviews.pageviewcount"];
        }
      }
    });
    return watchListDatatable
      .sort((x) => x["Watchlist.pgviews"].desc)
      .slice(0, 5);
  };

  const getPollsCardata = (resultSet) => {
    let pollsDatatable = resultSet.loadResponses[0].data;
    let pollsFinalDatatable = [];
    if (pollsDatatable.length === 0) {
      return pollsDatatable;
    }
    pollsDatatable.forEach((element) => {
      element["PresentationPollsQaDetails.answerData"] = [];
      element["PresentationPollsQaDetails.answerData"].push({
        ans: element["PresentationPollsQaDetails.answersValue"],
        count: element["PresentationPollsQaDetails.answersCount"],
      });
      if (pollsFinalDatatable.length > 0) {
        let tempPoll = pollsFinalDatatable.find(
          (x) =>
            x["PresentationPollsQaDetails.question"] ===
            element["PresentationPollsQaDetails.question"]
        );
        if (tempPoll !== null && tempPoll !== undefined) {
          tempPoll["PresentationPollsQaDetails.answerData"].push(
            element["PresentationPollsQaDetails.answerData"][0]
          );
        } else {
          pollsFinalDatatable.push(element);
        }
      } else {
        pollsFinalDatatable.push(element);
      }
    });
    return pollsFinalDatatable;
  };

  const getMultiPollsCardata = (resultSet) => {
    let pollsDatatable = resultSet.loadResponses[0].data;
    let pollsFinalDatatable = [];
    if (pollsDatatable.length === 0) {
      return pollsDatatable;
    }
    pollsDatatable.forEach((element) => {
      element["PresentationsPollsQA.answerData"] = [];
      element["PresentationsPollsQA.answerData"].push({
        ans: element["PresentationsPollsQA.answersValue"],
        count: element["PresentationsPollsQA.answersCount"],
      });
      if (pollsFinalDatatable.length > 0) {
        let tempPoll = pollsFinalDatatable.find(
          (x) =>
            x["PresentationsPollsQA.question"] ===
            element["PresentationsPollsQA.question"]
        );
        if (tempPoll !== null && tempPoll !== undefined) {
          tempPoll["PresentationsPollsQA.answerData"].push(
            element["PresentationsPollsQA.answerData"][0]
          );
        } else {
          pollsFinalDatatable.push(element);
        }
      } else {
        pollsFinalDatatable.push(element);
      }
    });
    return pollsFinalDatatable;
  };

  const getVisitorDetails = (resultset, queryArray) => {
    let vitorListDatatable = resultset.loadResponses[0].data;
    if (vitorListDatatable.length === 0) {
      return vitorListDatatable;
    }
    const watchListDatatable = ExecuteQueryObject(queryArray[1]);
    if (
      watchListDatatable !== null &&
      watchListDatatable !== undefined &&
      watchListDatatable.loadResponses[0].data != null &&
      watchListDatatable.loadResponses[0].data.length > 0
    ) {
      watchListDatatable.loadResponses[0].data.forEach((element) => {
        let visitor = vitorListDatatable.find(
          (x) => x["Visitors.VisitorId"] == element["Watchlist.Visitorid"]
        );
        if (visitor !== undefined && visitor !== null) {
          visitor["Visitors.iswatchlist"] = "1";
        }
      });
    }
    return vitorListDatatable;
  };


  function GetWatchListCategoryWisePage(reurltSet, queryArray) {
    if (reurltSet === null) {
      return 0;
    }
    let pageWatchlistTable = reurltSet.loadResponses[0].data;
    let tempFinalWatchlist = [];

    if (pageWatchlistTable.length === 0) {
      return pageWatchlistTable.length;
    }
    const pageViewsDataTable = ExecuteQueryObject(queryArray[1]);
    pageWatchlistTable.forEach((element) => {
      if (
        pageViewsDataTable != null &&
        pageViewsDataTable.loadResponses[0].data != null &&
        pageViewsDataTable.loadResponses[0].data.length > 0
      ) {
        let obgvisitor = pageViewsDataTable.loadResponses[0].data.find(
          (x) =>
            x["Visitors_country.cmp_id"] == element["Watchlist.Visitorid"] &&
            x["Visitor_Pageviews.countryid"] == element["Watchlist.Visitorcountryid"]
        );
        if (obgvisitor != null && obgvisitor !== undefined) {
          tempFinalWatchlist.push(element);
        }
      }
    });
    return tempFinalWatchlist.length;
  }

  function Page(showPagination) {
    const o = JSON.parse(showPagination.query);
    const component = TypeToMemoChartComponent[o.chartType];
    const renderProps = useCubeQuery(o.query);

    return renderProps.resultSet;
  }

  function ExecuteQuery(compQuery) {
    const o = JSON.parse(compQuery);
    const component = TypeToMemoChartComponent[o.chartType];
    const renderProps = useCubeQuery(o.query);
    return renderProps.resultSet;
  }

  function ExecuteQueryObject(compQuery) {
    // const  o =JSON.parse(compQuery.query);
    const component = TypeToMemoChartComponent["table"];
    const renderProps = useCubeQuery(compQuery);
    return renderProps.resultSet;
  }

  const renderChart =
    (Component) =>
      ({
        resultSet,
        cardProperty,
        totalRecords,
        pageNo,
        setPageNo,
        items,
        setItems,
        onItemChange,
        onPageNoChange,
        showSearchTextBox,
        searchText,
        setSearchText,
        onSearchBoxChange,
        legends,
        error,
        pivotConfig,
        show,
        isRefresh,
        setIsRefresh,
        widjetName,
        queryArray,
        setd3,
        contextData,
        tableCaption,
        minimumRows,
        setShowModal,
        setSelectedItem,
        cardName,
        title,
        downloadExcel,
        handlePlusButtonClick,
        deleteSubscriber,
        editSubscriber,
        apiUrl
      }) => {
        var showLoader = false;

        if (resultSet == null) {
          setTimeout((showLoader = true), 1000);
        }

        return (
          (resultSet && (
            <Component
              resultSet={resultSet}
              cardProperty={cardProperty}
              pageNo={pageNo}
              setPageNo={setPageNo}
              onPageNoChange={onPageNoChange}
              items={items}
              setItems={setItems}
              onItemChange={onItemChange}
              showSearchTextBox={showSearchTextBox}
              searchText={searchText}
              onSearchBoxChange={onSearchBoxChange}
              totalRecords={totalRecords}
              legends={legends}
              pivotConfig={pivotConfig}
              showPagination={show}
              setSearchText={setSearchText}
              isRefresh={isRefresh}
              setIsRefresh={setIsRefresh}
              widjetName={widjetName}
              queryArray={queryArray}
              setd3={setd3}
              contextData={contextData}
              tableCaption={tableCaption}
              minimumRows={minimumRows}
              setShowModal={setShowModal}
              setSelectedItem={setSelectedItem}
              cardName={cardName}
              title={title}
              downloadExcel={downloadExcel}
              handlePlusButtonClick={handlePlusButtonClick}
              deleteSubscriber={deleteSubscriber}
              editSubscriber={editSubscriber}
              apiUrl={apiUrl}
            />
          )) || <Loader show={showLoader} />
        );
      };
  ChartRenderer.propTypes = {
    vizState: PropTypes.object,
    cubejsApi: PropTypes.object,
    cardProperty: PropTypes.object,
    pageNo: PropTypes.number,
    setPageNo: PropTypes.func,
    onPageNoChange: PropTypes.func,
    onItemChange: PropTypes.func,
    searchText: PropTypes.string,
    onSearchTextChange: PropTypes.func,
    legends: PropTypes.object,
    handlePlusButtonClick: PropTypes.func,
    showPlusModal: PropTypes.bool,
    setShowPlusModal: PropTypes.func,
    deleteSubscriber: PropTypes.func,
    editSubscriber: PropTypes.func,
    apiUrl: string
  };
  ChartRenderer.defaultProps = {
    vizState: {},
    cubejsApi: null,
    cardProperty: {},
    pageNo: 1,
    onPageNoChange: null,
    handlePlusButtonClick: null,
    onItemChange: null,
    searchText: "",
    onSearchTextChange: null,
    legends: {},
    apiUrl: ""
  };
  return (
    component &&
    renderChart(component)({
      ...renderProps,
      cardProperty,
      totalRecords,
      pageNo,
      setPageNo,
      items,
      setItems,
      onItemChange,
      onPageNoChange,
      showSearchTextBox,
      searchText,
      setSearchText,
      onSearchBoxChange,
      legends,
      pivotConfig,
      show,
      isRefresh,
      setIsRefresh,
      widjetName,
      queryArray,
      setd3,
      contextData,
      tableCaption,
      minimumRows,
      setShowModal,
      setSelectedItem,
      cardName,
      title,
      downloadExcel,
      handlePlusButtonClick,
      showPlusModal,
      setShowPlusModal,
      deleteSubscriber,
      editSubscriber,
      apiUrl
    })
  );
};
export default ChartRenderer;
