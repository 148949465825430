import React, { useState, useEffect, useContext, useMemo } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { Row, Col, Image } from '@themesberg/react-bootstrap';
import { withRouter } from "react-router";
import AppContext from '../components/AppContext';
import CommonHeader from '../components/CommonHeader';
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { useTranslation } from 'react-i18next';
import { decryptData } from '../utils/crypto';

const ReactGridLayout = WidthProvider(RGL);

const AlertSubscribers = ({ children, dashboardItems, setPageNo, setOffset, selectedWebsiteId, selectedOtpclientid, setSelectedWebsiteId, setSelectedOtpclientid, history, location, handlePlusButtonClick }) => {
  const contextData = useContext(AppContext);
  const [selectedOtpclientidNew, setSelectedOtpclientidNew] = useState("");
  const [autoLoad, setAutoLoad] = useState(false);
  const [blurLoader, setBlurLoader] = useState(false);
  const [updateKey,setUpdateKey] = useState("");
  const [t, i18n] = useTranslation();
  const [alertState,setAlertState] = useState([]);
  
  useEffect(() => {
    if (localStorage.getItem("IsPageRefresh") == "1") {
      setSelectedWebsiteId(JSON.parse(localStorage.getItem("alertWebsiteId")));
    }
  }, []);

  useEffect(() => {
    getWebsites();
  }, [contextData.userId, contextData.companyId]);

  const getWebsites = () => {
    setBlurLoader(true);
    request(baseURL + `/Intelligence/GetAlertsChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        let resultData = [];
        if (res.result !== null && res.result.length) {
          resultData = res.result.map((e) => ({ ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl, ["ClietConfiguration.otpclientid"]: e.OTPClientId, ["WebsiteList.AlertTrackingDate"]: e.AlertTrackingDate }))
        }
        rendersite({ resultSet: resultData })
      })
      .catch((err) => {
      })
  }

  const onWebsiteDropdownChange = (data) => {
    setSelectedOtpclientidNew({});
    const websiteId = data["WebsiteList.WebsiteId"];
    setSelectedOtpclientidNew(data);
  };

  const applyFilters = () => { 
    setBlurLoader(false)
    const websiteId = selectedOtpclientidNew["WebsiteList.WebsiteId"];
    contextData.changeWebsiteId(websiteId);
    setSelectedWebsiteId(websiteId);
    setSelectedOtpclientid(selectedOtpclientidNew);
    localStorage.setItem("alertWebsiteId", JSON.stringify(selectedOtpclientidNew));// Adding ManagesubscriptionWebsiteId to local stoarge in case of Page Refresh
  }

  const cancelFilters = () => {
    onWebsiteDropdownChange(JSON.parse(localStorage.getItem("alertWebsiteId")))
  }

  const rendersite = ({ resultSet, error, pivotConfig, request }) => {
    if (resultSet !== undefined && resultSet.length > 0 && Object.keys(selectedOtpclientid).length <= 0) {
      if (JSON.parse(localStorage.getItem('alertWebsiteId')) !== null) {
        onWebsiteDropdownChange(JSON.parse(localStorage.getItem('alertWebsiteId')));
        setUpdateKey(Math.random())
      } else {
        onWebsiteDropdownChange(resultSet[0]);
        setAutoLoad(!autoLoad);
        setUpdateKey(Math.random());
      }
    }
    if (resultSet !== undefined && resultSet.length > 0) {
      setAlertState(resultSet);
      localStorage.setItem("alertState", JSON.stringify(resultSet))
      setAutoLoad(!autoLoad);
      setUpdateKey(Math.random())
    }
    return (
      <></>
    );
  };

  const commonHeader = useMemo(() => {
    return <><CommonHeader cancelFilters={cancelFilters} autoLoad={autoLoad} dataSource={[
      { name: 'Dropdown1 - website', data: alertState, selectedItem: selectedOtpclientidNew, isVisible: true, label: t("header.filterLabelWebsite"), callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" }
    ]} title={t("header.manageSubscriptionTitle")} showDate={false} applyFilters={applyFilters} /></>
  }, [selectedOtpclientidNew, autoLoad, updateKey]);
  
  return (
    <>
      {blurLoader && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}
      {commonHeader}
      <Row className="ps-3 mt-1 mb-1">
        <Col sm={12}>
          <label>{t("header.AlertManageSubscribersInstructionCAS2")}</label>
        </Col>
      </Row>
      {/* Change css when react grid item order will be changed or rearranged */}
      <Row>
        <Col xs={12}>
          <ReactGridLayout cols={12} rowHeight={50}
          isDraggable={false} isResizable={false} className="rgl-manage-subscriber"
        >
          {children}
        </ReactGridLayout>  
        </Col>
      </Row>
    </>
  );
};

export default withRouter(AlertSubscribers);
