import React, { useState, useEffect, useContext, useMemo } from "react";
import { Nav, Tab, Image, Accordion, Card, OverlayTrigger, Tooltip, Button } from "@themesberg/react-bootstrap";
import { withRouter } from "react-router";
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import AppContext from "../components/AppContext";
import request from "../apis/request";
import { METHODS } from "../apis/utilities/constant";
import { useTranslation } from 'react-i18next';
import { decryptData } from "../utils/crypto";

const VisitorDetails = () => {
    const [visitorInsightDetails, setVisitorInsightDetails] = useState({});
    const contextData = useContext(AppContext);
    const [t, i18n] = useTranslation();
    useEffect(() => {

        if (contextData.startDate && contextData.endDate && contextData.selectedProfile["WebsiteProfiles.ProfileId"]) {
            setVisitorInsightDetails({ loadingcomplete: false })
            let ms = new Date(Date.parse(contextData.startDate));
            let sdate = ms.getFullYear() + "-" + parseInt(ms.getMonth() + 1) + "-" + ms.getDate()
            let ms2 = new Date(Date.parse(contextData.endDate));
            let sdate2 = ms2.getFullYear() + "-" + parseInt(ms2.getMonth() + 1) + "-" + ms2.getDate()
            request(`${process.env.REACT_APP_API_KEY_NEW}/VisitorAnalytics/GetResolvedUnresolvedVisitorsCountByProfileId?endDate=` + sdate2 + `&startDate=` + sdate + `&profileId=` + contextData.selectedProfile["WebsiteProfiles.ProfileId"] +
                `&companyid=${contextData.companyId}`, METHODS.GET, {},
                { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json" })
                .then((response) => {
                    if (response != null) {
                        setVisitorInsightDetails({
                            allVisitors: response.allVisitors,
                            bots: response.bots,
                            ispProvider: response.ispProvider,
                            resolvedVisitors: response.resolvedVisitors,
                            unresolvedVisitors: response.unresolvedVisitors,
                            rVisitorsPercentage: response.rVisitorsPercentage,
                            unresolvedVisitorsPercentage: response.unresolvedVisitorsPercentage,
                            ispProviderPercentage: response.ispProviderPercentage,
                            botsPercentage: response.botsPercentage,
                            loadingcomplete: true
                        });
                    }
                    else {
                        setVisitorInsightDetails({ loadingcomplete: true })
                    }
                })
                .catch(() => {
                    setVisitorInsightDetails({ loadingcomplete: true })
                })
        }

    }, [contextData.selectedProfile, contextData.startDate, contextData.endDate]);
    const createTooltip = (tooltipKey, placement) => {
        if (tooltipKey !== "") {
            return (
                <OverlayTrigger
                    placement={placement}
                    trigger={["hover", "focus"]}
                    overlay={
                        // <Tooltip>{t("header.VisitorAnalyticsTooltip")}</Tooltip>
                        <Tooltip>
                            <div
                            // dangerouslySetInnerHTML={{
                            //   __html: t("header.VisitorAnalyticsTooltip", {
                            //     interpolation: { escapeValue: false },
                            //   }),
                            // }}
                            >{tooltipKey}</div>
                        </Tooltip>
                    }
                >

                    <Button variant="white" className="btn-help border-0 p-2">
                        <i className="far fa-question-circle lg"></i>
                    </Button>
                </OverlayTrigger>
            );
        }
    }
    return (
        <>
            <div className="d-inline-block pt-3 ps-3 w-100 position-relative">
                <div className="nested-card-container visitor-key-insights-box rounded" id="insights-box">
                    <Accordion className={"className"} defaultActiveKey={"eventKey-11"}>
                        <Accordion.Item eventKey={"eventKey-11"} className="shadow-soft border rounded border-light mb-0">
                            <Accordion.Button variant="link" className="w-100 d-flex justify-content-between p-4 pe-3">
                                <h6 className="mb-0">{t("header.VisitorInsightsEquationHeading1") + " "} {visitorInsightDetails.resolvedVisitors ? visitorInsightDetails.resolvedVisitors : 0}
                                    &nbsp;{t("header.VisitorInsightsEquationHeading2") + " "} {visitorInsightDetails.allVisitors ? visitorInsightDetails.allVisitors : 0} {t("header.VisitorInsightsEquationHeading3")}  </h6>
                            </Accordion.Button>
                            <Accordion.Body>
                                <Card.Body>

                                    {visitorInsightDetails.loadingcomplete == false && <div className={`preloader loader-center-align`} >
                                        <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                                    </div>}

                                    {visitorInsightDetails.loadingcomplete == true && visitorInsightDetails.allVisitors != 0 && <div className="pod card-visitor-insights-container ng-scope">
                                        <div className="card card-key-insights eq-card-1">
                                            <div className="card-body">
                                                <div className="key-insight-value ng-binding" title={visitorInsightDetails.allVisitors}>{visitorInsightDetails.allVisitors}</div>
                                                <div className="card-value-title ng-binding key-insight-title-box">{t("header.VisitorInsightTotalVisitorsLabel")}
                                                    {createTooltip(t("header.WOVisitorEquationTotalVisitors"), "right")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-operator">=</div>
                                        <div className="card card-key-insights identified-company-visitor eq-card-2">
                                            <div className="card-body">
                                                <div className="key-insight-value ng-binding" title= {visitorInsightDetails.resolvedVisitors}>
                                                    {visitorInsightDetails.resolvedVisitors}
                                                    <span className="insight-percentage-value ng-binding" title={`${visitorInsightDetails.rVisitorsPercentage} of total visitors`}>{visitorInsightDetails.rVisitorsPercentage}</span>
                                                </div>
                                                <div className="card-value-title ng-binding key-insight-title-box">{t("header.VisitorInsightIdentifiedCompanyVisitorLabel")}
                                                    {createTooltip(t("header.WOVisitorEquationIdentifiedCompanyVisitors"), "right")}
                                                </div>
                                            </div>


                                        </div>
                                        <div className="card-operator">+</div>
                                        <div className="card card-key-insights eq-card-3">
                                            <div className="card-body">
                                                <div className="key-insight-value ng-binding" title={visitorInsightDetails.bots}>
                                                    {visitorInsightDetails.bots}
                                                    <span className="insight-percentage-value ng-binding" title={`${visitorInsightDetails.botsPercentage} of total visitors`}>{visitorInsightDetails.botsPercentage}</span>
                                                </div>
                                                <div className="card-value-title ng-binding key-insight-title-box">{t("header.VisitorInsightIdentifiedBotsLabel")}
                                                    {createTooltip(t("header.WOVisitorEquationIdentifiedBots"), "right")}
                                                </div>

                                            </div>
                                        </div>
                                        <div className="card-operator">+</div>
                                        <div className="card card-key-insights eq-card-4">
                                            <div className="card-body">
                                                <div className="key-insight-value ng-binding" title={visitorInsightDetails.ispProvider}>
                                                    {visitorInsightDetails.ispProvider}
                                                    <span className="insight-percentage-value ng-binding" title={`${visitorInsightDetails.ispProviderPercentage} of total visitors`}>{visitorInsightDetails.ispProviderPercentage}</span>
                                                </div>
                                                <div className="card-value-title ng-binding key-insight-title-box">{t("header.VisitorInsightIdentifiedISPSLabel")}
                                                    {createTooltip(t("header.visitorDetailsMessageText"), "right")}
                                                </div>

                                            </div>
                                        </div>
                                        <div className="card-operator">+</div>
                                        <div className="card card-key-insights eq-card-5">
                                            <div className="card-body">
                                                <div className="key-insight-value ng-binding" title={visitorInsightDetails.unresolvedVisitors}>
                                                    {visitorInsightDetails.unresolvedVisitors}
                                                    <span className="insight-percentage-value ng-binding" title={`${visitorInsightDetails.unresolvedVisitorsPercentage} of total visitors`}>{visitorInsightDetails.unresolvedVisitorsPercentage}</span>
                                                </div>
                                                <div className="card-value-title ng-binding key-insight-title-box">{t("header.VisitorInsightUnknownVisitorsLabel")}
                                                    {createTooltip(t("header.WOVisitorEquationUnknownVisitors"), "left")}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {visitorInsightDetails.loadingcomplete == true && visitorInsightDetails.allVisitors == 0 &&
                                        <>
                                            <div className="card-no-data">{t("header.contentUnavailable")}</div>
                                        </>
                                    }

                                </Card.Body>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </>
    );
}

export default withRouter(VisitorDetails);