import React, { useState, useContext } from 'react';
import { Form, InputGroup } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import request from "../apis/request";
import { METHODS } from "../apis/utilities/constant";
import { useEffect } from "react";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { decryptData } from '../utils/crypto';
import AppContext from '../components/AppContext';
import { useTranslation } from "react-i18next";

function Autocomplete(props) {
    const contextData = useContext(AppContext);
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestion, setShowSuggestion] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [t, i18n] = useTranslation();

    const [enableClear, setEnableClear] = useState(false);
    const [flag, setFlag] = useState(true); // this flag is to check if suggested company is selected. true = not-selected ; false = selected

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (inputValue.length > 2) {
                if (flag) {
                    setIsLoading(true);
                    setShowSuggestion(true)
                    request(props.apiurl + `?searchtext=` + inputValue, METHODS.GET, {}, { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json" })
                        .then((response) => {
                            setIsLoading(false);
                            let newresponse = []
                            if (props.autoTickEnabled == true && props.autoTickData?.length > 0) {
                                for (let item of response) {
                                    newresponse.push({ id: item.id, value: item.value, isexist: props.autoTickData.includes(item.value) })
                                }
                            }
                            else {
                                newresponse = response;
                            }
                            setSuggestions(newresponse);
                        })
                        .catch(() => {
                            setShowSuggestion(false)
                        })
                }
                else {
                    setFlag(true);
                }
            }
            else if (inputValue.length === 0) {
                clearSearch();
            }
            else {
                setShowSuggestion(false)
            }
        }, 500)

        return () => clearTimeout(delayDebounceFn)

    }, [inputValue])

    const handleInputChange = (e) => {
        setShowSuggestion(false)
        setSuggestions([]);
        const value = e.target.value;
        setInputValue(value);
        if (props.OnValueSelection !== undefined) {
            props.OnValueSelection({ id: 0 });
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setFlag(false)
        setInputValue(suggestion.value);
        setEnableClear(true);
        props.setCheckCompanyName(true);
        setSuggestions([]);
        setShowSuggestion(false)
        if (props.OnValueSelection !== undefined) {
            props.OnValueSelection(suggestion);
        }
    };

    const clearSearch = () => {
        setInputValue([]);
        setEnableClear(false);
        props.setCheckCompanyName(false);
        setFlag(true)
    }

    return (
        <>
            <InputGroup>
                <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                <Form.Control type="text" className="form-control input-search" name="companyName" placeholder={props.placeholder} value={inputValue} onChange={(e) => handleInputChange(e)} autoComplete="off" />
                {enableClear ? <span variant="white" className="watchlist-btn-search-reset border-0 p-2" onClick={() => clearSearch()}>
                    <FontAwesomeIcon icon={faTimes} />
                </span> : <></>}
                {showSuggestion === true &&
                    <div className="auto-dropdown">
                        <ul className="list-unstyled auto-dropdown-menu">
                            {suggestions.length > 0 && suggestions.map((value, index) =>
                                <li className="auto-dropdown-menu-item mt-1 mb-1" key={index} onClick={() => handleSuggestionClick(value)}> <b>{inputValue.length > 0 ? value.value.substring(0, inputValue.length) : ""}</b>{value.value ? value.value.slice(inputValue.length) : ""}  {value.isexist === true && <span className='pull-right'>  <FontAwesomeIcon icon={faCheckCircle} className="me-2" /></span>}</li>
                            )}
                            {
                                isLoading == true && <li>{t("header.Loading")}</li>
                            }
                            {
                                isLoading == false && suggestions.length === 0 && <li style={{ color: '#cc493f' }} className="mt-1 mb-1">{t("header.Content_unavailable")}</li>
                            }
                        </ul>
                    </div>}
            </InputGroup>
        </>
    );
}

export default Autocomplete;