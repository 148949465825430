import { Row, Col } from '@themesberg/react-bootstrap';
import React, { useEffect, useState, useContext } from 'react';
import EditablePage from '../components/EditablePage';
import { Link } from 'react-router-dom';
import { isMobile } from "react-device-detect";
// import { useQuery } from '@apollo/react-hooks';
// import { GET_DASHBOARD_ITEMS } from '../graphql/queries';
import ChartRenderer from '../components/ChartRenderer';
import Website from '../components/Website';
import { CARD_MOBILE_LAYOUT, DEFAULT_PAGE_NO } from "../AppConfig";
import { defaultLayout, defaultLayoutMobile } from "../utils/layoutConfig";
import SummaryRenderer from '../components/SummaryRenderer';
import { getWebsite } from "../stubs/ComponentJson/website";
import moment from "moment-timezone";
import AppContext from '../components/AppContext';
import context from '@themesberg/react-bootstrap/lib/esm/AccordionContext';

// const deserializeItem = (i) => ({
//     ...i,
//     layout: JSON.parse(i.layout) || {},
//     summary: JSON.parse(i.summary) || {},
//     layoutone: JSON.parse(i.layoutone) || {},
//     layouttwo: JSON.parse(i.layouttwo) || {},
//     vizStateSecond: JSON.parse(i.vizStateSecond) || {},
//     vizState: JSON.parse(i.vizState)
// });

function EditablePages() {
    // const contextData = useContext(AppContext);
    // const [pageNo, setPageNo] = useState(DEFAULT_PAGE_NO);
    // const [searchText, setSearchText] = useState("");
    // const [legends, setlegends] = useState("");
    // const [websiteId, setWebsiteId] = useState(0);
    //const [selectedWebsiteId, setSelectedWebsiteId] = useState({});
    // const [profileId, setProfileId] = useState(0);
    //const [selectedProfileId, setSelectedProfileId] = useState({});
    // const [websiteData, setWebsiteData] = useState([]);
    //const [usersData, setUsersData] = useState([]);
    // const [sourceMedium, setSourceMedium] = useState("direct");
    // const [startDate, setStartDate] = useState("");
    // const [endDate, setEndDate] = useState("");
    // const [isRefresh, setIsRefresh] = useState(false);


    // useEffect(() => {
    //     // const profileId = selectedProfileId && Object.keys(selectedProfileId).length > 0 ? selectedProfileId["WebsiteProfiles.ProfileId"]: 18841;

    //     const url = window.location.href.split("/");
    //     const urlEndpoints = `${url[0]}//${url[2]}/`;
    //     const selectedstartdate = moment(contextData.startDate).format("YYYY-MM-DD");
    //     const selectedenddate = moment(contextData.endDate).format("YYYY-MM-DD");
    //     const selectedPreviousYearStartDate = moment(contextData.startDate).add(-1, 'years').format("YYYY-MM-DD");
    //     const selectedPreviousYearEndDate = moment(contextData.endDate).add(-1, 'years').format("YYYY-MM-DD");





    //     // let udta = (dta !== null && dta !== undefined) ? dta.filter(x=> x.id === "3" || x.id === "4") : null;
    //     // setUsersData(udta);

    // }, [contextData.selectedProfile, contextData.selectedProfile, sourceMedium, contextData.startDate, contextData.endDate]);

    return (
        <>
            <Row className="mb-3">
                <Col lg={12} xl={12}>
                    <EditablePage
                    // usersData={usersData}
                    // deserializeItem={deserializeItem}
                    //dashboardItem={dashboardItem}
                    // selectedProfileId={contextData.selectedProfile}
                    // setSelectedProfileId={setSelectedProfileId}
                    // selectedWebsiteId={contextData.selectedWebsite}
                    // setSelectedWebsiteId={setSelectedWebsiteId}
                    // startDate={contextData.startDate}
                    // endDate={contextData.endDate}
                    // setStartDate={setStartDate}
                    // setEndDate={setEndDate}
                    ></EditablePage>
                </Col>
            </Row>
            {/* <Row className="mb-3">
                <Col lg={12}>
                    <h5 className="h5 ps-3">What? Editable Pages</h5>
                    <p className="ps-3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                        Donec dictum metus ligula, eget blandit erat volutpat eu. 
                        Praesent quis metus sollicitudin, accumsan risus in, tristique tellus. 
                        Sed eu congue velit.
                    </p>
                </Col>
            </Row> */}
        </>
    )
}

export default EditablePages