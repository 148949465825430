import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect, useContext, useMemo } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';
import { Row, Col, Button, Form, InputGroup, FormCheck } from '@themesberg/react-bootstrap';
import DropdownWithIconComponent from "../components/DropdownWithIcons";
import Loader from '../components/VoltLoader';
import IframeComponent from '../components/Iframe';
import { getStringValue } from "../utils/common";
import AppContext from '../components/AppContext';
import CommonHeader from "../components/CommonHeader";
import { METHODS } from "../apis/utilities/constant";
import request from "../apis/request";
import { baseURL } from '../apis/utilities/constant';
import { useTranslation } from "react-i18next";
import { decryptData } from "../utils/crypto";


export const ReportsPage = () => {
  const [selectedReportUrl, setselectedReportUrl] = useState("");
  const [selectedReportTitle, setselectedReportTitle] = useState([]);
  const [selectedReportDescription, setselectedReportDescription] = useState("");
  const [selectedReport, setselectedReport] = useState([]);
  const [autoLoad, setAutoLoad] = useState(false)
  const contextData = useContext(AppContext);
  const [t] = useTranslation();



  useEffect(() => {
    if (contextData.companyId>0) {
      const getReports = async () => {
        try {
          const response = await request(baseURL + `/Common/GetReportsByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
            METHODS.GET,
            {},
            {
              Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            });
          let resultData = [];
          if (response.result !== null && response.result.length) {
            resultData = response.result.map((e) => ({
              ["Reports.Embedurl"]: e.EmbedURL,
              ["Reports.Reportdescription"]: e.ReportDescription,
              ["Reports.Reporttitle"]: e.ReportTitle
            }));
          }
          rendersite({ resultSet: resultData });
        } catch (err) {
        }
      }
      getReports();
    }
  }, [selectedReport,contextData.companyId]);

  const onReportDropdownChange = (value) => {
    setselectedReport(value);
  }

  const applyFilters = () => {
    setselectedReportUrl(selectedReport["Reports.Embedurl"]);
    setselectedReportDescription(selectedReport["Reports.Reportdescription"]);
    setselectedReportTitle(selectedReport["Reports.Reporttitle"])
    localStorage.setItem("reportsPage", JSON.stringify(selectedReport))
  }

  const cancelFilters = () => {
    onReportDropdownChange(JSON.parse(localStorage.getItem("reportsPage")))
  }

  const rendersite = ({ resultSet }) => {
    // if (error) {
    //   return <div>{error.toString()}</div>;
    // }
    if (!resultSet) {
      return <Loader show={false} />;
    }
    if (resultSet !== null && resultSet.length && selectedReportUrl === "") {
      onReportDropdownChange(resultSet[0])
      setselectedReportUrl(resultSet[0]["Reports.Embedurl"]);
      setselectedReportDescription(resultSet[0]["Reports.Reportdescription"]);
      setselectedReportTitle(resultSet[0]["Reports.Reporttitle"])
    }
    if (resultSet !== null) {
      localStorage.setItem("reportsTypeData", JSON.stringify(resultSet))
      setAutoLoad(true)
    }
  };


  const Empty = () => (
    <div
      style={{
        textAlign: 'center',
        padding: 12,
      }}
    >
      <h2 className="ps-3 pe-3">{t("header.noChartsonDashboard")}</h2>
      {/* <Link to="/explore"> */}
      <Button variant="secondary" className="m-1">
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        {t("header.addChart")}
      </Button>
      {/* </Link> */}
    </div>
  );
  const commonHeader = useMemo(() => <CommonHeader cancelFilters={cancelFilters} autoLoad={autoLoad} dataSource={[
    { name: 'Dropdown1 - report type', data: JSON.parse(localStorage.getItem("reportsTypeData")), selectedItem: selectedReport, isVisible: true, label: t("header.reposrtPageFilterLabelReport_type"), callback: onReportDropdownChange, displayText: "Reports.Reporttitle" },
  ]} title={t("header.ReportsPageTitle")} showDate={false} applyFilters={applyFilters} />, [selectedReport]);
  return (
    <>
      <Row>
        <Col>
          {commonHeader}
        </Col>
        {/* <Col className="hide-desktop">
          <h2 className="h2 mb-0 ps-3 mb-3">{t("header.ReportsPageTitle")}</h2>
        </Col> */}
      </Row>

      {selectedReportUrl &&
        <div className="d-inline-block p-3 pe-0 w-100 hide-ipad">
          <div className="shadow-soft border rounded border-light w-100 nested-card-container Notification-container">
            <Row className="ps-3 pe-3 mt-2">
              <Col md={12}>
                <h2>{selectedReportTitle}</h2>
                <p>{selectedReportDescription}</p>
                <IframeComponent srcName={selectedReportUrl} shouldAllowFullScreen={true}></IframeComponent>
                <div className="mt-1">{t("header.ReportsDisclaimerText")}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      }
      <div className="ps-3 pe-0 w-100 d-inline-block unsupported-device-msg-box hide-desktop">
        <div className="shadow-soft border rounded border-light nested-card-container p-3 pt-4 text-center">
          <h6 className="unsupported-device-msg">{t("header.UnsupportedDeviceResolution")}</h6>
          <p className="mt-2 unsupported-device-msg">{t("header.UnsupportedDeviceResolutionText")}</p>
          <a className="fw-semi-bold link-primary" target="_blank" rel="noreferrer" href={selectedReportUrl}>Click here to view report</a>
        </div>
      </div>
    </>
  );
};


export default ReportsPage;