import React, { useState, useEffect, useContext, useRef } from 'react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Row, Col, Button, Tooltip, OverlayTrigger, Form, InputGroup } from '@themesberg/react-bootstrap';
import DropdownWithIconComponent from "../components/DropdownWithIcons";
import { withRouter } from "react-router";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { dashbordDateRangeList } from "../utils/datetime";
import ButtonComponent from "../components/Buttons";
import AppContext from '../components/AppContext';
import { useTranslation } from 'react-i18next';
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";

const CommonHeader = (props, { children, forceUpdate, dashboardItems, selectedProfileId, setSelectedProfileId, selectedWebsiteId, setSelectedWebsiteId, history, location, startDate, setStartDate, endDate, setEndDate }) => {
    const contextData = useContext(AppContext);
    const [selectionsText, setSelectionsText] = useState([]);
    const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
    const [showFiltersMenu, setShowFiltersMenu] = useState(false);
    const [datePickerValue, setDatePickerValue] = useState("");
    const [selectedDatePicker, setSelectedDatePicker] = useState({});
    const [datePickerCompareValue, setDatePickerCompareValue] = useState("");
    const [companyIdValue, setcompanyIdValue] = useState("");
    const [newStartDate, setNewStartDate] = useState();
    const [newEndDate, setNewEndDate] = useState();
    const [errorNotice, setErrorNotice] = useState(false);
    const [t, i18n] = useTranslation();
    const [errorMessage, setErrorMessage] = useState("");
    const [dateRange, setDateRange] = useState("");
    const [dateCompareValue, setDateCompreValue] = useState("")
    const [showStartDate, setShowStartDate] = useState(false)
    const [showEndDate, setShowEndDate] = useState(false);
    const [showtitle, setShowTitle] = useState(true)
    const [dateRangeList, setDateRangeList] = useState(dashbordDateRangeList);
    const filterRef = useRef(null);
    const customDateRangeRef = useRef(null);
    const isDemoClient = localStorage.getItem("isDemoClient");

    useEffect(() => {
        if (props.autoLoad) {
            // props.applyFilters();
            applyFilters();
        }
        setErrorNotice(false)
    }, [props.autoLoad
        //,contextData.hasEvents
    ]);

    useEffect(() => {
        if (props.channel === "website") {
            let endDate = moment(new Date).subtract(1, 'day').format("MMM DD,YYYY");
            // let endDate = newEndDate ?? moment(new Date).subtract(1, 'day').format("MMM DD,YYYY");
            let startDate = moment(new Date).subtract(180, 'days').format("MMM DD,YYYY");
            // let startDate = newStartDate ?? moment(new Date).subtract(180, 'days').format("MMM DD,YYYY");
            const datePickerValue = moment(startDate).format("MMM DD,YYYY") + ` - ${moment(endDate).format("MMM DD,YYYY")}`;
            let diffDays = moment(endDate).diff(startDate, 'days') + 1;
            const getDropdownPickerDetails = dateRangeList.find(dta => dta.key === ((diffDays !== 7 && diffDays !== 30 && diffDays !== 90 && diffDays !== 180) ? "" : datePickerValue));
            setNewEndDate(endDate);
            setNewStartDate(startDate);
            setSelectedDatePicker(getDropdownPickerDetails);
            setDatePickerValue(datePickerValue);
            setDtPickerCompareValueOnDatePickerChange(moment(startDate).format("MMM DD,YYYY"), moment(endDate).format("MMM DD, YYYY"));
        }
    }, [contextData.companyId]);

    useEffect(() => {
        if (props.channel === "alert" && props.showDate) {
            let selectedItem = props.dataSource[0]?.selectedItem;
            let isDateChanged = false;
            if (selectedItem?.["WebsiteList.AlertTrackingDate"] && selectedItem?.["WebsiteList.AlertTrackingDate"] !== "" && props.dataSource[0].data && props.dataSource[0].data.length > 0) {
                if (props.dataSource[0].data) {
                    let data = props.dataSource[0].data;
                    if (data.find(x => x["WebsiteList.WebsiteName"] === selectedItem["WebsiteList.WebsiteName"])["WebsiteList.AlertTrackingDate"] !== selectedItem["WebsiteList.AlertTrackingDate"]) {
                        isDateChanged = true;
                        selectedItem["WebsiteList.AlertTrackingDate"] = data.find(x => x["WebsiteList.WebsiteName"] === selectedItem["WebsiteList.WebsiteName"])["WebsiteList.AlertTrackingDate"];
                        localStorage.setItem("alertWebsiteId", JSON.stringify(selectedItem));
                    }
                }
                let endDate = moment(new Date).subtract(1, 'day').format("MMM DD,YYYY");
                let startDate = null;
                const alertDate = moment(props.dataSource[0]?.selectedItem["WebsiteList.AlertTrackingDate"]).add(1, "day");
                const currentDate = moment();
                const daysDifference = currentDate.diff(alertDate, 'days');
                if (daysDifference >= 180) {
                    startDate = moment(new Date).subtract(180, 'days').format("MMM DD,YYYY");
                }
                else if (daysDifference >= 90) {
                    startDate = moment(new Date).subtract(90, 'days').format("MMM DD,YYYY");
                }
                else if (daysDifference >= 30) {
                    startDate = moment(new Date).subtract(30, 'days').format("MMM DD,YYYY");
                }
                else if (daysDifference >= 7) {
                    startDate = moment(new Date).subtract(7, 'days').format("MMM DD,YYYY");
                }
                else {
                    if (endDate == moment(alertDate).subtract(1, 'days').format("MMM DD,YYYY")) {
                        startDate = moment(alertDate).subtract(1, 'days').format("MMM DD,YYYY");
                    } else if (moment(alertDate).subtract(1, 'days').format("MMM DD,YYYY") == moment(new Date).format("MMM DD,YYYY")) {
                        startDate = moment(alertDate).subtract(2, 'days').format("MMM DD,YYYY");
                    } else {
                        startDate = moment(alertDate).format("MMM DD,YYYY");
                    }
                }
                let datePickerValue = moment(startDate).format("MMM DD,YYYY") + ` - ${moment(endDate).format("MMM DD,YYYY")}`;
                let diffDays = moment(endDate).diff(startDate, 'days') + 1;

                let dateRangeList = dashbordDateRangeList.filter(x => x.compareOn === 0 || daysDifference >= x.compareOn);
                let getDropdownPickerDetails = dateRangeList.find(dta => dta.key === ((diffDays !== 7 && diffDays !== 30 && diffDays !== 90 && diffDays !== 180) ? "" : datePickerValue));
                let selectedDateRange = null;
                if (localStorage.getItem("SelectedDatePicker_alert") && localStorage.getItem("SelectedDatePicker_alert") !== "") {
                    selectedDateRange = JSON.parse(localStorage.getItem("SelectedDatePicker_alert"));
                }
                if (!isDateChanged && selectedDateRange && dateRangeList.findIndex(x => x.compareOn === selectedDateRange.compareOn) > -1) {
                    onDatePickerDropdownChange(selectedDateRange);
                }
                else {
                    setNewEndDate(endDate);
                    setNewStartDate(startDate);
                    setSelectedDatePicker(getDropdownPickerDetails);
                    setDatePickerValue(datePickerValue);
                    setDtPickerCompareValueOnDatePickerChange(moment(startDate).format("MMM DD,YYYY"), moment(endDate).format("MMM DD, YYYY"));
                }
                setDateRangeList(dateRangeList);
            }
            else {
                // let endDate = moment(new Date).subtract(1, 'day').format("MMM DD,YYYY");
                let endDate = newEndDate ?? moment(new Date).subtract(1, 'day').format("MMM DD,YYYY");
                // let startDate =  moment(new Date).subtract(180, 'days').format("MMM DD,YYYY");
                let startDate = newStartDate ?? moment(new Date).subtract(180, 'days').format("MMM DD,YYYY");
                const datePickerValue = moment(startDate).format("MMM DD,YYYY") + ` - ${moment(endDate).format("MMM DD,YYYY")}`;
                let diffDays = moment(endDate).diff(startDate, 'days') + 1;
                const getDropdownPickerDetails = dateRangeList.find(dta => dta.key === ((diffDays !== 7 && diffDays !== 30 && diffDays !== 90 && diffDays !== 180) ? "" : datePickerValue));
                setNewEndDate(endDate);
                setNewStartDate(startDate);
                setSelectedDatePicker(getDropdownPickerDetails);
                setDatePickerValue(datePickerValue);
                setDtPickerCompareValueOnDatePickerChange(moment(startDate).format("MMM DD,YYYY"), moment(endDate).format("MMM DD, YYYY"));
            }
        }
    }, [props.dataSource[0], contextData.companyId])

    useEffect(() => {
        if (newStartDate) {
            setDefaultPickerValueOnDateChange("startDate");
        }
    }, [newStartDate]);

    useEffect(() => {
        if (newEndDate) {
            setDefaultPickerValueOnDateChange("endDate");
        }
    }, [newEndDate]);

    useEffect(() => {
        if (contextData.userId > 0 && contextData.companyId > 0) {
            setcompanyIdValue(contextData.companyId);
            // Fetching values from Local storage in case of Page Refresh
            if (localStorage.getItem("IsPageRefresh") == "1") {
                if (localStorage.getItem("WebsiteListWebsiteId")) {
                    if (selectedWebsiteId) {
                        setSelectedWebsiteId(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")));
                    }
                }
            }
            let channel = "";
            if (props.channel !== undefined) {
                channel = "_" + props.channel;
            }
            if (localStorage.getItem("SelectedDatePicker" + channel) && localStorage.getItem("SelectedDatePicker" + channel) != "") {
                onDatePickerDropdownChange(JSON.parse(localStorage.getItem("SelectedDatePicker" + channel)))
            }
        }
    }, [contextData.userId, contextData.companyId]);

    useEffect(() => {
        if (showFiltersMenu || showCustomDatePicker) {
            document.addEventListener("click", handleClickOutside);
        } else {
            document.removeEventListener("click", handleClickOutside);
        }
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [showFiltersMenu, showCustomDatePicker]);

    const createLabels = () => {
        let labelString = "";
        let labelValue = "";
        if (props.dataSource.length) {
            props.dataSource.map((element) => {
                setShowTitle(true);
                labelValue = "";
                if (element.isVisible) {
                    if (element.data !== null && element.data.length === 0) {
                        if (element.name.toLowerCase().includes("profile")) {
                            setShowTitle(false);
                        }
                        labelValue = " - ";
                    } else {
                        if (element.selectedItem[element.displayText] !== undefined) {
                            labelValue = element.selectedItem[element.displayText]
                        }
                        else if (element.data != null) {
                            let objectdetails = element.data[0];
                            labelValue = objectdetails["" + element.displayText];
                        }
                    }
                    if (element.selectedItem !== undefined) {
                        labelString += " " + element.label + ": " + labelValue + " | "
                    }
                }
            });
        }
        labelString = labelString.substring(0, labelString.length - 2)
        labelString = labelString.replace("undefined", " - ")
        setSelectionsText(labelString);
    }

    const toggleFiltersMenu = () => {
        setShowFiltersMenu(prevState => !prevState);
        setShowCustomDatePicker(false);
    };

    const handleClickOutside = (event) => {
        var classesToCheck = ['rdtSwitch', 'rdtMonth', 'rdtDay', 'rdtYear'];
        var isDatePickerContainer = classesToCheck.some(function (className) {
            return event.target.classList.contains(className);
        });
        if (showFiltersMenu && filterRef.current && (!filterRef.current.contains(event.target) && !isDatePickerContainer)) {
            setShowFiltersMenu(false);
            setShowCustomDatePicker(false);
        }
        if (showCustomDatePicker && customDateRangeRef.current && (!customDateRangeRef.current.contains(event.target) && !isDatePickerContainer)) {
            setShowCustomDatePicker(false);
        }
    };

    const setDefaultPickerValueOnDateChange = (dateType) => {
        if (datePickerValue.length > 0) {
            const datePickerSplit = datePickerValue.split("-");
            let datePickerVle;
            if (dateType == "startDate") {
                datePickerVle = `${moment(newStartDate).format("MMM DD, YYYY")} - ${moment(datePickerSplit[1]).format("MMM DD, YYYY")}`;
                setDtPickerCompareValueOnDatePickerChange(newStartDate, moment(datePickerSplit[1]));
            }
            else if (dateType == "endDate") {
                datePickerVle = `${moment(moment(datePickerSplit[0])).format("MMM DD, YYYY")} - ${moment(newEndDate).format("MMM DD, YYYY")}`;
                setDtPickerCompareValueOnDatePickerChange(moment(datePickerSplit[0]), newEndDate);
            }
            setDatePickerValue(datePickerVle);
        }
    };

    const setDtPickerCompareValueOnDatePickerChange = (startDate, endDate) => {
        endDate = moment(endDate);
        startDate = moment(startDate);
        let previousEndDate = moment(endDate).subtract(1, 'years').format("MMM DD, YYYY");
        let previousStartDate = moment(startDate).subtract(1, 'years').format("MMM DD, YYYY");
        let datePickerCmpr = `${previousStartDate} - ${previousEndDate}`;
        setDatePickerCompareValue(datePickerCmpr);
    };

    const cancelFilters = function () {
        setTimeout(() => { setShowFiltersMenu(false) }, 100)
        if (props.cancelFilters() !== undefined) {
            props.cancelFilters()
        }

        setShowCustomDatePicker(false)
        let channel = "";
        if (props.channel !== undefined) {
            channel = "_" + props.channel;
        }

        if (localStorage.getItem("SelectedDatePicker" + channel) && localStorage.getItem("SelectedDatePicker" + channel) != "") {
            onDatePickerDropdownChange(JSON.parse(localStorage.getItem("SelectedDatePicker" + channel)))
        }
    }

    const updateDatelabel = () => {
        setDateRange(datePickerValue)
        setDateCompreValue(datePickerCompareValue);
    }

    const applyFilters = function () {
        setErrorNotice(false)
        let newStartDataSelected = new Date(newStartDate).getTime();
        let newEndDateSelected = new Date(newEndDate).getTime()
        let days = ((((newEndDateSelected - newStartDataSelected) / 1000) / 60) / 60) / 24;

        if (days > 365) {
            setErrorNotice(true)
            setErrorMessage(t("header.dateValidationError"))
            setShowFiltersMenu(true)
            setShowCustomDatePicker(true)
        } else if (newEndDateSelected < newStartDataSelected) {
            setErrorNotice(true)
            setErrorMessage(t("header.dateValidationErrorNew"))
            setShowFiltersMenu(true)
            setShowCustomDatePicker(true)
        } else if (newEndDate !== undefined && newStartDate === undefined) {
            setErrorNotice(true)
            setErrorMessage(t("header.errorStartDate"))
            setShowCustomDatePicker(true)
        } else if (newStartDate !== undefined && newEndDate === undefined) {
            setErrorNotice(true)
            setErrorMessage(t("header.errorEndDate"))
            setShowCustomDatePicker(true)
        } else {
            updateDatelabel();
            setErrorNotice(false);
            createLabels();

            //moment(contextData.startDate).isSame(newStartDate)
            if (!moment(contextData.startDate).isSame(newStartDate)) {
                contextData.changeStartDate(newStartDate)
            }
            if (!moment(contextData.endDate).isSame(newEndDate)) {
                contextData.changeEndDate(newEndDate)
            }

            props.applyFilters(newStartDate, newEndDate)
            setTimeout(() => setShowFiltersMenu(false), 100)
            let channel = "";
            if (props.channel !== undefined) {
                channel = "_" + props.channel;
            } else {
                channel = "";
            }
            if (selectedDatePicker && selectedDatePicker.showDatePicker === false) {
                contextData.changeSelectedDatePickr(0);
                localStorage.setItem("SelectedDatePicker" + channel, JSON.stringify(selectedDatePicker));// Adding SelectedDatePicker to local stoarge in case of Page Refresh 
            }
            if (selectedDatePicker && selectedDatePicker.showDatePicker) {
                contextData.changeSelectedDatePickr(1);
                localStorage.setItem("SelectedDatePicker" + channel, JSON.stringify({ "compareOn": 0, "value": "Custom Range", "key": datePickerValue, "showDatePicker": true }));// Adding SelectedDatePicker to local stoarge in case of Page Refresh 
            }
        }
    }

    const onDatePickerDropdownChange = (data) => {
        if (data !== null && data.compareOn === 0) {
            setErrorMessage(false)
        }
        if (data && Object.keys(data).length > 0) {
            if (data.key.length > 0) {
                const datePickerSplit = data.key.split("-");
                setNewStartDate(moment(datePickerSplit[0]));
                setNewEndDate(moment(datePickerSplit[1]));
                if (data.compareOn || data.showDatePicker) {
                    const compareOnStartDate = moment(datePickerSplit[0]).add(-data.compareOn, 'days').format("MMM DD, YYYY");
                    const compareOnEndDate = moment(datePickerSplit[1]).add(-data.compareOn, 'days').format("MMM DD, YYYY");
                    const compareOnValue = `${compareOnStartDate} - ${compareOnEndDate}`;
                    setDatePickerValue(`${moment(datePickerSplit[0]).format("MMM DD,YYYY")} - ${moment(datePickerSplit[1]).format("MMM DD,YYYY")}`);
                    setDtPickerCompareValueOnDatePickerChange(datePickerSplit[0], datePickerSplit[1]);
                }
            }
            if (data.showDatePicker)
                setShowCustomDatePicker(true);
            else
                setShowCustomDatePicker(false);
        }
        setSelectedDatePicker(data)
    };

    const validStartDate = (current) => {
        let currentDay = moment(endDate).format("MM/DD/YYYY");
        if (props.channel === "website") {
            return current.isBefore(currentDay) && current.isAfter(moment().subtract(2, "years"));
        }
        else if (props.channel === "alert") {
            const alertDate = moment(props.dataSource[0]?.selectedItem["WebsiteList.AlertTrackingDate"]);
            const currentDate = moment();
            const daysDifference = currentDate.diff(alertDate, 'days');
            return current.isBefore(currentDay) && current.isAfter(moment().subtract(daysDifference, "days"));
        }
        else {
            return current.isBefore(currentDay);
        }
    };

    const validEndDate = (current) => {
        let yesterday = moment().subtract(1, 'day');
        return current.isBefore(yesterday);
    };

    return (
        <>
            <Row className="align-items-center mt-2 mb-3">
                <Col lg={12} xl={4}>
                    {showtitle && <h2 className="h2 mb-0 ps-3">{props.title}</h2>}
                </Col>
                <Col lg={12} xl={8} className="text-md-start text-lg-start text-xl-end position-relative">
                    <div className="edit-profile-container">

                        <div className="edit-profile-labels">
                            <span className="ps-3 me-3 web-analytics-date d-block" id="websiteWebsiteProfile">{selectionsText}</span>
                            {props.showDate &&
                                <><span className="ps-3 me-4 web-analytics-date" id="dateRange">{t("header.InsightDateLabel")}: {dateRange}</span><span className="pe-3 web-comp-date">{t("header.WOComparedToText")} {dateCompareValue}</span></>
                            }
                        </div>
                        <div className="slider-dropdown-tigger edit-profile-tigger icon-nav-list svg-icon-nav-list">
                            <span className="icon-nav-box ms-0" onClick={toggleFiltersMenu}>
                                <svg className="icon-nav interactive-icon-color">
                                    <title>{t("header.Icon_Tooltip_Filters")}</title>
                                    <use href={iconSprite + `#Edit`} />
                                </svg>
                            </span>
                        </div>

                    </div>

                    {showFiltersMenu &&
                        <Form ref={filterRef} onSubmit={(e) => { e.preventDefault() }} className="date-range-container">
                            <Row className="text-end">
                                <Col xs={12} sm={12} className="text-left">
                                    <h4 className="mt-4">{t("header.webcastViewersFiltersText")} <OverlayTrigger
                                        placement="right"
                                        trigger={['hover', 'focus']}
                                        overlay={
                                            <Tooltip>{t("header.filtersMessage")}</Tooltip>
                                        }>
                                        <Button variant="white" className="btn-help border-0 p-2">
                                            <i className="far fa-question-circle lg"></i>
                                        </Button>
                                    </OverlayTrigger></h4>
                                </Col>

                                {contextData.companyId === companyIdValue &&
                                    <Col xs={12} sm={12} className="mb-1">
                                    </Col>
                                }
                                {(selectedWebsiteId && Object.keys(selectedWebsiteId).length > 0) &&
                                    <Col xs={12} sm={12} className="mb-1">
                                    </Col>
                                }

                                <Col xs={12} sm={12} className="mb-1 position-relative">
                                    {
                                        props.dataSource.map((element) => {
                                            if (element.data !== null && element.data.length > 0) {
                                                if (element.isVisible) {
                                                    return (<div className="text-left pt-2"><span>{element.label}</span><DropdownWithIconComponent
                                                        dropdownData={element.data}
                                                        arrayValue={element.displayText}
                                                        selectedDropdown={element.selectedItem}
                                                        onChange={element.callback}
                                                        showLabel={false}
                                                        showIconInDropdownItem={false}
                                                        customClassName="full-width text-overflow"
                                                        isDropdownDisabled={isDemoClient}
                                                    /></div>)
                                                } else {
                                                    return <></>
                                                }
                                            }
                                        })
                                    }
                                    {props.showDate &&
                                        <Form>
                                            <div className="text-left pt-2"><span>{t("header.dateRange")}</span></div>
                                            <DropdownWithIconComponent
                                                dropdownData={dateRangeList}
                                                arrayValue="value"
                                                selectedDropdown={selectedDatePicker}
                                                onChange={onDatePickerDropdownChange}
                                                showLabel={false}
                                                showIconInDropdownItem={false}
                                                customClassName="full-width text-overflow"
                                            />
                                            <div className="invalid-feedback d-block" style={{ textAlign: "left" }}>{errorNotice ? errorMessage : ''}</div>
                                        </Form>
                                    }

                                    {showCustomDatePicker &&
                                        <Form ref={customDateRangeRef} className="date-range-container header-custom-date-range">
                                            <Form.Group className="" onClick={() => { setTimeout(() => setShowCustomDatePicker(true), 10) }}>
                                                <Datetime
                                                    open={showStartDate}
                                                    timeFormat={false}
                                                    closeOnSelect={false}
                                                    onChange={(val) => {
                                                        setNewStartDate(val._d);
                                                        setShowStartDate(false);
                                                        setShowEndDate(true);
                                                    }}
                                                    className="mt-2 datetime-startdate"
                                                    isValidDate={validStartDate}
                                                    value={newStartDate}
                                                    renderInput={(props, openCalendar) => (
                                                        <><InputGroup>
                                                            <Form.Control
                                                                onClick={() => {
                                                                    setTimeout(() => setShowCustomDatePicker(true), 10);
                                                                    setShowStartDate(true);
                                                                    setShowEndDate(false)
                                                                }}
                                                                required
                                                                type="text"
                                                                value={newStartDate ? moment(newStartDate).format("MMM DD, YYYY") : ""}
                                                                placeholder={t("header.ToolsEventCalEventListColStartDate")}
                                                                onFocus={openCalendar}
                                                                onChange={() => {
                                                                }} />
                                                            <InputGroup.Text>
                                                                <FontAwesomeIcon onClick={() => { setShowStartDate(!showStartDate); setShowEndDate(false) }} icon={faCalendarAlt} /></InputGroup.Text>
                                                        </InputGroup></>
                                                    )} />
                                                <Datetime
                                                    open={showEndDate}
                                                    timeFormat={false}
                                                    closeOnSelect={false}
                                                    onChange={(val) => {
                                                        setNewEndDate(val._d);
                                                        setShowStartDate(false);
                                                        setShowEndDate(false);
                                                    }}
                                                    isValidDate={validEndDate}
                                                    className="mt-2"
                                                    value={newEndDate}
                                                    renderInput={(props, openCalendar) => (
                                                        <><InputGroup>
                                                            <Form.Control
                                                                onClick={() => {
                                                                    setTimeout(() => setShowCustomDatePicker(true), 10);
                                                                    setShowStartDate(false);
                                                                    setShowEndDate(true)
                                                                }}
                                                                required
                                                                type="text"
                                                                value={newEndDate ? moment(newEndDate).format("MMM DD, YYYY") : ""}
                                                                placeholder={t("header.ToolsEventCalEventListColEndDate")}
                                                                onFocus={openCalendar}
                                                                onChange={() => { }} />
                                                            <InputGroup.Text><FontAwesomeIcon onClick={() => { setShowEndDate(!showEndDate); setShowStartDate(false) }} icon={faCalendarAlt} /></InputGroup.Text>
                                                        </InputGroup></>
                                                    )} />
                                            </Form.Group>
                                        </Form>
                                    }
                                </Col>
                                <Col className="text-start" lg={12} xl={12}>
                                    <div className="d-block mt-4">
                                        <ButtonComponent
                                            onClick={() => applyFilters()}
                                            isIcon={false}
                                            isPrimary={true}
                                            btnText={t("header.AlertConfirmButtonOK")}
                                            // btn_class={"btn-md mb-3 filter-btn"}
                                            btn_class={props.isApplyDisabled ? "btn-md mb-3 filter-btn disabled" : "btn-md mb-3 filter-btn"} //props.isAllpyDisabled is used to disable the ok button for manage event page, if there is no language for selected website.
                                        />
                                    </div>
                                    <div className="d-block">
                                        <ButtonComponent
                                            onClick={() => cancelFilters()}
                                            isIcon={false}
                                            isPrimary={false}
                                            btnText={t("header.AlertConfirmButtonCancel")}
                                            btn_class="btn-md mb-3 filter-btn"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    }
                </Col>
            </Row>
        </>
    );
};

export default withRouter(CommonHeader);