import request from "../request"
import { baseURL, METHODS } from "../utilities/constant"
import { decryptData } from "../../utils/crypto";

export function GetAlertStatisticsByClientMultilineChart(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"),cryptoKey);
    request(
        baseURL + `/Intelligence/GetAlertStatisticsByClient?WebsiteId=${payload.websiteId}&startDate=${payload.startDate}&endDate=${payload.endDate}&categoryId=${payload.alertCategoryId}&companyid=${payload.companyId}&userid=${payload.userId}&alertType=${payload.alertCategoryName}`,
        METHODS.GET,
        {},
        { 
            Authorization: `Bearer ${token}`, "content-type": "application/json"
        }
    )
        .then((response) => {
            callBackFn && callBackFn(response?.result?.GraphDetail, payload.id);
        })
        .catch((error) => {
            callBackFn && callBackFn(undefined, payload.id);
        })
}

export function GetAlertSummeryBarChartData(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"),cryptoKey);
    request(
        baseURL + `/Intelligence/GetAlertStatisticsReport?WebsiteId=${payload.websiteId}&year=${payload.year}&companyid=${payload.companyId}&userid=${payload.userId}`,
        METHODS.GET,
        {},
        { 
            Authorization: `Bearer ${token}`, "content-type": "application/json"
        }
    )
        .then((response) => {
            callBackFn && callBackFn(response, payload.id);
        })
        .catch((error) => {
            callBackFn && callBackFn(undefined, payload.id);
        })
}

export function GetAlertStatisticsByClientForDomain(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"),cryptoKey);
    request(
        baseURL + `/Intelligence/GetDomainDetailByClient?WebsiteId=${payload.websiteId}&startDate=${payload.startDate}&endDate=${payload.endDate}&categoryId=${payload.alertCategoryId}&companyid=${payload.companyId}&userid=${payload.userId}&alertType=${payload.alertCategoryName}`,
        METHODS.GET,
        {},
        { Authorization: `Bearer ${token}`, "content-type": "application/json" }
    )
        .then((response) => {
            callBackFn && callBackFn(response?.result, payload.id);
        })
        .catch((error) => {
            callBackFn && callBackFn(undefined, payload.id);
        })
}

export function GetAlertStatisticsByClientForDevice(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"),cryptoKey);
    request(
        baseURL + `/Intelligence/GetDeviceDetailByClient?WebsiteId=${payload.websiteId}&startDate=${payload.startDate}&endDate=${payload.endDate}&categoryId=${payload.alertCategoryId}&companyid=${payload.companyId}&userid=${payload.userId}&alertType=${payload.alertCategoryName}`,
        METHODS.GET,
        {},
        { Authorization: `Bearer ${token}`, "content-type": "application/json" }
    )
        .then((response) => {
            callBackFn && callBackFn(response?.result, payload.id);
        })
        .catch((error) => {
            callBackFn && callBackFn(undefined, payload.id);
        })
}

export function GetAlertStatisticsByClientForCountry(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"),cryptoKey);
    request(
        baseURL + `/Intelligence/GetTopCountriesByClient?WebsiteId=${payload.websiteId}&startDate=${payload.startDate}&endDate=${payload.endDate}&categoryId=${payload.alertCategoryId}&companyid=${payload.companyId}&userid=${payload.userId}&alertType=${payload.alertCategoryName}&culture=${payload.culture}`,
        METHODS.GET,
        {},
        { Authorization: `Bearer ${token}`, "content-type": "application/json" }
    )
        .then((response) => {
            callBackFn && callBackFn(response?.result, payload.id);
        })
        .catch((error) => {
            callBackFn && callBackFn(undefined, payload.id);
        })
}

export function GetAlertStatisticsByClientForDomainTable(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"),cryptoKey);
    request(
        baseURL + `/Intelligence/GetDomainDetailByClientTable?WebsiteId=${payload.websiteId}&startDate=${payload.startDate}&endDate=${payload.endDate}&categoryId=${payload.alertCategoryId}&companyid=${payload.companyId}&userid=${payload.userId}&alertType=${payload.alertCategoryName}`,
        METHODS.GET,
        {},
        { Authorization: `Bearer ${token}`, "content-type": "application/json" }
    )
        .then((response) => {
            callBackFn && callBackFn(response?.result, payload.id);
        })
        .catch((error) => {
            callBackFn && callBackFn(undefined, payload.id);
        })
}

export function GetAlertStatisticsByClientForDeviceTable(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"),cryptoKey);
    request(
        baseURL + `/Intelligence/GetDeviceDetailByClientTable?WebsiteId=${payload.websiteId}&startDate=${payload.startDate}&endDate=${payload.endDate}&categoryId=${payload.alertCategoryId}&companyid=${payload.companyId}&userid=${payload.userId}&alertType=${payload.alertCategoryName}`,
        METHODS.GET,
        {},
        { Authorization: `Bearer ${token}`, "content-type": "application/json" }
    )
        .then((response) => {
            callBackFn && callBackFn(response?.result, payload.id);
        })
        .catch((error) => {
            callBackFn && callBackFn(undefined, payload.id);
        })
}

export function GetAlertStatisticsByClientForCountryTable(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"),cryptoKey);
    request(
        baseURL + `/Intelligence/GetTopCountriesByClientTable?WebsiteId=${payload.websiteId}&startDate=${payload.startDate}&endDate=${payload.endDate}&categoryId=${payload.alertCategoryId}&companyid=${payload.companyId}&userid=${payload.userId}&alertType=${payload.alertCategoryName}&culture=${payload.culture}`,
        METHODS.GET,
        {},
        { Authorization: `Bearer ${token}`, "content-type": "application/json" }
    )
        .then((response) => {
            callBackFn && callBackFn(response?.result, payload.id);
        })
        .catch((error) => {
            callBackFn && callBackFn(undefined, payload.id);
        })
}

export function GetTopDevicesByMessage(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"),cryptoKey);
    request(
        baseURL + `/Intelligence/GetTopDevicesByMessage?WebsiteId=${payload.websiteId}&MessageId=${payload.alertMessageId}&companyid=${payload.companyId}&userid=${payload.userId}`,
        METHODS.GET,
        {},
        {
            Authorization: `Bearer ${token}`, "content-type": "application/json"
        }
    )
        .then((response) => {
            callBackFn && callBackFn(response?.result, payload.id);
        })
        .catch((error) => {

            callBackFn && callBackFn(undefined, payload.id);
        })
}

export function GetTopCountriesByMessage(cryptoKey, payload, callBackFn) {
    const token = decryptData(localStorage.getItem("token"),cryptoKey);
    request(
        baseURL + `/Intelligence/GetTopCountriesByMessage?WebsiteId=${payload.websiteId}&MessageId=${payload.alertMessageId}&companyid=${payload.companyId}&userid=${payload.userId}&culture=${payload.culture}`,
        METHODS.GET,
        {},
        {
            Authorization: `Bearer ${token}`, "content-type": "application/json"
        }
    )
        .then((response) => {
            callBackFn && callBackFn(response?.result, payload.id);
        })
        .catch((error) => {

            callBackFn && callBackFn(undefined, payload.id);
        })
}


