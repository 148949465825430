import { Button } from '@themesberg/react-bootstrap';
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import Loader from "../components/VoltLoader";
import html2pdf from "html2pdf.js";
import { PDFCardHeader } from '../stubs/PdfTemplate/header';
import AppContext from '../components/AppContext';
import { decryptData } from '../utils/crypto';

const PdfComponent = (props) => {
    const [t, i18n] = useTranslation();
    const [showLoader, setShowLoader] = useState(false);
    const [isDisableDownload, setIsDisbaleDownload] = useState(true);
    const contextData = useContext(AppContext);

    var url = window.location.hash.indexOf("/website/visitoranalytics-page") > -1 ||
        window.location.hash.indexOf("/alert/statistics") > -1 ||
        window.location.hash.indexOf("/webcast/manage-statistics") > -1 ||
        window.location.hash.indexOf("/alert/summary") > -1 ||
        window.location.hash.indexOf("/website/page-analytics-page") > -1 ||
        window.location.hash.indexOf("/website/cookie-analytics-page") > -1 ||
        window.location.hash.indexOf("/website/search-page") > -1 ||
        window.location.hash.indexOf("website/useranalytics-page") > -1 ||
        window.location.hash.indexOf("tnf/insights") > -1;
    if (props.iscard) {
        url = true;
    }

    useEffect(() => {
        if (!props.iscard) {
            const observer = new MutationObserver(mutationsList => {
                const preloaderNotShown = document.querySelectorAll('.preloader:not(.show)').length > 0;
                setIsDisbaleDownload(preloaderNotShown);
            });

            const config = { attributes: true, childList: true, subtree: true };

            observer.observe(document.body, config);

            return () => observer.disconnect();
        }
        else {
            setIsDisbaleDownload(false);
        }
    })

    const downloadCardPDF = async () => {
        // props.setIsPageLoaderShown(true);
        document.getElementById('card_' + props.title).querySelectorAll(".truncate-text-container").forEach(x => {
            x.style.overflow = "unset";
        })
        const cardNode = document.getElementById('card_' + props.title).cloneNode(true);
        if (props.title === "User") {
            cardNode.innerHTML = await getUser(cardNode);
        }
        else {
            cardNode.innerHTML = await getCard(cardNode);
        }
        cardNode.querySelectorAll(".truncate-text-container").forEach(x => {
            if (x.querySelector(".truncate-text-container")) {
                x.innerHTML = x.querySelector(".truncate-text-container").innerHTML;
            }
            if (props.title === "Content Insights") {
                x.innerHTML = x.innerHTML.length > 30 ? x.innerHTML.substring(0, 30) + "..." : x.innerHTML;
            }
            else {
                x.innerHTML = x.innerHTML.length > 50 ? x.innerHTML.substring(0, 50) + "..." : x.innerHTML;
            }
        })
        document.getElementById('card_' + props.title).querySelectorAll(".truncate-text-container").forEach(x => {
            x.style.overflow = "hidden";
        })

        if (cardNode) {
            const combinedNode = document.createElement("div");
            combinedNode.appendChild(getHeaderContent(props.title));
            combinedNode.appendChild(cardNode.cloneNode(true));
            combinedNode.querySelectorAll('.metrics')?.forEach(item => {
                item.style.fontWeight = '600';
                item.style.fontSize = "36px";
            });
            combinedNode.querySelectorAll('.btn-help')?.forEach(btn => {
                btn.style.display = "none";
            });
            combinedNode.querySelectorAll("a").forEach(link => {
                link.removeAttribute("href");
                link.style.pointerEvents = "none";
            });
            await html2pdf().set(getOptions(props.title, false)).from(combinedNode).save().then(() => {
                // props.setIsPageLoaderShown(false);
            });
        }
    }

    const downloadPagePDF = async () => {
        setShowLoader(true);
        const content = document.createElement("div");
        let title = "";

        document.querySelectorAll(".truncate-text-container").forEach(x => {
            x.style.overflow = "unset";
        })
        if (window.location.hash.indexOf("/website/cookie-analytics-page") > -1) {
            title = "Cookies";
            content.appendChild(getCookiesContent("cookiesContent"));
        }
        else if (window.location.hash.indexOf("/website/search-page") > -1) {
            title = "Search";
            content.appendChild(getSearchContent("searchContent"));
        }
        else if (window.location.hash.indexOf("website/useranalytics-page") > -1) {
            title = "User Analytics";
            content.appendChild(getUserAnalyticsContent("userAnalyticsContent"));
        }
        else if (window.location.hash.indexOf("/page-analytics-page") > -1) {
            title = "Page Analytics";
            content.appendChild(getPageAnalyticsContent("pageAnalyticsContent"));
        }
        else if (window.location.hash.indexOf("/alert/summary") > -1) {
            title = "Alert Summary";
            content.appendChild(getAlertSummaryContent("alertSummary"));
        }
        else if (window.location.hash.indexOf("/alert/statistics") > -1) {
            title = "Alert Statistics";
            content.appendChild(getAlertStatisticsContent("alertStatistics"));
        }
        else if (window.location.hash.indexOf("/webcast/manage-statistics") > -1) {
            title = "Webcast Statistics";
            content.appendChild(getWebcastStatisticsContent("webcastStatistics"));
        }
        else if (window.location.hash.indexOf("/website/visitoranalytics-page") > -1) {
            title = "Visitor Analytics";
            content.appendChild(getVisitorAnalyticsContent("visitorAnalyticsContent"));
        }
        else if (window.location.hash.indexOf("/tnf/insights") > -1) {
            title = "Tools Insights";
            content.appendChild(getToolsAnalyticsContent("toolsInsights"));
        }

        const combinedNode = document.createElement("div");
        combinedNode.appendChild(getHeaderContent(title));
        combinedNode.appendChild(content);

        combinedNode.querySelectorAll(".react-grid-item").forEach(element => {
            element.style.height = 'auto';
            element.style.removeProperty('width');
        });

        combinedNode.querySelectorAll('.btn-help')?.forEach(btn => {
            btn.style.display = "none";
        });
        combinedNode.querySelectorAll('.pagination-content')?.forEach(btn => {
            btn.style.display = "none";
        });
        combinedNode.querySelectorAll(".table").forEach(table => {
            table.querySelectorAll("tr").forEach(tr => {
                tr.style.pageBreakInside = "avoid";
            });
        })
        combinedNode.querySelectorAll(".dropdown").forEach(dropdown => {
            if (dropdown.querySelector(".me-2")) {
                let dropdownContent = dropdown.querySelector(".me-2");
                dropdown.innerHTML = `<div style='border:1px solid #eee;padding: 8px;border-radius: 5px;'>${dropdownContent.innerHTML}</div>`;
            }
        })
        combinedNode.querySelectorAll(".table-search-box")?.forEach(item => {
            item.style.display = "none";
        });
        combinedNode.querySelectorAll(".me-1")?.forEach(item => {
            item.style.display = "none";
        });
        combinedNode.querySelectorAll(".switch-uncheck").forEach(item => {
            item.innerHTML = item.innerHTML.replace("checked", "");
        });
        combinedNode.querySelectorAll("a")?.forEach(link => {
            link.removeAttribute("href");
            link.style.pointerEvents = "none";
        });

        document.querySelectorAll(".truncate-text-container").forEach(item => {
            item.style.overflow = "hidden";
        });
        combinedNode.innerHTML = await covertSVGToIMG(combinedNode);
        await html2pdf().set(getOptions(title, true)).from(combinedNode).save().then(() => {
            setShowLoader(false);
        });
    }

    const loadImageAsync = (img) => {
        return new Promise((resolve, reject) => {
            img.onload = () => resolve(img);
            img.onerror = reject;
        });
    };

    const covertSVGToIMG = async (content) => {
        var dynamicSvgs = content.querySelectorAll('svg:not(.svg-inline--fa):not(.icon-nav)');
        for (let i = 0; i < dynamicSvgs.length; i++) {
            dynamicSvgs[i].querySelectorAll("line").forEach(x => {
                x.setAttribute("stroke", "#dddee2");
            });
            dynamicSvgs[i].querySelectorAll("path.domain[stroke='currentColor']").forEach(x => {
                x.style.display = "none";
            });
            var canvas = document.createElement('canvas');
            const scaleFactor = 2;
            canvas.width = dynamicSvgs[i].width.baseVal.value * scaleFactor;
            canvas.height = dynamicSvgs[i].height.baseVal.value * scaleFactor;
            var context = canvas.getContext('2d');
            context.scale(scaleFactor, scaleFactor);
            context.fillStyle = '#ffffff';
            context.fillRect(0, 0, canvas.width, canvas.height);
            var svgData = new XMLSerializer().serializeToString(dynamicSvgs[i]);
            var img = new Image();
            img.src = 'data:image/svg+xml,' + encodeURIComponent(svgData);
            await loadImageAsync(img);
            const scaleWidthFactor = 1;
            context.drawImage(img, 0, 0, img.width * scaleWidthFactor, img.height * scaleWidthFactor);
            var imgSrc = canvas.toDataURL('image/jpeg');
            var dynamicImg = document.createElement('img');
            dynamicImg.src = imgSrc;
            let parent = dynamicSvgs[i].parentNode;
            while (parent && parent !== content) {
                parent = parent.parentNode;
            }
            if (parent === content) {
                dynamicSvgs[i].parentNode.replaceChild(dynamicImg, dynamicSvgs[i]);
            }
        }
        return content.innerHTML;
    };

    const getUser = async (node) => {
        const cardNode = document.createElement("div");
        const sections = document.createElement("div");
        sections.classList.add("d-flex");
        sections.classList.add("flex-wrap");
        sections.classList.add("justify-content-between");
        node.querySelectorAll(".user-section").forEach(userSection => {
            const col = document.createElement("div");
            col.classList.add("w-50");
            col.classList.add("px-1");
            userSection.classList.remove("col-sm-6");
            col.appendChild(userSection);
            const legends = [];
            col.querySelectorAll(".legend-holder-line .legend").forEach(legend => {
                const circle = legend.querySelector("circle");
                const text = legend.querySelector("text");
                const color = circle.getAttribute("style").match(/fill:\s*(.*?);/)[1];
                const year = text.textContent;
                legends.push({ year, color });
            });

            if (col.querySelector(".legend-holder-line")) {
                col.querySelector(".legend-holder-line").style.display = "none";
            }

            if (legends.length > 0) {
                const legendContainer = document.createElement("div");
                legendContainer.style.display = "flex";
                legendContainer.style.justifyContent = "center";
                legendContainer.style.alignItems = "center";
                legendContainer.style.gap = "30px";

                legends.forEach(item => {
                    const legendItem = document.createElement("div");
                    legendItem.style.display = "flex";
                    legendItem.style.alignItems = "center";

                    const dot = document.createElement("div");
                    dot.style.width = "12px";
                    dot.style.height = "12px";
                    dot.style.borderRadius = "50%";
                    dot.style.marginRight = "7px";
                    dot.style.backgroundColor = item.color;
                    dot.style.flexShrink = "0";

                    const text = document.createElement("h6");
                    text.textContent = item.year;
                    text.style.margin = "0";
                    text.style.lineHeight = "12px";

                    legendItem.appendChild(dot);
                    legendItem.appendChild(text);
                    legendContainer.appendChild(legendItem);
                });
                col.appendChild(legendContainer);
            }
            sections.appendChild(col);
        });
        cardNode.appendChild(sections);
        cardNode.innerHTML = await covertSVGToIMG(cardNode);
        return cardNode.innerHTML;
    }

    const getCard = async (node) => {
        const cardNode = document.createElement("div");
        cardNode.classList.add("d-flex");
        cardNode.classList.add("flex-wrap");
        cardNode.classList.add("justify-content-between");
        const col = document.createElement("div");
        col.classList.add("w-75");
        col.classList.add("px-1");
        col.appendChild(node);
        cardNode.appendChild(col);
        const legends = [];
        cardNode.querySelectorAll(".legend-holder-line .legend").forEach(legend => {
            const circle = legend.querySelector("circle");
            const text = legend.querySelector("text");
            const color = circle.getAttribute("style").match(/fill:\s*(.*?);/)[1];
            const year = text.textContent;
            legends.push({ year, color });
        });

        if (cardNode.querySelector(".legend-holder-line")) {
            cardNode.querySelector(".legend-holder-line").style.display = "none";
        }
        if (legends.length > 0) {
            const legendContainer = document.createElement("div");
            legendContainer.style.display = "flex";
            legendContainer.style.justifyContent = "center";
            legendContainer.style.alignItems = "center";
            legendContainer.style.gap = "30px";
            legendContainer.classList.add("w-75");
            legends.forEach(item => {
                const legendItem = document.createElement("div");
                legendItem.style.display = "flex";
                legendItem.style.alignItems = "center";

                const dot = document.createElement("div");
                dot.style.width = "12px";
                dot.style.height = "12px";
                dot.style.borderRadius = "50%";
                dot.style.marginRight = "7px";
                dot.style.backgroundColor = item.color;
                dot.style.flexShrink = "0";

                const text = document.createElement("h6");
                text.textContent = item.year;
                text.style.margin = "0";
                text.style.lineHeight = "12px";

                legendItem.appendChild(dot);
                legendItem.appendChild(text);
                legendContainer.appendChild(legendItem);
            });
            cardNode.appendChild(legendContainer);
        }

        let legendText = "";
        cardNode.querySelectorAll(".legend-holder .legend").forEach(legend => {
            legendText = legend.querySelector("text").textContent;
        });

        if (cardNode.querySelector(".legend-holder")) {
            cardNode.querySelector(".legend-holder").style.display = "none";
        }
        if (legendText !== "") {
            const legendContainer = document.createElement("div");
            legendContainer.style.display = "flex";
            legendContainer.style.justifyContent = "center";
            legendContainer.style.alignItems = "center";
            legendContainer.style.gap = "30px";
            legendContainer.classList.add("w-75");
            const legendItem = document.createElement("div");
            legendItem.style.display = "flex";
            legendItem.style.alignItems = "center";
            const text = document.createElement("h6");
            text.textContent = legendText;
            legendItem.appendChild(text);
            legendContainer.appendChild(legendItem);
            cardNode.appendChild(legendContainer);
        }
        cardNode.innerHTML = await covertSVGToIMG(cardNode);
        return cardNode.innerHTML;
    }

    const getCookiesContent = (id) => {
        const page = document.createElement("div");
        const tempPage = document.createElement("div");
        const cards = document.createElement("div");
        tempPage.innerHTML = document.getElementById(id).cloneNode(true).innerHTML;
        cards.classList.add("d-flex");
        cards.classList.add("flex-wrap");
        cards.classList.add("justify-content-between");
        tempPage.querySelectorAll(".card").forEach(x => {
            const col = document.createElement("div");
            col.style.setProperty("margin-bottom", "15px");
            col.classList.add("w-100");
            col.classList.add("px-1");
            col.appendChild(x);
            cards.appendChild(col);
        });
        page.appendChild(cards);
        if (page.querySelector(".cookies-additional")) {
            page.querySelector(".cookies-additional").style.pageBreakInside = "avoid";
        }
        return page;
    }

    const getSearchContent = (id) => {
        const page = document.createElement("div");
        const tempPage = document.createElement("div");
        const cards = document.createElement("div");
        tempPage.innerHTML = document.getElementById(id).cloneNode(true).innerHTML;
        cards.classList.add("d-flex");
        cards.classList.add("flex-wrap");
        cards.classList.add("justify-content-between");
        tempPage.querySelectorAll(".card").forEach(x => {
            const col = document.createElement("div");
            col.style.setProperty("margin-bottom", "15px");
            col.classList.add("w-100");
            col.classList.add("px-1");
            col.appendChild(x);
            cards.appendChild(col);
        });
        page.appendChild(cards);
        page.querySelectorAll(".truncate-text-container").forEach(x => {
            if (x.querySelector(".truncate-text-container")) {
                x.innerHTML = x.querySelector(".truncate-text-container").innerHTML;
            }
            x.innerHTML = x.innerHTML.length > 30 ? x.innerHTML.substring(0, 30) + "..." : x.innerHTML;
        });
        return page;
    }

    const getUserAnalyticsContent = (id) => {
        const page = document.createElement("div");
        const tempPage = document.createElement("div");
        const cards = document.createElement("div");
        tempPage.innerHTML = document.getElementById(id).cloneNode(true).innerHTML;
        cards.classList.add("d-flex");
        cards.classList.add("flex-wrap");
        cards.classList.add("justify-content-between");
        tempPage.querySelectorAll("#card_User .row").forEach((x, index) => {
            const col = document.createElement("div");
            col.style.setProperty("margin-bottom", "15px");
            col.classList.add("w-100");
            col.classList.add("px-1");
            if (index == 1) {
                x.style.pageBreakBefore = "always";
                x.style.transform = "scale(0.9)";
            }
            const legends = [];
            x.querySelectorAll(".legend-holder-line-big .legend").forEach(legend => {
                const circle = legend.querySelector("circle");
                const text = legend.querySelector("text");
                const color = circle.getAttribute("style").match(/fill:\s*(.*?);/)[1];
                const year = text.textContent;
                legends.push({ year, color });
            });

            if (x.querySelector(".legend-holder-line-big")) {
                x.querySelector(".legend-holder-line-big").style.display = "none";
            }
            col.appendChild(x);
            if (legends.length > 0) {
                const legendContainer = document.createElement("div");
                legendContainer.style.display = "flex";
                legendContainer.style.justifyContent = "center";
                legendContainer.style.alignItems = "center";
                legendContainer.style.gap = "30px";
                legendContainer.style.marginTop = "5px";
                legends.forEach(item => {
                    const legendItem = document.createElement("div");
                    legendItem.style.display = "flex";
                    legendItem.style.alignItems = "center";

                    const dot = document.createElement("div");
                    dot.style.width = "12px";
                    dot.style.height = "12px";
                    dot.style.borderRadius = "50%";
                    dot.style.marginRight = "7px";
                    dot.style.backgroundColor = item.color;
                    dot.style.flexShrink = "0";

                    const text = document.createElement("h6");
                    text.textContent = item.year;
                    text.style.margin = "0";
                    text.style.lineHeight = "12px";

                    legendItem.appendChild(dot);
                    legendItem.appendChild(text);
                    legendContainer.appendChild(legendItem);
                });
                col.appendChild(legendContainer);
            }
            cards.appendChild(col);
        });
        page.appendChild(cards);
        page.querySelectorAll(".truncate-text-container").forEach(x => {
            if (x.querySelector(".truncate-text-container")) {
                x.innerHTML = x.querySelector(".truncate-text-container").innerHTML;
            }
            x.innerHTML = x.innerHTML.length > 30 ? x.innerHTML.substring(0, 30) + "..." : x.innerHTML;
        });
        return page;
    }

    const getPageAnalyticsContent = (id) => {
        const page = document.createElement("div");
        const tempPage = document.createElement("div");
        const cards = document.createElement("div");
        tempPage.innerHTML = document.getElementById(id).cloneNode(true).innerHTML;
        cards.classList.add("d-flex");
        cards.classList.add("flex-wrap");
        cards.classList.add("justify-content-between");
        tempPage.querySelectorAll(".card").forEach((x, index) => {
            const col = document.createElement("div");
            col.style.setProperty("margin-bottom", "15px");
            col.classList.add("w-100");
            col.classList.add("px-1");
            col.appendChild(x);
            cards.appendChild(col);
            if (index === 0) {
                cards.appendChild(pageBreak());
            }
        });
        page.appendChild(cards);
        page.querySelectorAll(".truncate-text-container").forEach(x => {
            if (x.querySelector(".truncate-text-container")) {
                x.innerHTML = x.querySelector(".truncate-text-container").innerHTML;
            }
            x.innerHTML = x.innerHTML.length > 30 ? x.innerHTML.substring(0, 30) + "..." : x.innerHTML;
        });
        return page;
    }

    const getAlertSummaryContent = (id) => {
        const page = document.createElement("div");
        const tempPage = document.createElement("div");
        const cards = document.createElement("div");
        tempPage.innerHTML = document.getElementById(id).cloneNode(true).innerHTML;
        cards.classList.add("d-flex");
        cards.classList.add("flex-wrap");
        cards.classList.add("justify-content-between");
        tempPage.querySelectorAll(".card").forEach(x => {
            const col = document.createElement("div");
            col.style.setProperty("margin-bottom", "15px");
            col.classList.add("w-100");
            col.classList.add("px-1");
            col.appendChild(x);
            cards.appendChild(col);
        });
        page.appendChild(cards);
        page.querySelectorAll(".truncate-text-container").forEach(x => {
            if (x.querySelector(".truncate-text-container")) {
                x.innerHTML = x.querySelector(".truncate-text-container").innerHTML;
            }
            x.innerHTML = x.innerHTML.length > 30 ? x.innerHTML.substring(0, 30) + "..." : x.innerHTML;
        });
        return page;
    }

    const getAlertStatisticsContent = (id) => {
        const page = document.createElement("div");
        const tempPage = document.createElement("div");
        const cards = document.createElement("div");
        tempPage.innerHTML = document.getElementById(id).cloneNode(true).innerHTML;
        cards.classList.add("d-flex", "flex-wrap", "justify-content-between");
        let cardCount = tempPage.querySelectorAll(".card").length;
        tempPage.querySelectorAll(".card").forEach((x, index) => {
            x.style.minHeight = "550px";
            const col = document.createElement("div");
            col.style.setProperty("margin-bottom", "15px");
            if (index === 0 || index === cardCount - 1) {
                col.classList.add("w-100");
            }
            else {
                col.classList.add("w-50");
                x.style.pageBreakInside = "avoid";
                x.style.height = "550px";
            }
            col.classList.add("px-1");
            col.appendChild(x);
            cards.appendChild(col);
            if (index === 0 || index === 2 || index === 4 || index === 6) {
                cards.appendChild(pageBreak());
            }
        });
        page.appendChild(cards);
        page.querySelectorAll(".switch-checked").forEach(item => {
            item.querySelector("input").setAttribute("checked", "checked");
        });
        page.querySelectorAll(".truncate-text-container").forEach(x => {
            if (x.querySelector(".truncate-text-container")) {
                x.innerHTML = x.querySelector(".truncate-text-container").innerHTML;
            }
            x.innerHTML = x.innerHTML.length > 30 ? x.innerHTML.substring(0, 30) + "..." : x.innerHTML;
        });
        return page;
    }

    const getWebcastStatisticsContent = (id) => {
        let questionContainer = null;
        if (document.querySelector(".question-container")) {
            questionContainer = document.querySelector(".question-container");
            questionContainer.classList.remove("question-container");
        }
        const page = document.createElement("div");
        const tempPage = document.createElement("div");
        const cards = document.createElement("div");
        tempPage.innerHTML = document.getElementById(id).cloneNode(true).innerHTML;
        cards.classList.add("d-flex", "flex-wrap", "justify-content-between");
        let cardCount = tempPage.querySelectorAll(".card").length;
        tempPage.querySelectorAll(".card").forEach((x, index) => {
            x.style.pageBreakInside = "avoid";
            x.style.minHeight = "550px";
            const col = document.createElement("div");
            col.style.setProperty("margin-bottom", "15px");
            if (index === cardCount - 1) {
                col.classList.add("w-100");
            }
            else {
                col.classList.add("w-50");
                x.style.height = "550px";
            }
            col.classList.add("px-1");
            col.appendChild(x);
            cards.appendChild(col);
            if (index === 1 || index === 3 || index === 5) {
                cards.appendChild(pageBreak());
            }
        });
        page.appendChild(cards);

        page.querySelectorAll(".webcast-questions-card-nav").forEach(x => {
            x.style.display = "none";
        });
        page.querySelectorAll(".truncate-text-container").forEach(x => {
            if (x.querySelector(".truncate-text-container")) {
                x.innerHTML = x.querySelector(".truncate-text-container").innerHTML;
            }
            if (cardCount > 3) {
                x.innerHTML = x.innerHTML.length > 20 ? x.innerHTML.substring(0, 20) + "..." : x.innerHTML;
            }
            else {
                x.innerHTML = x.innerHTML.length > 30 ? x.innerHTML.substring(0, 30) + "..." : x.innerHTML;
            }
        });
        if (page.querySelector(".question-container")) {
            page.querySelector(".question-container").querySelectorAll(".poll-question-block").forEach(x => {
                x.style.pageBreakInside = "avoid";
            });
        }
        if (questionContainer) {
            questionContainer.classList.add("question-container");
        }
        return page;
    }

    const getVisitorAnalyticsContent = (id) => {
        const page = document.createElement("div");
        page.innerHTML = document.getElementById(id).cloneNode(true).innerHTML;
        page.innerHTML = page.innerHTML.replace("accordion-collapse collapse", "accordion-collapse");
        let cardCount = page.querySelectorAll(".nested-card-container").length;
        page.querySelectorAll(".nested-card-container").forEach((item, index) => {
            if ((cardCount === 3 && index === 0) || (cardCount === 4 && index === 1)) {
                for (var i = 0; i < item.childNodes.length; i++) {
                    if (item.childNodes[i].nodeType === 1) {
                        if (i !== 0 && i !== 1 && i !== 3) {
                            const div = document.createElement("div");
                            div.classList.add("d-flex");
                            div.classList.add("flex-wrap");
                            div.classList.add("justify-content-between");
                            if (i === 2) {
                                const card = document.createElement("div");
                                card.classList.add("w-100");
                                let cardBody = item.childNodes[i].querySelector(".card-body");
                                card.appendChild(cardBody.childNodes[2]);
                                card.appendChild(cardBody.childNodes[2]);
                                card.appendChild(pageBreak());
                                card.appendChild(cardBody.querySelector("svg"));
                                card.style.marginBottom = "100px";
                                div.appendChild(card);
                            }
                            if (i === 4 || i === 5 || i === 6) {
                                if (item.childNodes[i].querySelector(":not([hidden])")) {
                                    const card = document.createElement("div");
                                    card.classList.add("d-flex");
                                    card.classList.add("flex-wrap");
                                    card.classList.add("justify-content-between");
                                    const svg = document.createElement("div");
                                    svg.classList.add("w-50");
                                    svg.appendChild(item.childNodes[i].querySelector("svg"));
                                    card.appendChild(svg);
                                    const table = document.createElement("div");
                                    table.classList.add("w-50");
                                    table.appendChild(item.childNodes[i].querySelector(".table-responsive-lg"));
                                    card.appendChild(table);
                                    card.style.pageBreakInside = "avoid";
                                    div.appendChild(card);
                                }
                            }
                            item.childNodes[i].innerHTML = div.innerHTML;
                            if (item.querySelector(".react-grid-layout"))
                                item.querySelector(".react-grid-layout").style.removeProperty("height");
                        }
                    }
                }
            }
            if ((cardCount === 2 && index === 1) || (cardCount === 3 && index === 2)) {
                item.querySelector(".card-merices-container").style.pageBreakInside = "avoid";
            }
        });

        page.querySelectorAll(".truncate-text-container").forEach(x => {
            if (x.querySelector(".truncate-text-container")) {
                x.innerHTML = x.querySelector(".truncate-text-container").innerHTML;
            }
            x.innerHTML = x.innerHTML.length > 30 ? x.innerHTML.substring(0, 30) + "..." : x.innerHTML;
        });
        return page;
    }

    const getToolsAnalyticsContent = (id) => {
        const page = document.createElement("div");
        page.innerHTML = document.getElementById(id).cloneNode(true).innerHTML;
        page.innerHTML = page.innerHTML.replace("accordion-collapse collapse", "accordion-collapse");
        const directChildren = Array.from(page.children).filter(child => child.tagName === 'DIV')[0].childNodes;
        directChildren.forEach((div, index) => {
            if (index === 0) {
                div.style.display = "none";
            }
            else if (index === 3) {
                div.querySelectorAll('.tools-dashboard-card-loader').forEach((card, cardIndex) => {
                    if (cardIndex === 0) {
                        let cardbodyDiv = Array.from(card.querySelector('.card-body').children).filter(child => child.tagName === 'DIV');
                        card.innerHTML = "";
                        cardbodyDiv.forEach((lcd, i) => {
                            if (i == 1) {
                                lcd.style.marginTop = "40px";
                                card.appendChild(lcd);
                                pageBreak();
                                cardbodyDiv[0].style.pageBreakInside = "avoid";
                                card.appendChild(cardbodyDiv[0]);
                            }
                        });
                    }
                });
            }
        });
        page.querySelectorAll(".truncate-text-container").forEach(x => {
            if (x.querySelector(".truncate-text-container")) {
                x.innerHTML = x.querySelector(".truncate-text-container").innerHTML;
            }
            x.innerHTML = x.innerHTML.length > 30 ? x.innerHTML.substring(0, 30) + "..." : x.innerHTML;
        });
        return page;
    }

    const pageBreak = () => {
        const pageBreakDiv = document.createElement("div");
        pageBreakDiv.innerHTML = "<div style='page-break-before: always;'></div>";
        return pageBreakDiv;
    }

    const getHeaderContent = (title) => {
        const header = document.createElement("div");
        const logo = document.getElementById("logo").cloneNode(true);
        const logoImage = logo.querySelector("img");
        if (logoImage) {
            logoImage.style.height = "44px";
            logoImage.style.width = "256px";
        }
        const websiteWebsiteProfile = document.getElementById("websiteWebsiteProfile")?.cloneNode(true).innerHTML ?? "";
        const dateRange = document.getElementById("dateRange")?.cloneNode(true).innerHTML ?? "";
        const companyName = decryptData(localStorage.getItem("companyName"), contextData.cryptoKey) ?? "";
        header.innerHTML = PDFCardHeader
            .replace("{{logo}}", logo.innerHTML)
            .replace("{{websiteWebsiteProfile}}", websiteWebsiteProfile)
            .replace("{{dateRange}}", dateRange)
            .replace("{{pageTitle}}", title + " for " + companyName);
        return header;
    }

    const getOptions = (title, isLandscpe) => {
        return {
            filename: title.replace(" ", "_"),
            margin: [5, 2, 2, 2], //[top, right, bottom, left]
            image: { type: "jpeg", quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "mm", format: "a4", orientation: "landscape" }
        }
    }

    return (
        url && ((showLoader && (<Loader show={showLoader} />)) ||
            (<>
                <div className="icon-nav-list svg-icon-nav-list ms-3">
                    <Button disabled={isDisableDownload} onClick={(e) => props.iscard ? downloadCardPDF() : downloadPagePDF()} variant="outline-primary" className="mb-0 header-logout-btn">
                        <span className="icon-nav-box me-2 ms-0">
                            <svg className="icon-nav common-icon-color">
                                <use href={iconSprite + `#Download`} />
                            </svg>
                        </span>
                        {t("header.AppManageDocDownloadTitle")}
                    </Button>
                </div>
            </>)
        )
    );
}

export default withRouter(PdfComponent);