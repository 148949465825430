import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NumberComponent from "../components/Number";
import {CAROSAL_LAYOUT_TYPE} from "../AppConfig";
import { Row, Col } from '@themesberg/react-bootstrap';

const CardSummary = ({
    summaryPrimary,
    summarySecondary,
    showPercentageForPrimary,
    showPositiveOrNegativeForPrimary,
    showPercentageForSecoundary,
    showPositiveOrNegativeForSecoundary,
    carousalMainSummary,
    layoutType
}) => {
    return (
        <div className="card-merices-container">
            <div className="mt-1 mb-2 card-merices">
                {
                    (typeof summaryPrimary == "number")
                        ?
                        <NumberComponent
                            showPercentage={showPercentageForPrimary}
                            showPositiveOrNegative={showPositiveOrNegativeForPrimary}
                            number={summaryPrimary}
                            isPrimary={true}
                        />
                        :
                        <span className="metrics">{summaryPrimary} </span>
                }
                {
                    (typeof summarySecondary == "number")
                        ?
                        <NumberComponent
                            showPercentage={showPercentageForSecoundary}
                            showPositiveOrNegative={showPositiveOrNegativeForSecoundary}
                            number={summarySecondary}
                            isPrimary={false}
                            isCarouselCard={layoutType === CAROSAL_LAYOUT_TYPE ? true : false}
                        />
                        :
                        <span className="metrics-summary ms-2">{summarySecondary} </span>
                }
            </div>
            {/* {layoutType === CAROSAL_LAYOUT_TYPE &&
                <div className="large mb-4">
                    <span className="metrics-summary ms-2">{carousalMainSummary} </span>
                </div>
            } */}
        </div>
    );

};

CardSummary.propTypes = {
    summaryPrimary: PropTypes.string,
    summarySecondary: PropTypes.string,
    carousalMainSummary: PropTypes.string,
    showPercentageForPrimary: PropTypes.bool,
    showPositiveOrNegativeForPrimary: PropTypes.bool,
    showPercentageForSecoundary: PropTypes.bool,
    showPositiveOrNegativeForSecoundary: PropTypes.bool,
    layoutType: PropTypes.string
};


CardSummary.defaultProps = {
    summaryPrimary: "",
    summarySecondary: "",
    carousalMainSummary: "",
    showPercentageForPrimary: false,
    showPositiveOrNegativeForPrimary: false,
    showPercentageForSecoundary: false,
    showPositiveOrNegativeForSecoundary: false,
    layoutType: ""

};


export default CardSummary;
