import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from "react-device-detect";
// import { useQuery } from '@apollo/react-hooks';
// import { GET_DASHBOARD_ITEMS } from '../graphql/queries';
import ChartRenderer from '../components/ChartRenderer';
import Dashboard from '../components/Dashboard';
import DashboardItem from '../components/DashboardItem';
import { Button, Alert } from '@themesberg/react-bootstrap';
import Loader from '../components/VoltLoader';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { CARD_MOBILE_LAYOUT, DEFAULT_PAGE_NO } from "../AppConfig";
import ChartSummary from '../components/CardSummary';
import { dahbordCardItems } from "../stubs/dashbordCardItems";
import { useTranslation } from 'react-i18next';

const deserializeItem = (i) => ({
  ...i,
  layout: JSON.parse(i.layout) || {},
  summary: JSON.parse(i.summary) || {},
  vizState: JSON.parse(i.vizState)
});

const defaultLayout = (i) => ({
  x: i.layout.x || 0,
  y: i.layout.y || 0,
  w: i.layout.w || 4,
  h: i.layout.h || 8,
  minW: 4,
  minH: 8,
});

const defaultLayoutMobile = (i) => ({
  x: i.layout.x || 0,
  y: i.layout.y || 0,
  w: 12,
  // w: i.layout.w || 4,
  h: i.layout.h + 2 || 12,
  minW: 4,
  minH: 8,
});

const DashboardPage = () => {
  const [pageNo, setPageNo] = useState(DEFAULT_PAGE_NO);
  const [searchText, setSearchText] = useState("");
  const [t, i18n] = useTranslation();
  // const { loading, error, data } = useQuery(GET_DASHBOARD_ITEMS);

  // if (loading) {
  //   return <Loader show={true} />;
  // }

  // if (error) {
  //   return (
  //     <Alert variant="danger">
  //       {`Error occured while loading your query - ${error.toString()}`}
  //     </Alert>

  //   );
  // }

  const renderSummary = (cardSummary) => {
    return (
      <ChartSummary
        summaryPrimary={cardSummary.summaryPrimary}
        summarySecondary={cardSummary.summarySecondary}
        isCarouselCard={cardSummary.isCarouselCard}
        showPercentageForPrimary={cardSummary.showPercentageForPrimary}
        showPositiveOrNegativeForPrimary={cardSummary.showPositiveOrNegativeForPrimary}
        showPercentageForSecoundary={cardSummary.showPercentageForSecoundary}
        showPositiveOrNegativeForSecoundary={cardSummary.showPositiveOrNegativeForSecoundary}
        carousalMainSummary={cardSummary.carousalMainSummary}
      />
    );

  };

  const onPageNoChange = (number) => {
    setPageNo(number);

  };
  const onSearchTextChange = (value) => {
    setSearchText(value);
  }

  const dashboardItem = (item) => (
    <div key={item.id} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
      <DashboardItem
        key={item.id}
        itemId={item.id}
        title={item.name}
      >
        {Object.keys(item.summary).length > 0 && renderSummary(item.summary)}
        <ChartRenderer vizState={item.vizState} cardProperty={item.layout}
          pageNo={pageNo} onPageNoChange={onPageNoChange}
          searchText={searchText} onSearchTextChange={onSearchTextChange} />
      </DashboardItem>
    </div>
  );

  const Empty = () => (
    <div
      style={{
        textAlign: 'center',
        padding: 12,
      }}
    >
      <h2>{t("header.noChartsonDashboard")}</h2>
      {/* <Link to="/explore"> */}
      <Button variant="secondary" className="m-1">
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        {t("header.addChart")}
      </Button>
      {/* </Link> */}
    </div>
  );

  return !dahbordCardItems || dahbordCardItems.length > 0 ? (
    <Dashboard dashboardItems={dahbordCardItems}>
      {dahbordCardItems.map(deserializeItem).map(dashboardItem)}
    </Dashboard>
  ) : (
    <Empty />
  );
};

export default DashboardPage;
