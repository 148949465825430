import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from "react-device-detect";
// import { useQuery } from '@apollo/react-hooks';
// import { GET_DASHBOARD_ITEMS } from '../graphql/queries';
import ChartRenderer from '../components/ChartRenderer';
import PagePerformance from '../components/PagePerformance';
import DashboardItem from '../components/DashboardItem';
import { CARD_MOBILE_LAYOUT, DEFAULT_PAGE_NO } from "../AppConfig";
import { defaultLayout, defaultLayoutMobile } from "../utils/layoutConfig";
import SummaryRenderer from '../components/SummaryRenderer';
import moment from "moment-timezone";
import AppContext from '../components/AppContext';
import context from '@themesberg/react-bootstrap/lib/esm/AccordionContext';
import { Row, Col } from '@themesberg/react-bootstrap';
import { getPages, getPageSearch } from '../stubs/ComponentJson/Pages';
import { TABLE_TOTAL_ROWS } from "../AppConfig";
import MultilineToggleComponent from '../components/MultilineToggleComponent';
import ChartRendererTable from '../components/ChartRendererTable';
import CookiePerformance from '../components/CookiePerformance';
import { useTranslation } from 'react-i18next';
const deserializeItem = (i) => ({
  ...i,
  layout: JSON.parse(i.layout) || {},
  summary: JSON.parse(i.summary) || {},
  layoutone: JSON.parse(i.layoutone) || {},
  layouttwo: JSON.parse(i.layouttwo) || {},
  vizStateSecond: JSON.parse(i.vizStateSecond) || {},
  vizState: JSON.parse(i.vizState)
});

const CookieAnalyticsPage = (props) => {
  const [t, i18n] = useTranslation();
  const contextData = useContext(AppContext);
  const [pageNo, setPageNo] = useState(DEFAULT_PAGE_NO);
  const [searchText, setSearchText] = useState("");
  const [legends, setlegends] = useState("");
  // const [websiteId, setWebsiteId] = useState(0);
  const [selectedWebsiteId, setSelectedWebsiteId] = useState({});
  // const [profileId, setProfileId] = useState(0);
  const [selectedProfileId, setSelectedProfileId] = useState({});
  const [pagesData, setPagesData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [sourceMedium, setSourceMedium] = useState("direct");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isRefresh, setIsRefresh] = useState(false);
  const [offset, setOffset] = useState(0);
  const [d3, setd3] = useState();
  const [pageviewsAndUsersApi, setPageviewsAndUsersApi] = useState({ url: "http://localhost:3004/audiencetrends/", type: "get", count: "" });


  useEffect(() => {
    // const profileId = selectedProfileId && Object.keys(selectedProfileId).length > 0 ? selectedProfileId["WebsiteProfiles.ProfileId"]: 18841;

    const url = window.location.href.split("/");
    const urlEndpoints = `${url[0]}//${url[2]}/`;
    const selectedstartdate = moment(contextData.startDate).format("YYYY-MM-DD");
    const selectedenddate = moment(contextData.endDate).format("YYYY-MM-DD");
    const selectedPreviousYearStartDate = moment(contextData.startDate).add(-1, 'years').format("YYYY-MM-DD");
    const selectedPreviousYearEndDate = moment(contextData.endDate).add(-1, 'years').format("YYYY-MM-DD");

    let dta;
    if (searchText == undefined || searchText == "") {
      if (contextData.selectedProfile["WebsiteProfiles.ProfileId"] !== undefined) {
        dta = ((contextData.selectedProfile["WebsiteProfiles.ProfileId"] !== undefined && contextData.selectedWebsite["WebsiteList.WebsiteUrl"] !== undefined
          && contextData.selectedProfile["WebsiteProfiles.IntelConfigId"] !== undefined && sourceMedium !== undefined && selectedstartdate !== "Invalid date" && selectedenddate !== "Invalid date" && selectedPreviousYearStartDate !== "Invalid date" && selectedPreviousYearEndDate !== "Invalid date"))
          ? getPages(contextData.selectedProfile["WebsiteProfiles.ProfileId"], contextData.selectedWebsite["WebsiteList.WebsiteUrl"], sourceMedium, selectedstartdate, selectedenddate, selectedPreviousYearStartDate, selectedPreviousYearEndDate, contextData.userId, contextData.selectedProfile["WebsiteProfiles.IntelConfigId"], contextData.companyId, offset)
          : null;
      } else {
        dta = ((contextData.selectedWebsite["WebsiteList.WebsiteUrl"] !== undefined && sourceMedium !== undefined && selectedstartdate !== "Invalid date" && selectedenddate !== "Invalid date" && selectedPreviousYearStartDate !== "Invalid date" && selectedPreviousYearEndDate !== "Invalid date"))
          ? getPages(contextData.selectedProfile["WebsiteProfiles.ProfileId"], contextData.selectedWebsite["WebsiteList.WebsiteUrl"], sourceMedium, selectedstartdate, selectedenddate, selectedPreviousYearStartDate, selectedPreviousYearEndDate, contextData.userId, contextData.selectedProfile["WebsiteProfiles.IntelConfigId"], contextData.companyId, offset)
          : null;
      }
    }
    else {
      if (contextData.selectedProfile["WebsiteProfiles.ProfileId"] !== undefined) {
        dta = ((contextData.selectedProfile["WebsiteProfiles.ProfileId"] !== undefined && contextData.selectedWebsite["WebsiteList.WebsiteUrl"] !== undefined
          && contextData.selectedProfile["WebsiteProfiles.IntelConfigId"] !== undefined && sourceMedium !== undefined && selectedstartdate !== "Invalid date" && selectedenddate !== "Invalid date" && selectedPreviousYearStartDate !== "Invalid date" && selectedPreviousYearEndDate !== "Invalid date"))
          ? getPageSearch(contextData.selectedProfile["WebsiteProfiles.ProfileId"], contextData.selectedWebsite["WebsiteList.WebsiteUrl"], sourceMedium, selectedstartdate, selectedenddate, selectedPreviousYearStartDate, selectedPreviousYearEndDate, contextData.userId, contextData.selectedProfile["WebsiteProfiles.IntelConfigId"], contextData.companyId, offset, searchText)
          : null;
      } else {
        dta = ((contextData.selectedWebsite["WebsiteList.WebsiteUrl"] !== undefined && sourceMedium !== undefined && selectedstartdate !== "Invalid date" && selectedenddate !== "Invalid date" && selectedPreviousYearStartDate !== "Invalid date" && selectedPreviousYearEndDate !== "Invalid date"))
          ? getPageSearch(contextData.selectedProfile["WebsiteProfiles.ProfileId"], contextData.selectedWebsite["WebsiteList.WebsiteUrl"], sourceMedium, selectedstartdate, selectedenddate, selectedPreviousYearStartDate, selectedPreviousYearEndDate, contextData.userId, contextData.selectedProfile["WebsiteProfiles.IntelConfigId"], contextData.companyId, offset, searchText)
          : null;
      }
    }



    // let udta = (dta !== null && dta !== undefined) ? dta.filter(x=> x.id === "3" || x.id === "4") : null;
    // setUsersData(udta);
    setPageviewsAndUsersApi({ url: `${process.env.REACT_APP_API_KEY_NEW}/WebsiteAnalytics/GetCardDataFromGA?StartDate=${moment(contextData.startDate).format("YYYY-MM-DD")}&EndDate=${moment(contextData.endDate).format("YYYY-MM-DD")}&profileID=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}&Matrix=screenPageViews&Dimensions=pageviews,users&Component=multiline`, type: "get", count: "" });
    setPagesData([]);
  }, [contextData.selectedProfile, contextData.selectedProfile, sourceMedium, contextData.startDate, contextData.endDate, searchText, offset]);

  const onPageNoChange = (number) => {
    const num = (number - 1) * TABLE_TOTAL_ROWS;
    const page = number;
    setOffset(num);
    setPageNo(page);
  };
  const onSearchTextChange = (value) => {
    setSearchText(value["Googleanalyticsdata.pagetitle"]);
  }
  const showTooltips = (item) => {
    if (item.name == 'Page views and Users') {
      return "header.pageAnalyticsPageviewsandUserTooltipText";
    } else if (item.name == 'Content Insight') {
      return 'header.pageAnalyticsContentInsightTooltipText';
    } else {
      return "";
    }
  }
  const ApiUrls = (item) => {
    if (item.name = "Page views and Users") {
      return pageviewsAndUsersApi;
    }
  }
  const handleBackButtonClick = (e) => {
    localStorage.setItem("IsPageRefresh", "0");
    props.history.push(`/website`);
  }

  const dashboardItem = (item) => (
    <div className="page-analytics-switch-toggle page-analytics-summary-tooltip" key={item.id} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
      <DashboardItem
        key={item.id}
        itemId={item.id}
        title={item.name}
        enableOption={item.enableOption}>
        <Row>
          <Col>
            {Object.keys(item.summary).length > 0 && item.apiUrl == undefined && <SummaryRenderer summaryTooltip={showTooltips(item)} vizState={item.summary} title={item.name} />}
            {
              item.apiUrl !== undefined &&
              <ChartRendererTable
                showPagination={true}
                apiUrl={item.apiUrl}
                showCount={true}
              />
            }
            {
              item.apiUrl === undefined &&
              <ChartRenderer apiUrl={ApiUrls(item)} vizState={item.vizState} cardProperty={Object.keys(item.layoutone).length > 0 ? item.layoutone : item.layout}
                pageNo={pageNo} onPageNoChange={onPageNoChange} showPagination={item.pagination} offset={offset} setOffset={setOffset} showSearchTextBox={item.showSearch} setSearchText={setSearchText}
                searchText={searchText} onSearchBoxChange={onSearchTextChange} title={item.title} downloadExcel={item.downloadExcel} legends={item.legends} isRefresh={isRefresh} setIsRefresh={setIsRefresh} setd3={setd3} />
            }

          </Col>


        </Row>
      </DashboardItem>

    </div>
  );

  const Empty = () => (
    <div
      style={{
        textAlign: 'center',
        padding: 12,
      }}
    >
      <h2>{t("header.getConnectIdIntelligence")}</h2>
      {/* <Link to="/explore"> */}
      {/* <Button variant="secondary" className="m-1">
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        Add chart
      </Button> */}
      {/* </Link> */}
    </div>
  );

  return (
    <>
      <CookiePerformance dashboardItems={pagesData}
       handleBackButtonClick={handleBackButtonClick}
        usersData={usersData}
        deserializeItem={deserializeItem}
        dashboardItem={dashboardItem}
        selectedProfileId={contextData.selectedProfile}
        setSelectedProfileId={setSelectedProfileId}
        selectedWebsiteId={contextData.selectedWebsite}
        setSelectedWebsiteId={setSelectedWebsiteId}
        startDate={contextData.startDate}
        endDate={contextData.endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      >
        {pagesData || pagesData.length > 0 && pagesData.map(deserializeItem).map(dashboardItem)}
      </CookiePerformance>
    </>
  )

}

export default CookieAnalyticsPage