import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from "react-device-detect";
// import { useQuery } from '@apollo/react-hooks';
// import { GET_DASHBOARD_ITEMS } from '../graphql/queries';
import ChartRenderer from '../components/ChartRenderer';
import SearchPageComponent from '../components/SearchPage';
import DashboardItem from '../components/DashboardItem';
import { CARD_MOBILE_LAYOUT, DEFAULT_PAGE_NO } from "../AppConfig";
import { defaultLayout, defaultLayoutMobile } from "../utils/layoutConfig";
import SummaryRenderer from '../components/SummaryRenderer';
import moment from "moment-timezone";
import AppContext from '../components/AppContext';
import context from '@themesberg/react-bootstrap/lib/esm/AccordionContext';
import { Row, Col } from '@themesberg/react-bootstrap';

import { TABLE_TOTAL_ROWS } from "../AppConfig";
import MultilineToggleComponent from '../components/MultilineToggleComponent';
import ChartRendererTable from '../components/ChartRendererTable';
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import Search from "../components/Search";
import { string } from 'prop-types';
import { useTranslation } from "react-i18next";
import { decryptData } from '../utils/crypto';
import { object_equals } from "../AppConfig";
import CommonHeader from '../components/CommonHeader';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from '@themesberg/react-bootstrap';

const deserializeItem = (i) => ({
  ...i,
  layout: JSON.parse(i.layout) || {},
  // summary: JSON.parse(i.summary) || {},
  // layoutone: JSON.parse(i.layoutone) || {},
  // layouttwo: JSON.parse(i.layouttwo) || {},
  // vizStateSecond: JSON.parse(i.vizStateSecond) || {},
  // vizState: JSON.parse(i.vizState)
});

const SearchPage = (props) => {
  const [t] = useTranslation();
  const contextData = useContext(AppContext);
    
 

  const [selectedProfileIdNew, setSelectedProfileIdNew] = useState("")
  const [selectedWebsiteIdNew, setSelectedWebsiteIdNew] = useState("");
  const [autoLoad, setAutoLoad] = useState(false);
  const [websiteList, setWebsiteList] = useState([]);
  const [profilesList, setProfilesList] = useState([]);

  const [profileId, setProfileId] = useState(0);
   

  useEffect(() => {

    if (contextData.companyId > 0) {
      setAutoLoad(false);
      if (localStorage.getItem("websiteData") && JSON.parse(localStorage.getItem("websiteData")).length > 0
        && localStorage.getItem("AllprofileData") && JSON.parse(localStorage.getItem("AllprofileData")).length > 0) {

        let websiteList = JSON.parse(localStorage.getItem("websiteData"));
        let selectedWebsite = JSON.parse(localStorage.getItem("WebsiteListWebsiteId"));

        renderWebsite(websiteList, selectedWebsite)

      }
      else if (!localStorage.getItem("websiteData") || !localStorage.getItem("AllprofileData")) {
        //These conditions are bifurcated because there are chances that a user can navigate from a page where only website 
        //is store in localstorage but not profile, hence profile needs to be stored & then the logic of localstorage for website & profile works smoothly.
        if (!localStorage.getItem("websiteData")) {
          getWebsites();
        }
        else if (!localStorage.getItem("AllprofileData")) {
          getProfiles(JSON.parse(localStorage.getItem("websiteData")));
        }
      }
    }
  }, [contextData.companyId])

  const renderWebsite = (resultSet, selectedWebsiteValue) => {

    setWebsiteList(resultSet)
    setSelectedWebsiteIdNew(selectedWebsiteValue);
    renderProfileList(selectedWebsiteValue, 0);

  };

  const renderProfileList = (selectedWebsiteValue, isTempUpdate) => {

    if (localStorage.getItem("AllprofileData") && JSON.parse(localStorage.getItem("AllprofileData")).length > 0) {

      let profileList = JSON.parse(localStorage.getItem("AllprofileData"));
      let selectedWebsiteProfiles = profileList.filter((e) => { return e.WebsiteId === selectedWebsiteValue["WebsiteList.WebsiteId"] });

      if (selectedWebsiteProfiles.length > 0) {
        localStorage.setItem("cookieProfileData", JSON.stringify(selectedWebsiteProfiles));
        let profileData = selectedWebsiteProfiles.map((e) => ({ ["WebsiteProfiles.ProfileId"]: e.ProfileId, ["WebsiteProfiles.DisplayName"]: e.DisplayName, ["WebsiteProfiles.IntelConfigId"]: e.IntelConfigId }));

        setProfilesList(profileData)

        let selectedProfile = profileData[0];


        if (isTempUpdate === 0) {

          let storedProfile = JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId"));
          if (storedProfile && Object.keys(storedProfile).length > 0
            && profileData.some(obj => obj["WebsiteProfiles.ProfileId"] === storedProfile["WebsiteProfiles.ProfileId"])) {
            selectedProfile = storedProfile;
          };
          localStorage.setItem("profileData", JSON.stringify(profileData))
        }
        setSelectedProfileIdNew(selectedProfile);

      }
      else {
        setProfilesList([])
        setSelectedProfileIdNew({});
        if (isTempUpdate === 0) {
          localStorage.setItem("profileData", JSON.stringify([]))
        }
      }
      setAutoLoad(true)
    }
  }



  const getWebsites = () => {

    request(baseURL + `/CommonV2/GetWebsiteChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        let resultData = [];

        if (res.result && res.result.length > 0) {
          resultData = res.result.map((e) => ({ cmsplatformid: res.platformid, ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl }))
        }

        if (resultData.length > 0) {
          localStorage.setItem("websiteData", JSON.stringify(resultData))
          getProfiles(resultData)
        }
        else {
          localStorage.setItem("websiteData", JSON.stringify([]))
          localStorage.setItem("AllprofileData", JSON.stringify([]))
        }

      })
      .catch((err) => {

      })

  }

  const getProfiles = (resultData) => {
    request(baseURL + `/WebsiteAnalytics/GetWebsites?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {

        if (res.result && res.result.length > 0) {

          let result = removeDuplicateObjects(res.result, 'IntelConfigId')
          localStorage.setItem("AllprofileData", JSON.stringify(result))

        }
        else {
          localStorage.setItem("profileData", JSON.stringify([]))
        }
        // localStorage.setItem("websiteData", JSON.stringify(resultData))


        if (!localStorage.getItem("WebsiteListWebsiteId")) {
          renderWebsite(resultData, resultData[0])
        }
        else {
          renderWebsite(resultData, JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
        }


        if (res.result.length == 0) {
          setAutoLoad(true);
        }

      })
      .catch((err) => {

      })
  }



  function removeDuplicateObjects(array, key) {
    const seen = {};
    return array.filter(item => {
      const itemKey = key ? item[key] : item;
      return seen.hasOwnProperty(itemKey) ? false : (seen[itemKey] = true);
    });
  }

  const onWebsiteDropdownChange = (data) => {
    if (!object_equals(selectedWebsiteIdNew, data)) {
      setSelectedProfileIdNew({});
    }
    setSelectedWebsiteIdNew(data);
    renderProfileList(data, 1)
  };

  const onProfileDropdownChange = (data) => {
    const intellConfigId = data["WebsiteProfiles.IntelConfigId"];
    contextData.changeIntelConfigId(intellConfigId);
    setSelectedProfileIdNew(data)
  };

  const cancelFilters = () => {
    onWebsiteDropdownChange(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
    onProfileDropdownChange(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")))
  }
  const applyFilters = () => {
    const websiteId = selectedWebsiteIdNew["WebsiteList.WebsiteId"];

    contextData.changeWebsiteId(websiteId);
    contextData.changeSelectedWebsite(selectedWebsiteIdNew);

    contextData.changeSelectedProfile(selectedProfileIdNew);

    if (selectedProfileIdNew) {
      setProfileId(selectedProfileIdNew["WebsiteProfiles.ProfileId"]);
    }
    if (profilesList.length > 0) {
      localStorage.setItem("profileData", JSON.stringify(profilesList))
    }
    else {
      localStorage.setItem("profileData", JSON.stringify([]))
    }
    localStorage.setItem("WebsiteListWebsiteId", JSON.stringify(selectedWebsiteIdNew));// Adding WebsiteList.WebsiteId to local stoarge in case of Page Refresh
    localStorage.setItem("WebsiteProfilesIntelConfigId", JSON.stringify(selectedProfileIdNew));// Adding WebsiteProfiles.IntelConfigId to local stoarge in case of Page Refresh
  }
  const handleBackButtonClick = (e) => {
    //localStorage.setItem("IsPageRefresh", "0");
    props.history.push(`/website`);
  }
  const commonHeaderChild =
    useMemo(() =>
      <>
        <CommonHeader channel="website" cancelFilters={cancelFilters} autoLoad={autoLoad} dataSource={[
          { name: 'Dropdown1 - Website', data: websiteList, selectedItem: selectedWebsiteIdNew, isVisible: true, label: t("header.filterLabelWebsite"), callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" },
          { name: 'Dropdown2 - Profile', data: profilesList, selectedItem: selectedProfileIdNew, isVisible: true, label: t("header.filterLabelProfile"), callback: onProfileDropdownChange, displayText: "WebsiteProfiles.DisplayName" },
        ]}
          title={selectedProfileIdNew ? t("header.SEOKeywordSearchTT") : ""}
          showDate={true}
          applyFilters={applyFilters}
        />
      </>,
      [selectedProfileIdNew, selectedWebsiteIdNew, autoLoad])

  return (
    <>
      <Row>
        <Col lg={12}>
          <Button variant="light" onClick={(e) => handleBackButtonClick(e)} bsPrefix="text" className="ms-3 link-icn-btn">
            <FontAwesomeIcon className="me-1" icon={faChevronLeft} /> {t("header.backButton")}
          </Button>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col>
          {commonHeaderChild}
        </Col>
      </Row>
      {contextData.selectedProfile["WebsiteProfiles.ProfileId"] && <SearchPageComponent
        //dashboardItems={searchData}
        // handleBackButtonClick={handleBackButtonClick}
        // usersData={searchData}
        // deserializeItem={deserializeItem}
        // dashboardItem={dashboardItem}
        // selectedProfileId={contextData.selectedProfile}
        // setSelectedProfileId={setSelectedProfileId}
        // selectedWebsiteId={contextData.selectedWebsite}
        // setSelectedWebsiteId={setSelectedWebsiteId}
        // startDate={contextData.startDate}
        // endDate={contextData.endDate}
        // setStartDate={setStartDate}
        // setEndDate={setEndDate}
        cryptoKey={contextData.cryptoKey}
        websiteID={contextData.websiteId}
        profileID={contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
        startDate={contextData.startDate}
        endDate={contextData.endDate}
        // intelConfigID={contextData.selectedProfile["WebsiteProfiles.IntelConfigId"]}
        companyID={contextData.companyId}
      >
        {/* {searchData && searchData.map(deserializeItem).map(dashboardItem)} */}
      </SearchPageComponent>}
      {!contextData.selectedProfile["WebsiteProfiles.ProfileId"] && <h1 className='text-center'>connect.ID Intelligence</h1>}

    </>
  )

}

export default SearchPage