import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Row, Col } from '@themesberg/react-bootstrap';
import AppContext from '../../components/AppContext';
import { InsightsSummary } from '../../components/TnfAnalytics/InsightsSummary';
import { InsightsCharts } from '../../components/TnfAnalytics/InsightsCharts';
import { InsightsTables } from '../../components/TnfAnalytics/InsightsTables';
import request from '../../apis/request';
import { METHODS, baseURL } from '../../apis/utilities/constant';
import { decryptData } from '../../utils/crypto';
import { useTranslation } from "react-i18next";
import CommonHeader from '../../components/CommonHeader';
import DropdownWithIconComponent from "../../components/DropdownWithIcons";

export const InsightsPage = () => {

  const [t] = useTranslation();
  const contextData = useContext(AppContext);

  const [toolsList, setToolsList] = useState([]);
  const [selectedTool, setSelectedTool] = useState();

  const [websiteList, setWebsiteList] = useState([]);
  const [profilesList, setProfilesList] = useState([]);

  const [selectedWebsiteIdNew, setSelectedWebsiteIdNew] = useState(websiteList[0]);
  const [selectedProfileIdNew, setSelectedProfileIdNew] = useState({})
  const [autoLoad, setAutoLoad] = useState(false);


  useEffect(() => {
    if (contextData.companyId > 0) {

      setAutoLoad(false);
      if (localStorage.getItem("TnfWebsiteData") && JSON.parse(localStorage.getItem("TnfWebsiteData")).length > 0
        && localStorage.getItem("TnfAllProfileData") && JSON.parse(localStorage.getItem("TnfAllProfileData")).length > 0) {

        let websiteList = JSON.parse(localStorage.getItem("TnfWebsiteData"));
        let selectedWebsite = JSON.parse(localStorage.getItem("TnfWebsiteListWebsiteId"));
        renderWebsite(websiteList, selectedWebsite)

      } else if (!localStorage.getItem("TnfWebsiteData") || !localStorage.getItem("TnfAllProfileData")
        || JSON.parse(localStorage.getItem("TnfWebsiteData")).length == 0 || JSON.parse(localStorage.getItem("TnfAllProfileData")).length == 0) {

        if (!localStorage.getItem("TnfWebsiteData") || JSON.parse(localStorage.getItem("TnfWebsiteData")).length == 0) {
          getWebsites();
        }
        else if (!localStorage.getItem("TnfAllProfileData") || JSON.parse(localStorage.getItem("TnfAllProfileData")).length == 0) {
          getProfiles(JSON.parse(localStorage.getItem("TnfWebsiteData")));
        }
      }

    }
  }, [contextData.companyId])

  useEffect(() => {

    if (contextData.menu && contextData.menu.length) {

      const tnfMenuTools = contextData.menu.find(item => item.id === 15) // 15 for tnf analytics
      if (tnfMenuTools.subMenu && tnfMenuTools.subMenu.length) {
        let dataObj = [{ toolID: 0, title: "All Tools" }];
        tnfMenuTools.subMenu.map((obj) => {
          if (obj.toolid > 0) {
            dataObj.push({ toolID: obj.toolid, title: obj.title })
          }
        });
        setSelectedTool(dataObj[0]);
        setToolsList(dataObj);
      }

    }
  }, [contextData.menu])

  const getWebsites = () => {
    request(baseURL + `/TnfAnalytics/GetTnFChannelWebsiteByClientUser?CompanyId=${contextData.companyId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        let resultData = [];

        if (res.result && res.result.length > 0) {
          resultData = res.result.map((e) => ({ cmsplatformid: res.platformid, ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl }))
        }

        if (resultData.length > 0) {
          localStorage.setItem("TnfWebsiteData", JSON.stringify(resultData))
          getProfiles(resultData)
        }
        else {
          localStorage.setItem("TnfWebsiteData", JSON.stringify([]))
          localStorage.setItem("TnfAllProfileData", JSON.stringify([]))
        }

      })
      .catch((err) => {

      })
  }

  const getProfiles = (resultData) => {
    request(baseURL + `/TnfAnalytics/GetTnFWebsites?CompanyId=${contextData.companyId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        if (res.result != null && res.result.length > 0) {
          let result = removeDuplicateObjects(res.result, 'IntelConfigId')
          localStorage.setItem("TnfAllProfileData", JSON.stringify(result))

        } else {

          localStorage.setItem("TnfAllProfileData", JSON.stringify([]))
        }
        if (!localStorage.getItem("TnfWebsiteListWebsiteId")) {
          renderWebsite(resultData, resultData[0])
        }
        else {
          renderWebsite(resultData, JSON.parse(localStorage.getItem("TnfWebsiteListWebsiteId")))
        }

        if (res.result.length == 0) {
          setAutoLoad(true);
        }
      })
      .catch((err) => {
      })
  };

  const renderWebsite = (resultSet, selectedWebsiteValue) => {
    setWebsiteList(resultSet)
    setSelectedWebsiteIdNew(selectedWebsiteValue);
    renderProfileList(selectedWebsiteValue, 0);
  };

  const renderProfileList = (selectedWebsiteValue, isTempUpdate) => {
    if (localStorage.getItem("TnfAllProfileData") && JSON.parse(localStorage.getItem("TnfAllProfileData")).length > 0) {

      let profileList = JSON.parse(localStorage.getItem("TnfAllProfileData"));
      let selectedWebsiteProfiles = profileList.filter((e) => { return e.WebsiteId === selectedWebsiteValue["WebsiteList.WebsiteId"] });

      if (selectedWebsiteProfiles.length > 0) {
        let profileData = selectedWebsiteProfiles.map((e) => ({ ["WebsiteProfiles.ProfileId"]: e.ProfileId, ["WebsiteProfiles.DisplayName"]: e.DisplayName, ["WebsiteProfiles.IntelConfigId"]: e.IntelConfigId }));

        setProfilesList(profileData)

        let selectedProfile = profileData[0];


        if (isTempUpdate === 0) {

          let storedProfile = JSON.parse(localStorage.getItem("TnfWebsiteProfilesIntelConfigId"));
          if (storedProfile && Object.keys(storedProfile).length > 0
            && profileData.some(obj => obj["WebsiteProfiles.ProfileId"] === storedProfile["WebsiteProfiles.ProfileId"])) {
            selectedProfile = storedProfile;
          };

          localStorage.setItem("TnfProfileData", JSON.stringify(profileData))
        }
        setSelectedProfileIdNew(selectedProfile);
      }
      else {
        setProfilesList([])
        setSelectedProfileIdNew({});
        if (isTempUpdate === 0) {
          localStorage.setItem("TnfProfileData", JSON.stringify([]))
        }
      }
    }
    setAutoLoad(true)
  }

  function removeDuplicateObjects(array, key) {
    const seen = {};
    return array.filter(item => {
      const itemKey = key ? item[key] : item;
      return seen.hasOwnProperty(itemKey) ? false : (seen[itemKey] = true);
    });
  }

  const onWebsiteDropdownChange = (data) => {

    setSelectedWebsiteIdNew(data);
    renderProfileList(data, 1)
  };

  const onProfileDropdownChange = (data) => {
    const intellConfigId = data["WebsiteProfiles.IntelConfigId"];
    contextData.changeIntelConfigId(intellConfigId);
    setSelectedProfileIdNew(data)
  };

  const applyFilters = () => {
    const websiteId = selectedWebsiteIdNew["WebsiteList.WebsiteId"];

    contextData.changeWebsiteId(websiteId);
    contextData.changeSelectedWebsite(selectedWebsiteIdNew);

    const lastUpdatedID = JSON.parse(localStorage.getItem("TnfWebsiteProfilesIntelConfigId"))["WebsiteProfiles.ProfileId"];

    if (lastUpdatedID > 0 && selectedProfileIdNew["WebsiteProfiles.ProfileId"] > 0
      && lastUpdatedID !== selectedProfileIdNew["WebsiteProfiles.ProfileId"]) {
      setSelectedTool();
    }

    if (JSON.parse(localStorage.getItem("TnfAllProfileData")).length > 0) {
      contextData.changeSelectedProfile(selectedProfileIdNew);
    }


    if (profilesList.length > 0) {
      localStorage.setItem("TnfProfileData", JSON.stringify(profilesList))
    }
    else {
      localStorage.setItem("TnfProfileData", JSON.stringify([]))
    }
    localStorage.setItem("TnfWebsiteListWebsiteId", JSON.stringify(selectedWebsiteIdNew));// Adding WebsiteList.WebsiteId to local stoarge in case of Page Refresh
    localStorage.setItem("TnfWebsiteProfilesIntelConfigId", JSON.stringify(selectedProfileIdNew));// Adding WebsiteProfiles.IntelConfigId to local stoarge in case of Page Refresh
  }

  const cancelFilters = () => {
    onWebsiteDropdownChange(JSON.parse(localStorage.getItem("TnfWebsiteListWebsiteId")))
    onProfileDropdownChange(JSON.parse(localStorage.getItem("TnfWebsiteProfilesIntelConfigId")))
  }

  const onToolsDropdownChange = (data) => {
    setSelectedTool(data);
  };

  const commonHeaderChild = useMemo(() =>
    <>
      <CommonHeader channel="website" cancelFilters={cancelFilters} autoLoad={autoLoad} dataSource={[
        { name: 'Dropdown1 - Website', data: websiteList, selectedItem: selectedWebsiteIdNew, isVisible: true, label: t("header.filterLabelWebsite"), callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" },
        { name: 'Dropdown2 - Profile', data: profilesList, selectedItem: selectedProfileIdNew, isVisible: true, label: t("header.filterLabelProfile"), callback: onProfileDropdownChange, displayText: "WebsiteProfiles.DisplayName" },
      ]}
        title={t("header.tools_insights")}
        showDate={true}
        applyFilters={applyFilters}
      />
    </>,
    [selectedProfileIdNew, selectedWebsiteIdNew, autoLoad])

  const selectedComapnyData = contextData.companies.find(dta => dta.companyId === contextData.companyId);

  return (
    <Row id='toolsInsights'>
      <Col>
        <Row className="align-items-center">
          <Col>
            {commonHeaderChild}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={3} className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
            <div className="ps-3 w-100 d-inline-block">
              {selectedTool && <DropdownWithIconComponent
                dropdownData={toolsList}
                arrayValue="title"
                selectedDropdown={selectedTool}
                onChange={onToolsDropdownChange}
                showLabel={false}
                showIconInDropdownItem={false}
                customClassName="full-width text-overflow"
                isDropdownDisabled={false}
              />}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            {selectedTool && <InsightsSummary
              CryptoKey={contextData.cryptoKey}
              ProfileId={contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
              StartDate={contextData.startDate}
              EndDate={contextData.endDate}
              CompanyId={contextData.companyId}
              IntelConfigId={contextData.selectedProfile["WebsiteProfiles.IntelConfigId"]}
              ToolId={selectedTool.toolID}
            />}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            {selectedTool && <InsightsCharts cryptoKey={contextData.cryptoKey} profileID={contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
              startDate={contextData.startDate} endDate={contextData.endDate} toolID={selectedTool.toolID} intelConfigID={contextData.selectedProfile["WebsiteProfiles.IntelConfigId"]} />}
          </Col>
        </Row >
        <Row>
          <Col lg={12}>
            {selectedTool &&
              <InsightsTables
                UserId={contextData.userId}
                cryptoKey={contextData.cryptoKey}
                ProfileId={contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
                StartDate={contextData.startDate}
                EndDate={contextData.endDate}
                IntelConfigId={contextData.selectedProfile["WebsiteProfiles.IntelConfigId"]}
                CompanyId={contextData.companyId}
                ToolId={selectedTool.toolID}
                ToolName={selectedTool}
                WebsiteData={contextData.selectedWebsite}
                ProfileData={contextData.selectedProfile}
                CompanyName={selectedComapnyData.companyName}
              />}
          </Col>
        </Row>
        {!selectedTool && <h1 className='text-center'>connect.ID Intelligence</h1>}
      </Col>
    </Row>
  )
}
