import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import ErrorPage from '../pages/ErrorPage';

const PublicRouter = ({ component: Component, ...rest }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Route {...rest} render={props => (
            <>
                {/* <Preloader show={loaded ? false : true} /> */}
                <ErrorPage><Component {...props} /></ErrorPage>
            </>
        )} />
    );
};

export default PublicRouter;
