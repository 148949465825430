import React, { useEffect, useState } from 'react';
import { Col, Row, Nav, Tab, TabPane, Dropdown, ButtonGroup, Alert, Image } from '@themesberg/react-bootstrap';
import CommonMessageComponent from '../CommonMessageComponent.js';
import { DataStatusMessage } from "../../utils/constant";
import DataTableRenderer from '../DataTableRenderer.js';
import { TnfTableHeaders } from '../../utils/constant';
import { baseURL, METHODS } from '../../apis/utilities/constant.js';
import request, { requestPDF } from '../../apis/request.js';
import { decryptData } from '../../utils/crypto.js';
import ModalComponent from "../../components/VoltModal";
import { useTranslation } from 'react-i18next';
import iconSprite from "../../assets/Icon/svg-icn-sprite.svg";
import Search from '../Search.js';
import DataChartRenderer from '../DataChartRenderer.js';
import moment from 'moment';
import LoaderImage from "../../assets/img/LoaderGraphic.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import html2pdf from "html2pdf.js";
import { convertBase64 } from '../../utils/common.js';

const TnfAllPages = ({ StartDate, EndDate, IntelConfigId, ProfileId, CompanyId, ToolId, cryptoKey, searchData, setDownloadEnable, ToolName, ...props }) => {
    const [t] = useTranslation();

    const [allPagesResponse, setAllPagesResponse] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [allPages, setAllPages] = useState({ transactionStatus: DataStatusMessage.Loading });

    const [showVisitorAllModal, setShowVisitorAllModal] = useState(false);
    const [rowDetails, setRowDetails] = useState([]);
    const [allPagesPopupLinechart, setAllPagesPopupLinechart] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [appPagesPopupTableResponse, setAllPagesPopupTableResponse] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [allPagesPopupTable, setAllPagesPopupTable] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [trafficSourceBarchart, setTrafficSourceBarchart] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [trafficSourceTable, setTrafficSourceTable] = useState({ transactionStatus: DataStatusMessage.Loading });

    const [trafficSource, setTrafficSource] = useState("Organic");
    const [tabVisitorModal, setTabVisitorModal] = useState(0);
    const [popupSearchText, setPopupSearchText] = useState([]);
    const [popupSearchDataList, setPopupSearchDataList] = useState([]);
    const [showAutoFillForPopupSearch, setShowAutoFillForPopupSearch] = useState(false);
    const [trafficSourceText, setTrafficSourceText] = useState(t("header.VisitorsActivityPopTrafficChartOrganicHelpText"));
    const [trafficSourceTotal, setTrafficSourceTotal] = useState(0);
    const [trafficSourceMetrices, setTrafficSourceMetrices] = useState({ transactionStatus: DataStatusMessage.DataUnAvailable });
    const [blurLoader, setBlurLoader] = useState(false);
    const [alertVisible, setAlertVisible] = useState(false);
    const [toggle, setToggle] = useState(true);

    useEffect(() => {
        setAllPagesData();
    }, [StartDate, EndDate, ToolId]);

    useEffect(() => {
        if (allPagesResponse.result) {
            setAllPages(prevState => ({
                ...prevState,
                data: searchContent(allPagesResponse.result)
            }));
        }
    }, [searchData]);

    useEffect(() => {
        if (appPagesPopupTableResponse.result) {
            setAllPagesPopupTable(prevState => ({
                ...prevState,
                data: searchContentForPopup(appPagesPopupTableResponse.result, popupSearchText)
            }));
        }
    }, [popupSearchText]);

    useEffect(() => {
        setPopupSearchText("");
        setToggle(!toggle);
        setShowAutoFillForPopupSearch(false);
        const selectedStartDate = moment(StartDate).format("YYYY-MM-DD");
        const selectedEndDate = moment(EndDate).format("YYYY-MM-DD");
        if (tabVisitorModal === 1) {
            request(`${process.env.REACT_APP_API_KEY_NEW}/VisitorAnalytics/GetPageViewVisitsWithPRByPage?profileid=${ProfileId}&startdate=${selectedStartDate}&enddate=${selectedEndDate}&pageid=${rowDetails.pagesId}&companyid=${CompanyId}&isTF=1&toolid=${ToolId}&intelconfigid=${IntelConfigId}`,
                METHODS.GET, {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                    "content-type": "application/json"
                })
                .then((response) => {
                    if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                        setAllPagesPopupLinechart({ result: response.result, transactionStatus: response.transactionStatus })
                    } else {
                        setAllPagesPopupLinechart({ result: [], transactionStatus: DataStatusMessage.ErrorOccured })
                    }
                }).catch(() => {
                    setAllPagesPopupLinechart({ result: [], transactionStatus: DataStatusMessage.ErrorOccured })
                })


            request(`${process.env.REACT_APP_API_KEY_NEW}/VisitorAnalytics/GetVisitorByPage?profileid=${ProfileId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&companyid=${CompanyId}&pageid=${rowDetails.pagesId}&isTF=1&toolid=${ToolId}&intelconfigid=${IntelConfigId}`,
                METHODS.GET, {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                    "content-type": "application/json"
                })
                .then((response) => {
                    if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                        setAllPagesPopupTableResponse(response);
                        setPopupSearchDataList(response.result.filterdata);
                        setAllPagesPopupTable({ header: TnfTableHeaders.AllToolsPopupVisitorsTable, data: response.result.data, transactionStatus: DataStatusMessage.DataAvailable })

                    } else {
                        setAllPagesPopupTable({ result: [], transactionStatus: DataStatusMessage.ErrorOccured })
                    }
                }).catch(() => {
                    setAllPagesPopupTable({ result: [], transactionStatus: DataStatusMessage.ErrorOccured })
                })
        } else if (tabVisitorModal === 2) {

            //get barchart data
            request(`${process.env.REACT_APP_API_KEY_NEW}/VisitorAnalytics/GetTrafficSourceByPage?profileid=${ProfileId}&startdate=${selectedStartDate}&enddate=${selectedEndDate}&pageid=${rowDetails.pagesId}&companyid=${CompanyId}&isTF=1&toolid=${ToolId}&intelconfigid=${IntelConfigId}`,
                METHODS.GET, {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                    "content-type": "application/json"
                })
                .then((response) => {

                    if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                        setTrafficSourceBarchart({ result: response.result, transactionStatus: response.transactionStatus })
                    } else {
                        setTrafficSourceBarchart({ result: [], transactionStatus: DataStatusMessage.ErrorOccured })
                    }

                })
                .catch(() => {
                    setTrafficSourceBarchart({ result: [], transactionStatus: DataStatusMessage.ErrorOccured })

                })
            getTrafficSource(trafficSource);
        }

    }, [tabVisitorModal])

    const setAllPagesData = () => {
        const selectedStartDate = moment(StartDate).format("YYYY-MM-DD");
        const selectedEndDate = moment(EndDate).format("YYYY-MM-DD");
        request(`${process.env.REACT_APP_API_KEY_NEW}/VisitorAnalytics/GetPagesDataByProfileId?profileid=${ProfileId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&companyid=${CompanyId}&isTF=1&toolid=${ToolId}&sortexpression=PageViews&sortdirection=true&intelconfigid=${IntelConfigId}`,
            METHODS.GET, {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                "content-type": "application/json"
            })
            .then((response) => {
                if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                    setAllPagesResponse(response);
                    setAllPages({
                        header: TnfTableHeaders.AllPagesTable, data: response.result.data, transactionStatus: response.transactionStatus, count: response.result.count
                    });
                    setDownloadEnable(response.result.data.length > 0);
                }
                else {
                    setAllPages({ transactionStatus: DataStatusMessage.DataUnAvailable });
                }
            })
            .catch(() => {
                setAllPages({ transactionStatus: DataStatusMessage.DataUnAvailable });
            })
    }

    const getTrafficSource = (source) => {
        const selectedStartDate = moment(StartDate).format("YYYY-MM-DD");
        const selectedEndDate = moment(EndDate).format("YYYY-MM-DD");
        let TSType = source; //traffic source type
        if (trafficSourceMetrices.transactionStatus === DataStatusMessage.DataAvailable) {
            if (source === "Organic") {
                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartOrganicHelpText"));
                setTrafficSourceTotal(t("header.TFallPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + source) + " " + t("header.allPagesTrafficSourceTotal2") + " " + trafficSourceMetrices.result.organic);
            }
            else if (source === "Direct") {
                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartDirectHelpText"));
                setTrafficSourceTotal(t("header.TFallPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + source) + " " + t("header.allPagesTrafficSourceTotal2") + " " + trafficSourceMetrices.result.direct);
            }
            else if (source === "Referral") {
                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartReferralHelpText"));
                setTrafficSourceTotal(t("header.TFallPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + source) + " " + t("header.allPagesTrafficSourceTotal2") + " " + trafficSourceMetrices.result.referral);
            }
            else if (source === "Email") {
                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartEmailHelpText"));
                setTrafficSourceTotal(t("header.TFallPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + source) + " " + t("header.allPagesTrafficSourceTotal2") + " " + trafficSourceMetrices.result.email);
            }
            else if (source === "Social") {
                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartSocialHelpText"));
                setTrafficSourceTotal(t("header.TFallPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + source) + " " + t("header.allPagesTrafficSourceTotal2") + " " + trafficSourceMetrices.result.social);
            }
            else if (source === "PaidSearch") {
                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartPaidSearchHelpText"));
                setTrafficSourceTotal(t("header.TFallPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + source) + " " + t("header.allPagesTrafficSourceTotal2") + " " + trafficSourceMetrices.result.paidSearch);
            }
        } else {
            request(`${process.env.REACT_APP_API_KEY_NEW}/VisitorAnalytics/GetTrafficSourceByPage?profileid=${ProfileId}&startdate=${selectedStartDate}&enddate=${selectedEndDate}&pageid=${rowDetails.pagesId}&companyid=${CompanyId}&isTF=1&getvalues=1&toolid=${ToolId}&intelconfigid=${IntelConfigId}`,
                METHODS.GET, {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                    "content-type": "application/json"
                })
                .then((response) => {
                    setTrafficSourceMetrices({ result: response, transactionStatus: DataStatusMessage.DataAvailable })
                    if (response.organic === 0) {
                        TSType = "Direct";
                    }
                    if (TSType === "Organic") {
                        setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartOrganicHelpText"));
                        setTrafficSourceTotal(t("header.TFallPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + TSType) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.organic);
                    }
                    else if (TSType === "Direct") {
                        setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartDirectHelpText"));
                        setTrafficSourceTotal(t("header.TFallPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + TSType) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.direct);
                    }
                    else if (TSType === "Referral") {
                        setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartReferralHelpText"));
                        setTrafficSourceTotal(t("header.TFallPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + TSType) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.referral);
                    }
                    else if (TSType === "Email") {
                        setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartEmailHelpText"));
                        setTrafficSourceTotal(t("header.TFallPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + TSType) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.email);
                    }
                    else if (TSType === "Social") {
                        setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartSocialHelpText"));
                        setTrafficSourceTotal(t("header.TFallPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + TSType) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.social);
                    }
                    else if (TSType === "PaidSearch") {
                        setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartPaidSearchHelpText"));
                        setTrafficSourceTotal(t("header.TFallPagesTrafficSourceTotal1") + " " + t("header.barchart_Xaxis_Below_" + TSType) + " " + t("header.allPagesTrafficSourceTotal2") + " " + response.paidSearch);
                    }
                })
                .catch(() => {

                })
        }

        if (TSType === "Organic" || TSType === "Referral" || TSType === "Social") {
            request(`${process.env.REACT_APP_API_KEY_NEW}/VisitorAnalytics/GetTopDomainByTrafficSourcePage?profileid=${ProfileId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&companyid=${CompanyId}&pageid=${rowDetails.pagesId}&traffic=${TSType}&packageid=5&sectionid=0&isTF=1&toolid=${ToolId}&intelconfigid=${IntelConfigId}`,
                METHODS.GET, {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                    "content-type": "application/json"
                })
                .then((response) => {
                    ;
                    if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                        setTrafficSourceTable({ header: TnfTableHeaders.VisitorAllPopuptrafficSourceTable, data: response.result.data, transactionStatus: response.transactionStatus });
                    }
                })
        }
    }

    const searchContent = (objSearch) => {
        let filteredResult = [];
        if (searchData) {
            if (objSearch.filter === 'contains') {
                filteredResult = objSearch.data.filter(item => item.filterybykey.toLowerCase().indexOf(searchData.toLowerCase()) > -1)
            }
        }
        else {
            filteredResult = objSearch.data.slice();
        }
        return filteredResult;
    }

    const searchContentForPopup = (objSearch, searchText) => {
        let filteredResult = [];
        if (searchText) {
            if (objSearch.filtercolumns && objSearch.filtercolumns.length > 0) {
                const filtercolumns = objSearch.filtercolumns;
                let filterfs = {};
                for (let i = 0; i < filtercolumns.length; i++) {
                    filterfs[filtercolumns[i]] = searchText;
                }
                let result = objSearch.data;
                filteredResult = result.filter(function (item) {
                    for (var key in filterfs) {
                        if (item[key]?.toLowerCase() == filterfs[key]?.toLowerCase()) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                });
            }
        }
        else {
            filteredResult = objSearch.data.slice();
        }
        setDownloadEnable(filteredResult.length > 0);
        return filteredResult;
    }

    const rowClickEvent = (rowobj) => {
        setShowVisitorAllModal(true);
        setRowDetails(rowobj.data)
        setTabVisitorModal(1);
    }

    const handleModalClose = () => {
        setShowVisitorAllModal(false);
        setTrafficSource("Organic");
        setTabVisitorModal(0);
        setAllPagesPopupLinechart({ transactionStatus: DataStatusMessage.Loading })
        setAllPagesPopupTable({ transactionStatus: DataStatusMessage.Loading })
        setTrafficSourceBarchart({ transactionStatus: DataStatusMessage.Loading })
        setTrafficSourceTable({ transactionStatus: DataStatusMessage.Loading })
        setTrafficSourceMetrices({ transactionStatus: DataStatusMessage.DataUnAvailable })
    }

    const setDowloadDropdownMenu = () => {
        let ddList = ["Excel", "PDF"];
        return ddList.map(item => {
            return (
                <Dropdown.Item className='dropdown-item-download' onClick={() => downloadGrid(item)}>
                    {item}
                </Dropdown.Item>
            );
        })
    }

    const downloadGrid = (item) => {
        if (item === "Excel") {
            downloadExcel()
        }
        else {
            downloadPDF();
        }
    }

    const downloadExcel = async () => {
        setBlurLoader(true);
        let paramsList = {
            type: 1,
            page: rowDetails.filterybykey,
            pageid: rowDetails.pagesId,
            profileid: ProfileId,
            profileName: props.ProfileData["WebsiteProfiles.DisplayName"],
            packageid: 5,
            path: rowDetails.pagesPagePath,
            startdate: moment(StartDate).format("YYYY-MM-DD"),
            enddate: moment(EndDate).format("YYYY-MM-DD"),
            companyName: props.CompanyName,
            webSiteName: props.WebsiteData["WebsiteList.WebsiteName"],
            pagesectionid: ToolName.toolID,
            pageSectionName: ToolName.title,
            culture: localStorage.getItem('i18nextLng'),
            isTF: 1,
            toolid: ToolId,
            intelconfigid: IntelConfigId
        }

        request(baseURL + `/VisitorAnalytics/ExportVisitorsByPageExcelFileWithEPPlus?encodeStr=${convertBase64(JSON.stringify(paramsList))}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
            })
            .then((res) => {
                if (res) {
                    let fetchDataModified = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res}`;
                    let a = document.createElement("a");
                    a.href = fetchDataModified;
                    a.download = "Insights_PageVisited.xlsx";
                    a.click();
                }
                setBlurLoader(false);
            })
            .catch((err) => {
                setBlurLoader(false);
                console.error('Error generating Excel:', err);
            })
    };

    const downloadPDF = async () => {
        setBlurLoader(true);
        const selectedStartDate = moment(StartDate).format("YYYY-MM-DD");
        const selectedEndDate = moment(EndDate).format("YYYY-MM-DD");
        let url = `${process.env.REACT_APP_API_KEY_NEW}/VisitorAnalytics/GetVisitorByPage?profileid=${ProfileId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&companyid=${CompanyId}&pageid=${rowDetails.pagesId}&isTF=1&toolid=${ToolId}&intelconfigid=${IntelConfigId}`;
        let fileName = "Insights_Visitors.pdf";
        let filters = (popupSearchText !== "" && popupSearchText !== null) ? "companyName|contains|" + popupSearchText : "";
        let obj = {
            Title: rowDetails.filterybykey,
            SubTitle: t("header.TFPagesDetailsPopVisitorsChartTextPageViewsOf") + rowDetails.filterybykey + " " + t("header.PagesDetailsPopVisitorsChartTextBetween") + " " + moment(StartDate).format("DD MMM YYYY") + t("header.PagesDetailsPopVisitorsChartTextTo") + moment(EndDate).format("DD MMM YYYY"),
            PageName: "Visitors",
            Filters: filters,
            Url: url,
            Token: decryptData(localStorage.getItem("token"), cryptoKey),
            FileName: fileName,
            Email: decryptData(localStorage.getItem("email"), cryptoKey),
            Name: decryptData(localStorage.getItem("firstName"), cryptoKey),
            Count: 0,
            CountTitle: "",
            FilterContent: "",
            ClientName: "",
            TableHeaders: TnfTableHeaders.AllToolsPopupVisitorsTable
        }

        requestPDF(`${process.env.REACT_APP_API_KEY}/CommonV2/GeneratePdf`,
            METHODS.POST,
            obj,
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`
            })
            .then((res) => {
                const contentType = res.headers['content-type'];
                if (res.status === 200) {
                    if (contentType?.includes('application/pdf')) {
                        const blob = new Blob([res.data], { type: 'application/pdf' });
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                    else {
                        setAlertVisible(true);
                        setTimeout(() => {
                            setAlertVisible(false);
                        }, 3000);
                    }
                    setBlurLoader(false);
                }
                else {
                    setBlurLoader(false);
                    console.error('Error generating PDF.');
                }
            })
            .catch((err) => {
                setBlurLoader(false);
                console.error('Error generating PDF:', err);
            })
    };


    const downloadPageLevelPDF = async (pageName, fileName) => {
        setBlurLoader(true);
        const content = document.createElement("div");
        content.innerHTML = document.querySelector('.tab-content .fade.tab-pane.active.show.' + pageName)?.innerHTML;
        const options = {
            filename: fileName,
            margin: [5, 2, 2, 2],
            image: { type: "jpeg", quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "mm", format: "a4", orientation: "landscape" }
        };
        const combinedNode = document.createElement("div");
        combinedNode.innerHTML = `<div class="row mt-3">
            <div class="col-md-12">
                <div class="ms-2 mb-0 pdf-download-title">${rowDetails.filterybykey}</div>
                <div class="ms-2 mb-0 pdf-download-subtitle">${rowDetails.pagesPagePath}</div>
            </div>
        </div>`;
        combinedNode.appendChild(content.cloneNode(true));
        combinedNode.querySelectorAll('.pagination-content')?.forEach(btn => {
            btn.style.display = "none";
        });
        if (combinedNode.querySelector(".table-responsive-lg")) {
            combinedNode.querySelector(".table-responsive-lg")?.querySelectorAll("tr").forEach(tr => {
                tr.style.pageBreakInside = "avoid";
            });
        }
        combinedNode.querySelectorAll(".me-1")?.forEach(item => {
            item.style.display = "none";
        });
        combinedNode.querySelectorAll("a").forEach(link => {
            link.removeAttribute("href");
            link.style.pointerEvents = "none";
        });
        combinedNode.innerHTML = await covertSVGToIMG(combinedNode);
        await html2pdf().set(options).from(combinedNode).save().then(() => {
            setBlurLoader(false);
        });
    }

    const loadImageAsync = (img) => {
        return new Promise((resolve, reject) => {
            img.onload = () => resolve(img);
            img.onerror = reject;
        });
    };

    const covertSVGToIMG = async (content) => {
        var dynamicSvgs = content.querySelectorAll('svg:not(.svg-inline--fa):not(.icon-nav)');

        for (let i = 0; i < dynamicSvgs.length; i++) {
            dynamicSvgs[i].querySelectorAll("line").forEach(x => {
                x.setAttribute("stroke", "#dddee2");
            });
            dynamicSvgs[i].querySelectorAll("path.domain[stroke='currentColor']").forEach(x => {
                x.style.display = "none";
            });

            var canvas = document.createElement('canvas');
            const scaleFactor = 2;
            canvas.width = dynamicSvgs[i].width.baseVal.value * scaleFactor;
            canvas.height = dynamicSvgs[i].height.baseVal.value * scaleFactor;

            var context = canvas.getContext('2d');
            context.scale(scaleFactor, scaleFactor);

            context.fillStyle = '#ffffff';
            context.fillRect(0, 0, canvas.width, canvas.height);

            var svgData = new XMLSerializer().serializeToString(dynamicSvgs[i]);
            var img = new window.Image();
            img.src = 'data:image/svg+xml,' + encodeURIComponent(svgData);

            await loadImageAsync(img);

            const scaleWidthFactor = 1;

            context.drawImage(img, 0, 0, img.width * scaleWidthFactor, img.height * scaleWidthFactor);

            var imgSrc = canvas.toDataURL('image/jpeg');
            var dynamicImg = document.createElement('img');
            dynamicImg.src = imgSrc;

            let parent = dynamicSvgs[i].parentNode;
            while (parent && parent !== content) {
                parent = parent.parentNode;
            }

            if (parent === content) {
                dynamicSvgs[i].parentNode.replaceChild(dynamicImg, dynamicSvgs[i]);
            }
        }
        return content.innerHTML;
    };

    return (
        <>
            {
                allPages.transactionStatus === DataStatusMessage.DataAvailable && <DataTableRenderer
                    header={allPages.header}
                    dataSet={allPages.data}
                    transactionStatus={allPages.transactionStatus}
                    customAttributes={{ displayRowsPerPage: 15, showPagination: true, summaryText: t("header.allToolsTotalTools"), summaryCount: allPages.data.length, showCount: true }}
                    rowClickEvent={rowClickEvent}
                ></DataTableRenderer>
            }
            <Row>
                <Col md={12}>
                    <CommonMessageComponent transactionStatus={allPages.transactionStatus}></CommonMessageComponent>
                </Col>
            </Row>

            <ModalComponent
                title={rowDetails.filterybykey}
                subtitle={rowDetails.pagesPagePath}
                showCloseButton={true}
                open={showVisitorAllModal}
                handleClose={() => { handleModalClose() }}
                className="visitor-analytics-modal tnf-insights-modal">
                <Row>
                    <Col>
                        {blurLoader && <div id='blurdiv' className="blurbackground">
                            <div className={`preloader loader-center-align`}>
                                <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                            </div>
                        </div>}
                        {alertVisible && (<div className="fade modal-backdrop show"></div>)}
                        <Alert
                            className="toast-custom"
                            variant="success"
                            show={alertVisible}
                        >
                            <div className="d-flex justify-content-between">
                                <div>
                                    <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                                    {t("header.PdfDeliveredToMailMessage")}
                                </div>
                            </div>
                        </Alert>
                        {/* Visitors tab */}
                        <Row className="position-relative hide-mobile" hidden={tabVisitorModal == 2}>
                            <Col md={12}>
                                <Row className="mb-4">
                                    <Col md={12} className={localStorage.getItem("isDemoClient") ? "custom-disable text-end" : "text-end"}>
                                        <div className="icon-nav-list svg-icon-nav-list float-end mb-2">
                                            <span className={(allPagesPopupLinechart.transactionStatus !== DataStatusMessage.Loading && allPagesPopupTable.transactionStatus !== DataStatusMessage.Loading) ? "icon-nav-box ms-2 hide-mobile custom-select" : "icon-nav-box hide-mobile disabled"}
                                                onClick={() => downloadPageLevelPDF('visitorsContent', 'Insights_Pages_Viewed')}
                                            >
                                                <svg className="icon-nav">
                                                    <title>{t("header.Icon_Tooltip_DownloadPDF")}</title>
                                                    <use href={iconSprite + `#Download`} />
                                                </svg>
                                            </span>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={12} className={localStorage.getItem("isDemoClient") ? "custom-disable text-end" : "text-end"}>
                                        <div className="search-box-container modal-search-box">
                                            <Search
                                                setsearchData={setPopupSearchText}
                                                searchData={popupSearchText}
                                                searchWaterMark={t("header.visitorAllWatermark")}
                                                isAutoComplete={true}
                                                setAutoFillForCompany={setShowAutoFillForPopupSearch}
                                                showAutoFillForCompany={showAutoFillForPopupSearch}
                                                data={popupSearchDataList}
                                                type={"client"}
                                                emptyTextBox={toggle}
                                            ></Search>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* traffic source tab */}
                        <Row className="position-relative hide-mobile"
                            hidden={tabVisitorModal == 1}>
                            <Col md={12}>
                                {/* Traffic source download chart */}
                                <Row className="mb-4">
                                    <Col md={12} className={localStorage.getItem("isDemoClient") ? "custom-disable text-end" : "text-end"}>
                                        <div className="icon-nav-list svg-icon-nav-list float-end mb-2">
                                            <span className={(trafficSourceBarchart.transactionStatus !== DataStatusMessage.Loading && trafficSourceTable.transactionStatus !== DataStatusMessage.Loading) ? "icon-nav-box ms-2 hide-mobile custom-select" : "icon-nav-box hide-mobile disabled"}
                                                onClick={() => downloadPageLevelPDF('trafficContent', 'Insights_Traffic_Sources')}
                                            >
                                                <svg className="icon-nav">
                                                    <title>{t("header.Icon_Tooltip_DownloadPDF")}</title>
                                                    <use href={iconSprite + `#Download`} />
                                                </svg>
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col md={12}>
                        <Tab.Container defaultActiveKey="Visitors">
                            <Nav fill variant="pills" className="tab subtab ms-1 me-0">
                                <Nav.Item className="pe-0">
                                    <Nav.Link eventKey="Visitors" onClick={() => { setTabVisitorModal(1) }} className="mb-sm-3 mb-md-0">
                                        {t("header.tnfAllPagesPopupVisitors")}
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item className="pe-0">
                                    <Nav.Link eventKey="TrafficSource" onClick={() => { setTabVisitorModal(2) }} className="mb-sm-3 mb-md-0">
                                    {t("header.tnfAllPagesPopupTrafficSource")}
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>

                            <Tab.Content>
                                <Tab.Pane eventKey="Visitors" className="mt-4 visitorsContent" >
                                    <Row className="mt-4">
                                        <p>{t("header.TFPagesDetailsPopVisitorsChartTextPageViewsOf")} {rowDetails.filterybykey} {t("header.PagesDetailsPopVisitorsChartTextBetween")} {moment(StartDate).format("DD MMM YYYY")} {t("header.PagesDetailsPopVisitorsChartTextTo")} {moment(EndDate).format("DD MMM YYYY")}</p>
                                    </Row>

                                    {tabVisitorModal === 1 &&
                                        <>
                                            {/* visitors line chart */}
                                            <>
                                                {
                                                    allPagesPopupLinechart.transactionStatus === DataStatusMessage.DataAvailable &&
                                                    <Row>
                                                        <Col md={12}>
                                                            <DataChartRenderer
                                                                data={
                                                                    allPagesPopupLinechart.result
                                                                }
                                                                chartType="line" />
                                                        </Col>
                                                    </Row>
                                                }
                                                {
                                                    allPagesPopupLinechart.transactionStatus !== DataStatusMessage.DataAvailable &&
                                                    <Row>
                                                        <Col md={12}>
                                                            <CommonMessageComponent transactionStatus={allPagesPopupLinechart.transactionStatus}></CommonMessageComponent>
                                                        </Col>
                                                    </Row>
                                                }
                                            </>
                                            <Row className="mb-2 mt-6-mobile">
                                                <Col md={12}>
                                                    <div className="alert alert-gray-800 mb-0" role="alert">
                                                        <p className="mb-0 text-body fw-bold"><i className="fa fa-info-circle lg"></i> {t("header.PagesAllPagesDetailsPopVisitorsHelpText")}</p>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={12} className={localStorage.getItem("isDemoClient") ? "custom-disable text-end" : "text-end"}>
                                                    <div className="icon-nav-list svg-icon-nav-list float-end mb-2 mt-2">
                                                        <span className={allPagesPopupTable.transactionStatus === DataStatusMessage.DataAvailable ? "icon-nav-box ms-2 hide-mobile" : "icon-nav-box hide-mobile disabled"}>
                                                            <Dropdown as={ButtonGroup}>
                                                                <Dropdown.Toggle split className="card-dropdown-btn p-0 border-0 pdf-download-btn">
                                                                    <svg className="icon-nav">
                                                                        <title>{t("header.Icon_Tooltip_Download")}</title>
                                                                        <use href={iconSprite + `#Download`} />
                                                                    </svg>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {setDowloadDropdownMenu()}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>

                                            {/* visitors table */}
                                            <>
                                                {
                                                    allPagesPopupTable.transactionStatus === DataStatusMessage.DataAvailable &&
                                                    <Row>
                                                        <Col md={12}>
                                                            {
                                                                allPagesPopupTable.transactionStatus === DataStatusMessage.DataAvailable && <DataTableRenderer
                                                                    header={allPagesPopupTable.header}
                                                                    dataSet={allPagesPopupTable.data}
                                                                    transactionStatus={allPagesPopupTable.transactionStatus}
                                                                    customAttributes={{ displayRowsPerPage: 10, showPagination: true }}
                                                                ></DataTableRenderer>
                                                            }
                                                        </Col>
                                                    </Row>
                                                }
                                                {
                                                    allPagesPopupTable.transactionStatus !== DataStatusMessage.DataAvailable &&
                                                    <Row>
                                                        <Col md={12}>
                                                            <CommonMessageComponent transactionStatus={allPagesPopupTable.transactionStatus}></CommonMessageComponent>
                                                        </Col>
                                                    </Row>
                                                }
                                            </>
                                        </>
                                    }
                                </Tab.Pane>

                                <TabPane eventKey="TrafficSource" className="mt-4 trafficContent" >
                                    <Row className="mt-4">
                                        <Col md={12}>
                                            <p>{t("header.allPagesTrafficSourceByVisitor")} {rowDetails.filterybykey} {t("header.PagesDetailsPopVisitorsChartTextBetween")} {moment(StartDate).format("DD MMM YYYY")} {t("header.PagesDetailsPopTrafficChartTextTo")} {moment(EndDate).format("DD MMM YYYY")} </p>
                                        </Col>
                                    </Row>

                                    {tabVisitorModal === 2 &&
                                        <>
                                            {/* traffic source barchart */}
                                            <>
                                                {trafficSourceBarchart.transactionStatus === DataStatusMessage.DataAvailable &&
                                                    <Row>
                                                        <Col md={12}>
                                                            <DataChartRenderer
                                                                data={
                                                                    trafficSourceBarchart
                                                                }
                                                                barChartClickEvent={function (d) { setTrafficSource(d.name); getTrafficSource(d.name); }}
                                                                chartType="bar" />
                                                        </Col>
                                                    </Row>
                                                }
                                            </>

                                            <Row className="mb-0">
                                                <Col md={12}>
                                                    {trafficSourceText &&
                                                        <div className="alert alert-gray-800" role="alert">
                                                            <p className="mb-0 text-body fw-bold"><i className="fa fa-info-circle lg"></i> {trafficSourceText}</p>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>

                                            {(trafficSourceBarchart.transactionStatus !== DataStatusMessage.Loading && trafficSourceTable.transactionStatus !== DataStatusMessage.Loading) &&
                                                <Row className="mb-0 mt-0">
                                                    <Col md={12} >
                                                        <p className="text-body fw-bold">{trafficSourceTotal} </p>
                                                    </Col>
                                                </Row>
                                            }

                                            {/* traffic source table */}
                                            <>
                                                {trafficSourceTable.transactionStatus === DataStatusMessage.DataAvailable &&

                                                    <Row>
                                                        <Col md={12}>
                                                            {(trafficSource === "Organic" || trafficSource === "Referral" || trafficSource === "Social") &&
                                                                <DataTableRenderer
                                                                    header={trafficSourceTable.header}
                                                                    dataSet={trafficSourceTable.data}
                                                                    transactionStatus={trafficSourceTable.transactionStatus}
                                                                    customAttributes={{ displayRowsPerPage: 10, showPagination: true }}
                                                                ></DataTableRenderer>
                                                            }
                                                        </Col>
                                                    </Row>
                                                }
                                                {
                                                    trafficSourceTable.transactionStatus === DataStatusMessage.Loading &&
                                                    <Row>
                                                        <Col md={12}>
                                                            <CommonMessageComponent transactionStatus={trafficSourceTable.transactionStatus}></CommonMessageComponent>
                                                        </Col>
                                                    </Row>
                                                }
                                            </>
                                        </>
                                    }
                                </TabPane>
                            </Tab.Content>
                        </Tab.Container>
                    </Col>
                </Row>
            </ModalComponent>
        </>
    );
}

export default TnfAllPages;