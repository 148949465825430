import RGL, { WidthProvider } from 'react-grid-layout';
import React from "react";
import DashboardItem from "../components/DashboardItem";
import ChartRenderer from "../components/ChartRenderer";
import { isMobile } from "react-device-detect";
import ChartRendererTable from './ChartRendererTable';
import { baseURL } from '../apis/utilities/constant';
import ButtonsComponent from './Buttons';
import { useTranslation } from 'react-i18next';

const ReactGridLayout = WidthProvider(RGL);
const UserAppDigestComponent = (props) => {
  const [t, i18n] = useTranslation();
  const deserializeItem = (i) => ({
    ...i,
    layout: JSON.parse(i.layout) || {},
    //  summary: JSON.parse(i.summary) || {},
    //vizState: JSON.parse(i.vizState)
  });
  const defaultLayout = (i) => ({
    x: i.layout.x || 0,
    y: i.layout.y || 0,
    w: i.layout.w || 4,
    h: i.layout.h || 8,
    minW: 4,
    minH: 8,
  });

  const defaultLayoutMobile = (i) => ({
    x: i.layout.x || 0,
    y: i.layout.y || 0,
    w: 12,
    // w: i.layout.w || 4,
    h: i.layout.h + 2 || 12,
    minW: 4,
    minH: 8,
  });

  const dashboardItem = (item) => (
    <div
      key={item.id}
      //className='model-table'
      data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}
    >
      <DashboardItem key={item.id} itemId={item.id} title={item.name}>
        <ChartRendererTable
          showCount={false}
          onItemChange={props.onItemChange}
          apiUrl={item.apiUrl}
        />
        <div className="ps-3 text-end">
          <ButtonsComponent
            onClick={(e) => props.handleSubmitButtonClick(e)}
            isIcon={false}
            dissable={props.appTableData && props.appTableData.length > 0 ? false : true}
            isPrimary={true}
            btn_class="me-3"
            btnText={t("header.digestSubscriptionButtonSave")}
          />
        </div>

      </DashboardItem>
    </div>
  );

  return <>{props.userAppDigestData && props.userAppDigestData.length > 0 &&
    <ReactGridLayout cols={12} rowHeight={30} isDraggable={false} isResizable={false}>
      {props.userAppDigestData.map(deserializeItem).map(dashboardItem)}
    </ReactGridLayout>
    
  }</>;
};
export default UserAppDigestComponent;
