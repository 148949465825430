export const notificationListItems = (intellConfigId,userId,offset) => {
    
    let notificationList = [
        {
            id: "1",
            layout: "{\"x\":0,\"y\":1,\"w\":12,\"h\":12}",
            name: "Notifications",
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false}, \"query\":{\"measures\":[\"Notifications.Totalnotifications\"],\"timeDimensions\":[],\"order\":{},\"dimensions\":[],\"filters\":[{\"member\":\"Notifications.IntelConfigId\",\"operator\":\"equals\",\"values\":[\"${intellConfigId}"]},{\"member\":\"Notifications.UserId\",\"operator\":\"equals\",\"values\":[\"${userId}"]}]}}`,
            pagination:{show:true,query:`{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false}, \"query\":{\"measures\":[\"Notifications.Totalnotifications\"],\"timeDimensions\":[],\"order\":{},\"dimensions\":[],\"filters\":[{\"member\":\"Notifications.IntelConfigId\",\"operator\":\"equals\",\"values\":[\"${intellConfigId}"]},{\"member\":\"Notifications.UserId\",\"operator\":\"equals\",\"values\":[\"${userId}"]}]}}`},
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false}, \"query\":{\"measures\":[\"Videoanalytics.count\"],\"timeDimensions\":[],\"order\":{},\"dimensions\":[],\"filters\":[{\"member\":\"Videoanalytics.presentationid\",\"operator\":\"contains\",\"values\":[\"${PresentationId}"]}]}}`,
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Notifications.VisitorandCountry\",\"Notifications.Active\",\"Notifications.Ismailsent\",\"Notifications.Edit\",\"Notifications.History\"],\"y\":[],\"fillMissingDates\":true,\"joinDateRange\":false, \"limit\": 10},\"query\":{\"measures\":[],\"timeDimensions\":[],\"order\":{\"Notifications.Active\":\"desc\",\"Notifications.Createdt\":\"desc\"},\"filters\":[{\"member\":\"Notifications.IntelConfigId\",\"operator\":\"equals\",\"values\":[\"${intellConfigId}"]},{\"member\":\"Notifications.UserId\",\"operator\":\"equals\",\"values\":[\"${userId}"]}],\"limit\": 15,\"offset\": ${offset},\"dimensions\":[\"Notifications.VisitorandCountry\",\"Notifications.Active\",\"Notifications.Ismailsent\",\"Notifications.Edit\",\"Notifications.History\"]}}`,
            enableOption: true
        }
        
        
        
    ];

    return notificationList;

};
export const notificationWithVisitorListItems = (intellConfigId,userId,VisitorId,offset) => {
    
    let notificationList = [
        {
            id: "1",
            layout: "{\"x\":0,\"y\":1,\"w\":12,\"h\":12}",
            name: "Notifications",
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false}, \"query\":{\"measures\":[\"Notifications.Totalnotifications\"],\"timeDimensions\":[],\"order\":{},\"dimensions\":[],\"filters\":[{\"member\":\"Notifications.IntelConfigId\",\"operator\":\"equals\",\"values\":[\"${intellConfigId}"]},{\"member\":\"Notifications.UserId\",\"operator\":\"equals\",\"values\":[\"${userId}"]},{\"member\":\"Notifications.Aicompanyid\",\"operator\":\"equals\",\"values\":[\"${VisitorId}"]}]}}`,
            pagination:{show:true,query:`{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false}, \"query\":{\"measures\":[\"Notifications.Totalnotifications\"],\"timeDimensions\":[],\"order\":{},\"dimensions\":[],\"filters\":[{\"member\":\"Notifications.IntelConfigId\",\"operator\":\"equals\",\"values\":[\"${intellConfigId}"]},{\"member\":\"Notifications.UserId\",\"operator\":\"equals\",\"values\":[\"${userId}"]},{\"member\":\"Notifications.Aicompanyid\",\"operator\":\"equals\",\"values\":[\"${VisitorId}"]}]}}`},
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false}, \"query\":{\"measures\":[\"Videoanalytics.count\"],\"timeDimensions\":[],\"order\":{},\"dimensions\":[],\"filters\":[{\"member\":\"Videoanalytics.presentationid\",\"operator\":\"contains\",\"values\":[\"${PresentationId}"]}]}}`,
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Notifications.VisitorandCountry\",\"Notifications.Active\",\"Notifications.Ismailsent\",\"Notifications.Edit\",\"Notifications.History\"],\"y\":[],\"fillMissingDates\":true,\"joinDateRange\":false, \"limit\": 10},\"query\":{\"measures\":[],\"timeDimensions\":[],\"order\":{\"Notifications.Active\":\"desc\",\"Notifications.Createdt\":\"desc\"},\"filters\":[{\"member\":\"Notifications.IntelConfigId\",\"operator\":\"equals\",\"values\":[\"${intellConfigId}"]},{\"member\":\"Notifications.UserId\",\"operator\":\"equals\",\"values\":[\"${userId}"]},{\"member\":\"Notifications.Aicompanyid\",\"operator\":\"equals\",\"values\":[\"${VisitorId}"]}],\"limit\": 15,\"offset\": ${offset},\"dimensions\":[\"Notifications.VisitorandCountry\",\"Notifications.Active\",\"Notifications.Ismailsent\",\"Notifications.Edit\",\"Notifications.History\"]}}`,
            enableOption: true
        }
        
        
        
    ];

    return notificationList;

};
export const visitorandcountry = (Airuleid) => {
    
    let VisitorandCountryDetails = `{\"measures\":[],\"timeDimensions\":[],\"order\":{\"Notifications.Description\":\"desc\",\"Notifications.Createdt\":\"desc\"},\"filters\":[{\"member\":\"Notifications.Airuleid\",\"operator\":\"equals\",\"values\":[\"${Airuleid}"]}],\"limit\": 15,\"offset\": 0 ,\"dimensions\":[\"Notifications.Description\",\"Notifications.Pageviews\",\"Notifications.Emailalert\",\"Notifications.Smsalert\",\"Notifications.Durationid\"]}`;
        
    return VisitorandCountryDetails;

};

export const notificationHistoryItems = (Airuleid,countryId,offset) => {
    
    let notificationHistoryList = [
        {
            id: "1",
            layout: "{\"x\":0,\"y\":1,\"w\":10,\"h\":12}",
            name: "notificationhistory",
            summary: "{}",
            classname: "col-full-width",
           // pagination:{show:true,query:`{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false}, \"query\":{\"measures\":[\"NotificationsHistory.Totalnotifications\"],\"timeDimensions\":[],\"order\":{},\"dimensions\":[],\"filters\":[{\"member\":\"NotificationsHistory.Airuleid\",\"operator\":\"equals\",\"values\":[\"${Airuleid}"]}]}}`},
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false}, \"query\":{\"measures\":[\"Videoanalytics.count\"],\"timeDimensions\":[],\"order\":{},\"dimensions\":[],\"filters\":[{\"member\":\"Videoanalytics.presentationid\",\"operator\":\"contains\",\"values\":[\"${PresentationId}"]}]}}`,
            vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"NotificationsHistory.Updatedt\",\"NotificationsHistory.description\",\"NotificationsHistory.Activehistory\",\"NotificationsHistory.Systemdisabled\",\"NotificationsHistory.Durationdescription\",\"NotificationsHistory.PageViews\"],\"y\":[],\"fillMissingDates\":true,\"joinDateRange\":false, \"limit\": 10},\"query\":{\"measures\":[],\"timeDimensions\":[],\"order\":{\"NotificationsHistory.Updatedt\":\"desc\"},\"filters\":[{\"member\":\"NotificationsHistory.Airuleid\",\"operator\":\"equals\",\"values\":[\"${Airuleid}"]}],\"limit\": 15,\"offset\": 0 ,\"dimensions\":[\"NotificationsHistory.Updatedt\",\"NotificationsHistory.description\",\"NotificationsHistory.Activehistory\",\"NotificationsHistory.Systemdisabled\",\"NotificationsHistory.Durationdescription\",\"NotificationsHistory.PageViews\"]}}`,
            enableOption: true
        }
        
        
        
    ];

    return notificationHistoryList;

};
