import React, { useContext, useEffect, useMemo, useState } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Image, Row } from '@themesberg/react-bootstrap';
import moment from "moment-timezone";
import { isMobile } from "react-device-detect";
import { useTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import { object_equals } from "../AppConfig";
import request from '../apis/request';
import { METHODS, baseURL } from '../apis/utilities/constant';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import AppContext from '../components/AppContext';
import CommonHeader from '../components/CommonHeader';
import { decryptData } from '../utils/crypto';
import { getSearchPage } from '../stubs/ComponentJson/search';
import { defaultLayout, defaultLayoutMobile } from "../utils/layoutConfig";
import DashboardItem from '../components/DashboardItem';
import ChartRendererTable from '../components/ChartRendererTable';
import Search from "../components/Search";

const ReactGridLayout = WidthProvider(RGL);

const SearchPageComponent = (props) => {

  const contextData = useContext(AppContext);
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  // const [selectedDatePicker, setSelectedDatePicker] = useState({});
  // const [companyIdValue, setcompanyIdValue] = useState("");
  const [t, i18n] = useTranslation();
  // const [updateKey, setUpdateKey] = useState(0)

  const [searchData, setSearchData] = useState([]);
  // const [searchResult, setSearchResult] = useState({})
  // const [searchResultBackup, setSearchResultBackup] = useState({});
  const [searchValue, setSearchValue] = useState("");



  const deserializeItem = (i) => ({
    ...i,
    layout: JSON.parse(i.layout) || {}
  });
  useEffect(() => {


    if (contextData.selectedProfile["WebsiteProfiles.ProfileId"]) {

      const selectedstartdate = moment(contextData.startDate).format("YYYY-MM-DD");
      const selectedenddate = moment(contextData.endDate).format("YYYY-MM-DD");
      setSearchData(getSearchPage(contextData.selectedProfile["WebsiteProfiles.ProfileId"], selectedstartdate, selectedenddate));
    }
    else {
      setSearchData(null);
    }

  }, [props.profileID, props.startDate, props.endDate]);

  useEffect(() => {

    if (searchData && searchData[0] !== undefined) {
      setTimeout(() => { getSearchResult(searchData[0].apiUrl) }, 500)
    }

  }, [searchData])

  const getSearchResult = (apiUrl) => {
    //setSearchResult({});
    localStorage.setItem("pageNo", 1)
    if (apiUrl !== undefined) {
      request(apiUrl.url,
        METHODS.GET,
        {},
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        })
        .then((res) => {
          let response = res;
          response.headers[0].shortTitle = "Search term";
          response.headers[1].shortTitle = "Number of searches";
          //setSearchResult(response)
          //setSearchResultBackup(response)
        })
        .catch((err) => {
        })
    }

  }


  const dashboardItem = (item) => (
    <div className="page-analytics-switch-toggle" key={item.id} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
      <DashboardItem
        key={item.id}
        itemId={item.id}
        title={item.name}
        enableOption={item.enableOption}>
        <Row className="mb-4">
          <Col className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
            <div className="d-inline-block float-end page-search-box">
              <Search
                searchWaterMark={t("header.searchPlaceHolder")}
                key={item.name + "search"}
                setsearchData={setSearchValue}
                searchData={searchValue}
                type={'client'}
                isAutoComplete={false}></Search>
            </div>

          </Col>
        </Row>
        <Row>
          <Col>

            <ChartRendererTable
              key={item.id + "table"}
              //pageNo={localStorage.getItem("pageNo") != undefined ? parseInt(localStorage.getItem("pageNo")) : 1}
              apiUrl={searchData[0].apiUrl}
              showPagination={true}
              //resultSet={searchResult}
              showNumberOfRows={15}
              showCount={false}
              SearchText={searchValue}
              setSearchText={setSearchValue}
            />

          </Col>


        </Row>
      </DashboardItem>

    </div>
  );

  return (
    <>

      <Row>

        <Col lg={12} id="searchContent">
          {/* <div className="d-inline-block pe-0 page-performance-detail"> */}
          {/* <div className="shadow-soft border rounded border-light p-3 ps-2 pe-2 w-100 nested-card-container "> */}
          <ReactGridLayout cols={12} rowHeight={50}
            isDraggable={false} isResizable={false}
          >
            {searchData && searchData.map(deserializeItem).map(dashboardItem)}
            {/* {children} */}
          </ReactGridLayout>
          {/* </div> */}
          {/* </div> */}
        </Col>

      </Row>

    </>
  );
};

export default withRouter(SearchPageComponent);
