import React, { useState, useEffect, useContext, useRef } from 'react';
import IdleTimer from 'react-idle-timer';
import { withRouter } from 'react-router-dom';
import { Col, Row, Card, Form, Image, InputGroup, FormCheck, Button, Modal } from '@themesberg/react-bootstrap';
import ButtonComponent from "./Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faPlus, faClock } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';
import CountdownTimer from './CountdownTimer';
import { AUTO_LOGOUT_KEY, ForcedLogoutTokenExpireMinitues, IntimationBeforeForcedLogoutMinitues, SessionTimeoutPopupTime,SessionTimeoutWarningTime,headerTitle } from "../utils/common";
import { Helmet } from 'react-helmet'
import { ReactComponent as TimeSvg } from '../assets/Icon/time.svg';
import AppContext from '../components/AppContext';

const IdleTimerContainer = (props) => {
    const contextData = useContext(AppContext);
    const idleTimerRef = useRef(null);
    const sessionTimeOutRef = useRef(null);
    const [modelIsOpen, setModelIsOpen] = useState(false);
    const [sessionmodelIsOpen, setSessionmodelIsOpen] = useState(false);
    const [t, i18n] = useTranslation();
    const [finalCountDownStart, setFinalCountDownStart] = useState(false);

    let intervalId = undefined;
    useEffect(() => {
        
        //checkSession()
        intervalId = setInterval(() => checkSession(), 10000);

        return () => {
          
        } 
    }, [])

    const checkSession = () => {
        var startTime = new Date(localStorage.getItem("sessionTime")); 
        
        if(startTime) {
            var endTime = new Date();
            var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
            var resultInMinutes = Math.round(difference / 60000);
            var resultSec = Math.round((difference / 60000) / 1000);
            
            let isIntimationGiven = localStorage.getItem(AUTO_LOGOUT_KEY);
            isIntimationGiven = isIntimationGiven ? isIntimationGiven : false;
            
            if(resultInMinutes > ForcedLogoutTokenExpireMinitues) {
                setModelIsOpen(false);
                setSessionmodelIsOpen(true);
                clearTimeout(sessionTimeOutRef.current);
                localStorage.clear();
                sessionStorage.clear();
                clearInterval(intervalId);
            }
            else if(!isIntimationGiven && resultInMinutes > (ForcedLogoutTokenExpireMinitues - IntimationBeforeForcedLogoutMinitues)) {
                localStorage.setItem(AUTO_LOGOUT_KEY, true);
                setFinalCountDownStart(true);
                setModelIsOpen(true);
            }
        }
    }

    const onIdle = () => {
        if (!sessionmodelIsOpen) {
            setModelIsOpen(true);
            sessionTimeOutRef.current = setTimeout(LogoutandShowModel, SessionTimeoutPopupTime);
        }
    }
    const stayActive = () => {
        setModelIsOpen(false);
        idleTimerRef.current = null;
        clearTimeout(sessionTimeOutRef.current);
    }
    const Logout = () => {
        contextData.changeUerId(0);
        contextData.changeCompanyId(0);
        setModelIsOpen(false);
        localStorage.clear();
        sessionStorage.clear();
        clearTimeout(sessionTimeOutRef.current);
        props.history.push("/login");
        window.location.reload();
    }
    const LogoutandShowModel = () => {
        setModelIsOpen(false);
        setSessionmodelIsOpen(true);
        clearTimeout(sessionTimeOutRef.current);
        localStorage.clear();
        sessionStorage.clear();
    }
    return (
        <div>
             <Helmet>
                <title>{headerTitle}</title>
            </Helmet>
            <Modal as={Modal.Dialog} size="lg" centered show={modelIsOpen} className="modal-session-timeout">
                <Modal.Header className="pt-0">
                    <Modal.Title>{t("header.LoginSessionOutMessage")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!finalCountDownStart ? <>
                        <p className="mb-4">{t("header.USTimeOutMessage1")}</p>
                        <p className="session-time">
                            {/* <FontAwesomeIcon icon={faClock} className="me-3" /> */}
                            <span className="session-time-icon me-3">
                                <TimeSvg />
                            </span>
                            <span><CountdownTimer></CountdownTimer></span> 
                        </p>
                        <p className="note-msg">{t("header.USTimeOutMessage2")} {t("header.USTimeOutMessage3")}</p>
                    </> : <><p className="mb-4">{t("header.USTimeOutintimationMessage")}</p></>}
                </Modal.Body>
                <Modal.Footer className="pb-0">
                    <ButtonComponent
                        isIcon={false}
                        isPrimary={true}
                        btnText={t("header.USTimeOutButtonContinue")}
                        onClick={stayActive}
                        btn_class="me-3" />
                    <ButtonComponent
                        isIcon={false}
                        isPrimary={false}
                        btnText={t("header.USTimeOutButtonLogOut")}
                        onClick={Logout}
                        btn_class="mb-0" />
                </Modal.Footer>
            </Modal>
            <Modal as={Modal.Dialog} size="md" centered show={sessionmodelIsOpen} className="modal-session-timeout session-expired" >
                <Modal.Header className="pt-0">
                    <Modal.Title>{t("header.USExpireHeader")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="session-time d-inline-block mb-0">
                        <span className="session-time-icon me-3 session-expired-icon">
                            <TimeSvg />
                        </span>
                        <span className="session-time-txt body-text mt-3">{t("header.USExpireMessage1")}<br />{t("header.USExpireMessage2")}</span>
                    </p>
                </Modal.Body>
                <Modal.Footer className="pb-0">
                    <ButtonComponent
                        isIcon={false}
                        isPrimary={true}
                        btnText={t("header.USExpireButtonOK")}
                        onClick={Logout}
                        btn_class="me-3 fmw-100" />
                </Modal.Footer>
            </Modal>
            <IdleTimer ref={idleTimerRef} timeout={SessionTimeoutWarningTime} onIdle={onIdle}></IdleTimer>
        </div>
    )
}

export default withRouter(IdleTimerContainer);