import { getCardDataFromGA, getCardDataFromGAMap } from "../../apis/services/websitePageService";
import { COMPONENT } from "../../apis/utilities/constant";

export const apiChartsUserAnalytucs = [
    {
        id: 1,
        service: (cryptoKey, payload, callBackFn) => { getCardDataFromGA(cryptoKey, "users", payload, callBackFn, COMPONENT.USERS, 0) },
    },
    // {
    //     id: 2,
    //     service: (payload, callBackFn) => { getCardDataFromGAMap("usersmap",payload, callBackFn) },
    // }
]
export const apiChartsUserAnalytucsMap = [
    // {
    //     id: 1,
    //     service: (payload, callBackFn) => { getCardDataFromGA("users",payload, callBackFn) },
    // },
    {
        id: 2,
        service: (cryptoKey, payload, callBackFn) => { getCardDataFromGAMap(cryptoKey, "usersmap", payload, callBackFn, COMPONENT.COUNTRY) },
    }
]

export const getUserAnalyticsData = (profileId, startDate, endDate, culture) => {
    let userAnalyticsData = [
        // original
        {
            isSummeryShown: false,
            chartType: "line",
            isResultShownFromApi: true,
            id: "1",
            layout: "{\"x\":0,\"y\":25,\"w\":12,\"h\":26.3}",
            layoutone: "{\"x\":0,\"y\":24,\"w\":12,\"h\":7}",
            layouttwo: "{\"x\":0,\"y\":24,\"w\":12,\"h\":7}",
            layoutthree: "{\"x\":0,\"y\":24,\"w\":12,\"h\":7}",
            name: "User",
            nametwoo: "Users by country",
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsusersdata.users\"],\"filters\":[{\"member\": \"Googleanalyticsusersdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsusersdata.gadate\", \"compareDateRange\": [[\"${previousYearStartDate}\",\"${previousYearEndDate}\"], [\"${startDate}\",\"${endDate}\"]]}],\"order\":{},\"dimensions\":[]}}`,
            //vizState: `{\"chartType\":\"line\",\"pivotConfig\":{\"x\":[\"Googleanalyticsusersdata.gadate\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsusersdata.users\"],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsusersdata.gadate\",\"granularity\":\"month\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Googleanalyticsusersdata.Monthid\":\"asc\"},\"filters\":[{\"member\": \"Googleanalyticsusersdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}],\"dimensions\":[]}}`,
            vizStateSecond: `{\"chartType\":\"map\",\"pivotConfig\":{\"x\":[\"Googleanalyticsdata.Code\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsdata.users\"],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Googleanalyticsdata.Monthid\":\"asc\"},\"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}],\"dimensions\":[\"Googleanalyticsdata.Code\"]}, \"tableCaption\":\"Users by country\"}`,
            paginationThird: { show: true, query: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[\"Googleanalyticsdata.countrycount\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"dimensions\":[],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":[],\"measures\":[\"Googleanalyticsdata.countrycount\"],\"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\",\"values\": [\"${profileId}\"]}]}}` },
            //vizStateThird: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Googleanalyticsusersdata.country\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false,\"limit\": 15},\"query\":{\"dimensions\":[\"Googleanalyticsusersdata.country\"],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsusersdata.gadate\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":[[\"Googleanalyticsusersdata.users\",\"desc\"]],\"measures\":[\"Googleanalyticsusersdata.users\"],\"filters\":[{\"member\": \"Googleanalyticsusersdata.profileid\", \"operator\": \"equals\",\"values\": [\"${profileId}\"]}],\"limit\": 15, \"offset\": ${offset}}}`,
            legends: [],
            enableOption: false,
            apiUrl: {url:`${process.env.REACT_APP_API_KEY_NEW}/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=${startDate}&EndDate=${endDate}&profileID=${profileId}&Matrix=users&Dimensions=country&Component=${COMPONENT.COUNTRY}&culture=${culture}`,type:"get",count:""},
         },
        //  {
        //     isSummeryShown: true,
        //     chartType: "map",
        //     isResultShownFromApi: true, 
        //     id: "2",
        //     layout: "{\"x\":0,\"y\":25,\"w\":12,\"h\":26.3}",
        //     layoutone: "{\"x\":0,\"y\":24,\"w\":12,\"h\":7}",
        //     layouttwo: "{\"x\":0,\"y\":24,\"w\":12,\"h\":7}",
        //     layoutthree: "{\"x\":0,\"y\":24,\"w\":12,\"h\":7}",
        //     name: "User",
        //     nametwoo: "Users by country",
        //     //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsusersdata.users\"],\"filters\":[{\"member\": \"Googleanalyticsusersdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsusersdata.gadate\", \"compareDateRange\": [[\"${previousYearStartDate}\",\"${previousYearEndDate}\"], [\"${startDate}\",\"${endDate}\"]]}],\"order\":{},\"dimensions\":[]}}`,
        //     //vizState: `{\"chartType\":\"line\",\"pivotConfig\":{\"x\":[\"Googleanalyticsusersdata.gadate\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsusersdata.users\"],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsusersdata.gadate\",\"granularity\":\"month\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Googleanalyticsusersdata.Monthid\":\"asc\"},\"filters\":[{\"member\": \"Googleanalyticsusersdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}],\"dimensions\":[]}}`,
        //     vizStateSecond: `{\"chartType\":\"map\",\"pivotConfig\":{\"x\":[\"Googleanalyticsdata.Code\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsdata.users\"],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Googleanalyticsdata.Monthid\":\"asc\"},\"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}],\"dimensions\":[\"Googleanalyticsdata.Code\"]}, \"tableCaption\":\"Users by country\"}`, 
        //     paginationThird :{show:true,query:`{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[\"Googleanalyticsdata.countrycount\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"dimensions\":[],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":[],\"measures\":[\"Googleanalyticsdata.countrycount\"],\"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\",\"values\": [\"${profileId}\"]}]}}`},
        //     vizStateThird:  `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Googleanalyticsusersdata.country\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false,\"limit\": 15},\"query\":{\"dimensions\":[\"Googleanalyticsusersdata.country\"],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsusersdata.gadate\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":[[\"Googleanalyticsusersdata.users\",\"desc\"]],\"measures\":[\"Googleanalyticsusersdata.users\"],\"filters\":[{\"member\": \"Googleanalyticsusersdata.profileid\", \"operator\": \"equals\",\"values\": [\"${profileId}\"]}],\"limit\": 15, \"offset\": ${offset}}}`,  
        //     legends: [],
        //     enableOption: false,
        //     apiUrl: {url:`${process.env.REACT_APP_API_KEY_NEW}/Intelligence/GetCardDataFromGA?StartDate=${startDate}&EndDate=${endDate}&profileID=${profileId}&Matrix=users&Dimensions=country&Component=table,user`,type:"get",count:""},
        //  },
        // // dummy
        // { 
        //     id: "1",
        //     layout: "{\"x\":0,\"y\":25,\"w\":12,\"h\":26.3}",
        //     layoutone: "{\"x\":0,\"y\":24,\"w\":12,\"h\":7}",
        //     layouttwo: "{\"x\":0,\"y\":24,\"w\":12,\"h\":7}",
        //     layoutthree: "{\"x\":0,\"y\":24,\"w\":12,\"h\":7}",
        //     name: "User",
        //     nametwoo: "Users by country",
        //     summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsusersdata.users\"],\"filters\":[{\"member\": \"Googleanalyticsusersdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsusersdata.gadate\", \"compareDateRange\": [[\"${previousYearStartDate}\",\"${previousYearEndDate}\"], [\"${startDate}\",\"${endDate}\"]]}],\"order\":{},\"dimensions\":[]}}`,
        //     vizState: `{\"chartType\":\"line\",\"pivotConfig\":{\"x\":[\"Googleanalyticsusersdata.gadate\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsdata.users\"],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\",\"granularity\":\"month\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Googleanalyticsdata.Monthid\":\"asc\"},\"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}],\"dimensions\":[]}}`,
        //     vizStateSecond: `{\"chartType\":\"map\",\"pivotConfig\":{\"x\":[\"Googleanalyticsdata.Code\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"dimensions\": [\"Orders.status\"],\"timeDimensions\": [],\"order\": {\"Orders.totalAmount\": \"desc\"},\"filters\": [{\"member\": \"Orders.status\",\"operator\": \"contains\",\"values\": [\"new\"]}],\"measures\": [\"Orders.totalAmount\"]}}`, 
        //     paginationThird :{show:true,query:`{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[\"Googleanalyticsdata.countrycount\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"dimensions\":[],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":[],\"measures\":[\"Googleanalyticsdata.countrycount\"],\"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\",\"values\": [\"${profileId}\"]}]}}`},
        //     vizStateThird:  `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Googleanalyticsusersdata.country\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false,\"limit\": 15},\"query\":{\"dimensions\": [\"Orders.status\"],\"timeDimensions\": [],\"order\": {\"Orders.status\": \"asc\"},\"filters\": [{\"member\": \"Orders.status\",\"operator\": \"equals\",\"values\": [\"1\"]}]}}`,  
        //     legends: [],
        //     enableOption: false
        // },
    ];
    return userAnalyticsData;
};
